import { PaletteMode, Theme } from '@mui/material';
import { ENVIRONMENT } from '@/constants/environment';
import envVariables from '@/envVariables';
import { THEME_MODE } from './constants';
import { darkTheme, lightTheme } from './monitoringTool.theme';

export const getMediaPrefersDarkTheme = () => {
  return window.matchMedia('(prefers-color-scheme: dark)');
};

export const getPreferredTheme = (matchMedia: MediaQueryList): PaletteMode => {
  if (!matchMedia) return THEME_MODE.LIGHT;
  return matchMedia.matches ? THEME_MODE.DARK : THEME_MODE.LIGHT;
};

export const getEnvColor = (theme: Theme, env: ENVIRONMENT) => {
  return theme.palette.env[env] || 'transparent';
};

export const getAppTheme = (mode: PaletteMode) =>
  mode === THEME_MODE.LIGHT ? lightTheme : darkTheme;

export const getEnvBorder = (theme: Theme) => {
  const env = envVariables.env;
  const { DEV, STAGING, SANDBOX } = ENVIRONMENT;
  if ([DEV, STAGING, SANDBOX].includes(env)) {
    return `${theme.spacing(0.5)} solid ${getEnvColor(theme, env)}`;
  }
  return 'none';
};
