import useSWR from 'swr';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { ApiError } from '@/service/ApiError';
import { API_METHOD, HTTP_STATUS_CODE } from '@/service/constants';
import {
  StoredPreferences,
  PREFERENCE_TYPE,
} from '@/contexts/preferences/types';
import {
  fetchFromUserPreferencesService,
  USER_PREFERENCES_ENDPOINT,
} from '@/service/fetcher/userPreferencesService';
import { makeUrlWithParams } from '@/service/fetcher/monitoringToolService';
import envVariables from '@/envVariables';

const PREFERENCES_KEY = 'preferences';

type UseFilterTemplatesProps = {
  token: string | undefined;
};

export const usePreferencesApi = ({ token }: UseFilterTemplatesProps) => {
  const { data, error, mutate } = useSWR<
    { key: string; value: string },
    ApiError
  >(
    token
      ? {
          token,
          endpoint: `${USER_PREFERENCES_ENDPOINT.Preferences}/${PREFERENCES_KEY}`,
        }
      : null,
    fetchFromUserPreferencesService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  const updatePreferences = async (
    preferences: StoredPreferences,
    apiMethod: API_METHOD.POST | API_METHOD.DELETE,
    showSnackbarWithText?: PREFERENCE_TYPE,
  ) => {
    const url = makeUrlWithParams(
      envVariables.userPreferencesApi + USER_PREFERENCES_ENDPOINT.Preferences,
    );

    const res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: API_METHOD.POST,
      body: JSON.stringify({
        key: PREFERENCES_KEY,
        value: JSON.stringify(preferences),
      }),
    });

    if (!res.ok && res.status !== 210) {
      const error = await ApiError.parseFromResponse(res);
      return enqueueSnackbar(error.title, { variant: 'error' });
    }

    mutate();

    return (
      showSnackbarWithText &&
      enqueueSnackbar(
        apiMethod === API_METHOD.POST
          ? `${showSnackbarWithText} successfully saved`
          : `${showSnackbarWithText} successfully removed`,
        { variant: 'success' },
      )
    );
  };

  const parsedPreferences = useMemo(() => {
    if (data?.value) {
      try {
        return JSON.parse(data.value);
      } catch {
        return undefined;
      }
    }
    return undefined;
  }, [data]);

  return {
    error,
    isLoading: !data && !error,
    data: parsedPreferences,
    updatePreferences,
  };
};
