import { useCallback, useContext } from 'react';
import { FixtureActionComment } from '@/service/types';
import {
  SCORING_WORKER_HOST_ACTION,
  ActionCommentAddMsg,
  ActionCommentUpdateMsg,
  ActionCommentRemoveMsg,
} from '@/workers/scoring/types';
import { ScoringContext } from '@/contexts/scoring/context';

export type CommentActionType = {
  commentActionType: 'add' | 'update' | 'remove';
  comment: FixtureActionComment;
};

export const useCommentAction = () => {
  const { useDispatchWithResponse } = useContext(ScoringContext);

  const addCommentAction = useDispatchWithResponse<ActionCommentAddMsg>(
    SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_ADD
  );
  const updateCommentAction = useDispatchWithResponse<ActionCommentUpdateMsg>(
    SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_UPDATE
  );

  const removeCommentAction = useDispatchWithResponse<ActionCommentRemoveMsg>(
    SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_REMOVE
  );

  const commentAction = useCallback(
    ({ commentActionType, comment }: CommentActionType) => {
      if (commentActionType === 'add') {
        addCommentAction.dispatch(
          {
            fixtureId: comment.fixtureId,
            fixtureActionId: comment.fixtureActionId || '',
            comment: comment.comment || '',
          },
          { successMessage: 'Comment successfully added' }
        );
      }

      if (commentActionType === 'update') {
        if (comment.id) {
          updateCommentAction.dispatch(
            {
              fixtureId: comment.fixtureId,
              id: comment.id,
              comment: comment.comment || '',
            },
            {
              successMessage: 'Comment successfully updated',
            }
          );
        }
      }

      if (commentActionType === 'remove') {
        if (comment.id) {
          removeCommentAction.dispatch(
            {
              fixtureId: comment.fixtureId,
              id: comment.id,
            },
            {
              successMessage: 'Comment successfully removed',
            }
          );
        }
      }
    },
    [addCommentAction, updateCommentAction, removeCommentAction]
  );

  return {
    commentAction,
  };
};
