import { ENVIRONMENT } from '@/constants/environment';

class EnvVariables {
  private readonly _env: ENVIRONMENT;
  private readonly _mtApiUrl: string;
  private readonly _opsUiUrl: string;
  private readonly _googleMapsKey: string;
  private readonly _oktaIssuer: string;
  private readonly _oktaClientId: string;
  private readonly _userPreferencesApi: string;
  private readonly _datadogClientToken: string;
  private readonly _datadogSite: string;
  private readonly _datadogServiceName: string;

  constructor() {
    const {
      MODE,
      VITE_MT_API_URL,
      VITE_OPS_UI_URL,
      VITE_GOOGLE_MAPS_KEY,
      VITE_OKTA_ISSUER,
      VITE_OKTA_CLIENT_ID,
      VITE_USER_PREFERENCES_API,
      VITE_DATADOG_CLIENT_TOKEN,
      VITE_DATADOG_SITE,
      VITE_DATADOG_SERVICE,
    } = import.meta.env;

    if (!MODE) {
      throw new Error('There is missing env variable: MODE');
    }
    if (!VITE_MT_API_URL) {
      throw new Error('There is missing env variable: VITE_MT_API_URL');
    }
    if (!VITE_OPS_UI_URL) {
      throw new Error('There is missing env variable: VITE_OPS_UI_URL');
    }
    if (!VITE_GOOGLE_MAPS_KEY) {
      throw new Error('There is missing env variable: VITE_GOOGLE_MAPS_KEY');
    }
    if (!VITE_OKTA_ISSUER) {
      throw new Error('There is missing env variable: VITE_OKTA_ISSUER');
    }
    if (!VITE_OKTA_CLIENT_ID) {
      throw new Error('There is missing env variable: VITE_OKTA_CLIENT_ID');
    }
    if (!VITE_USER_PREFERENCES_API) {
      throw new Error(
        'There is missing env variable: VITE_USER_PREFERENCES_API',
      );
    }
    if (!VITE_DATADOG_CLIENT_TOKEN) {
      throw new Error(
        'There is missing env variable: VITE_DATADOG_CLIENT_TOKEN',
      );
    }
    if (!VITE_DATADOG_SITE) {
      throw new Error('There is missing env variable: VITE_DATADOG_SITE');
    }
    if (!VITE_DATADOG_SERVICE) {
      throw new Error('There is missing env variable: VITE_DATADOG_SERVICE');
    }

    this._env = MODE as ENVIRONMENT;
    this._mtApiUrl = VITE_MT_API_URL;
    this._opsUiUrl = VITE_OPS_UI_URL;
    this._googleMapsKey = VITE_GOOGLE_MAPS_KEY;
    this._oktaIssuer = VITE_OKTA_ISSUER;
    this._oktaClientId = VITE_OKTA_CLIENT_ID;
    this._userPreferencesApi = VITE_USER_PREFERENCES_API;
    this._datadogClientToken = VITE_DATADOG_CLIENT_TOKEN;
    this._datadogSite = VITE_DATADOG_SITE;
    this._datadogServiceName = VITE_DATADOG_SERVICE;
  }

  get env() {
    return this._env;
  }
  get mtApiUrl() {
    return this._mtApiUrl;
  }
  get opsUiUrl() {
    return this._opsUiUrl;
  }
  get googleMapsKey() {
    return this._googleMapsKey;
  }
  get oktaIssuer() {
    return this._oktaIssuer;
  }
  get oktaClientId() {
    return this._oktaClientId;
  }
  get userPreferencesApi() {
    return this._userPreferencesApi;
  }
  get datadogClientToken() {
    return this._datadogClientToken;
  }
  get datadogSite() {
    return this._datadogSite;
  }
  get datadogServiceName() {
    return this._datadogServiceName;
  }
}

// eslint-disable-next-line import/no-default-export,import/no-anonymous-default-export
export default new EnvVariables();
