import useSWR from 'swr/immutable';
import { enqueueSnackbar } from 'notistack';
import { UserRolePermission } from '../types';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';

interface UseUserRolesParams {
  token: string | undefined;
}
export const useUserRoles = ({ token }: UseUserRolesParams) => {
  const { data, error, mutate } = useSWR<UserRolePermission, ApiError>(
    token
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.UserAuthorization.myPermissions.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
    }
  );

  return {
    data: data?.permissions,
    roleName: data?.roleName,
    userId: data?.userId,
    error,
    isLoading: !data && !error,
    mutate,
  };
};
