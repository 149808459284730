import { Slider, SliderProps, Typography } from '@mui/material';
import { FC } from 'react';

export type VolumeSliderProps = SliderProps & {
  value: number;
};
export const VolumeSlider: FC<VolumeSliderProps> = (props) => {
  const volumePercentage = (props.value * 100).toFixed(0) + '%';
  return (
    <>
      <Slider max={1} min={0} step={0.01} {...props} />
      <Typography variant='caption' minWidth='5ch' textAlign='center'>
        {volumePercentage}
      </Typography>
    </>
  );
};
