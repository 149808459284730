import React, { FC, PropsWithChildren, useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import { ScoringContext } from '@/contexts/scoring/context';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useStreaming } from '@/service/hooks/useStreaming';
import { checkIsStreamedSport } from '@/contexts/streaming/utils';
import { WITHIN_TRIGGER_SEC_TIME } from '@/components/FixtureTabs/FixtureStreaming/constants';
import { useTimer } from '@/utils/hooks/useTimer';
import { StreamingContext } from './context';
import { Streaming } from './types';

export const StreamingProvider: FC<PropsWithChildren> = ({ children }) => {
  const token = useAuthToken();
  const {
    state: { fixtureId },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const isStreamSport = checkIsStreamedSport(fixtureConfig);

  const { liveStreamUrl, archivedStreamUrl, streamStartDateTime } =
    useStreaming({
      token,
      fixtureId,
      isStreamSport,
    });

  const [playing, setPlaying] = useState(!!liveStreamUrl);
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const streamStartDate = streamStartDateTime && streamStartDateTime;

  const { endTimer, timerDuration } = useTimer(
    streamStartDate,
    WITHIN_TRIGGER_SEC_TIME,
  );

  const switchPictureInPictureMode = (mode: boolean) =>
    setPictureInPicture(mode);

  const streamUrl = liveStreamUrl
    ? liveStreamUrl
    : archivedStreamUrl
    ? archivedStreamUrl
    : undefined;

  const providerValue: Streaming = {
    streamStartDate,
    endTimer,
    timerDuration,
    playing,
    setPlaying,
    streamUrl,
    pictureInPicture,
    playerWithStream:
      isStreamSport && streamUrl ? (
        <ReactPlayer
          url={streamUrl}
          playing={playing}
          pip={false}
          muted
          controls
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          stopOnUnmount={false}
          width='100%'
          height='99%'
          config={{
            file: { attributes: { disablePictureInPicture: true } },
          }}
        />
      ) : null,
    switchPictureInPictureMode,
  };

  return (
    <StreamingContext.Provider value={providerValue}>
      {children}
    </StreamingContext.Provider>
  );
};
