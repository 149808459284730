import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useContext } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import { ConnectionStatus } from '../ConnectionStatus';

export const NetworkStatus = () => {
  const {
    state: { isOnline },
  } = useContext(ScoringContext);
  if (isOnline) return <></>;
  return (
    <ConnectionStatus
      label='Network'
      customIndicator={<WifiOffIcon color='error' fontSize='small' />}
    />
  );
};
