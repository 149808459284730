export const UNKNOWN_PLAYER_ID = '00000000-0000-0000-0000-000000000000';
export const ZERO_MINUTES_PLAYED_STRING = '00:00';
export const UNKNOWN_PLAYER_NAME = 'Unknown Player';
export const TEAM_DEFAULT_COLOR = '0,0,0';

export const MATCH_ACTION_SEND_TYPE_NAME = {
  1: 'Pending',
  2: 'Updated',
  3: 'Cancelled',
  4: 'Deleted',
  5: 'Confirmed',
} as const;
