import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FixtureAction } from '@/service/types';
import {
  getReadableTime,
  convertTimeTicks,
} from '../ActionEvaluationDialog/utils';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { BUTTON_NAME } from './constants';

export interface LatencyCellProps {
  action: FixtureAction;
}

export const LatencyCell = ({ action }: LatencyCellProps) => {
  const isLatencyTicks = action.slaBreachSummary?.latencyTicks;

  return (
    <AnimatedIconButton
      disabled={false}
      name={BUTTON_NAME.LATENCY}
      size='small'
      aria-label='latency-time-button'
      sx={{
        borderRadius: isLatencyTicks ? (theme) => theme.spacing(0.5) : '50%',
      }}
    >
      {isLatencyTicks ? (
        getReadableTime(convertTimeTicks(action.slaBreachSummary?.latencyTicks))
      ) : (
        <AccessTimeIcon fontSize='small' />
      )}
    </AnimatedIconButton>
  );
};
