import {
  COLLECTION_STATUS,
  COLLECTION_STATUS_ID,
  CollectionStatusId,
  COVERAGE_LEVEL,
  COVERAGE_LEVEL_ID,
  CoverageLevelId,
  FIXTURE_STATUS,
  FIXTURE_STATUS_ID,
  FixtureStatusId,
} from '@/service/constants';

export const FIXTURE_STATUS_OPTIONS = Object.values<FixtureStatusId>(
  FIXTURE_STATUS_ID
).map((fixtureStatusId) => ({
  name: FIXTURE_STATUS[fixtureStatusId],
  value: fixtureStatusId,
}));
export const COLLECTION_STATUS_OPTIONS = Object.values<CollectionStatusId>(
  COLLECTION_STATUS_ID
).map((collectionStatusId) => ({
  name: COLLECTION_STATUS[collectionStatusId],
  value: collectionStatusId,
}));

export const COVERAGE_LEVEL_OPTIONS = Object.values<CoverageLevelId>(
  COVERAGE_LEVEL_ID
).map((coverageLevelId) => ({
  name: COVERAGE_LEVEL[coverageLevelId],
  value: coverageLevelId,
}));

export const ELEMENT_ID = {
  FIXTURE_STATUS_SELECT_LABEL: 'fixture-status-edit-label',
  FIXTURE_STATUS_REASON_SELECT_LABEL: 'fixture-status-reason-edit-label',
  COLLECTION_STATUS_SELECT_LABEL: 'collection-status-edit-label',
  COLLECTION_STATUS_REASON_SELECT_LABEL: 'collection-status-reason-edit-label',
  COVERAGE_LEVEL_SELECT_LABEL: 'coverage-level-edit-label',
  COVERAGE_LEVEL_REASON_SELECT_LABEL: 'coverage-level-reason-edit-label',
  FIXTURE_STATUS_SELECT: 'fixture-status-edit-select',
  FIXTURE_STATUS_REASON_SELECT: 'fixture-status-reason-edit-select',
  COLLECTION_STATUS_SELECT: 'collection-status-edit-select',
  COLLECTION_STATUS_REASON_SELECT: 'collection-status-reason-edit-select',
  COVERAGE_LEVEL_SELECT: 'coverage-level-edit-select',
  COVERAGE_LEVEL_REASON_SELECT: 'coverage-level-reason-edit-select',
};

export const ELEMENT_LABEL = {
  FIXTURE_STATUS_SELECT: 'Fixture Status Select',
  FIXTURE_STATUS_REASON_SELECT: 'Fixture Status Reason Select',
  COLLECTION_STATUS_SELECT: 'Collection Status Select',
  COLLECTION_STATUS_REASON_SELECT: 'Collection Status Reason Select',
  COVERAGE_LEVEL_SELECT: 'Coverage Level Select',
  COVERAGE_LEVEL_REASON_SELECT: 'Coverage Level Reason Select',
};
export const REASON_CODE = {
  NONE: 0,
  OTHER: 1,
  EMPTY: null,
};

export const EMPTY_REASON = {
  code: REASON_CODE.EMPTY,
  reason: '',
};
