import { TableRow, Tooltip } from '@mui/material';
import { useContext, useMemo, useRef, useState } from 'react';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import {
  StatsTable,
  StatsTableContainer,
  StatsTableHead,
  StyledCol,
  TableRowWithHover,
} from '@/components/StatsTableCells/common';
import {
  StatsTableDataCell,
  StatsTableHeaderActionCell,
  StatsTableStickyDataCell,
  StatsTableStickyHeaderActionCell,
} from '@/components/StatsTableCells/StatsTableCells';
import { getPlayerName } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { ZoneStatistics } from '@/service/types';
import {
  filterBasketballTableData,
  generateBasketballStatsHeader,
} from '@/components/BasketballStatsTable/utils';
import { StatsContext } from '@/contexts/stats/context';
import { ScoringContext } from '@/contexts/scoring/context';
import { ActiveColumnType } from '@/components/StatsTableCells/types';

import {
  BASKETBALL_STATS_RESULTS,
  BasketballStatsTableData,
} from '@/components/BasketballStatsTable/types';
import { StatsTableBody } from '@/components/StatsTableBody/StatsTableBody';
import { InfoMessage } from '@/components/common/InfoMessage';
import { ARIA_LABEL } from '@/constants/ariaLabel';

export const BasketballStatsTable = () => {
  const {
    state: { fixtureSummary },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const {
    activeButtons: { quarter, type, team, statsType },
  } = useContext(StatsContext);
  const [activeColumn, setActiveColumn] = useState<ActiveColumnType>(null);
  const colGroupRef = useRef<HTMLTableColElement>(null);
  const header = useMemo(
    () => generateBasketballStatsHeader(statsType, type),
    [statsType, type]
  );
  const tableData = useMemo(() => {
    if (!fixtureSummary) return;
    return filterBasketballTableData({
      activeQuarter: quarter,
      activeType: type,
      activeTeam: team,
      activeStatsType: statsType,
      advancedSportStatistics: fixtureSummary.advancedSportStatistics,
      homeTeamId: fixtureSummary?.homeTeam.id,
    }) as BasketballStatsTableData[];
  }, [fixtureSummary, quarter, type, team, statsType]);

  if (!tableData || tableData.length === 0) {
    return <InfoMessage message={`Basketball stats data doesn't exist...`} />;
  }

  return (
    <StatsTableContainer>
      <StatsTable>
        <colgroup ref={colGroupRef}>
          {!(
            statsType === STATS_BUTTONS.STATS.GAME &&
            type === STATS_BUTTONS.TYPE.GAME
          ) && <col />}
          {header.map((data, index) => (
            <StyledCol
              key={`col-${index}`}
              index={
                !(
                  statsType === STATS_BUTTONS.STATS.GAME &&
                  type === STATS_BUTTONS.TYPE.GAME
                )
                  ? index + 1
                  : index
              }
              activeColumn={activeColumn}
              aria-label={ARIA_LABEL.HOVERED_COL}
            />
          ))}
        </colgroup>
        <StatsTableHead>
          <TableRow>
            {!(
              statsType === STATS_BUTTONS.STATS.GAME &&
              type === STATS_BUTTONS.TYPE.GAME
            ) && (
              <StatsTableStickyHeaderActionCell key='header-name'>
                Name
              </StatsTableStickyHeaderActionCell>
            )}
            {header?.map((action) => (
              <Tooltip
                title={action.value}
                key={action.value}
                placement='top'
                arrow
              >
                <StatsTableHeaderActionCell key={action.shortValue}>
                  {action.shortValue}
                </StatsTableHeaderActionCell>
              </Tooltip>
            ))}
          </TableRow>
        </StatsTableHead>
        <StatsTableBody
          activeColumn={activeColumn}
          setActiveColumn={setActiveColumn}
          colGroupRef={colGroupRef}
        >
          {tableData.map((statistics) => (
            <TableRowWithHover
              key={`table-cell-${statistics.playerId || statistics.teamId}`}
            >
              {!(
                statsType === STATS_BUTTONS.STATS.GAME &&
                type === STATS_BUTTONS.TYPE.GAME
              ) && (
                <StatsTableStickyDataCell>
                  {statistics?.playerId
                    ? getPlayerName(
                        statistics?.playerId,
                        fixtureConfig,
                        fixtureSummary
                      )
                    : statistics?.teamId
                    ? getTeamName(
                        statistics?.teamId,
                        fixtureConfig,
                        fixtureSummary
                      )
                    : ''}
                </StatsTableStickyDataCell>
              )}
              {header.map((action) => (
                <StatsTableDataCell
                  key={`${statistics.playerId || statistics.teamId}-${
                    action?.subKey
                      ? `${action.key} -${action.subKey}`
                      : action.key
                  }`}
                >
                  {!action.subKey
                    ? typeof statistics.statistics[action.key] !== 'boolean'
                      ? (statistics.statistics[action.key] as string | number)
                      : !statistics.statistics[action.key]
                      ? BASKETBALL_STATS_RESULTS.NO
                      : BASKETBALL_STATS_RESULTS.YES
                    : (statistics.statistics[action.key] as ZoneStatistics)[
                        action.subKey
                      ]}
                </StatsTableDataCell>
              ))}
            </TableRowWithHover>
          ))}
        </StatsTableBody>
      </StatsTable>
    </StatsTableContainer>
  );
};
