export const generateDataForColumns = <T>(
  data: T[],
  columnCount: number
): T[][] => {
  let result = [];

  for (let i = columnCount; i > 0; i--) {
    result.push(data.splice(0, Math.ceil(data.length / i)));
  }

  return result;
};
