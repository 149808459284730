import { konsole } from '@/utils/konsole';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';

type Column = {
  name: string;
  width: string;
  sortAttr?: string;
  secondarySortAttr?: string;
};

type Columns = {
  [key: string]: Column;
};

export const COLUMNS: Columns = {
  SEQ: { name: 'Seq Num', width: '8%', sortAttr: 'fixtureSeqNum' },
  SEND_TYPE: { name: 'Send Type', width: '10%' },
  CLOCK_TIME: { name: 'Clock Time', width: '11%' },
  PERIOD: {
    name: 'Period',
    width: '7%',
    sortAttr: 'period',
    secondarySortAttr: 'timestamp',
  },
  ACTION_ICONS: { name: '', width: '3%' },
  ACTION: { name: 'Action', width: '14%' },
  TEAM: { name: 'Team', width: '14%' },
  PLAYER: { name: 'Player', width: '14%' },
  SCORE: { name: 'Score', width: '7%' },
  LATENCY: { name: 'Latency', width: '7%' },
  MISTAKE_COMMENT_ICON: { name: 'Mistake Icon', width: '3%' },
  MORE: {
    name: 'More',
    width: '12%',
  },
};

export const NOT_DELETABLE_ACTIONS = [
  FIXTURE_ACTION_TYPE.TEAM_COLOURS,
  FIXTURE_ACTION_TYPE.START_CLOCK,
  FIXTURE_ACTION_TYPE.STOP_CLOCK,
  FIXTURE_ACTION_TYPE.ADJUST_CLOCK,
  FIXTURE_ACTION_TYPE.START_DELAY,
  FIXTURE_ACTION_TYPE.END_DELAY,
  FIXTURE_ACTION_TYPE.SUBSTITUTION_IN,
  FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT,
  FIXTURE_ACTION_TYPE.GAIN_POSSESSION,
  FIXTURE_ACTION_TYPE.VIDEO_CHECK,
  FIXTURE_ACTION_TYPE.DISTRESS,
  FIXTURE_ACTION_TYPE.ATTENDANCE,
  FIXTURE_ACTION_TYPE.POST_MATCH_CHECK_COMPLETE,
  FIXTURE_ACTION_TYPE.STATUS_UPDATE,
  FIXTURE_ACTION_TYPE.MATCH_CONDITIONS,
  FIXTURE_ACTION_TYPE.DEVICE_LOCATION,
  FIXTURE_ACTION_TYPE.LINEUPS,
  FIXTURE_ACTION_TYPE.PLAYERS_READY,
  FIXTURE_ACTION_TYPE.START_FIXTURE,
  FIXTURE_ACTION_TYPE.START_PERIOD,
  FIXTURE_ACTION_TYPE.END_FIXTURE,
  FIXTURE_ACTION_TYPE.END_PERIOD,
];

export const SHEET_NAME = {
  MATCH_DATA: 'Matсh Data',
  FIXTURE: 'Fixture',
  HOME_TEAM_STATS: 'Home Team Stats',
  AWAY_TEAM_STATS: 'Away Team Stats',
};

export const TABLE_CLASS_NAMES = {
  ROW: 'action-row',
  CELL: 'action-cell',
};

const colWidthsTotal = Object.values(COLUMNS).reduce((acc, column) => {
  const val = Number(column.width.slice(0, -1));
  return acc + val;
}, 0);
if (colWidthsTotal !== 100) {
  konsole.warn('ActionsTable column widths', colWidthsTotal);
}

export const BUTTON_NAME = {
  FLAG: 'flag-button',
  MORE: 'more-button',
  COMMENT: 'comment-button',
  STREAM: 'stream-button',
  LATENCY: 'latency-button',
} as const;

export const ACTION_ICON_ID = {
  GOAL: 'SportsSoccerIcon',
  RED_CARD: 'RedCardIcon',
  YELLOW_CARD: 'YellowCardIcon',
  SECOND_YELLOW_CARD: 'YellowRedCardIcon',
  PENALTY_AWARDED: 'PenaltyAwardedIcon',
  CORNER_AWARDED: 'CornerAwardedIcon',
  CLOSE_MARKET: 'WarningAmberIcon',
  KICK_OFF: 'SportsIcon',
};
