import { Divider, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { QuarterButtons } from '@/components/StatsButtons/QuarterButtons';
import { TypeButtons } from '@/components/StatsButtons/TypeButtons';
import { TeamButtons } from '@/components/StatsButtons/TeamButtons';
import { StatsContext } from '@/contexts/stats/context';
import { StatsTypeSelect } from '@/components/StatsButtons/StatsTypeSelect';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';
import { ButtonsWrapper } from '@/components/StatsButtons/ButtonsWrapper';

export const BasketballStatsButtons = () => {
  const {
    typeButtons,
    changeActiveButton,
    activeButtons: { statsType, type },
  } = useContext(StatsContext);
  useEffect(() => {
    const defaultTypeButton = typeButtons.find(
      (typeButton) => typeButton.value === STATS_BUTTONS.TYPE.PLAYER
    ) as StatsButtonType;
    if (
      (statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO ||
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT ||
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT) &&
      (type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.GAME)
    ) {
      return changeActiveButton(defaultTypeButton, BUTTON_TYPES.TYPE);
    }
    if (
      !(statsType === STATS_BUTTONS.STATS.GAME) &&
      type === STATS_BUTTONS.TYPE.GAME
    ) {
      return changeActiveButton(defaultTypeButton, BUTTON_TYPES.TYPE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsType, type]);
  return (
    <>
      <Stack direction='row' gap={1}>
        <ButtonsWrapper>
          <StatsTypeSelect />
        </ButtonsWrapper>

        {(statsType === STATS_BUTTONS.STATS.BOX_SCORE ||
          statsType === STATS_BUTTONS.STATS.ZONE_STATS) && (
          <>
            <Divider flexItem orientation='vertical' />
            <QuarterButtons />
          </>
        )}
      </Stack>
      <Divider flexItem variant='fullWidth' />
      <Stack direction='row' gap={1}>
        {(statsType === STATS_BUTTONS.STATS.GAME ||
          statsType === STATS_BUTTONS.STATS.BOX_SCORE ||
          statsType === STATS_BUTTONS.STATS.ZONE_STATS) && (
          <TypeButtons gameStatsType={statsType === STATS_BUTTONS.STATS.GAME} />
        )}
        {!(type === STATS_BUTTONS.TYPE.TEAM) &&
          !(type === STATS_BUTTONS.TYPE.GAME) && (
            <>
              {(statsType === STATS_BUTTONS.STATS.GAME ||
                statsType === STATS_BUTTONS.STATS.BOX_SCORE ||
                statsType === STATS_BUTTONS.STATS.ZONE_STATS) && (
                <Divider flexItem orientation='vertical' />
              )}
              <TeamButtons />
            </>
          )}
      </Stack>
    </>
  );
};
