import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { MTDialog } from '@/components/common/MTDialog';
import {
  COMMON_STRING,
  DELETE_CONFIRMATION_STRING,
  DICTIONARY,
} from '@/constants/dictionary';
import { ActionDialogProps } from '@/components/ActionsDialog/types';
import { ConfirmButtons } from '@/components/ActionsDialog/ConfirmButtons';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  DeleteFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';

export const DeleteActionDialog = ({ action, onClose }: ActionDialogProps) => {
  const {
    state: { fixtureId },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { dispatch: deleteAction, isLoading } =
    useDispatchWithResponse<DeleteFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.DELETE_FIXTURE_ACTION
    );

  const onSubmit = async () =>
    await deleteAction(
      { fixtureId, fixtureActionId: action.id },
      { successMessage: DICTIONARY.SUCCESS.ACTION_SUCCESSFULLY_DELETED }
    ).finally(onClose);

  return (
    <MTDialog
      open={true}
      onClose={onClose}
      title={COMMON_STRING.DELETE_ACTION}
      maxWidth='xs'
      fullWidth
      sx={{ justifyContent: 'left' }}
    >
      <Stack gap={2} width='100%'>
        <Typography>{DELETE_CONFIRMATION_STRING.ACTION}</Typography>
        <ConfirmButtons
          onSubmit={onSubmit}
          onReject={onClose}
          isLoading={isLoading}
        />
      </Stack>
    </MTDialog>
  );
};
