import { Box, Button, Stack, useTheme } from '@mui/material';
import { useContext, useMemo } from 'react';
import { PreferencesContext } from '@/contexts/preferences/context';
import { ScoringContext } from '@/contexts/scoring/context';
import { getSport } from '@/service/utils/getSport';
import { UIStateContext } from '@/contexts/UIState/context';
import { PANEL } from '@/contexts/UIState/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { THEME_MODE } from '@/contexts/theme/constants';
import { SortableTemplateList } from '../SortableTemplateList/SortableTemplateList';
import { TemplateDragDirection } from '../types';
import { TemplateSwitcherItem } from './TemplateSwitcherItem';

const ADD_TEMPLATE_WIDTH = '150px';

export const TemplateSwitcher = () => {
  const { templates } = useContext(PreferencesContext);

  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const sport = getSport(fixtureConfig);
  const { setOpenedPanel } = useContext(UIStateContext);
  const {
    palette: { grey },
    palette: { mode },
  } = useTheme();

  const onTemplateAdd = () => {
    setOpenedPanel(PANEL.ADD_FILTER_TEMPLATE);
  };

  const currentSportTemplates = useMemo(() => {
    return templates?.find((template) => template.sportId === sport?.id)
      ?.templateFilters;
  }, [templates, sport]);

  return (
    <Stack
      flexDirection={'row'}
      width={'100%'}
      alignItems={'flex-end'}
      justifyContent={'space-between'}
      flexWrap={'nowrap'}
      sx={{
        backgroundColor: mode === THEME_MODE.LIGHT ? grey[200] : grey[800],
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: 'red',
          zIndex: 2,
          width: `calc(100% - ${ADD_TEMPLATE_WIDTH})`,
          height: 30,
          top: 0,
          background: `linear-gradient(to right, rgba(255,255,255,0) 97%, ${
            mode === THEME_MODE.LIGHT ? grey[200] : grey[800]
          } 100%)`,
          pointerEvents: 'none',
        }}
      />
      <Stack
        sx={{
          height: 36,
          pr: 3,
          overflowX: 'auto',
          '--scrollbar-size': '5px',
        }}
        justifyContent={'flex-start'}
        direction={'row'}
        alignItems={'flex-end'}
      >
        <TemplateSwitcherItem key={'basic table'} template={undefined} />
        <SortableTemplateList
          templates={currentSportTemplates}
          dragDirection={TemplateDragDirection.Horizontal}
        />
      </Stack>
      <Button
        variant='text'
        color='secondary'
        sx={{
          height: 30,
          minWidth: ADD_TEMPLATE_WIDTH,
          textTransform: 'none',
          textAlign: 'bottom',
          color: mode === THEME_MODE.LIGHT ? grey[600] : grey[400],
          backgroundColor: mode === THEME_MODE.LIGHT ? grey[200] : grey[800],
          px: 2,
          lineHeight: 1,
          whiteSpace: 'nowrap',
        }}
        onClick={onTemplateAdd}
      >
        {COMMON_STRING.ADD_TEMPLATE}
      </Button>
    </Stack>
  );
};
