import { MutableRefObject } from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { IconButton, Tooltip } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useSnackbar } from 'notistack';
import { scrollToTop } from '@/components/ActionsTable/utils';
import { COMMON_STRING } from '@/constants/dictionary';
import { SnackbarCloseActionProps } from '@/components/SnackbarActions/SnackbarCloseAction';

interface SnackbarGoToTopActionProps extends SnackbarCloseActionProps {
  listRef: MutableRefObject<ViewportListRef | null>;
}

export const SnackbarGoToTopAction = ({
  snackbarId,
  listRef,
}: SnackbarGoToTopActionProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Tooltip title={COMMON_STRING.GO_TO_TOP} placement='top' arrow>
      <IconButton
        color='inherit'
        onClick={() => {
          closeSnackbar(snackbarId);
          scrollToTop(listRef);
        }}
      >
        <KeyboardDoubleArrowUpIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};
