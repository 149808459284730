import { UNKNOWN_PLAYER_ID } from '@/constants';
import { FixtureAction } from '../types';

export function isPlayerUnknown(playerId: FixtureAction['playerId']) {
  return playerId === UNKNOWN_PLAYER_ID;
}

export function isActionPlayerUnknown(action: FixtureAction) {
  return isPlayerUnknown(action.playerId);
}

export function isActionFlagged(action: FixtureAction) {
  return 'flag' in action && action.flag !== null;
}
