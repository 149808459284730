import { useSortable } from '@dnd-kit/sortable';
import { Box, Stack } from '@mui/material';
import { FC, ReactElement } from 'react';
import { CSS } from '@dnd-kit/utilities';
import AppsIcon from '@mui/icons-material/Apps';

type SortableTemplateItemProps = {
  children: ReactElement;
  id: string;
  withDragHandle?: boolean;
};

export const SortableTemplateItem: FC<SortableTemplateItemProps> = ({
  children,
  id,
  withDragHandle,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return withDragHandle ? (
    <Stack
      ref={setNodeRef}
      direction={'row'}
      style={style}
      sx={{ width: '100%' }}
      alignItems={'flex-start'}
    >
      {children}
      <AppsIcon
        id={id}
        {...listeners}
        {...attributes}
        sx={{
          mt: 3,
          outline: 'none',
          cursor: 'grab',
          position: 'relative',
          right: 12,
          color: (theme) => theme.palette.grey[500],
        }}
        fontSize='small'
      />
    </Stack>
  ) : (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </Box>
  );
};
