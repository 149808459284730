import {
  Accordion,
  AccordionSummary,
  Stack,
  FormControlLabel,
  Box,
  Radio,
  AccordionDetails,
  Chip,
  useTheme,
  Divider,
} from '@mui/material';
import { useContext, useRef, useState, MouseEvent, useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getSport } from '@/service/utils/getSport';
import { ScoringContext } from '@/contexts/scoring/context';
import { PreferencesContext } from '@/contexts/preferences/context';
import { getFixtureActionTypesFromApi } from '@/service/utils/getFixtureActionTypes';
import {
  RADIO_ACTIONS_UPDATED,
  RADIO_COMMENT_LABELS,
  RADIO_FLAG_LABELS,
  RADIO_PLAYER_LABELS,
} from '../constants';
import { RadioLabelsType } from '../types';
import { TemplateFormData } from './types';
import { MTEmoji } from './Emoji/MTEmoji';
import { DeleteConfirm } from './DeleteConfirm';

const DRAG_HANDLE_WIDTH = '20px';

type DisplayTemplateAccordionProps = {
  template: TemplateFormData;
  setAccordionOpen: (value: string | undefined) => void;
  editFromDrawer?: boolean;
  accordionOpen: string | undefined;
  onEdit: (id: string, sportId: number) => void;
  onDelete: (id: string, sportId: number) => void;
};

export const DisplayTemplateAccordion = ({
  template,
  setAccordionOpen,
  editFromDrawer,
  accordionOpen,
  onEdit,
  onDelete,
}: DisplayTemplateAccordionProps) => {
  const {
    templates,
    selectedTemplate,
    actionTypes: actionTypesFromApi,
    actions: { setSelectedTemplate },
  } = useContext(PreferencesContext);

  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const sport = getSport(fixtureConfig);

  const [anchorEl, setAnchorEl] = useState<{
    target: Element;
    id: string;
  } | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const actionTypes = useMemo(() => {
    return getFixtureActionTypesFromApi(actionTypesFromApi, template.sportId);
  }, [actionTypesFromApi, template.sportId]);

  const generateFilters = (template: TemplateFormData): string[] => {
    let filters = [] as string[];
    const actions = template.actions?.values.map(
      (action) =>
        `${template.actions?.hidden ? 'Hide ' : ''}Action: ${actionTypes.find(
          (type) => type.id === action.id,
        )?.name}`,
    );
    const teams = template.team?.map((team) => `Team: ${team}`);
    const sendTypes = template.sendTypes?.values.map(
      (sendType) =>
        `${template.sendTypes?.hidden ? 'Hide ' : ''}Send Type: ${sendType}`,
    );

    filters = actions ? filters.concat(actions) : filters;
    filters = sendTypes ? filters.concat(sendTypes) : filters;
    filters = teams ? filters.concat(teams) : filters;
    template.player &&
      filters.push(
        `Player: ${getRadioLabelFromValue(
          template.player,
          RADIO_PLAYER_LABELS,
        )}`,
      );
    template.comment &&
      filters.push(
        `Comment: ${getRadioLabelFromValue(
          template.comment,
          RADIO_COMMENT_LABELS,
        )}`,
      );
    template.flag &&
      filters.push(
        `Flag: ${getRadioLabelFromValue(template.flag, RADIO_FLAG_LABELS)}`,
      );
    template.actionsUpdated &&
      filters.push(
        `Actions updated: ${getRadioLabelFromValue(
          template.actionsUpdated,
          RADIO_ACTIONS_UPDATED,
        )}`,
      );

    return filters;
  };

  const getRadioLabelFromValue = (value: string, labels: RadioLabelsType) => {
    return labels[value as keyof typeof labels];
  };

  const onTemplateSelect = (id: string | undefined, sportId: number) => {
    const templateFromSport = templates?.find(
      (template) => template.sportId === sportId,
    );
    const templateToSelect = templateFromSport?.templateFilters?.find(
      (template) => template.id === id,
    );

    setSelectedTemplate(templateToSelect, sportId);

    if (editFromDrawer || sportId !== sport?.id) {
      return;
    }

    if (templateToSelect?.templateName) {
      notifyAboutTemplateChange(templateToSelect);
    } else {
      notifyAboutTemplateChange(templateToSelect, true);
    }
  };

  const notifyAboutTemplateChange = (
    template: TemplateFormData | undefined,
    removed: boolean = false,
  ) => {
    if (removed) {
      enqueueSnackbar(`No default template selected for ${sport?.name}`, {
        variant: 'info',
      });
    } else {
      enqueueSnackbar(
        `${template?.templateName} set as default for ${sport?.name}`,
        {
          variant: 'info',
        },
      );
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selected: boolean,
  ) => {
    setAccordionOpen?.(event.target.value);
    if (selected) {
      onTemplateSelect(event.target.value, template.sportId);
    }
  };

  const iconStyle = {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  };

  const showDeleteConfirmPopup = (
    event: MouseEvent<SVGSVGElement>,
    templateId: string,
  ) => {
    setAnchorEl({ target: event.currentTarget, id: templateId });
    setShowDeleteConfirm(true);
  };

  const onConfirmDelete = (template: TemplateFormData, sportId: number) => {
    setAnchorEl(null);
    setShowDeleteConfirm(false);
    onDelete(template.id, sportId);
  };

  return (
    <Stack width={'100%'}>
      <Divider sx={{ minWidth: `calc(100% + ${DRAG_HANDLE_WIDTH})` }} />
      <Accordion
        expanded={accordionOpen === template.id}
        key={template.id}
        elevation={0}
        sx={{
          '&:before': { height: '0px' },
          backgroundColor: 'inherit',
        }}
        ref={ref}
        disableGutters
      >
        <AccordionSummary>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ width: '100%' }}
          >
            <FormControlLabel
              label={
                <Stack direction='row'>
                  {template.emoji && (
                    <MTEmoji emoji={template.emoji} size={16} />
                  )}
                  <Box
                    sx={{
                      whiteSpace: 'wrap',
                      wordBreak: 'break-all',
                    }}
                  >
                    {template.templateName}
                  </Box>
                </Stack>
              }
              control={
                <Radio
                  checked={selectedTemplate?.id === template.id}
                  onChange={handleChange}
                  value={template.id}
                />
              }
            />

            <Stack direction='row'>
              {accordionOpen === template.id ? (
                <ExpandLessIcon
                  sx={{ cursor: 'pointer', mr: 1 }}
                  onClick={() =>
                    accordionOpen === template.id
                      ? setAccordionOpen('')
                      : setAccordionOpen(template.id)
                  }
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: 'pointer', mr: 1 }}
                  onClick={() =>
                    accordionOpen === template.id
                      ? setAccordionOpen('')
                      : setAccordionOpen(template.id)
                  }
                />
              )}
              <EditOutlinedIcon
                sx={iconStyle}
                onClick={() => onEdit(template.id, template.sportId)}
              />
              <>
                <DeleteOutlineOutlinedIcon
                  sx={iconStyle}
                  onClick={(e) => showDeleteConfirmPopup(e, template.id)}
                />
                {/* Note: drag handle is inside SortableTemplateItem*/}
                <DeleteConfirm
                  id={template.id}
                  open={showDeleteConfirm && anchorEl?.id === template.id}
                  onConfirm={() => onConfirmDelete(template, template.sportId)}
                  onClose={() => setShowDeleteConfirm(false)}
                  anchorElement={anchorEl?.target}
                />
              </>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction='row' gap={0.5} flexWrap='wrap'>
            {generateFilters(template).map((filter, index) => (
              <Chip
                key={index}
                sx={{
                  cursor: 'inherit',
                }}
                size='small'
                label={filter}
                variant={'filled'}
                color={'default'}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
