import { Stack } from '@mui/material';
import { LineupsProps } from '@/components/Lineups/types';
import {
  LineupFirstName,
  LineupIndicator,
  LineupLastName,
  LineupShirtNumber,
  TeamNameTypography,
} from '@/components/Lineups/LineupsStyledComponents';
export const HOME_TEAM_TEST_ID = 'home-team';
export const AWAY_TEAM_TEST_ID = 'away-team';
export const HOME_TEAM_SUBSTITUTES_TEST_ID = 'home-team-substitutes';
export const AWAY_TEAM_SUBSTITUTES_TEST_ID = 'away-team-substitites';
const PLAYER_NAME_TEST_ID = 'playerName';
const SHIRT_NUMBER_TEST_ID = 'shirtNumber';
const LINEUP_INDICATOR_TEST_ID = 'lineupIndicator';
const TEAM_NAME_TEST_ID = 'teamName';
const PLAYER_DETAILS_TEST_ID = 'player-details';
export const LineupsPlayers = ({
  players,
  teamName,
  awayTeam = false,
}: LineupsProps) => {
  return (
    <Stack
      sx={{ flex: 1, padding: (theme) => theme.spacing(2, 0), gap: 1 }}
      data-testid={awayTeam ? AWAY_TEAM_TEST_ID : HOME_TEAM_TEST_ID}
    >
      {teamName && (
        <TeamNameTypography
          textAlign={awayTeam ? 'left' : 'right'}
          data-testid={TEAM_NAME_TEST_ID}
        >
          {teamName}
        </TeamNameTypography>
      )}
      {players?.map((player) => (
        <Stack
          key={player.id}
          textAlign={awayTeam ? 'left' : 'right'}
          direction='row'
          justifyContent={awayTeam ? 'flex-start' : 'flex-end'}
          alignItems='center'
          gap={1.5}
          lineHeight={1.1}
          data-testid={PLAYER_DETAILS_TEST_ID}
        >
          {awayTeam ? (
            <>
              <LineupIndicator
                isInCurrentLineup={player.isInCurrentLineup}
                data-testid={LINEUP_INDICATOR_TEST_ID}
              />
              <LineupShirtNumber data-testid={SHIRT_NUMBER_TEST_ID}>
                {player.shirtNumber}{' '}
              </LineupShirtNumber>
              <Stack
                flexWrap='wrap'
                flex={1}
                direction='row'
                data-testid={PLAYER_NAME_TEST_ID}
              >
                <LineupFirstName>{player.firstName}</LineupFirstName>
                <LineupLastName>&nbsp;{player.lastName}</LineupLastName>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                flexWrap='wrap'
                flex={1}
                direction='row'
                justifyContent='flex-end'
                data-testid={PLAYER_NAME_TEST_ID}
              >
                <LineupFirstName>{player.firstName}</LineupFirstName>
                <LineupLastName>&nbsp;{player.lastName}</LineupLastName>
              </Stack>
              <LineupShirtNumber data-testid={SHIRT_NUMBER_TEST_ID}>
                {player.shirtNumber}
              </LineupShirtNumber>
              <LineupIndicator
                isInCurrentLineup={player.isInCurrentLineup}
                data-testid={LINEUP_INDICATOR_TEST_ID}
              />
            </>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
