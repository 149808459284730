import { Stack } from '@mui/material';
import { ComparisonTableCell } from '@/components/ComparisonTable/ComparisonTableCell';
import { COMPARISON_COLUMNS } from '@/components/ComparisonTable/constants';
import { ComparisonTableStickyCell } from './ComparisonTableStickyCell';

export const ComparisonTableHeader = () => {
  return (
    <Stack
      direction='row'
      textAlign='center'
      sx={{
        clipPath: 'inset(0)',
        '&>div': {
          borderRight: (theme) => `1px solid ${theme.palette.tableBorderColor}`,
          borderBottom: (theme) =>
            `1px solid ${theme.palette.tableBorderColor}`,
          borderTop: (theme) => `1px solid ${theme.palette.tableBorderColor}`,
          '&:first-of-type': {
            borderLeft: (theme) =>
              `1px solid ${theme.palette.tableBorderColor}`,
          },
        },
      }}
    >
      <ComparisonTableStickyCell
        width={COMPARISON_COLUMNS.SOURCE.width}
        columnName={COMPARISON_COLUMNS.SOURCE.name}
        label={COMPARISON_COLUMNS.SOURCE.name}
        textAlign='left'
        position='sticky'
        zIndex={1}
        left={0}
      />

      <ComparisonTableCell
        width={COMPARISON_COLUMNS.GOAL.width}
        columnName={COMPARISON_COLUMNS.GOAL.name}
        label={COMPARISON_COLUMNS.GOAL.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.GOAL_DISALLOWED.width}
        columnName={COMPARISON_COLUMNS.GOAL_DISALLOWED.name}
        label={COMPARISON_COLUMNS.GOAL_DISALLOWED.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.YELLOW_CARD.width}
        columnName={COMPARISON_COLUMNS.YELLOW_CARD.name}
        label={COMPARISON_COLUMNS.YELLOW_CARD.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.RED_CARD.width}
        columnName={COMPARISON_COLUMNS.RED_CARD.name}
        label={COMPARISON_COLUMNS.RED_CARD.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.ATTACK.width}
        columnName={COMPARISON_COLUMNS.ATTACK.name}
        label={COMPARISON_COLUMNS.ATTACK.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.DANGEROUS_ATTACK.width}
        columnName={COMPARISON_COLUMNS.DANGEROUS_ATTACK.name}
        label={COMPARISON_COLUMNS.DANGEROUS_ATTACK.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.PENALTY_AWARDED.width}
        columnName={COMPARISON_COLUMNS.PENALTY_AWARDED.name}
        label={COMPARISON_COLUMNS.PENALTY_AWARDED.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.PENALTY_MISSED.width}
        columnName={COMPARISON_COLUMNS.PENALTY_MISSED.name}
        label={COMPARISON_COLUMNS.PENALTY_MISSED.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.FREE_KICK.width}
        columnName={COMPARISON_COLUMNS.FREE_KICK.name}
        label={COMPARISON_COLUMNS.FREE_KICK.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.SHOT_ON_TARGET.width}
        columnName={COMPARISON_COLUMNS.SHOT_ON_TARGET.name}
        label={COMPARISON_COLUMNS.SHOT_ON_TARGET.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.SHOT_OFF_TARGET.width}
        columnName={COMPARISON_COLUMNS.SHOT_OFF_TARGET.name}
        label={COMPARISON_COLUMNS.SHOT_OFF_TARGET.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.CORNER.width}
        columnName={COMPARISON_COLUMNS.CORNER.name}
        label={COMPARISON_COLUMNS.CORNER.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.SHOT_BLOCKED.width}
        columnName={COMPARISON_COLUMNS.SHOT_BLOCKED.name}
        label={COMPARISON_COLUMNS.SHOT_BLOCKED.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.OFFSIDE.width}
        columnName={COMPARISON_COLUMNS.OFFSIDE.name}
        label={COMPARISON_COLUMNS.OFFSIDE.name}
      />
      <ComparisonTableCell
        width={COMPARISON_COLUMNS.THROW_IN.width}
        columnName={COMPARISON_COLUMNS.THROW_IN.name}
        label={COMPARISON_COLUMNS.THROW_IN.name}
      />
    </Stack>
  );
};
