import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface NotchedBoxProps {
  notchWidth?: number;
  notchHeight?: number;
}
const DEFAULT_NOTCH_WIDTH = 40;
const DEFAULT_NOTCH_HEIGHT = 10;
export const NotchedBox = styled(Box)<NotchedBoxProps>(
  ({
    notchWidth = DEFAULT_NOTCH_WIDTH,
    notchHeight = DEFAULT_NOTCH_HEIGHT,
  }) => {
    const notchStartX = 50 - notchWidth / 2;
    const notchEndX = 50 + notchWidth / 2;
    return {
      clipPath: `polygon(-2px -2px,
        ${notchStartX}% -2px,
        ${notchStartX}% ${notchHeight}%,
        ${notchEndX}% ${notchHeight}%,
        ${notchEndX}% -2px,
        101% -2px,
        101% 101%,
        -2px 101%
      )`,
    };
  }
);
