import { useEffect } from 'react';
import { usePreviousValue } from '@/utils/hooks/usePreviousValue';
import { STATE_CHANGE_SOUND } from '@/contexts/preferences/constants';
import { SoundId } from '@/contexts/notifications/types';
import { ScoringState } from './types';

export type UseStateChangeSoundsProps = {
  state: ScoringState;
  playSound: (soundId: SoundId) => void;
};
/**
 * This hook should be used to detect any scoring state changes
 * and play a corresponding sound for certain state change.
 */
export const useStateChangeSounds = ({
  state,
  playSound,
}: UseStateChangeSoundsProps) => {
  const wasScorerConnected = usePreviousValue(state.isScorerConnected);

  useEffect(() => {
    if (state.isScorerConnected) return;
    if (!wasScorerConnected) return;
    playSound(STATE_CHANGE_SOUND.SCORER_DISCONNECTED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isScorerConnected, playSound]);
};
