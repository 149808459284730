import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TEAM_DEFAULT_COLOR } from '@/constants';

interface FixtureScoreTeamInPlayColorBoxProps {
  homeTeam?: boolean;
}

export const FixtureScoreInfoTeam = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '1.5rem',
  lineHeight: 1,
  gap: theme.spacing(2),
}));

export const FixtureScoreTeamColorBox = styled('span')(({ theme }) => ({
  width: '0.3em',
  height: '1em',
  '--teamColor': `rgb(${TEAM_DEFAULT_COLOR})`,
  backgroundColor: 'var(--teamColor)',
  borderRadius: theme.shape.borderRadius,
}));

export const FixtureScoreTeamInPlayColorBox = styled('span', {
  shouldForwardProp: (prop) => prop !== 'homeTeam',
})<FixtureScoreTeamInPlayColorBoxProps>(({ theme, homeTeam = false }) => ({
  position: 'relative',
  marginBottom: theme.spacing(1),
  '--teamColor': `rgb(${TEAM_DEFAULT_COLOR})`,
  backgroundColor: 'var(--teamColor)',
  width: 'var(--size, 0.53em)',
  aspectRatio: '1',
  borderTopRightRadius: '30%',
  transform: `rotate(${
    homeTeam ? '-30deg' : '-90deg'
  }) skewX(-30deg) scale(1,.866)`,

  '&:before': {
    inset: 0,
    borderTopRightRadius: '30%',
    content: '""',
    position: 'absolute',
    backgroundColor: 'inherit',
    transform:
      'rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0,-50%)',
  },

  '&:after': {
    inset: 0,
    borderTopRightRadius: '30%',
    content: '""',
    position: 'absolute',
    backgroundColor: 'inherit',
    transform: 'rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%)',
  },
}));

export const FixtureScoreTimeoutColorBox = styled('span')(({ theme }) => ({
  width: '1.125em',
  height: '0.625em',
  '--teamColor': `rgb(${TEAM_DEFAULT_COLOR})`,
  backgroundColor: 'var(--teamColor)',
  borderRadius: theme.spacing(0.5),
}));
