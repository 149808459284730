import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import Close from '@mui/icons-material/Close';
import { COMMON_STRING } from '@/constants/dictionary';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { THEME_MODE } from '@/contexts/theme/constants';

type TemplateConfirmationDialogProps = {
  onConfirm: (dontShowAgain: boolean) => void;
  onCancel: VoidFunction;
  showDialog: boolean;
  title: string;
  dialogText: string;
};

export const TemplateConfirmationDialog: FC<
  TemplateConfirmationDialogProps
> = ({ onConfirm, showDialog, onCancel, title, dialogText }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const {
    palette: { grey },
    palette: { mode },
  } = useTheme();

  return (
    <Dialog
      open={showDialog}
      aria-label={ARIA_LABEL.TEMPLATE_CONFIRMATION_DIALOG}
    >
      <DialogTitle
        sx={{
          backgroundColor: mode === THEME_MODE.LIGHT ? grey[300] : grey[800],
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box>{title}</Box>
          <Box>
            <IconButton onClick={onCancel}>
              <Close />
            </IconButton>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>{dialogText}</DialogContent>
      <DialogActions
        sx={{
          backgroundColor: mode === THEME_MODE.LIGHT ? undefined : grey[800],
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          sx={{ px: 2 }}
        >
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  value={dontShowAgain}
                  onChange={(_event, checked) => setDontShowAgain(checked)}
                />
              }
              label={COMMON_STRING.DONT_SHOW_AGAIN}
              sx={{ mr: 'auto' }}
            />
          </Box>
          <Stack direction='row' gap={2}>
            <Button
              variant='contained'
              onClick={() => onConfirm(dontShowAgain)}
            >
              {COMMON_STRING.PROCEED}
            </Button>
            <Button variant='outlined' onClick={onCancel}>
              {COMMON_STRING.CLOSE}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
