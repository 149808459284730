import useSWR from 'swr';
import { ApiError } from '@/service/ApiError';
import { HTTP_STATUS_CODE } from '@/service/constants';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '@/service/fetcher/monitoringToolService';
import { SportActionType } from '@/contexts/preferences/types';
import { FilterSendType, SportType } from '../ActionsTable/ActionFilters/types';

type FilterOptionsApiProps = {
  token: string | undefined;
};

export const useFiltersOptionsApi = ({ token }: FilterOptionsApiProps) => {
  const { data, error } = useSWR<SportActionType[], ApiError>(
    token
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.FixtureActions().ActionTypes.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  const { data: sendTypes, error: sendTypesError } = useSWR<
    FilterSendType[],
    ApiError
  >(
    token
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.FixtureActions().SendTypes.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    error: error || sendTypesError,
    isLoading: !data && !error && !sendTypes && !sendTypesError,
    sports: data?.map(
      (entry): SportType => ({
        id: entry.sportId,
        name: entry.sportName,
      }),
    ),
    actionTypes: data,
    sendTypes,
  };
};
