import { ChecklistWarning } from '@/contexts/checklist/types';
import {
  CHECKLIST_ELEMENT_INDEX,
  CHECKLIST_ELEMENT_NAME,
} from '@/service/types/checklist';

/**
 * Checklist time constraints in minutes.
 */
export const CHECKLIST_TIME_THRESHOLD: Partial<Record<string, number>> = {
  [CHECKLIST_ELEMENT_NAME.ATTENDANCE_GEO]: 60,
  [CHECKLIST_ELEMENT_NAME.ATTENDANCE_SELFIE]: 45,
  [CHECKLIST_ELEMENT_NAME.MATCH_CONDITIONS]: 35,
  [CHECKLIST_ELEMENT_NAME.TEAM_COLOURS]: 30,
  [CHECKLIST_ELEMENT_NAME.LINEUPS]: 20,
};

export const CHECKLIST_WARNING = {
  LINEUPS_SQUAD: {
    name: 'lineups-squad',
    message: 'Players missing in SDM: ',
  } as ChecklistWarning,
} as const;

export const ERROR_MESSAGE: Record<number, string> = {
  [CHECKLIST_ELEMENT_INDEX.POST_MATCH_CHECK_COMPLETE]:
    'Post match check failed',
  [CHECKLIST_ELEMENT_INDEX.SLA_BREACH_COMPLETE]: 'Sla breach complete failed',
};
