import { comparePostMatchStatistics } from '@/service/utils/comparePostMatchStatistics';
import { COMMON_STRING } from '@/constants/dictionary';
import {
  FixturePostMatchCheck,
  FixturePostMatchCheckStatistics,
  FixtureSummary,
} from '../types';

export const forceStatsType = (
  statistics: FixturePostMatchCheckStatistics | undefined,
  statType: string
) => {
  if (statistics === undefined) {
    return { statsType: statType };
  }
  if (!statistics.statsType) {
    return { ...statistics, statsType: statType };
  }
  return statistics;
};

export const getComparisonData = (
  data: FixturePostMatchCheck | null | undefined,
  fixtureSummary: FixtureSummary | null
) => {
  const internalHome = forceStatsType(
    data?.internalTeamsStatistics.find(
      (statistics) => statistics.teamName === fixtureSummary?.homeTeam.teamName
    ),
    COMMON_STRING.STATS.INTERNAL_HOME
  );
  const internalAway = forceStatsType(
    data?.internalTeamsStatistics.find(
      (statistics) => statistics.teamName === fixtureSummary?.awayTeam.teamName
    ),
    COMMON_STRING.STATS.INTERNAL_AWAY
  );
  const externalHome = forceStatsType(
    data?.externalApiTeamStatistics?.find((statistics) => statistics.isHome),
    COMMON_STRING.STATS.EXTERNAL_HOME
  );
  const externalAway = forceStatsType(
    data?.externalApiTeamStatistics?.find((statistics) => !statistics.isHome),
    COMMON_STRING.STATS.EXTERNAL_AWAY
  );

  return [
    ...comparePostMatchStatistics(internalHome, externalHome),
    ...comparePostMatchStatistics(internalAway, externalAway),
  ];
};
