import { createRoot } from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from '@/router';
import { initDatadogLogger } from '@/initDatadogLogger';

initDatadogLogger();
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<RouterProvider router={router} />);
