import { FixtureScorerRating } from '@/service/types';

export const SL_THRESHOLD = {
  SL3: 50,
  SL2: 80,
  SL1: 100,
} as const;

export const getServiceLevel = (overallScore: number) => {
  if (overallScore <= SL_THRESHOLD.SL3) {
    return 2;
  }
  if (overallScore <= SL_THRESHOLD.SL2) {
    return 1;
  }
  if (overallScore <= SL_THRESHOLD.SL1) {
    return 0;
  }
};

export const convertServiceLevelToString = (serviceLevel?: number) => {
  switch (serviceLevel) {
    case 1:
      return 'SL2';
    case 2:
      return 'SL3';
    case 0:
    default:
      return 'SL1';
  }
};

export const calculateOverallScore = (rating: FixtureScorerRating) => {
  let newOverallScore =
    100 -
    (100 - (rating.quantityScore || 0)) -
    (100 - (rating.latencyScore || 0));
  if (newOverallScore < 0) {
    newOverallScore = 0;
  }

  const newServiceLevel = getServiceLevel(newOverallScore);
  return {
    ...rating,
    overallScore: newOverallScore,
    serviceLevel: newServiceLevel,
  };
};

export const generateDefaultScorerRating = (
  fixtureId: FixtureScorerRating['fixtureId'],
  user: FixtureScorerRating['user'],
) => {
  const defaultRating: FixtureScorerRating = {
    fixtureId: fixtureId,
    comment: '',
    latencyScore: 100,
    quantityScore: 100,
    overallScore: 100,
    serviceLevel: 0,
    user: user,
  };
  return defaultRating;
};

export const compareRatings = (
  defaultRating: FixtureScorerRating,
  scorerRating: FixtureScorerRating,
) => {
  return (
    scorerRating.comment === defaultRating.comment &&
    scorerRating.latencyScore === defaultRating.latencyScore &&
    scorerRating.quantityScore === defaultRating.quantityScore
  );
};
