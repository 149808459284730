import { useSnackbar } from 'notistack';
import useSWR from 'swr';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
} from '../fetcher/monitoringToolService';
import { FixtureSlaBreachChecklist } from '../types';

interface UseSlaBreachQuantityParams {
  token: string | undefined;
  fixtureId: string;
}

export const useSlaBreachChecklist = ({
  token,
  fixtureId,
}: UseSlaBreachQuantityParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR<FixtureSlaBreachChecklist[], ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Fixture(fixtureId).slaChecklist.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    slaBreachChecklist: data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};
