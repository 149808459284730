import { Stack } from '@mui/material';
import { useContext, useMemo } from 'react';
import { FixtureConfig, Period } from '@/service/types';
import { ScoresheetDescription } from '@/components/BasketballScoresheet/ScoresheetDescription';
import { ScoresheetScore } from '@/components/BasketballScoresheet/ScoresheetScore';
import { ScoringContext } from '@/contexts/scoring/context';

interface ScoresheetSummaryProps {
  activePeriod?: Period;
  fixtureConfig?: FixtureConfig;
}

export const ScoresheetSummary = ({
  activePeriod,
  fixtureConfig,
}: ScoresheetSummaryProps) => {
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);

  const periodsScore = useMemo(
    () =>
      `(${fixtureSummary?.periods
        .map(
          (period) =>
            `${period.periodScore.homeTeamScore}:${period.periodScore.awayTeamScore}`
        )
        .join(', ')})`,
    [fixtureSummary?.periods]
  );

  return (
    <Stack gap={7}>
      <ScoresheetDescription fixtureConfig={fixtureConfig} />
      <ScoresheetScore
        homeTeam={{
          name: fixtureSummary?.homeTeam.teamName,
          score: !activePeriod
            ? fixtureSummary?.currentScore.homeTeamScore
            : activePeriod.periodScore.homeTeamScore,
        }}
        awayTeam={{
          name: fixtureSummary?.awayTeam.teamName,
          score: !activePeriod
            ? fixtureSummary?.currentScore.awayTeamScore
            : activePeriod.periodScore.awayTeamScore,
        }}
        period={!activePeriod ? periodsScore : activePeriod?.label}
      />
    </Stack>
  );
};
