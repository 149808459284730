import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import { FC, JSXElementConstructor } from 'react';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export type ContextMenuItem = {
  Icon: JSXElementConstructor<any>;
  label: string | JSX.Element;
  onClick: () => void;
  key?: string;
  isLoading?: boolean;
  permissionName?: string;
};
export type ContextMenuProps = {
  items: ContextMenuItem[];
};

const renderMenuItem = (
  key: string | undefined,
  label: string | JSX.Element,
  index: number,
  length: number,
  onClick: () => void,
  isLoading: boolean,
  Icon: JSXElementConstructor<any>
) => {
  return (
    <MenuItem
      key={key || label.toString()}
      divider={index < length - 1}
      onClick={onClick}
      disabled={isLoading}
    >
      <ListItemIcon>
        <Icon fontSize='small' />
      </ListItemIcon>
      <ListItemText
        disableTypography
        sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
      >
        {label}
      </ListItemText>
      <LoadingOverlay isLoading={isLoading} size={'1.5em'} />
    </MenuItem>
  );
};

export const ContextMenu: FC<ContextMenuProps> = ({ items }) => {
  return (
    <Paper elevation={10} sx={{ width: 'fit-content', maxWidth: '100%' }}>
      <MenuList variant='menu' sx={{ py: 0 }}>
        {items.map(
          (
            { onClick, Icon, label, key, permissionName, isLoading = false },
            index,
            { length }
          ) =>
            permissionName ? (
              <PermissionsChecker name={permissionName} key={index}>
                {renderMenuItem(
                  key,
                  label,
                  index,
                  length,
                  onClick,
                  isLoading,
                  Icon
                )}
              </PermissionsChecker>
            ) : (
              <>
                {renderMenuItem(
                  key,
                  label,
                  index,
                  length,
                  onClick,
                  isLoading,
                  Icon
                )}
              </>
            )
        )}
      </MenuList>
    </Paper>
  );
};
