import { useState } from 'react';
import { v4 as uuid } from 'uuid'; // UUID RFC version 4 (random)
import { useSnackbar } from 'notistack';
import {
  ActionOf,
  PayloadOf,
  ScoringAPIWorker,
  ScoringWorkerHostMsg,
  ScoringWorkerMsgEvent,
  SCORING_WORKER_ACTION,
} from '@/workers/scoring/types';
import { ddApplicationLogger } from '@/datadogLoggers';

export type SocketRequestPayload<T> = Omit<PayloadOf<T>, 'requestId'>;

export type DispatchWithResponseState<T> = {
  requestId: string;
  requestPayload: SocketRequestPayload<T> | null;
  isSuccess: boolean;
  error: string;
};

export type DispatchWithResponseOutput<T> = DispatchWithResponseState<T>;

export type InvokeDispatchWithResponse<T> = (
  requestPayload: SocketRequestPayload<T>,
  options?: InvokeDispatchWithResponseOptions,
) => Promise<DispatchWithResponseOutput<T>>;

export type UseDispatchWithResponse<T> = DispatchWithResponseState<T> & {
  isLoading: boolean;
  dispatch: InvokeDispatchWithResponse<T>;
};

export type InvokeDispatchWithResponseOptions = {
  successMessage?: string;
  errorMessage?: string;
  showSnackbar?: boolean;
};

export const INVOKE_DISPATCH_WITH_RESPONSE_DEFAULT_OPTIONS = {
  successMessage: undefined,
  errorMessage: undefined,
  showSnackbar: true,
} as const satisfies InvokeDispatchWithResponseOptions;

/**
 * This hook allows the component to send a message and await for
 * confirmation response message. This is a singular version of that hook.
 * It gives better control and is recommended to be used in 'lowest level' components.
 * If you need to perform the dispatchWithResponse on higher level component (parent component)
 * you should take a look on `createDispatchManyWithResponse` which allows for multiple calls
 * and provides different state API.
 */
export const createUseDispatchWithResponse = (worker: ScoringAPIWorker) => {
  const useDispatchWithResponse = <T>(
    action: ActionOf<T>,
  ): UseDispatchWithResponse<T> => {
    const [state, setState] = useState<DispatchWithResponseState<T>>({
      requestId: '',
      requestPayload: null,
      isSuccess: false,
      error: '',
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    let pendingPromise: Promise<DispatchWithResponseState<T>> | null = null;

    const dispatch: InvokeDispatchWithResponse<T> = (
      requestPayload,
      options,
    ) => {
      if (pendingPromise) return pendingPromise;
      pendingPromise = new Promise<DispatchWithResponseState<T>>(
        (resolve, reject) => {
          const requestId = uuid();
          /**
           * When a component dispatches the action with requestConfirmation
           * it should use this hook state to display the loading state
           * while awaiting for the response message.
           * However, when response is coming quick enough then
           * components render loading state and then updated state immediatly after that.
           * This looks glitchy.
           * This timeout is slightly delaying setting state properties which determine
           * whether it is loading state or not.
           */
          const loadingStateTimeout = setTimeout(() => {
            setIsLoading(true);
          }, 100);
          setState({ ...state, requestId, requestPayload });

          const confirmationCb = (e: ScoringWorkerMsgEvent) => {
            const { action: responseAction, payload } = e.data;
            if (responseAction !== SCORING_WORKER_ACTION.REQUEST_CONFIRMATION)
              return;
            if (payload.requestId !== requestId) return;

            ddApplicationLogger.info(
              `Received from WS: action ${responseAction}, payload ${JSON.stringify(
                payload,
              )}`,
              {
                action: responseAction,
                ...payload,
              },
            );

            clearTimeout(loadingStateTimeout);
            setIsLoading(false);
            worker.removeEventListener('message', confirmationCb);
            const { successMessage, errorMessage, showSnackbar } = {
              ...INVOKE_DISPATCH_WITH_RESPONSE_DEFAULT_OPTIONS,
              ...options,
            };
            if (showSnackbar) {
              if (payload.isSuccess) {
                if (successMessage) {
                  enqueueSnackbar(successMessage, {
                    variant: 'success',
                  });
                }
              } else {
                enqueueSnackbar(
                  errorMessage || payload.errorMessage || `${action} failed`,
                  {
                    variant: 'error',
                  },
                );
              }
            }

            setState((prevState) => {
              const newState = {
                ...prevState,
                isSuccess: payload.isSuccess,
                error: payload.errorMessage,
              };
              if (payload.isSuccess) {
                resolve(newState);
              } else {
                reject(newState);
              }
              return newState;
            });
          };
          worker.addEventListener('message', confirmationCb);

          ddApplicationLogger.info(
            `Sending to WS: action ${action}, payload ${JSON.stringify(
              requestPayload,
            )}`,
            {
              action: action,
              requestId,
              ...requestPayload,
            },
          );

          worker.postMessage({
            action: action as ActionOf<T>,
            payload: { ...requestPayload, requestId } as PayloadOf<T>,
          } as ScoringWorkerHostMsg);
        },
      ).then((output) => {
        pendingPromise = null;
        return output;
      });

      return pendingPromise;
    };

    return {
      ...state,
      isLoading,
      dispatch,
    };
  };

  return useDispatchWithResponse;
};
