import { FormGroup } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SetChecklistElementActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { FixtureSlaBreachChecklist } from '@/service/types';
import { SlaBreachCheckbox } from '@/components/SlaBreachFixtureEvaluation/SlaBreachCheckbox';
import { useSlaBreachChecklist } from '@/service/hooks/useSlaBreachChecklist';

export const SlaBreachFixtureEvaluation = () => {
  const {
    state: { fixtureId },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const token = useAuthToken();

  const { slaBreachChecklist, mutate } = useSlaBreachChecklist({
    token,
    fixtureId,
  });
  const [checkBoxesState, setCheckboxesState] = useState(slaBreachChecklist);

  const { dispatch: addChecklistElementAction, isLoading } =
    useDispatchWithResponse<SetChecklistElementActionMsg>(
      SCORING_WORKER_HOST_ACTION.SET_SLA_BREACH_CHECKLIST,
    );

  useEffect(() => {
    setCheckboxesState(slaBreachChecklist);
  }, [slaBreachChecklist]);

  const onBreachHandler = async (
    checklistElement: FixtureSlaBreachChecklist,
  ) => {
    if (!checkBoxesState) return;

    await addChecklistElementAction({
      fixtureId,
      timestamp: new Date().toISOString(),
      ruleId: checklistElement.ruleId,
      isBreached: !checklistElement.isBreached,
    }).then(() => mutate());
  };

  if (!slaBreachChecklist?.length) {
    return null;
  }

  return (
    <FormGroup sx={{ px: 4, pt: 2 }}>
      {checkBoxesState?.map((checklistElement) => (
        <SlaBreachCheckbox
          slaBreachChecklistElement={checklistElement}
          disabled={isLoading}
          onBreachHandler={onBreachHandler}
          key={checklistElement.ruleId}
        />
      ))}
    </FormGroup>
  );
};
