export const PLAYERS_TABLE_HEADER_KEYS = [
  { key: 'No' },
  { key: 'Name' },
  { key: 'Min' },
  { key: 'PTS' },
  { key: 'Field Goals', subKeys: ['M/A', '%'] },
  { key: '2 Points', subKeys: ['M/A', '%'] },
  { key: '3 Points', subKeys: ['M/A', '%'] },
  { key: 'Free Throws', subKeys: ['M/A', '%'] },
  { key: 'Rebounds', subKeys: ['OR', 'DR', 'TOT'] },
  { key: 'AS' },
  { key: 'TO' },
  { key: 'ST' },
  { key: 'Blocks', subKeys: ['BS', 'BA'] },
  { key: 'Fouls', subKeys: ['PF', 'FD'] },
  { key: '+/-' },
];
