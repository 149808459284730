import Stack from '@mui/material/Stack';
import { FC } from 'react';
import { Period, PeriodActionAggregate } from '@/service/types';
import { getPeriodActionAggregate } from '@/service/utils/getActionAggregates';
import { PeriodScore } from './PeriodScore';

interface PeriodsScoresProps {
  periods: Pick<Period, 'periodNumber' | 'periodScore'>[];
  actionAggregates?: ReturnType<typeof getPeriodActionAggregate>;
}
export const getAggregateForPeriod = (
  periodNumber: number,
  actionAggregates: PeriodsScoresProps['actionAggregates']
) => {
  if (!actionAggregates) return undefined;
  const findForPeriod = ({ period }: PeriodActionAggregate) =>
    period === periodNumber;
  return {
    homeTeam:
      actionAggregates.homeTeam.find(findForPeriod)?.actionAggregate || 0,
    awayTeam:
      actionAggregates.awayTeam.find(findForPeriod)?.actionAggregate || 0,
  };
};

export const PeriodsScores: FC<PeriodsScoresProps> = ({
  periods,
  actionAggregates,
}) => {
  return (
    <Stack
      direction='row'
      rowGap={1.5}
      flexWrap='wrap'
      justifyContent='center'
      mt={0.75}
    >
      {periods.map(({ periodNumber, periodScore }) => (
        <PeriodScore
          key={periodNumber}
          periodNumber={periodNumber}
          periodScore={periodScore}
          periodAggregate={getAggregateForPeriod(
            periodNumber,
            actionAggregates
          )}
        />
      ))}
    </Stack>
  );
};
