import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { GridWrap } from './Template/GridWrap';
import { CHANGE_MODE_BUTTON_LABEL } from './AutocompleteFilter';

type GenericCheckboxFilterGroupProps = {
  label: string;
  options: string[];
  selected: string[];
  onSelect: (values: string[]) => void;
  onHide?: (hidden: boolean) => void;
  hideable?: boolean;
  hidden?: boolean;
  name?: string;
} & PropsWithChildren;

export const GenericCheckboxFilterGroup: FC<
  GenericCheckboxFilterGroupProps
> = ({
  label,
  options,
  selected,
  onSelect,
  name,
  hideable,
  onHide,
  hidden,
  ...props
}) => {
  const handleSelect = (option: string) => {
    const isSelected = selected.some((selectedItem) => selectedItem === option);
    if (isSelected) {
      onSelect(selected.filter((item) => item !== option));
    } else {
      onSelect([...selected, option]);
    }
  };

  const isChecked = (option: string) =>
    selected.some((selectedItem) => selectedItem === option);

  const onHideClick = () => onHide?.(!hidden);

  return (
    <FormControl component='fieldset' variant='standard' {...props}>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Typography sx={{ fontSize: 12 }}>{label}</Typography>
        {hideable && (
          <Button
            variant='text'
            color='inherit'
            size='small'
            sx={{ width: '9ch', justifyContent: 'flex-start' }}
            onClick={onHideClick}
            startIcon={hidden ? <VisibilityOff /> : <Visibility />}
            aria-label={CHANGE_MODE_BUTTON_LABEL}
          >
            {hidden ? 'Hide' : 'Show'}
          </Button>
        )}
      </Stack>
      <Stack direction='row'>
        <GridWrap>
          {options.map((option) => (
            <Box key={option}>
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    aria-label={`${name}-${option}`}
                    checked={isChecked(option)}
                    onChange={() => handleSelect(option)}
                  />
                }
                label={<Typography sx={{ fontSize: 14 }}>{option}</Typography>}
              />
            </Box>
          ))}
        </GridWrap>
      </Stack>
    </FormControl>
  );
};
