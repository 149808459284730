import { useState, useRef, useMemo, useContext, Fragment } from 'react';
import { TableRow } from '@mui/material';
import { ActiveColumnType } from '@/components/StatsTableCells/types';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import {
  StatsTableContainer,
  StatsTable,
  StyledCol,
  StatsTableHead,
  TableRowWithHover,
} from '@/components/StatsTableCells/common';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import { getPlayerName } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { StatsTableBody } from '@/components/StatsTableBody/StatsTableBody';
import {
  StatsTableStickyHeaderActionCell,
  StatsTableHeaderActionCell,
  StatsTableStickyDataCell,
  StatsTableDataCell,
} from '@/components/StatsTableCells/StatsTableCells';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { ScoringContext } from '@/contexts/scoring/context';
import { COMMON_STRING } from '@/constants/dictionary';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { getSport } from '@/service/utils/getSport';
import { SPORT_ID } from '@/service/constants';
import { AdvancedStatsDataTableProps } from '../types';
import { getBallPossesion } from '../utils';

export const AdvancedStatsAllSportsTable = ({
  data,
  headerData,
}: AdvancedStatsDataTableProps) => {
  const {
    state: { fixtureSummary },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const {
    activeButtons: { type, quarter },
  } = useContext(StatsContext);

  const [activeColumn, setActiveColumn] = useState<ActiveColumnType>(null);
  const colGroupRef = useRef<HTMLTableColElement>(null);
  const isSoccer = getSport(fixtureConfig)?.id === SPORT_ID.SOCCER;
  const isTeamTabWithAllQuartersFilterSelected =
    type === STATS_BUTTONS.TYPE.TEAM &&
    quarter === STATS_BUTTONS.QUARTER.ALL.LABEL &&
    isSoccer;

  const columnsInGroup = useMemo(() => {
    let columnsCounter = 1;
    let extraColumns = [columnsCounter++];
    if (isTeamTabWithAllQuartersFilterSelected) {
      extraColumns.push(columnsCounter++);
    }
    const actionColumnsIndex =
      headerData
        ?.map((action) => {
          let columnsIndex: number[] = [];
          action?.subActions?.map(() => columnsIndex.push(columnsCounter++));
          columnsIndex.push(columnsCounter++);
          return columnsIndex;
        })
        .flat() || [];
    return extraColumns.concat(actionColumnsIndex);
  }, [headerData, isTeamTabWithAllQuartersFilterSelected]);

  return (
    <StatsTableContainer>
      <StatsTable>
        <colgroup ref={colGroupRef}>
          <col key={'col-names'} />
          {columnsInGroup?.map((index) => (
            <StyledCol
              key={`col-${index}`}
              index={index}
              activeColumn={activeColumn}
              aria-label={ARIA_LABEL.HOVERED_COL}
            />
          ))}
        </colgroup>
        <StatsTableHead>
          <TableRow>
            <StatsTableStickyHeaderActionCell rowSpan={2} key={'header-name'}>
              {COMMON_STRING.NAME}
            </StatsTableStickyHeaderActionCell>
            {isTeamTabWithAllQuartersFilterSelected && (
              <StatsTableHeaderActionCell rowSpan={2}>
                {COMMON_STRING.BALL_POSSESSION}
              </StatsTableHeaderActionCell>
            )}
            {headerData?.map((action) => (
              <StatsTableHeaderActionCell
                colSpan={
                  !!action?.subActions?.length
                    ? action.subActions.length + 1
                    : 1
                }
                key={`header-${action?.actionId}`}
              >
                {action?.actionId &&
                  getActionName(action.actionId, fixtureConfig)}
              </StatsTableHeaderActionCell>
            ))}
          </TableRow>
          <TableRow>
            {headerData?.map((action) => (
              <Fragment key={`subheader-action-wrapper-${action?.actionId}`}>
                {action?.subActions?.map((subAction) => (
                  <StatsTableHeaderActionCell
                    key={`subheader-${subAction}-${action.actionId}`}
                  >
                    {getActionSubTypeName(subAction, fixtureConfig) ||
                      subAction}
                  </StatsTableHeaderActionCell>
                ))}
                <StatsTableHeaderActionCell
                  key={`subheader-${action?.actionId}`}
                >
                  {COMMON_STRING.TOTAL}
                </StatsTableHeaderActionCell>
              </Fragment>
            ))}
          </TableRow>
        </StatsTableHead>
        <StatsTableBody
          activeColumn={activeColumn}
          setActiveColumn={setActiveColumn}
          colGroupRef={colGroupRef}
        >
          {data?.map((statistics) => (
            <TableRowWithHover
              key={`table-row-${statistics.playerId || statistics.teamId}`}
            >
              <StatsTableStickyDataCell>
                {statistics?.playerId
                  ? getPlayerName(
                      statistics.playerId,
                      fixtureConfig,
                      fixtureSummary,
                    )
                  : statistics?.teamId
                  ? getTeamName(
                      statistics.teamId,
                      fixtureConfig,
                      fixtureSummary,
                    )
                  : ''}
              </StatsTableStickyDataCell>
              {isTeamTabWithAllQuartersFilterSelected && (
                <StatsTableDataCell>
                  {statistics?.teamId
                    ? getBallPossesion({
                        teamId: statistics.teamId,
                        teamStatistics:
                          fixtureSummary?.advancedSportStatistics
                            ?.teamsStatistics,
                      })
                    : COMMON_STRING.NOT_AVAILABLE}
                </StatsTableDataCell>
              )}

              {headerData?.map((action) => (
                <Fragment key={`table-cell-${action?.actionId}`}>
                  {action?.subActions?.map(
                    (subAction) =>
                      subAction && (
                        <StatsTableDataCell
                          key={`${action.actionId}-${subAction}-${statistics.playerId}`}
                        >
                          {statistics.statistics
                            ?.find(
                              (statisticsAction) =>
                                action.actionId === statisticsAction?.actionId,
                            )
                            ?.subActionAggregates.find(
                              (aggregates) =>
                                aggregates.subActionId === subAction,
                            )?.subActionAggregate || 0}
                        </StatsTableDataCell>
                      ),
                  )}
                  <StatsTableDataCell
                    key={`${action?.actionId}-${statistics.playerId}`}
                  >
                    {statistics.statistics?.find(
                      (activeAction) =>
                        activeAction?.actionId === action?.actionId,
                    )?.actionAggregate || 0}
                  </StatsTableDataCell>
                </Fragment>
              ))}
            </TableRowWithHover>
          ))}
        </StatsTableBody>
      </StatsTable>
    </StatsTableContainer>
  );
};
