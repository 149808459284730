export function splitArray<
  T,
  U extends string | number | symbol,
  X extends U[]
>(source: T[], keys: X, keySelector: (val: T) => U): Record<U, T[]> {
  const init = {} as Record<U, T[]>;
  for (let key of keys) {
    init[key] = [];
  }
  return source.reduce((acc, val) => {
    acc[keySelector(val)].push(val);
    return acc;
  }, init);
}
