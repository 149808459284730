import {
  Autocomplete,
  Button,
  Checkbox,
  Stack,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FC, useEffect, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ActionFilter } from './useActionsFilters';

export interface AutocompleteFilterProps {
  options: ActionFilter[];
  onChange: (event: any, value: ActionFilter[]) => void;
  label: string;
  disabled?: boolean;
  selectedOptions: ActionFilter[];
}
export const CHANGE_MODE_BUTTON_LABEL = 'Change mode';

const changeExclude = (actionFilters: ActionFilter[], exclude: boolean) =>
  actionFilters.map((actionFilter) => ({
    ...actionFilter,
    exclude,
  }));

export const AutocompleteFilter: FC<AutocompleteFilterProps> = ({
  options,
  onChange,
  label,
  disabled = false,
  selectedOptions,
}) => {
  const [exclude, setExclude] = useState(false);
  const handleChange = (_: any, newValues: ActionFilter[]) => {
    onChange(_, changeExclude(newValues, exclude));
  };
  const onExcludeClick = (event: any) => {
    setExclude((previous) => {
      const newExclude = !previous;
      onChange(event, changeExclude(selectedOptions, newExclude));
      return newExclude;
    });
  };
  useEffect(() => {
    if (selectedOptions.some((option) => option.exclude !== exclude)) {
      setExclude((previous) => !previous);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <Stack direction='row' gap={1}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        disabled={disabled}
        size='small'
        componentsProps={{
          paper: { elevation: 10 },
        }}
        sx={{ flex: 1 }}
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        isOptionEqualToValue={(opt, val) =>
          opt.property === val.property && opt.value === val.value
        }
        getOptionLabel={(option) =>
          option.displayValue || (option.value as string)
        }
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                size='small'
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                checked={selected}
              />
              {option.displayValue}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      <Button
        variant='text'
        color='inherit'
        size='small'
        sx={{ width: '9ch', justifyContent: 'flex-start' }}
        onClick={onExcludeClick}
        startIcon={exclude ? <VisibilityOff /> : <Visibility />}
        aria-label={CHANGE_MODE_BUTTON_LABEL}
      >
        {exclude ? 'Hide' : 'Show'}
      </Button>
    </Stack>
  );
};
