import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import EastIcon from '@mui/icons-material/East';
import { EditActionSelectProps } from './types';

export const TeamSelect: FC<EditActionSelectProps> = ({
  editedAction,
  onChange,
  action,
  fixtureConfig,
}) => {
  return (
    <>
      <Typography variant='subtitle2'>Team</Typography>
      <Stack direction='row' alignItems='center' gap={2}>
        <Stack flexDirection='row' flex='3 0 0'>
          <Typography textTransform='capitalize'>
            {
              fixtureConfig?.fixture?.teams?.find(
                (team) => team.id === action.teamId
              )?.name
            }
          </Typography>
        </Stack>

        <EastIcon
          sx={{
            fontSize: '1.8rem',
            color: (theme) => theme.palette.iconsColors.borderColor,
          }}
        />

        <Stack flex='5 0 0 '>
          <Autocomplete
            size='small'
            clearIcon={false}
            disableClearable={true}
            value={editedAction.teamId!}
            options={fixtureConfig?.fixture?.teams.map((team) => team.id) ?? []}
            getOptionLabel={(teamId) =>
              fixtureConfig?.fixture?.teams.find((team) => team.id === teamId)
                ?.name ?? ''
            }
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(event, newValue) =>
              onChange({
                teamId: newValue,
              })
            }
            renderOption={(props, teamId) => (
              <li
                {...props}
                style={{ paddingLeft: '1.5em', textTransform: 'capitalize' }}
              >
                {fixtureConfig?.fixture?.teams.find(
                  (team) => team.id === teamId
                )?.name ?? ''}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label={'Team'} />}
            sx={{ '.MuiAutocomplete-input': { textTransform: 'capitalize' } }}
          />
        </Stack>
      </Stack>
    </>
  );
};
