import { GenericFilterValue } from '../constants';
import { FilterTemplateTeam, FilterTemplateSendType } from '../types';

export type MTEmojiType = {
  code?: string;
  country?: string;
  literal?: string;
};

export type TemplateFormData = {
  id: string;
  emoji?: MTEmojiType | undefined;
  templateName?: string;
  sportId: number;
  actions?: HideableFilterItem<ActionFiterType>;
  team?: FilterTemplateTeam[];
  player?: PlayerFilterValue;
  sendTypes?: HideableFilterItem<FilterTemplateSendType>;
  comment?: GenericFilterValue;
  flag?: GenericFilterValue;
  actionsUpdated?: GenericFilterValue;
  latency?: GenericFilterValue;
  mistake?: GenericFilterValue;
};

export type HideableFilterItem<T> = {
  hidden: boolean;
  values: T[];
};

export type ActionFiterType = { id: number; name: string };

export const PlayerFilterValueArray = ['', 'truthy', 'unknown'];

export type PlayerFilterValue = '' | 'truthy' | 'unknown';

export enum TemplateDragDirection {
  Vertical,
  Horizontal,
}
