import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { FC, useContext } from 'react';
import { DICTIONARY } from '@/constants/dictionary';
import { ScoringContext } from '@/contexts/scoring/context';
import { ChecklistContext } from '@/contexts/checklist/context';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { DialogTitleWithClose } from '../common/DialogTitleWithClose';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { getCheckListCheckboxPermissionName } from '../PermissionsChecker/utils';
import { ChecklistCheckbox } from './ChecklistCheckbox';
import { CheckboxWarning } from './CheckboxWarning';
import { useCheckboxHandlers } from './useCheckboxHandlers';

export interface ChecklistProps {
  onClose: () => void;
}

export const Checklist: FC<ChecklistProps> = ({ onClose }) => {
  const {
    state: { fixtureChecklist, fixtureId },
  } = useContext(ScoringContext);

  const checklistCtx = useContext(ChecklistContext);

  const isLoading = !fixtureChecklist;

  const { getBeforeChange, getOnBeforeChangeError } =
    useCheckboxHandlers(fixtureId);

  return (
    <Stack>
      <DialogTitleWithClose close={onClose}>
        {DICTIONARY.COMMON.CHECKLIST}
      </DialogTitleWithClose>
      <Divider />
      <FormGroup sx={{ px: 2 }}>
        {checklistCtx.checkboxes.map((checkbox) => {
          const hasWarnings = !!checkbox.warnings.length;
          const { index, requiresConfirmation } = checkbox;

          const beforeChange = getBeforeChange(index, requiresConfirmation);
          const onBeforeChangeError = getOnBeforeChangeError(
            index,
            requiresConfirmation,
          );

          return (
            <PermissionsChecker
              name={getCheckListCheckboxPermissionName(checkbox.index)}
              key={checkbox.name}
            >
              <Box>
                <Stack direction='row' alignItems='center' py={1}>
                  <FormControlLabel
                    disabled={checkbox.isDisabled}
                    control={
                      <ChecklistCheckbox
                        checklistCheckboxState={checkbox}
                        hasWarnings={hasWarnings}
                        beforeChange={beforeChange}
                        onBeforeChangeError={onBeforeChangeError}
                      />
                    }
                    label={checkbox.name}
                  />
                  {hasWarnings && (
                    <CheckboxWarning warnings={checkbox.warnings} />
                  )}
                </Stack>
                <Divider />
              </Box>
            </PermissionsChecker>
          );
        })}
      </FormGroup>
      <LoadingOverlay isLoading={isLoading} />
    </Stack>
  );
};
