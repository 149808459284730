import React, { useContext, useEffect, useMemo, useState } from 'react';
import { default as NewWindow } from 'react-new-window';
import { Box } from '@mui/material';
import { TabPanel } from '@/components/common/TabPanel';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  generateTabs,
  MAIN_TAB_NAME,
} from '@/components/FixtureTabs/helpers/generateTabs';
import { UIStateContext } from '@/contexts/UIState/context';
import { getSport } from '@/service/utils/getSport';
import { StreamingContext } from '@/contexts/streaming/context';
import { InfoMessage } from '@/components/common/InfoMessage';
import { StreamingBox } from '@/components/FixtureMain/FixtureMainStyledComponents';
import { COMMON_STRING } from '@/constants/dictionary';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { useTimer } from '@/utils/hooks/useTimer';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { ActionsTableProvider } from '../ActionsTable/context/ActionsTableProvider';
import { WITHIN_TRIGGER_SEC_TIME } from '../FixtureTabs/FixtureStreaming/constants';
import { FixtureTabs } from './FixtureTabs';

export const FixtureMain = () => {
  const { permissions } = useContext(RolePermissionsContext);
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const { setOpenedPanel } = useContext(UIStateContext);
  const {
    switchPictureInPictureMode,
    playing,
    setPlaying,
    playerWithStream,
    streamUrl,
    pictureInPicture,
    streamStartDate,
  } = useContext(StreamingContext);
  const { setIsSlaBreachActionSelected } = useContext(UIStateContext);

  const {
    state: { fixtureActions },
  } = useContext(ScoringContext);

  const { endTimer } = useTimer(streamStartDate, WITHIN_TRIGGER_SEC_TIME);

  const sport = getSport(fixtureConfig);
  const fixtureTabs = useMemo(
    () => generateTabs({ sportId: sport && sport.id, permissions }),
    [sport, permissions],
  );

  const [currentTabName, setCurrentTabName] = useState<string>(
    fixtureTabs[0].name,
  );

  useEffect(() => {
    if (currentTabName !== MAIN_TAB_NAME.QA) {
      setIsSlaBreachActionSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabName]);

  const handleChangeTab = (
    event: React.SyntheticEvent,
    selectedTab: string,
  ) => {
    if (streamUrl && playing) {
      if (
        selectedTab !== MAIN_TAB_NAME.STREAMING &&
        currentTabName === MAIN_TAB_NAME.STREAMING &&
        !pictureInPicture
      )
        switchPictureInPictureMode(true);
      if (selectedTab === MAIN_TAB_NAME.STREAMING)
        switchPictureInPictureMode(false);
    }
    setOpenedPanel(null);
    setCurrentTabName(selectedTab);
  };

  const isCurrentTab = (name: string) => name === currentTabName;

  return (
    <ActionsTableProvider actions={fixtureActions?.actions}>
      <FixtureTabs
        tabValue={currentTabName}
        tabHandler={handleChangeTab}
        tabs={fixtureTabs}
      />
      {fixtureTabs.map(({ name, component }, index) => (
        <TabPanel
          key={name}
          name={name}
          value={currentTabName}
          isStreamingTab={currentTabName === MAIN_TAB_NAME.STREAMING}
          sx={{
            height:
              currentTabName === MAIN_TAB_NAME.STREAMING && !endTimer
                ? '100%'
                : 'none',
            overflow: 'auto',
            flex:
              isCurrentTab(name) && currentTabName !== MAIN_TAB_NAME.STREAMING
                ? 1
                : isCurrentTab(name) &&
                  currentTabName === MAIN_TAB_NAME.STREAMING
                ? 'none'
                : 0,
          }}
        >
          {component}
        </TabPanel>
      ))}
      {streamUrl && (
        <PermissionsChecker name='FixturePage.Streaming'>
          <StreamingBox
            aria-label={ARIA_LABEL.STREAMING_BOX}
            isStreamingTab={currentTabName === MAIN_TAB_NAME.STREAMING}
          >
            {!pictureInPicture ? (
              playerWithStream
            ) : (
              <InfoMessage
                message={COMMON_STRING.PICTURE_IN_PICTURE_MODE_IS_OPEN}
              />
            )}
          </StreamingBox>
        </PermissionsChecker>
      )}
      {streamUrl && pictureInPicture && (
        <PermissionsChecker name='FixturePage.Streaming.PictureInPicture'>
          <NewWindow
            title={`${COMMON_STRING.LIVE_STREAM} - ${fixtureConfig?.fixture.name}`}
            features={{
              width: 450,
              height: 260,
            }}
            onUnload={() => {
              setPlaying(currentTabName === MAIN_TAB_NAME.STREAMING);
              switchPictureInPictureMode(false);
            }}
          >
            <Box bgcolor={(theme) => theme.palette.common.black}>
              {playerWithStream}
            </Box>
          </NewWindow>
        </PermissionsChecker>
      )}
    </ActionsTableProvider>
  );
};
