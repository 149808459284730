import { FC, PropsWithChildren, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { datadogLogs } from '@datadog/browser-logs';

export const Protected: FC<PropsWithChildren> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } else {
      datadogLogs.setUser({
        id: authState.idToken?.claims.sub,
        name: authState.idToken?.claims.name,
        email: authState.idToken?.claims.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return <>{children}</>;
};
