import VolumeDown from '@mui/icons-material/VolumeDown';
import { IconButton, Slider, Stack } from '@mui/material';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { COMMON_STRING } from '@/constants/dictionary';

interface StreamingVolumeSliderProps {
  volume: number;
  onClickVolume: (newVolume: number) => void;
}

export const StreamingVolumeSlider = ({
  volume,
  onClickVolume,
}: StreamingVolumeSliderProps) => {
  return (
    <Stack flexDirection='row' alignItems='center' width='30%' gap={1}>
      <IconButton
        disableRipple
        onClick={() => onClickVolume(volume > 0 ? 0 : 0.5)}
      >
        {volume === 0 && <VolumeOffIcon />}
        {volume !== 0 && <VolumeDown />}
      </IconButton>
      <Slider
        min={0}
        max={1}
        step={0.01}
        aria-label={COMMON_STRING.VOLUME}
        value={volume}
        onChange={(event: Event, newValue: number | number[]) => {
          if (typeof newValue === 'number') {
            onClickVolume(newValue);
          }
        }}
      />
      <IconButton disableRipple onClick={() => onClickVolume(1)}>
        <VolumeUp />
      </IconButton>
    </Stack>
  );
};
