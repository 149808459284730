import { useEffect, useMemo, useState } from 'react';
import { useNotificationsConfiguration } from '@/service/hooks/useNotificationsConfiguration';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import {
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';
import { Actions, FixtureSummary } from '@/service/types';

interface UseStartTimerProps {
  fixtureSummary: FixtureSummary | null;
  fixtureId: FixtureSummary['fixtureId'];
  fixtureActions: Actions | null;
}

export const useStartTimer = ({
  fixtureSummary,
  fixtureId,
  fixtureActions,
}: UseStartTimerProps) => {
  const token = useAuthToken();
  const [
    differenceBetweenPreStartAndRealTime,
    setDifferenceBetweenPreStartAndRealTime,
  ] = useState<number>();
  const [timerDuration, setTimerDuration] = useState<number>();
  const [showTimer, setShowTimer] = useState(false);
  const { notificationsConfiguration } = useNotificationsConfiguration({
    token,
    fixtureId,
  });

  const realTime = useMemo(
    () => new Date().getTime(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsConfiguration],
  );

  const fixtureStartTime = useMemo(
    () =>
      !notificationsConfiguration
        ? undefined
        : new Date(notificationsConfiguration.startTimeUtc).getTime(),
    [notificationsConfiguration],
  );

  useEffect(() => {
    if (!fixtureStartTime || !realTime || !notificationsConfiguration) {
      return;
    } else {
      const differenceBetweenStartAndRealTime = fixtureStartTime - realTime;

      setDifferenceBetweenPreStartAndRealTime(
        differenceBetweenStartAndRealTime <=
          notificationsConfiguration.kickOffNotificationOffsetInMilliseconds
          ? 0
          : differenceBetweenStartAndRealTime -
              notificationsConfiguration.kickOffNotificationOffsetInMilliseconds,
      );
    }
  }, [fixtureStartTime, realTime, notificationsConfiguration]);

  useEffect(() => {
    if (!fixtureStartTime || !realTime || !notificationsConfiguration) return;
    if (
      !fixtureSummary?.periods.length &&
      fixtureSummary?.currentPeriod === 1
    ) {
      setTimerDuration(
        differenceBetweenPreStartAndRealTime === 0
          ? fixtureStartTime - realTime >= 0
            ? fixtureStartTime - realTime
            : 0
          : notificationsConfiguration.kickOffNotificationOffsetInMilliseconds,
      );
    } else {
      const endPeriodAction = fixtureActions?.actions.find(
        (action) =>
          action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.END_PERIOD &&
          action.sendTypeId === MATCH_ACTION_SEND_TYPE.CONFIRMED,
      );
      const endPeriodActionTime = new Date(
        endPeriodAction?.timestamp as string,
      ).getTime();
      const time = new Date().getTime();
      const differenceBetweenRealAndEndPeriodActionTimes =
        time - endPeriodActionTime;

      if (
        differenceBetweenRealAndEndPeriodActionTimes >=
        notificationsConfiguration.afterPeriodNotificationDelayInMilliseconds
      ) {
        setTimerDuration(0);
      } else {
        setTimerDuration(
          notificationsConfiguration.afterPeriodNotificationDelayInMilliseconds -
            differenceBetweenRealAndEndPeriodActionTimes,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    differenceBetweenPreStartAndRealTime,
    fixtureSummary?.periods,
    fixtureSummary?.currentPeriod,
    notificationsConfiguration,
  ]);

  useEffect(() => {
    if (differenceBetweenPreStartAndRealTime === undefined || showTimer) {
      return;
    }

    setTimeout(() => {
      setShowTimer(true);
    }, differenceBetweenPreStartAndRealTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differenceBetweenPreStartAndRealTime]);

  return { showTimer, timerDuration, differenceBetweenPreStartAndRealTime };
};
