import { useContext } from 'react';
import Stack from '@mui/material/Stack';
import { FixtureScore } from '@/components/FixtureScore';
import { ScoringContext } from '@/contexts/scoring/context';
import { getSport } from '@/service/utils/getSport';
import {
  CollectionStatusId,
  FixtureStatusId,
  FIXTURE_ACTION_TYPE,
  SPORT_ID,
} from '@/service/constants';
import { getPeriodActionAggregate } from '@/service/utils/getActionAggregates';
import { getSoccerSummaryStats } from '@/service/utils/getSoccerSummaryStats';
import { GameState } from '@/components/FixtureSummary/GameState';
import {
  PostMatchQaUpdateMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { SUCCESS_STRING } from '@/constants/dictionary';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { LoadingPlaceholder } from '../common/LoadingPlaceholder';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { hasPermissionsToElement } from '../PermissionsChecker/utils';
import { StatsCompareTable } from '../StatsCompareTable';
import { NotUpdatedActionStats } from '../StatsCompareTable/NotUpdatedActionStats';
import { PeriodsScores } from './PeriodsScores';
import { StatusesBar } from './StatusesBar';

export const FixtureSummary = () => {
  const {
    state: { fixtureSummary, fixtureId, postMatchQa },
    fixtureConfigState: { fixtureConfig },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const sport = getSport(fixtureConfig);

  const isHockey = sport && sport.id === SPORT_ID.ICE_HOCKEY;
  const isBasket = sport && sport.id === SPORT_ID.BASKETBALL;
  const isSoccer = sport && sport.id === SPORT_ID.SOCCER;

  const isGameActionsToBeUpdated = isBasket || isHockey;

  const { permissions } = useContext(RolePermissionsContext);

  const { dispatch: updatePostMatchQa, isLoading } =
    useDispatchWithResponse<PostMatchQaUpdateMsg>(
      SCORING_WORKER_HOST_ACTION.UPDATE_POST_MATCH_QA,
    );

  const onUpdatePostMatchQa = () =>
    updatePostMatchQa(
      { fixtureId },
      { successMessage: SUCCESS_STRING.COMPARISON_DATA_SUCCESSFULLY_UPDATED },
    );

  if (!fixtureSummary) {
    return <LoadingPlaceholder py={5} />;
  }

  const {
    currentClock,
    isClockPaused,
    fixtureStatus,
    collectionStatus,
    coverageLevel,
    homeTeam,
    awayTeam,
    currentScore,
    periods,
    advancedSportStatistics,
  } = fixtureSummary;

  const periodAggregateActions = isBasket
    ? getPeriodActionAggregate(fixtureSummary, FIXTURE_ACTION_TYPE.PLAYER_FOUL)
    : undefined;

  const soccerStats = isSoccer
    ? getSoccerSummaryStats(
        fixtureSummary,
        postMatchQa?.externalApiTeamStatistics ?? [],
      )
    : undefined;

  return (
    <PermissionsChecker name='FixturePage.ScoreboardPanel'>
      <Stack direction='row' alignSelf='stretch' justifyContent='space-evenly'>
        <Stack
          data-testid='score-board-container'
          justifySelf='center'
          alignSelf='flex-start'
          alignItems='center'
          flex='1 1 auto'
          gap={1}
          p={1}
          pt={
            hasPermissionsToElement(
              'FixturePage.ScoreboardPanel.ExternalStatistics',
              permissions,
            )
              ? 0
              : 1
          }
        >
          <StatusesBar
            fixtureStatusId={fixtureStatus as FixtureStatusId}
            collectionStatusId={collectionStatus as CollectionStatusId}
            coverageLevelId={coverageLevel}
          />

          <FixtureScore
            homeTeam={{
              id: homeTeam.id,
              name: homeTeam.teamName,
              color: homeTeam.teamColor,
              score: currentScore.homeTeamScore,
            }}
            awayTeam={{
              id: awayTeam.id,
              name: awayTeam.teamName,
              color: awayTeam.teamColor,
              score: currentScore.awayTeamScore,
            }}
            stateWithTimeoutInfo={
              advancedSportStatistics?.americanFootballFixtureState
            }
            currentClock={currentClock}
            isClockPaused={isClockPaused}
          />
          <Stack
            width='100%'
            display={isGameActionsToBeUpdated ? 'grid' : 'flex'}
            gridTemplateColumns={
              isGameActionsToBeUpdated ? '1fr max-content 1fr' : 'none'
            }
            alignItems='center'
            direction={isSoccer && soccerStats ? 'row' : 'column'}
            justifyContent={'space-between'}
          >
            {isSoccer && soccerStats && (
              <StatsCompareTable
                stats={soccerStats.homeTeam}
                refreshData={onUpdatePostMatchQa}
                isUpdating={isLoading}
              />
            )}
            {isGameActionsToBeUpdated && <NotUpdatedActionStats />}
            <PeriodsScores
              periods={periods}
              actionAggregates={periodAggregateActions}
            />
            {isSoccer && soccerStats && (
              <StatsCompareTable
                reverse
                stats={soccerStats.awayTeam.reverse()}
                refreshData={onUpdatePostMatchQa}
                isUpdating={isLoading}
              />
            )}
          </Stack>
          {advancedSportStatistics?.americanFootballFixtureState && (
            <GameState />
          )}
        </Stack>
      </Stack>
    </PermissionsChecker>
  );
};
