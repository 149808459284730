import Stack, { StackProps } from '@mui/material/Stack';
import { FC, PropsWithChildren } from 'react';

export type ButtonsWrapperProps = PropsWithChildren<StackProps>;

export const ButtonsWrapper: FC<ButtonsWrapperProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Stack
      p={1}
      direction='row'
      gap={1}
      alignItems='center'
      flex={1}
      flexWrap='wrap'
      {...restProps}
    >
      {children}
    </Stack>
  );
};
