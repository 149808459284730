import { Dialog, DialogContent, DialogProps, Typography } from '@mui/material';
import { FC, useContext, useEffect, useReducer } from 'react';
import { Stack } from '@mui/system';
import Divider from '@mui/material/Divider';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  COLLECTION_STATUS_ID,
  CollectionStatusId,
  COVERAGE_LEVEL_ID,
  CoverageLevelId,
  FIXTURE_STATUS_ID,
  FixtureStatusId,
} from '@/service/constants';
import {
  SCORING_WORKER_HOST_ACTION,
  SetFixtureStatusMsg,
} from '@/workers/scoring/types';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useReasonOptions } from '@/service/hooks/useReasonOptions';
import {
  COLLECTION_STATUS_OPTIONS,
  COVERAGE_LEVEL_OPTIONS,
  ELEMENT_ID,
  ELEMENT_LABEL,
  FIXTURE_STATUS_OPTIONS,
  REASON_CODE,
} from '@/components/StatusesEditDialog/constants';
import { StatusReason } from '@/service/types';
import {
  createStatusesSuccessMessage,
  generateInitialStatusesState,
  generateReasonsForSubmit,
  setDefaultReasonValue,
  statusesReducer,
  validateStatusesChanges,
} from '@/components/StatusesEditDialog/utils';
import { StatusesButtons } from '@/components/StatusesEditDialog/common/StatusesButtons';
import { StatusesSelect } from '@/components/StatusesEditDialog/common/StatusesSelect';
import { StatusesReasonField } from '@/components/StatusesEditDialog/common/StatusesReasonField';
import { DialogTitleWithClose } from '../common/DialogTitleWithClose';
import { LoadingOverlay } from '../common/LoadingOverlay';

export interface StatusesEditDialogProps extends DialogProps {
  onClose: () => void;
}

const DialogBody: FC<Pick<StatusesEditDialogProps, 'onClose'>> = ({
  onClose,
}) => {
  const {
    state: { fixtureSummary, fixtureId },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const token = useAuthToken();
  const {
    fixtureStatusReasonCodes,
    coverageLevelReasonCodes,
    collectionStatusReasonCodes,
    isLoading: isLoadingReasons,
  } = useReasonOptions({ token });

  const [state, dispatchStatuses] = useReducer(
    statusesReducer,
    generateInitialStatusesState(fixtureSummary),
  );

  const { dispatch, isLoading } = useDispatchWithResponse<SetFixtureStatusMsg>(
    SCORING_WORKER_HOST_ACTION.FIXTURE_STATUSES_SET,
  );
  useEffect(() => {
    const isUnverifiedFixtureStatus =
      state.fixtureStatus === FIXTURE_STATUS_ID.UNVERIFIED &&
      state.fixtureStatus !== fixtureSummary?.fixtureStatus;

    const isDroppedCollectionStatus =
      state.collectionStatus === COLLECTION_STATUS_ID.DROPPED &&
      state.collectionStatus !== fixtureSummary?.collectionStatus;

    const isDefaultFixtureAndCollectionStatusesValues =
      state.fixtureStatus === fixtureSummary?.fixtureStatus &&
      state.collectionStatus === fixtureSummary?.collectionStatus;

    const dispatchCoverageLevel = (coverageLevel: CoverageLevelId) =>
      dispatchStatuses({
        coverageLevel: coverageLevel,
        ...setDefaultReasonValue({
          key: 'coverageLevel',
          newStatusValue: coverageLevel,
          defaultStatusValue: fixtureSummary?.coverageLevel,
          defaultReason: fixtureSummary?.coverageLevelReason,
          defaultReasonCode: fixtureSummary?.coverageLevelReasonCode,
          reasonCodesList: coverageLevelReasonCodes,
        }),
      });

    if (
      (isUnverifiedFixtureStatus || isDroppedCollectionStatus) &&
      state.coverageLevel !== COVERAGE_LEVEL_ID.NO_COVERAGE
    ) {
      return dispatchCoverageLevel(COVERAGE_LEVEL_ID.NO_COVERAGE);
    }

    if (isDefaultFixtureAndCollectionStatusesValues) {
      return dispatchCoverageLevel(fixtureSummary?.coverageLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fixtureStatus, state.collectionStatus]);

  const submit = async () => {
    if (
      state.fixtureStatus === null ||
      state.collectionStatus === null ||
      state.coverageLevel < 0
    ) {
      return;
    }

    dispatch(
      {
        fixtureId: fixtureId,
        fixtureStatus: state.fixtureStatus,
        collectionStatus: state.collectionStatus,
        coverageLevel: state.coverageLevel,
        ...generateReasonsForSubmit(state, fixtureSummary),
      },
      {
        successMessage: createStatusesSuccessMessage(state, fixtureSummary),
      },
    ).finally(() => onClose());
  };

  return (
    <>
      <DialogTitleWithClose close={onClose}>Match status</DialogTitleWithClose>
      <DialogContent sx={{ px: 2 }}>
        <Stack
          direction='column'
          justifyContent='stretch'
          gap={3}
          divider={<Divider flexItem />}
        >
          <Stack gap={2}>
            <Typography variant='subtitle2'>Fixture</Typography>
            <Stack flexDirection='row' gap={1}>
              <StatusesSelect
                id={ELEMENT_ID.FIXTURE_STATUS_SELECT}
                labelId={ELEMENT_ID.FIXTURE_STATUS_SELECT_LABEL}
                label='Fixture Status'
                aria-label={ELEMENT_LABEL.FIXTURE_STATUS_SELECT}
                value={state.fixtureStatus}
                onChange={(e) => {
                  return dispatchStatuses({
                    fixtureStatus: e.target.value as FixtureStatusId,
                    ...setDefaultReasonValue({
                      key: 'fixtureStatus',
                      newStatusValue: e.target.value as FixtureStatusId,
                      defaultStatusValue: fixtureSummary?.fixtureStatus,
                      defaultReason: fixtureSummary?.fixtureStatusReason,
                      defaultReasonCode:
                        fixtureSummary?.fixtureStatusReasonCode,
                      reasonCodesList:
                        fixtureStatusReasonCodes?.[state.fixtureStatus],
                    }),
                  });
                }}
                options={FIXTURE_STATUS_OPTIONS}
              />
              <StatusesSelect
                id={ELEMENT_ID.FIXTURE_STATUS_REASON_SELECT}
                labelId={ELEMENT_ID.FIXTURE_STATUS_REASON_SELECT_LABEL}
                label='Reason For Change'
                aria-label={ELEMENT_LABEL.COLLECTION_STATUS_REASON_SELECT}
                value={
                  fixtureStatusReasonCodes &&
                  state.fixtureStatusReasonCode !== REASON_CODE.EMPTY
                    ? state.fixtureStatusReasonCode
                    : ''
                }
                onChange={(e) =>
                  dispatchStatuses({
                    fixtureStatusReasonCode: e.target
                      .value as StatusReason['code'],
                  })
                }
                options={fixtureStatusReasonCodes?.[state.fixtureStatus]}
                disabled={state.fixtureStatus === fixtureSummary?.fixtureStatus}
              />
            </Stack>
            {state.fixtureStatusReasonCode === REASON_CODE.OTHER && (
              <StatusesReasonField
                disabled={state.fixtureStatus === fixtureSummary?.fixtureStatus}
                placeholder={'Reason for change fixture status...'}
                customReason={state.fixtureStatusReason}
                onChange={(e) =>
                  dispatchStatuses({
                    fixtureStatusReason: e.target
                      .value as StatusReason['reason'],
                  })
                }
              />
            )}
          </Stack>
          <Stack gap={2}>
            <Typography variant='subtitle2'>Collection</Typography>
            <Stack flexDirection='row' gap={1}>
              <StatusesSelect
                id={ELEMENT_ID.COLLECTION_STATUS_SELECT}
                labelId={ELEMENT_ID.COLLECTION_STATUS_SELECT_LABEL}
                label='Collection Status'
                aria-label={ELEMENT_LABEL.COLLECTION_STATUS_SELECT}
                value={state.collectionStatus}
                onChange={(e) =>
                  dispatchStatuses({
                    collectionStatus: e.target.value as CollectionStatusId,
                    ...setDefaultReasonValue({
                      key: 'collectionStatus',
                      newStatusValue: e.target.value as CollectionStatusId,
                      defaultStatusValue: fixtureSummary?.collectionStatus,
                      defaultReason: fixtureSummary?.collectionStatusReason,
                      defaultReasonCode:
                        fixtureSummary?.collectionStatusReasonCode,
                      reasonCodesList:
                        collectionStatusReasonCodes?.[state.collectionStatus],
                    }),
                  })
                }
                options={COLLECTION_STATUS_OPTIONS}
                testId='collection-status-select'
              />

              <StatusesSelect
                id={ELEMENT_ID.COLLECTION_STATUS_REASON_SELECT}
                labelId={ELEMENT_ID.COLLECTION_STATUS_REASON_SELECT_LABEL}
                label='Reason For Change'
                aria-label={ELEMENT_LABEL.COLLECTION_STATUS_REASON_SELECT}
                value={
                  collectionStatusReasonCodes &&
                  state.collectionStatusReasonCode !== REASON_CODE.EMPTY
                    ? state.collectionStatusReasonCode
                    : ''
                }
                onChange={(e) =>
                  dispatchStatuses({
                    collectionStatusReasonCode: e.target
                      .value as StatusReason['code'],
                  })
                }
                options={collectionStatusReasonCodes?.[state.collectionStatus]}
                disabled={
                  state.collectionStatus === fixtureSummary?.collectionStatus
                }
                testId='collection-status-reason-select'
              />
            </Stack>
            {state.collectionStatusReasonCode === REASON_CODE.OTHER && (
              <StatusesReasonField
                placeholder='Reason for change collection status...'
                customReason={state.collectionStatusReason}
                onChange={(e) =>
                  dispatchStatuses({
                    collectionStatusReason: e.target
                      .value as StatusReason['reason'],
                  })
                }
                disabled={
                  state.collectionStatus === fixtureSummary?.collectionStatus
                }
              />
            )}
          </Stack>
          <Stack gap={2}>
            <Typography variant='subtitle2'>Coverage</Typography>
            <Stack flexDirection='row' gap={1}>
              <StatusesSelect
                id={ELEMENT_ID.COVERAGE_LEVEL_SELECT}
                labelId={ELEMENT_ID.COVERAGE_LEVEL_SELECT_LABEL}
                label='Coverage Level'
                aria-label={ELEMENT_LABEL.COVERAGE_LEVEL_SELECT}
                value={state.coverageLevel}
                onChange={(e) =>
                  dispatchStatuses({
                    coverageLevel: e.target.value as number,
                    ...setDefaultReasonValue({
                      key: 'coverageLevel',
                      newStatusValue: e.target.value as CoverageLevelId,
                      defaultStatusValue: fixtureSummary?.coverageLevel,
                      defaultReason: fixtureSummary?.coverageLevelReason,
                      defaultReasonCode:
                        fixtureSummary?.coverageLevelReasonCode,
                      reasonCodesList: coverageLevelReasonCodes,
                    }),
                  })
                }
                options={COVERAGE_LEVEL_OPTIONS}
              />
              <StatusesSelect
                id={ELEMENT_ID.COVERAGE_LEVEL_REASON_SELECT}
                labelId={ELEMENT_ID.COVERAGE_LEVEL_REASON_SELECT_LABEL}
                label='Reason For Change'
                value={
                  coverageLevelReasonCodes &&
                  state.coverageLevelReasonCode !== REASON_CODE.EMPTY
                    ? state.coverageLevelReasonCode
                    : ''
                }
                aria-label={ELEMENT_LABEL.COVERAGE_LEVEL_REASON_SELECT}
                onChange={(e) =>
                  dispatchStatuses({
                    coverageLevelReasonCode: e.target
                      .value as StatusReason['code'],
                  })
                }
                options={coverageLevelReasonCodes}
                disabled={state.coverageLevel === fixtureSummary?.coverageLevel}
              />
            </Stack>
            {state.coverageLevelReasonCode === REASON_CODE.OTHER && (
              <StatusesReasonField
                disabled={state.coverageLevel === fixtureSummary?.coverageLevel}
                placeholder='Reason for change coverage level...'
                customReason={state.coverageLevelReason}
                onChange={(e) =>
                  dispatchStatuses({
                    coverageLevelReason: e.target
                      .value as StatusReason['reason'],
                  })
                }
              />
            )}
          </Stack>
        </Stack>
        <LoadingOverlay
          isLoading={!fixtureSummary || isLoading || isLoadingReasons}
        />
      </DialogContent>
      <StatusesButtons
        onClose={onClose}
        onSubmit={submit}
        disabled={validateStatusesChanges(fixtureSummary, state)}
      />
    </>
  );
};

export const StatusesEditDialog: FC<StatusesEditDialogProps> = (props) => {
  const { onClose, ...dialogProps } = props;

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      {...dialogProps}
      data-testid='statuses-edit-dialog'
    >
      <DialogBody onClose={onClose} />
    </Dialog>
  );
};
