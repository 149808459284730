import {
  GameStatistics,
  PlayerGameStatistics,
  PlayerPeriodStatistics,
  TeamGameStatistics,
  TeamPeriodStatistics,
} from '@/service/types';
import {
  KeyType,
  SubKeyType,
} from '@/components/ScoresheetTables/common/types';

export enum LEADS_TABLE_KEYS {
  POINTS_IN_THE_PAINT = 'pointsInThePaint',
  BIGGEST_LEAD = 'biggestLead',
  BIGGEST_SCORING_RUN = 'biggestScoringRun',
  LEAD_CHANGES = 'leadChanges',
  TIMES_TIED = 'timesTied',
  TIME_WITH_LEAD = 'timeWithLead',
}

export type LeadsTableCells = {
  [key in keyof Partial<
    TeamGameStatistics &
      PlayerGameStatistics &
      PlayerPeriodStatistics &
      GameStatistics &
      TeamPeriodStatistics
  >]: {
    keys: KeyType[];
    subKeys?: SubKeyType[];
    colSpan?: number;
  };
};

export const LEADS_TABLE_CELLS: LeadsTableCells = {
  pointsInThePaint: {
    keys: ['paintStats'],
    subKeys: [
      'zonePoints',
      'zoneShotsMade',
      'zoneShotAttempts',
      'zoneShotsPercentage',
    ],
  },
  biggestLead: { keys: ['biggestLead', 'biggestLeadScore'] },
  biggestScoringRun: {
    keys: ['longestScoringRun', 'longestScoringRunScore'],
  },
  leadChanges: { keys: ['leadChanges'], colSpan: 2 },
  timesTied: { keys: ['timesTied'], colSpan: 2 },
  timeWithLead: { keys: ['timeOnLead'] },
};
