import { FixtureAction, FixtureConfig } from '@/service/types';
import {
  MarketPerAction,
  MarketPerActionOpenClose,
  MarketsContextValue,
  OpenCloseMarketFilterType,
} from '@/contexts/markets/types';
import {
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';

interface FindOpenAndCloseMarketActionsProps {
  openMarketFilters: OpenCloseMarketFilterType[];
  closeMarketFilters: OpenCloseMarketFilterType[];
  fixtureActions: FixtureAction[];
}

export const isMarketOpen = (actions: FixtureAction[] | undefined) => {
  const marketAction = actions?.find(
    (action) =>
      (action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.OPEN_MARKET ||
        action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.CLOSE_MARKET) &&
      action.sendTypeId !== MATCH_ACTION_SEND_TYPE.CANCELLED &&
      action.sendTypeId !== MATCH_ACTION_SEND_TYPE.PENDING,
  );

  if (!marketAction) {
    return true;
  }

  if (marketAction.fixtureActionTypeId === FIXTURE_ACTION_TYPE.CLOSE_MARKET) {
    return marketAction.sendTypeId === MATCH_ACTION_SEND_TYPE.DELETED;
  } else {
    return marketAction.sendTypeId !== MATCH_ACTION_SEND_TYPE.DELETED;
  }
};

export const isSeparateMarketOpen = (
  actions: FixtureAction[] | undefined,
  marketPerActionList: MarketPerAction[],
): MarketPerActionOpenClose => {
  return marketPerActionList.reduce(
    (o, action) =>
      Object.assign(o, {
        [action.id]: !actions
          ? { openMarket: -1, closeMarket: -1, isMarketOpen: true }
          : findOpenAndCloseMarketActions({
              ...generateOpenCloseMarketFilters(action.id),
              fixtureActions: actions,
            }),
      }),
    {},
  );
};

export const generateInitialMarketsState = (
  fixtureActions: FixtureAction[],
) => {
  return {
    allMarkets: isMarketOpen(fixtureActions),
  } as MarketsContextValue;
};

export const generateMarketsPerActionList = (
  fixtureConfig: FixtureConfig | undefined,
): { id: number; name: string }[] | undefined => {
  if (!fixtureConfig) return undefined;

  const { actionButtons } = fixtureConfig.fixtureOptions;
  const FIXTURE_ACTION_TYPES = new Set([
    FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY,
    FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER,
  ]);

  const subTypeMap: { [key: number]: string } = {};

  for (const actionButton of actionButtons) {
    const { id, subTypes } = actionButton.actionType;
    if (FIXTURE_ACTION_TYPES.has(id) && subTypes) {
      for (const subType of subTypes) {
        const subtypeIdString = subType?.id.toString();
        if (subtypeIdString) {
          const newSubTypeId = Number(
            subtypeIdString.replace(id.toString(), ''),
          );
          if (!subTypeMap[newSubTypeId]) {
            subTypeMap[newSubTypeId] = subType?.name;
          }
        }
      }
    }
  }

  const marketsPerActionList = Object.entries(subTypeMap).map(([id, name]) => ({
    id: Number(id),
    name,
  }));

  return marketsPerActionList.length === 0 ? undefined : marketsPerActionList;
};

export const generateFindActionOpenCloseMarketRules = (
  filters: OpenCloseMarketFilterType[],
  fixtureAction: FixtureAction,
) =>
  filters.find(
    (filter) =>
      filter.actionType === fixtureAction.fixtureActionTypeId &&
      filter.sendType === fixtureAction.sendTypeId &&
      filter.actionSubType === fixtureAction.fixtureActionSubTypeId,
  );

export const findOpenAndCloseMarketActions = ({
  openMarketFilters,
  closeMarketFilters,
  fixtureActions,
}: FindOpenAndCloseMarketActionsProps) => {
  const openMarketAction = fixtureActions.find((fixtureAction) =>
    generateFindActionOpenCloseMarketRules(openMarketFilters, fixtureAction),
  )?.fixtureSeqNum;

  const closeMarketAction = fixtureActions.find((fixtureAction) =>
    generateFindActionOpenCloseMarketRules(closeMarketFilters, fixtureAction),
  )?.fixtureSeqNum;

  return {
    openMarket: openMarketAction ? openMarketAction : -1,
    closeMarket: closeMarketAction ? closeMarketAction : -1,
    isMarketOpen: (openMarketAction || -1) >= (closeMarketAction || -1),
  };
};

export const generateOpenCloseMarketFilters = (subTypeId: number) => {
  const dataReviewOverSubTypeId = Number(
    `${FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER}${subTypeId}`,
  );

  const dataReviewUnderwaySubTypeId = Number(
    `${FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY}${subTypeId}`,
  );

  return {
    openMarketFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER,
        actionSubType: dataReviewOverSubTypeId,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER,
        actionSubType: dataReviewOverSubTypeId,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY,
        actionSubType: dataReviewUnderwaySubTypeId,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    closeMarketFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY,
        actionSubType: dataReviewUnderwaySubTypeId,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY,
        actionSubType: dataReviewUnderwaySubTypeId,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER,
        actionSubType: dataReviewOverSubTypeId,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  };
};
