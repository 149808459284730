import { useState, useRef, useMemo, useContext } from 'react';
import { TableRow } from '@mui/material';
import { ActiveColumnType } from '@/components/StatsTableCells/types';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import {
  StatsTableContainer,
  StatsTable,
  StyledCol,
  StatsTableHead,
  TableRowWithHover,
} from '@/components/StatsTableCells/common';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import { getPlayerName } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { StatsTableBody } from '@/components/StatsTableBody/StatsTableBody';
import {
  StatsTableStickyHeaderActionCell,
  StatsTableHeaderActionCell,
  StatsTableStickyDataCell,
  StatsTableDataCell,
} from '@/components/StatsTableCells/StatsTableCells';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { ScoringContext } from '@/contexts/scoring/context';
import { COMMON_STRING } from '@/constants/dictionary';
import { AdvancedStatsDataTableProps } from '../types';

export const AdvancedStatsBasketballTable = ({
  data,
  headerData,
}: AdvancedStatsDataTableProps) => {
  const {
    state: { fixtureSummary },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const [activeColumn, setActiveColumn] = useState<ActiveColumnType>(null);
  const colGroupRef = useRef<HTMLTableColElement>(null);

  const columnsInGroup = useMemo(() => {
    let columnsCounter = 1;
    return headerData
      ?.map((action) => {
        let columns: number[] = [];
        action?.subActions?.map(() => columns.push(columnsCounter++));
        columns.push(columnsCounter++);
        return columns;
      })
      .flat();
  }, [headerData]);

  return (
    <StatsTableContainer>
      <StatsTable>
        <colgroup ref={colGroupRef}>
          <col key={'col-names'} />
          {columnsInGroup?.map((index) => (
            <StyledCol
              key={`col-${index}`}
              index={index}
              activeColumn={activeColumn}
              aria-label={ARIA_LABEL.HOVERED_COL}
            />
          ))}
        </colgroup>
        <StatsTableHead>
          <TableRow>
            <StatsTableStickyHeaderActionCell rowSpan={2} key={'header-name'}>
              Name
            </StatsTableStickyHeaderActionCell>
            {headerData?.map((action) => (
              <StatsTableHeaderActionCell
                colSpan={
                  !!action?.subActions?.length ? action.subActions.length : 1
                }
                rowSpan={!action?.subActions ? 2 : 1}
                key={`header-${action?.actionId}`}
              >
                {action?.actionId &&
                  getActionName(action.actionId, fixtureConfig)}
              </StatsTableHeaderActionCell>
            ))}
          </TableRow>
          <TableRow>
            {headerData?.map(
              (action) =>
                action?.subActions?.map((subAction) => (
                  <StatsTableHeaderActionCell
                    key={`subheader-${subAction}-${action.actionId}`}
                  >
                    {subAction === action?.actionId
                      ? COMMON_STRING.TOTAL
                      : getActionSubTypeName(subAction, fixtureConfig) ||
                        subAction}
                  </StatsTableHeaderActionCell>
                )),
            )}
          </TableRow>
        </StatsTableHead>
        <StatsTableBody
          activeColumn={activeColumn}
          setActiveColumn={setActiveColumn}
          colGroupRef={colGroupRef}
        >
          {data?.map((statistics) => (
            <TableRowWithHover
              key={`table-row-${statistics.playerId || statistics.teamId}`}
            >
              <StatsTableStickyDataCell>
                {statistics?.playerId
                  ? getPlayerName(
                      statistics.playerId,
                      fixtureConfig,
                      fixtureSummary,
                    )
                  : statistics?.teamId
                  ? getTeamName(
                      statistics.teamId,
                      fixtureConfig,
                      fixtureSummary,
                    )
                  : ''}
              </StatsTableStickyDataCell>
              {headerData?.map((action) =>
                action?.subActions ? (
                  action?.subActions?.map(
                    (subAction) =>
                      subAction && (
                        <StatsTableDataCell
                          key={`${action.actionId}-${subAction}-${statistics.playerId}`}
                        >
                          {subAction !== action.actionId
                            ? statistics.statistics
                                ?.find(
                                  (statisticsAction) =>
                                    action.actionId ===
                                    statisticsAction?.actionId,
                                )
                                ?.subActionAggregates.find(
                                  (aggregates) =>
                                    aggregates.subActionId === subAction,
                                )?.subActionAggregate || 0
                            : statistics.statistics?.find(
                                (activeAction) =>
                                  activeAction?.actionId === subAction,
                              )?.actionAggregate || 0}
                        </StatsTableDataCell>
                      ),
                  )
                ) : (
                  <StatsTableDataCell
                    key={`${action?.actionId}-${statistics.playerId}`}
                  >
                    {statistics.statistics?.find(
                      (activeAction) =>
                        activeAction?.actionId === action?.actionId,
                    )?.actionAggregate || 0}
                  </StatsTableDataCell>
                ),
              )}
            </TableRowWithHover>
          ))}
        </StatsTableBody>
      </StatsTable>
    </StatsTableContainer>
  );
};
