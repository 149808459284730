import { Popover, Box, Typography, Stack, Button } from '@mui/material';
import { FC } from 'react';
import { SoundId } from '@/contexts/notifications/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { SoundsSettings } from '@/contexts/preferences/types';
import { ColoredButton } from '@/components/common/ColoredButton';

export type onActionNotificationPopoverApply = (data: {
  soundId: SoundId | null;
}) => unknown;

export type ActionSoundSelectPopoverProps = {
  title?: string;
  currentSoundId?: number;
  anchorEl?: Element;
  onApply: onActionNotificationPopoverApply;
  onClose: () => void;
  isOpen: boolean;
  sounds: SoundsSettings;
};

export const SoundSelectPopover: FC<ActionSoundSelectPopoverProps> = ({
  title,
  anchorEl,
  currentSoundId,
  onApply,
  onClose,
  isOpen,
  sounds,
}) => {
  return (
    <Popover
      onClose={onClose}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
    >
      <Box p={1}>
        <Typography variant='overline'>{title}</Typography>
        <Stack direction='row' gap={0.5}>
          <Button
            size='small'
            color='inherit'
            onClick={() => {
              onApply({ soundId: null });
            }}
          >
            {COMMON_STRING.NONE}
          </Button>

          {Object.values(sounds).map(({ id, color, name }) => {
            const isCurrentSound = currentSoundId === id;
            return (
              <ColoredButton
                customColor={color}
                key={id}
                size='small'
                variant={isCurrentSound ? 'contained' : 'outlined'}
                aria-current={isCurrentSound}
                onClick={() =>
                  isCurrentSound ? onClose() : onApply({ soundId: id })
                }
              >
                {name}
              </ColoredButton>
            );
          })}
        </Stack>
      </Box>
    </Popover>
  );
};
