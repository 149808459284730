import { Stack } from '@mui/material';
import Container from '@mui/material/Container';
import { useContext, useMemo } from 'react';
import { ScoresheetStatsButtons } from '@/components/StatsButtons/ScoresheetStatsButtons';
import { ScoringContext } from '@/contexts/scoring/context';
import { StatsContext } from '@/contexts/stats/context';
import { ScoresheetLegend } from '@/components/BasketballScoresheet/ScoresheetLegend';
import { ScoresheetDate } from '@/components/BasketballScoresheet/ScoresheetDate';
import { ScoresheetSummary } from '@/components/BasketballScoresheet/ScoresheetSummary';
import { filterBasketballTableData } from '@/components/BasketballStatsTable/utils';
import { BasketballStatsTableData } from '@/components/BasketballStatsTable/types';
import { PointsTable } from '@/components/ScoresheetTables/PointsTable/PointsTable';
import { LeadsTable } from '@/components/ScoresheetTables/LeadsTable/LeadsTable';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { PlayersTable } from '@/components/ScoresheetTables/PlayersTable/PlayersTable';
import { formatDate } from '@/utils/formatDate/formatDate';
import { DATE_TIME_FORMAT } from '@/constants/dateFormats';
import { StyledStatisticsTabWrapper } from '@/components/common/StyledStatisticsTabWrapper';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export const SCORESHEET_ID = 'scoresheet-table';

export const FixtureScoresheet = () => {
  const reportGenerationDate = formatDate(
    new Date().toString(),
    DATE_TIME_FORMAT,
  );
  const {
    state: { fixtureSummary },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const {
    teamButtons,
    activeButtons: { quarter, team, statsType },
  } = useContext(StatsContext);

  const activePeriod = useMemo(
    () =>
      fixtureSummary?.periods.find((period) => period.periodNumber === quarter),
    [quarter, fixtureSummary?.periods],
  );

  const tableData = useMemo(() => {
    if (!fixtureSummary) return;
    return filterBasketballTableData({
      activeQuarter: quarter,
      activeType: STATS_BUTTONS.TYPE.TEAM,
      activeTeam: team,
      activeStatsType: statsType,
      advancedSportStatistics: fixtureSummary.advancedSportStatistics,
      homeTeamId: fixtureSummary.homeTeam.id,
    })?.reverse() as BasketballStatsTableData[];
  }, [fixtureSummary, quarter, team, statsType]);

  return (
    <PermissionsChecker name='FixturePage.Scoresheet'>
      <StyledStatisticsTabWrapper>
        <ScoresheetStatsButtons
          fixtureConfig={fixtureConfig}
          reportDate={reportGenerationDate}
          periodLabel={!activePeriod ? 'All quarters' : activePeriod.label}
        />
        <Container>
          <Stack px='5%' py='3%' width='1200px' gap={3} id={SCORESHEET_ID}>
            <ScoresheetDate date={reportGenerationDate} />
            <ScoresheetSummary
              fixtureConfig={fixtureConfig}
              activePeriod={activePeriod}
            />
            {teamButtons?.map((team) => {
              const teamStatistics = tableData?.find(
                (data) => data.teamId === team.id,
              );
              return (
                <PlayersTable
                  team={teamStatistics}
                  fixtureConfig={fixtureConfig}
                  key={team.id}
                  teamId={team.id as string}
                />
              );
            })}

            <Stack flexDirection='row' gap={2}>
              <PointsTable tableData={tableData} />
              <LeadsTable tableData={tableData} />
            </Stack>
            <ScoresheetLegend />
          </Stack>
        </Container>
      </StyledStatisticsTabWrapper>
    </PermissionsChecker>
  );
};
