import { ActionsTable } from '@/components/ActionsTable/ActionsTable';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export const FixtureActions = () => {
  return (
    <PermissionsChecker name='FixturePage.MatchData'>
      <ActionsTable />
    </PermissionsChecker>
  );
};
