import { Stack, TableContainer } from '@mui/material';
import { FixturePostMatchCheckStatistics } from '@/service/types';
import { ComparisonTableHeader } from '@/components/ComparisonTable/ComparisonTableHeader';
import { ComparisonTableCellRow } from '@/components/ComparisonTable/ComparisonTableCellRow';

interface ComparisonTableProps {
  comparisonData: FixturePostMatchCheckStatistics[];
}

export const ComparisonTable = ({ comparisonData }: ComparisonTableProps) => {
  return (
    <TableContainer
      sx={{
        flex: 1,
      }}
    >
      <Stack
        sx={{
          minWidth: 1400,
        }}
      >
        <ComparisonTableHeader />
        {comparisonData.map((statistics) => (
          <ComparisonTableCellRow
            key={statistics.statsType}
            statistics={statistics}
          />
        ))}
      </Stack>
    </TableContainer>
  );
};
