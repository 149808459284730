import { NoStreamProps } from '../FixtureStreaming/NoStream';
import {
  NOTIFICATION_STREAM,
  WITHIN_TRIGGER_SEC_TIME,
} from '../FixtureStreaming/constants';

export const getInfoMessage = ({
  streamStartDate,
  time,
  formattedTime,
}: NoStreamProps) => {
  if (!streamStartDate) {
    return NOTIFICATION_STREAM.UNAVAILABLE;
  }

  if (time > WITHIN_TRIGGER_SEC_TIME || time === 0) {
    return NOTIFICATION_STREAM.COMMING_SOON;
  }

  if (time < WITHIN_TRIGGER_SEC_TIME || time !== 0) {
    return `${NOTIFICATION_STREAM.START_IN}${formattedTime}`;
  }
};
