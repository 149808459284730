import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { getInfoMessage } from '../helpers/getInfoMessage';
import { FloatingText } from './StyledComponents';

export type NoStreamProps = {
  streamStartDate?: string | null;
  time: number;
  formattedTime?: string;
};

export const NoStream = ({ streamStartDate, time }: NoStreamProps) => {
  const formattedTime = useMemo(() => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String.fromCharCode(160)}${String(minutes).padStart(
      2,
      '0',
    )}:${String(seconds).padStart(2, '0')}`;
  }, [time]);

  const theme = useTheme();

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100%'
      position='relative'
      overflow='hidden'
      sx={{
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background:
            `repeating-linear-gradient(	transparent, ${theme.palette.stream?.noStreamColor} 2px, ${theme.palette.stream?.noStreamContrastColor} 3px, ${theme.palette.stream?.noStreamContrastColor} 2px), ` +
            `repeating-linear-gradient(90deg, ${theme.palette.stream?.noStreamColor} 0px, transparent 1px, ${theme.palette.stream?.noStreamContrastColor} 1px, ${theme.palette.stream?.noStreamContrastColor} 2px)`,
          animation: 'staticEffect 0.8s infinite',
          opacity: 0.5,
        },
        '@keyframes staticEffect': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.6,
          },
          '100%': {
            opacity: 1,
          },
        },
      }}
    >
      <FloatingText
        variant='h5'
        gutterBottom
        sx={{
          padding: theme.spacing(3),
          borderRadius: theme.spacing(1),
          color: theme.palette.common.white,
          bgcolor: theme.palette.secondary.dark,
        }}
      >
        {getInfoMessage({ streamStartDate, formattedTime, time })}
      </FloatingText>
    </Box>
  );
};
