import { Button, Paper, Popover, Stack, Typography } from '@mui/material';
import React from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { ARIA_LABEL } from '@/constants/ariaLabel';

type DeleteConfirmProps = {
  onConfirm: VoidFunction;
  onClose: VoidFunction;
  anchorElement: Element | undefined;
  open: boolean;
  id: string;
};

export const DeleteConfirm: React.FC<DeleteConfirmProps> = ({
  onConfirm,
  onClose,
  anchorElement,
  open,
  id,
}) => {
  if (!open) {
    return null;
  }

  return (
    <Popover
      aria-label={ARIA_LABEL.DELETE_CONFIRM}
      id={id}
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper sx={{ width: 300, p: 2 }}>
        <Stack gap={2}>
          <Typography variant='h5'>{COMMON_STRING.CONFIRM}</Typography>
          <Typography>{COMMON_STRING.TEMPLATE_DELETE_CONFIRM}</Typography>
          <Stack
            alignSelf={'flex-end'}
            direction={'row'}
            alignItems={'flex-end'}
            gap={1}
          >
            <Button onClick={onConfirm} variant='contained' color='primary'>
              {COMMON_STRING.YES}
            </Button>
            <Button onClick={onClose} variant='outlined' color='primary'>
              {COMMON_STRING.NO}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Popover>
  );
};
