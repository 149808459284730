import { green, red } from '@mui/material/colors';
import { styled, Theme } from '@mui/material/styles';

interface IndicatorProps {
  isConnected: boolean;
  successColor?: string;
  errorColor?: string;
}

export const indicatorSize = (theme: Theme) => theme.spacing(2);
export const indicatorSpacing = (theme: Theme) => theme.spacing(0.25);

export const SimpleIndicator = styled('div')<IndicatorProps>(
  ({ theme, isConnected, successColor, errorColor }) => ({
    backgroundColor: isConnected
      ? successColor || green[600]
      : errorColor || red[600],
    width: indicatorSize(theme),
    height: indicatorSize(theme),
    margin: indicatorSpacing(theme),
    borderRadius: '100%',
  })
);
