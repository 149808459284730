import Edit from '@mui/icons-material/Edit';
import { Button, Typography, TypographyProps } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { PANEL } from '@/contexts/UIState/types';
import { UIStateContext } from '@/contexts/UIState/context';
import {
  CollectionStatusId,
  COLLECTION_STATUS,
  FixtureStatusId,
  FIXTURE_STATUS,
  COLLECTION_STATUS_ID,
} from '@/service/constants';
import { COVERAGE_LEVEL_OPTIONS } from '@/components/StatusesEditDialog/constants';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { InfoLabel } from '../common/InfoLabel';
import { StatusesEditDialog } from '../StatusesEditDialog/StatusesEditDialog';
import { hasPermissionsToElement } from '../PermissionsChecker/utils';

const StatusText: FC<TypographyProps> = (props) => {
  return (
    <Typography
      fontSize='inherit'
      component='span'
      variant='body2'
      lineHeight={1}
      {...props}
    />
  );
};
const StatusValueText: FC<TypographyProps> = (props) => {
  return <StatusText fontWeight={700} {...props} />;
};

export interface StatusesBarProps {
  fixtureStatusId?: FixtureStatusId;
  collectionStatusId?: CollectionStatusId;
  coverageLevelId?: number;
}

const StatusBarContent: FC<StatusesBarProps & { canEdit: boolean }> = ({
  fixtureStatusId,
  collectionStatusId,
  coverageLevelId,
  canEdit,
}) => {
  const isSuspendedCollectionStatus = useMemo(
    () => collectionStatusId === COLLECTION_STATUS_ID.SUSPENDED,
    [collectionStatusId],
  );

  return (
    <InfoLabel>
      <StatusText>
        {'Fixture Status: '}
        <StatusValueText>
          {fixtureStatusId !== undefined && FIXTURE_STATUS[fixtureStatusId]}
        </StatusValueText>
      </StatusText>

      <StatusText>
        {'Collection Status: '}
        <StatusValueText
          sx={{
            animation: isSuspendedCollectionStatus
              ? 'warning-animation-blink 1s infinite'
              : 'none',
            padding: (theme) =>
              isSuspendedCollectionStatus ? theme.spacing(0.25, 0.5) : 0,
            borderRadius: (theme) =>
              isSuspendedCollectionStatus ? theme.spacing(0.5) : 0,
          }}
          data-testid='collection-status-value'
        >
          {collectionStatusId !== undefined &&
            COLLECTION_STATUS[collectionStatusId]}
        </StatusValueText>
      </StatusText>

      <StatusText>
        {'Coverage Level: '}
        <StatusValueText>
          {
            COVERAGE_LEVEL_OPTIONS.find(
              (coverageLevelOption) =>
                coverageLevelOption.value === coverageLevelId,
            )?.name
          }
        </StatusValueText>
      </StatusText>
      {canEdit && <Edit fontSize='inherit' />}
    </InfoLabel>
  );
};

export const StatusesBar: FC<StatusesBarProps> = (props) => {
  const { openedPanel, setOpenedPanel } = useContext(UIStateContext);
  const { permissions } = useContext(RolePermissionsContext);

  const hasAccess = hasPermissionsToElement(
    'FixturePage.ScoreboardPanel.StatusPopup',
    permissions,
  );

  if (hasAccess) {
    return (
      <>
        <Button
          color='secondary'
          variant='contained'
          disableFocusRipple
          sx={{ textTransform: 'unset', p: 0 }}
          onClick={() => setOpenedPanel(PANEL.STATUSES)}
          data-testid='statuses-bar-button'
        >
          <StatusBarContent {...props} canEdit={hasAccess} />
        </Button>
        <StatusesEditDialog
          open={openedPanel === PANEL.STATUSES}
          onClose={() => setOpenedPanel(null)}
        />
      </>
    );
  }

  return <StatusBarContent {...props} canEdit={hasAccess} />;
};
