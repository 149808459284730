import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SlaBreachRules } from '@/service/types';

interface EvaluationSelectProps {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options?: SlaBreachRules[];
}

export const EvaluationSelect = ({
  value,
  onChange,
  options,
}: EvaluationSelectProps) => {
  const isValidValue = options?.some(
    ({ displayRuleName }) => displayRuleName === value,
  );

  return (
    <Select value={isValidValue ? value : ''} onChange={onChange} size='small'>
      {options?.map(({ displayRuleName, ruleId }) => (
        <MenuItem value={displayRuleName} key={displayRuleName + ruleId}>
          {displayRuleName}
        </MenuItem>
      ))}
    </Select>
  );
};
