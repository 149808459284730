import { keyframes } from '@mui/system';
import { Typography, styled } from '@mui/material';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

export const FloatingText = styled(Typography)`
  display: inline-block;
  animation: ${floatAnimation} 4s ease-in-out infinite;
  position: absolute;
  transition: transform 0.3s;
`;
