import Stack from '@mui/material/Stack';
import { FC, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { DICTIONARY } from '@/constants/dictionary';
import { useScorerInfoConfig } from '@/service/hooks/useScorerInfo';
import { ScoringContext } from '@/contexts/scoring/context';
import { ScorerInfoConfig } from '@/service/types';
import { ScorerInfo } from './ScorersInfo';

export interface ScorersPanelProps {
  isOpen: boolean;
}

export const ScorersPanel: FC<ScorersPanelProps> = ({ isOpen }) => {
  const token = useAuthToken();

  const {
    state: { fixtureId },
  } = useContext(ScoringContext);

  const { data } = useScorerInfoConfig({
    token,
    fixtureId,
  });

  if (!data?.length)
    return (
      <Paper>
        <Collapse in={isOpen}>
          <Stack width='100%' direction='row' flexWrap='wrap'>
            <ScorerInfo
              userId={''}
              firstName={'Unassigned'}
              lastName={'Unassigned'}
              averageFor5LastFixtures={null}
              numberOfCompletedMatches={null}
            />
          </Stack>
        </Collapse>
      </Paper>
    );

  return (
    <Paper>
      <Collapse in={isOpen}>
        <Stack
          width='100%'
          direction={data.length >= 3 ? 'column' : 'row'}
          flexWrap='wrap'
        >
          {!fixtureId
            ? DICTIONARY.ERROR.FIXTURE_ID_MISSING
            : data.map(
                ({
                  userId,
                  firstName,
                  lastName,
                  averageFor5LastFixtures,
                  numberOfCompletedMatches,
                }: ScorerInfoConfig) => (
                  <ScorerInfo
                    key={userId}
                    firstName={firstName}
                    lastName={lastName}
                    averageFor5LastFixtures={averageFor5LastFixtures}
                    numberOfCompletedMatches={numberOfCompletedMatches}
                  />
                ),
              )}
        </Stack>
      </Collapse>
    </Paper>
  );
};
