import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import {
  LS_KEY,
  DEFAULT_ACTION_SOUNDS,
  DEFAULT_ACTION_CONDITION_SOUNDS,
} from './constants';
import { ActionSound, PreferencesActions, ActionConditionSound } from './types';
import {
  getUpdatedSoundArray,
  byFirstArrayItem,
  getActionSoundsDiff,
  isActionSoundsTheSame,
} from './utils';

const isNotInCurrentActionTypes = (
  actionSound: ActionSound,
  actionTypeIds: number[]
) => {
  return !actionTypeIds.some((id) => id === actionSound[0]);
};

export const useActionsSoundsPreferences = () => {
  // Action type sounds
  const [userDefaultActionSounds, setUserDefaultActionSounds] = useLocalStorage<
    ActionSound[]
  >(LS_KEY.ACTION_SOUNDS, [...DEFAULT_ACTION_SOUNDS]);
  const [actionSounds, setActionSounds] = useSessionStorage<ActionSound[]>(
    LS_KEY.ACTION_SOUNDS,
    userDefaultActionSounds
  );

  const [currentFixtureActionTypeIds, setCurrentFixtureActionTypeIds] =
    useState<number[]>([]);

  useEffect(() => {
    const syncActionSounds = (e: StorageEvent) => {
      if (e.key !== LS_KEY.ACTION_SOUNDS) return;
      const newUserDefaultActionSounds = JSON.parse(e.newValue || '[]');
      const { newItems, missingItems } = getActionSoundsDiff(
        newUserDefaultActionSounds,
        actionSounds
      );

      if (!newItems.length && !missingItems.length) return;
      const updatedCurrentActionSounds = new Map([...actionSounds]);
      if (newItems.length > 0) {
        const unrelatedNewItems = newItems.filter((newSound) =>
          isNotInCurrentActionTypes(newSound, currentFixtureActionTypeIds)
        );
        unrelatedNewItems.forEach(([actionTypeId, soundId]) => {
          updatedCurrentActionSounds.set(actionTypeId, soundId);
        });
      }
      if (missingItems.length > 0) {
        const unrelativeMissingItems = missingItems.filter((missingSound) =>
          isNotInCurrentActionTypes(missingSound, currentFixtureActionTypeIds)
        );
        unrelativeMissingItems.forEach(([actionTypeId]) => {
          updatedCurrentActionSounds.delete(actionTypeId);
        });
      }
      setActionSounds([...updatedCurrentActionSounds]);
    };

    window.addEventListener('storage', syncActionSounds);
    return () => {
      window.removeEventListener('storage', syncActionSounds);
    };
  }, [actionSounds, currentFixtureActionTypeIds, setActionSounds]);

  // Action condition sounds
  const [
    userDefaultActionConditionSounds,
    setUserDefaultActionConditionSounds,
  ] = useLocalStorage<ActionConditionSound[]>(LS_KEY.ACTION_CONDITION_SOUNDS, [
    ...DEFAULT_ACTION_CONDITION_SOUNDS,
  ]);

  const [actionConditionSounds, setActionConditionSounds] = useSessionStorage<
    ActionConditionSound[]
  >(LS_KEY.ACTION_CONDITION_SOUNDS, userDefaultActionConditionSounds);

  const isActionSoundsUserDefault = useMemo(
    () =>
      isActionSoundsTheSame(actionSounds, userDefaultActionSounds) &&
      isActionSoundsTheSame(
        actionConditionSounds,
        userDefaultActionConditionSounds
      ),
    [
      actionConditionSounds,
      actionSounds,
      userDefaultActionConditionSounds,
      userDefaultActionSounds,
    ]
  );
  const isActionSoundsAppDefault = useMemo(
    () =>
      isActionSoundsTheSame(actionSounds, DEFAULT_ACTION_SOUNDS) &&
      isActionSoundsTheSame(
        actionConditionSounds,
        DEFAULT_ACTION_CONDITION_SOUNDS
      ),
    [actionConditionSounds, actionSounds]
  );

  // Methods
  const updateActionSounds: PreferencesActions['updateActionSounds'] =
    useCallback(
      (value) => {
        setActionSounds((prevActionSounds) => {
          const updated = getUpdatedSoundArray(value, prevActionSounds);
          return updated.sort(byFirstArrayItem);
        });
      },
      [setActionSounds]
    );

  const updateActionConditionSounds: PreferencesActions['updateActionConditionSounds'] =
    useCallback(
      (value) => {
        setActionConditionSounds((prevActionConditionSounds) => {
          return getUpdatedSoundArray(value, prevActionConditionSounds);
        });
      },
      [setActionConditionSounds]
    );

  const restoreAppDefaultActionSounds = useCallback(() => {
    setActionSounds(DEFAULT_ACTION_SOUNDS);
    setActionConditionSounds(DEFAULT_ACTION_CONDITION_SOUNDS);
  }, [setActionSounds, setActionConditionSounds]);

  const restoreUserDefaultActionSounds = useCallback(() => {
    setActionSounds([...userDefaultActionSounds].sort(byFirstArrayItem));
    setActionConditionSounds(
      [...userDefaultActionConditionSounds].sort(byFirstArrayItem)
    );
  }, [
    setActionSounds,
    userDefaultActionSounds,
    setActionConditionSounds,
    userDefaultActionConditionSounds,
  ]);

  const updateUserDefaultActionSounds = useCallback(() => {
    setUserDefaultActionSounds([...actionSounds].sort(byFirstArrayItem));
    setUserDefaultActionConditionSounds(
      [...actionConditionSounds].sort(byFirstArrayItem)
    );
  }, [
    actionConditionSounds,
    actionSounds,
    setUserDefaultActionConditionSounds,
    setUserDefaultActionSounds,
  ]);

  return {
    actionConditionSounds,
    actionSounds,
    isActionSoundsAppDefault,
    isActionSoundsUserDefault,
    restoreAppDefaultActionSounds,
    restoreUserDefaultActionSounds,
    setCurrentFixtureActionTypeIds,
    updateActionConditionSounds,
    updateActionSounds,
    updateUserDefaultActionSounds,
  };
};
