import { Button, Divider, Stack } from '@mui/material';
import { FC, useContext } from 'react';
import { PreferencesContext } from '@/contexts/preferences/context';
import { PREFERENCES_STRING } from '@/constants/dictionary';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { SectionTitleTypography } from '../PreferencesTypography';
import { NotificationMasterVolume } from './NotificationMasterVolume';
import { NotificationSounds } from './NotificationSounds';

export const SoundPreferences: FC = () => {
  const {
    isSoundsPreferencesAppDefault,
    isSoundsPreferencesUserDefault,
    actions: {
      saveUserDefaultSoundsPreferences,
      restoreUserDefaultSoundsPreferences,
      restoreAppDefaultSoundsPreferences,
    },
  } = useContext(PreferencesContext);

  return (
    <PermissionsChecker name='PreferencesPage.SoundPreferences'>
      <Stack gap={2} padding={({ spacing }) => spacing(1, 2, 2)}>
        <PermissionsChecker name='PreferencesPage.SoundPreferences.MasterVolume'>
          <SectionTitleTypography>Master volume</SectionTitleTypography>
          <NotificationMasterVolume />
        </PermissionsChecker>

        <SectionTitleTypography>Sound list</SectionTitleTypography>
        <NotificationSounds />
        <Divider />

        <Stack
          direction={'row'}
          justifyContent='flex-end'
          flexWrap='wrap'
          gap={1}
        >
          <PermissionsChecker name='PreferencesPage.SoundPreferences.ApplyAppDefault'>
            <Button
              size='small'
              variant='text'
              disabled={isSoundsPreferencesAppDefault}
              onClick={restoreAppDefaultSoundsPreferences}
            >
              {PREFERENCES_STRING.APPLY_APP_DEFAULT}
            </Button>
          </PermissionsChecker>

          <PermissionsChecker name='PreferencesPage.SoundPreferences.ApplyMyDefault'>
            <Button
              size='small'
              variant='text'
              disabled={isSoundsPreferencesUserDefault}
              onClick={restoreUserDefaultSoundsPreferences}
            >
              {PREFERENCES_STRING.APPLY_USER_DEFAULT}
            </Button>
          </PermissionsChecker>

          <PermissionsChecker name='PreferencesPage.SoundPreferences.SaveAsMyDefault'>
            <Button
              size='small'
              variant='contained'
              disabled={isSoundsPreferencesUserDefault}
              onClick={saveUserDefaultSoundsPreferences}
            >
              {PREFERENCES_STRING.SAVE_USER_DEFAULT}
            </Button>
          </PermissionsChecker>
        </Stack>
      </Stack>
    </PermissionsChecker>
  );
};
