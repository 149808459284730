import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useContext } from 'react';
import { FIXTURE_ACTION_SUBTYPE } from '@/service/constants';
import { MarketsContext } from '@/contexts/markets/context';
import { ActionIcons } from '../ActionsTable/ActionIcons';
import { getActionName } from '../FixtureTabs/helpers/getActionName';
import { getActionSubTypeName } from '../FixtureTabs/helpers/getActionSubTypeName';
import { EditActionSelectProps } from './types';

export const ActionSelect = ({
  isOwnGoalActionSubType,
  editedAction: {
    fixtureActionType,
    fixtureSubActionType,
    player,
    additionalActionTypeOptions,
  },
  onChange,
  action,
  fixtureConfig,
}: EditActionSelectProps) => {
  const { marketsPerAction } = useContext(MarketsContext);
  const currentActionTypeName = getActionName(
    action.fixtureActionTypeId,
    fixtureConfig,
  );
  const currentActionSubTypeName = getActionSubTypeName(
    action.fixtureActionSubTypeId,
    fixtureConfig,
  );

  const additionalActionTypeOptionsIds = [
    ...new Set(additionalActionTypeOptions?.map((actionType) => actionType.id)),
  ];

  const isEditableActionType =
    (additionalActionTypeOptions && additionalActionTypeOptions?.length <= 1) ||
    (additionalActionTypeOptionsIds.length === 1 &&
      additionalActionTypeOptionsIds[0] === action.fixtureActionTypeId &&
      marketsPerAction);

  return (
    <>
      <Typography variant='subtitle2'>Action</Typography>
      <Stack direction='row' alignItems='center' gap={2}>
        <Stack flexDirection='row' gap={1} alignItems='center' flex='3 0 0'>
          <ActionIcons action={action} />
          <Stack lineHeight={1.5}>
            <Typography lineHeight='inherit'>
              {currentActionTypeName}
            </Typography>
            {currentActionSubTypeName && (
              <Typography lineHeight='inherit' variant='caption'>
                {currentActionSubTypeName}
              </Typography>
            )}
          </Stack>
        </Stack>

        <EastIcon
          sx={{
            fontSize: '1.8rem',
            color: (theme) => theme.palette.iconsColors.borderColor,
          }}
        />

        <Stack flex='5 0 0' gap={1}>
          <Autocomplete
            size='small'
            value={fixtureActionType}
            disabled={!!isEditableActionType}
            options={
              additionalActionTypeOptions
                ? additionalActionTypeOptions
                : [fixtureActionType]
            }
            onChange={(event, newValue) =>
              onChange({
                fixtureActionType: newValue,
                fixtureSubActionType: null,
                player: player,
              })
            }
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField {...params} label='Action Type' />
            )}
          />

          {fixtureActionType?.subTypes && (
            <Autocomplete
              size='small'
              disabled={!fixtureActionType?.subTypes}
              value={fixtureSubActionType}
              options={fixtureActionType?.subTypes || []}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) =>
                onChange({
                  fixtureSubActionType: newValue,
                  player:
                    isOwnGoalActionSubType ||
                    newValue?.id === FIXTURE_ACTION_SUBTYPE.OWN_GOAL
                      ? null
                      : player,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label='Action Sub Type' />
              )}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
