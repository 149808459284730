import { Box, Stack, Typography } from '@mui/material';
import { formatDate } from '@/utils/formatDate/formatDate';
import { SCORESHEET_DATE_TIME_FORMAT } from '@/constants/dateFormats';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { FixtureConfig } from '@/service/types';

interface ScoresheetDescriptionProps {
  fixtureConfig?: FixtureConfig;
}

export const ScoresheetDescription = ({
  fixtureConfig,
}: ScoresheetDescriptionProps) => {
  return (
    <Stack flexDirection='row' justifyContent='space-between'>
      <Box sx={{ fontWeight: 700, fontSize: '1.25rem', lineHeight: 1 }}>
        {fixtureConfig?.fixture.name}
        <Typography variant='body2'>
          {formatDate(
            fixtureConfig?.fixture.startDateUTC || '',
            SCORESHEET_DATE_TIME_FORMAT,
          )}
        </Typography>
      </Box>
      <Typography fontWeight={700} fontSize='1.25rem' lineHeight={1}>
        {fixtureConfig?.fixture?.stage?.season?.competition?.name}{' '}
        {STATS_BUTTONS.STATS.BOX_SCORE}
      </Typography>
    </Stack>
  );
};
