import { InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import React, { FC, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

type SearchBarProps = {
  onSearch: (value: string) => void;
  label: string;
  sx?: SxProps<Theme>;
};

export const SearchBar: FC<SearchBarProps> = ({ onSearch, label, sx }) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearchChange = (value: string) => {
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <TextField
      label={label}
      variant='outlined'
      size='small'
      value={searchValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onSearchChange(event.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={sx}
    />
  );
};
