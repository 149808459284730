import { parseISO, isValid } from 'date-fns';
import { Order, SortBy } from '@/utils/sort/useSort';
import { FixtureAction } from '@/service/types';

export const parseIfDate = (value: any) => {
  if (typeof value === 'string') {
    const parsedDate = parseISO(value);
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }
  return value;
};

export const descendingComparator = <T extends FixtureAction>(
  a: T,
  b: T,
  orderBy?: SortBy,
) => {
  const primaryA = parseIfDate(a[orderBy?.primary as keyof T]);
  const primaryB = parseIfDate(b[orderBy?.primary as keyof T]);
  if (primaryB < primaryA) {
    return -1;
  }
  if (primaryB > primaryA) {
    return 1;
  }

  if (orderBy?.secondary) {
    const secondaryA = parseIfDate(a[orderBy.secondary as keyof T]);
    const secondaryB = parseIfDate(b[orderBy.secondary as keyof T]);
    if (secondaryB < secondaryA) {
      return -1;
    }
    if (secondaryB > secondaryA) {
      return 1;
    }
  }

  return 0;
};

export const getComparator = (
  order: Order,
  orderBy?: SortBy,
): ((a: FixtureAction, b: FixtureAction) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(
  actions: FixtureAction[],
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = actions.map(
    (action, index) => [action, index] as [T, number],
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
