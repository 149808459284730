import { Paper } from '@mui/material';
import { FC } from 'react';
import {
  ComparisonTableCell,
  ComparisonTableCellProps,
} from './ComparisonTableCell';

export const ComparisonTableStickyCell: FC<ComparisonTableCellProps> = (
  props
) => {
  const { width, ...restProps } = props;
  return (
    <Paper
      style={{ width: width }}
      square
      elevation={5}
      sx={{
        position: 'sticky',
        left: 0,
        display: 'flex',
      }}
    >
      <ComparisonTableCell {...restProps} />
    </Paper>
  );
};
