import { FixturePostMatchCheckStatistics } from '@/service/types';

export const comparePostMatchStatistics = (
  internal: FixturePostMatchCheckStatistics,
  external: FixturePostMatchCheckStatistics
) => {
  if (!(internal && external)) {
    return [internal, external];
  }
  const newInternal = structuredClone(
    internal
  ) as FixturePostMatchCheckStatistics;
  const newExternal = structuredClone(
    external
  ) as FixturePostMatchCheckStatistics;
  (
    Object.keys(newInternal) as Array<keyof FixturePostMatchCheckStatistics>
  ).forEach((key) => {
    const internalStat = newInternal[key];
    if (
      typeof internalStat === 'string' ||
      typeof internalStat === 'boolean' ||
      !internalStat
    ) {
      return;
    }
    const externalStat = newExternal[key];
    if (
      typeof externalStat === 'string' ||
      typeof externalStat === 'boolean' ||
      !externalStat
    ) {
      return;
    }
    const internalValue = Number(internalStat.overallValue);
    const externalValue = Number(externalStat.overallValue);

    internalStat.isMismatch = externalStat.isMismatch =
      internalValue !== externalValue;
  });

  return [newInternal, newExternal];
};
