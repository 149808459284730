import { Box, Divider, Stack, Typography } from '@mui/material';
import { SCORESHEET_LEGEND } from '@/components/BasketballScoresheet/constants';

export const ScoresheetLegend = () => {
  return (
    <Stack gap={2}>
      <Divider />
      <Box
        display='grid'
        gridTemplateColumns='minmax(max-content, 2fr) minmax(max-content, 2fr) minmax(max-content, 2fr) minmax(max-content, 2fr) minmax(max-content, 2fr) minmax(max-content, 1fr)'
        gridAutoRows='max-content'
        gap={1}
      >
        {Object.keys(SCORESHEET_LEGEND).map((key) => (
          <Typography variant='body2' key={key}>
            <strong>{key}</strong>{' '}
            {SCORESHEET_LEGEND[key as keyof typeof SCORESHEET_LEGEND]}
          </Typography>
        ))}
      </Box>
      <Divider />
    </Stack>
  );
};
