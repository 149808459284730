import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { FixtureComparisonRating, FixtureScorerRating } from '@/service/types';
import envVariables from '@/envVariables';
import {
  fetchFromMTService,
  makeUrlWithParams,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { API_METHOD, HTTP_STATUS_CODE } from '../constants';

interface UseScoringRateParams {
  token: string | undefined;
  fixtureId: string;
}

export const useScoringRate = ({ token, fixtureId }: UseScoringRateParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR<FixtureComparisonRating[], ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.ScoringRate(fixtureId).scoringRate.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  const submitChanges = async (
    rating: FixtureScorerRating,
    defaultRating: FixtureScorerRating | [],
    apiMethod: API_METHOD.POST | API_METHOD.PUT | API_METHOD.DELETE,
  ) => {
    const url = makeUrlWithParams(
      envVariables.mtApiUrl +
        SERVICE_ENDPOINT.ScoringRate().scoringRate[apiMethod],
    );
    const res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: apiMethod,
      body: JSON.stringify(
        apiMethod === API_METHOD.POST
          ? {
              ...rating,
              user: {
                ...rating?.user,
                id: rating.user?.id,
                scorerRatings: defaultRating,
              },
            }
          : apiMethod === API_METHOD.PUT
          ? {
              ...rating,
              user: {
                ...rating?.user,
                id: rating.id,
                scorerRatings: [defaultRating],
              },
            }
          : { id: rating.id, fixtureId: fixtureId },
      ),
    });

    if (!res.ok && res.status !== 210) {
      const error = await ApiError.parseFromResponse(res);
      return enqueueSnackbar(error.title, { variant: 'error' });
    }
    const contentType = res.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json'))
      return res.text();
    mutate();
    return enqueueSnackbar(
      apiMethod === API_METHOD.POST
        ? 'Scorer successfully rated'
        : apiMethod === API_METHOD.PUT
        ? 'Rating successfully saved'
        : 'Rating successfully removed',
      { variant: 'success' },
    );
  };

  return {
    comparisonRating: data,
    error,
    isLoading: !data && !error,
    mutate,
    submitChanges,
  };
};
