export const SCORESHEET_LEGEND = {
  No: 'Playing Number',
  OR: 'Offensive Rebounds',
  TO: 'Turnovers',
  PF: 'Personal Fouls',
  '*': 'Game Starters',
  Min: 'Minutes Played',
  DR: 'Defensive Rebounds',
  ST: 'Steals',
  FD: 'Fouls Drawn',
  DNP: 'Did Not Play',
  'M/A': 'Made/Attempts',
  TOT: 'Total Rebounds',
  BS: 'Blocked Shots',
  '+/-': 'Plus/Minus',
  '%': 'Shooting Percentage',
  AS: 'Assists',
  BA: 'Blocks Against',
  PTS: 'Points',
};
