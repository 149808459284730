import { COMMON_STRING } from '@/constants/dictionary';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { getGameActionAggregate } from '@/service/utils/getActionAggregates';
import { StatsCompareItem } from '@/components/StatsCompareTable/StyledStatsComponents';
import { FixturePostMatchCheckStatistics, FixtureSummary } from '../types';

export interface SoccerSummaryStats {
  homeTeam: StatsCompareItem[];
  awayTeam: StatsCompareItem[];
}

export const SOCCER_SUMMARY_STATS_ACTION_NAME = {
  RED_CARDS: 'Red Cards',
  YELLOW_CARDS: 'Yellow Cards',
  CORNERS_TAKEN: 'Corners Taken',
  GOALS: 'Goals',
  SUBSTITUTIONS: 'Substitutions',
  DANGEROUS_ATTACKS: 'Dan. Attacks',
};

export const getSoccerSummaryStats = (
  fixtureSummary: FixtureSummary,
  externalStats: FixturePostMatchCheckStatistics[]
): SoccerSummaryStats => {
  const internalRedCards = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.RED_CARD
  );
  const internalYellowCards = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.YELLOW_CARD
  );
  const internalCorners = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.CORNER_TAKEN
  );
  const internalGoals = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.GOAL
  );
  const internalSubstitutions = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.SUBSTITUTION_IN
  );
  const internalDangerousAttacks = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.DANGEROUS_ATTACK
  );
  const externalHomeData = externalStats.find(
    (data) => data.statsType === COMMON_STRING.STATS.EXTERNAL_HOME
  );
  const externalAwayData = externalStats.find(
    (data) => data.statsType === COMMON_STRING.STATS.EXTERNAL_AWAY
  );

  return {
    homeTeam: [
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.RED_CARDS,
        internalValue: internalRedCards.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.redCard?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.YELLOW_CARDS,
        internalValue: internalYellowCards.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.yellowCard?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.CORNERS_TAKEN,
        internalValue: internalCorners.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.corner?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.GOALS,
        internalValue: internalGoals.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.goal?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.SUBSTITUTIONS,
        internalValue: internalSubstitutions.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.substitution?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.DANGEROUS_ATTACKS,
        internalValue: internalDangerousAttacks.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.dangerousAttack?.overallValue ?? null,
      },
    ],
    awayTeam: [
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.RED_CARDS,
        internalValue: internalRedCards.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.redCard?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.YELLOW_CARDS,
        internalValue: internalYellowCards.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.yellowCard?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.CORNERS_TAKEN,
        internalValue: internalCorners.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.corner?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.GOALS,
        internalValue: internalGoals.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.goal?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.SUBSTITUTIONS,
        internalValue: internalSubstitutions.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.substitution?.overallValue ?? null,
      },
      {
        name: SOCCER_SUMMARY_STATS_ACTION_NAME.DANGEROUS_ATTACKS,
        internalValue: internalDangerousAttacks.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.dangerousAttack?.overallValue ?? null,
      },
    ],
  };
};
