import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import { ThemeProvider } from '@mui/material/styles';
import {
  getAppTheme,
  getMediaPrefersDarkTheme,
  getPreferredTheme,
} from '../theme/utils';
import { ThemeMode, THEME_MODE } from '../theme/constants';
import { PreferencesActions, Preferences } from './types';
import { LS_KEY } from './constants';
import { PreferencesContext } from './context';
import { useSoundsPreferences } from './useSoundsPreferences';
import { useActionsSoundsPreferences } from './useActionsSoundsPreferences';
import { useTemplatePreferences } from './useTemplatePreferences';

export const PreferencesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [themeMode, setThemeMode] = useLocalStorage<ThemeMode>(
    LS_KEY.THEME,
    getPreferredTheme(getMediaPrefersDarkTheme()),
  );
  const theme = useMemo(() => getAppTheme(themeMode), [themeMode]);

  // get default theme in sync with OS preferred theme
  useEffectOnce(() => {
    const onPreferredColorChange = (event: MediaQueryListEvent) => {
      setThemeMode(event.matches ? THEME_MODE.DARK : THEME_MODE.LIGHT);
    };
    const matchMedia = getMediaPrefersDarkTheme();
    matchMedia.addEventListener('change', onPreferredColorChange);
    return () => {
      matchMedia.removeEventListener('change', onPreferredColorChange);
    };
  });

  useEffect(() => {
    document.documentElement.classList.toggle(
      THEME_MODE.DARK,
      themeMode === THEME_MODE.DARK,
    );
  }, [themeMode]);

  const {
    soundsPreferences,
    isSoundsPreferencesAppDefault,
    isSoundsPreferencesUserDefault,
    restoreAppDefaultSoundsPreferences,
    restoreUserDefaultSoundsPreferences,
    saveUserDefaultSoundsPreferences,
    updateSoundsPreferences,
    updateSoundPreferences,
  } = useSoundsPreferences();

  const {
    actionConditionSounds,
    actionSounds,
    isActionSoundsAppDefault,
    isActionSoundsUserDefault,
    restoreAppDefaultActionSounds,
    restoreUserDefaultActionSounds,
    setCurrentFixtureActionTypeIds,
    updateActionConditionSounds,
    updateActionSounds,
    updateUserDefaultActionSounds,
  } = useActionsSoundsPreferences();

  const {
    templates,
    selectedTemplate,
    selectedTemplateFilters,
    dontShowFilterTemplateApplyWarning,
    sports,
    actionTypes,
    sendTypes,
    dontShowTemplateChangeWarning,
    setSelectedTemplate,
    updateTemplate,
    setDontShowFilterTemplateApplyWarning,
    setDontShowTemplateChangeWarning,
    deleteTemplate,
    reorderTemplates,
  } = useTemplatePreferences();

  const preferencesActions: PreferencesActions = {
    setThemeMode,
    updateSoundsPreferences,
    updateSoundPreferences,
    restoreAppDefaultSoundsPreferences,
    restoreUserDefaultSoundsPreferences,
    saveUserDefaultSoundsPreferences,
    updateActionSounds,
    updateUserDefaultActionSounds,
    restoreAppDefaultActionSounds,
    restoreUserDefaultActionSounds,
    setCurrentFixtureActionTypeIds,
    updateActionConditionSounds,
    setSelectedTemplate,
    updateTemplate,
    setDontShowFilterTemplateApplyWarning,
    setDontShowTemplateChangeWarning,
    deleteTemplate,
    reorderTemplates,
  };

  const providerValue: Preferences = {
    themeMode,
    soundsPreferences,
    isSoundsPreferencesAppDefault,
    isSoundsPreferencesUserDefault,
    actionSounds,
    isActionSoundsUserDefault,
    isActionSoundsAppDefault,
    actionConditionSounds,
    templates,
    selectedTemplate,
    selectedTemplateFilters,
    dontShowFilterTemplateApplyWarning,
    dontShowTemplateChangeWarning,
    actions: preferencesActions,
    sports,
    actionTypes,
    sendTypes,
  };

  return (
    <PreferencesContext.Provider value={providerValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </PreferencesContext.Provider>
  );
};
