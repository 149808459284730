import { FC, useContext } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionDialogProps } from '@/components/ActionsDialog/types';
import { ScoringContext } from '@/contexts/scoring/context';
import { formatDate } from '@/utils/formatDate/formatDate';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { EditAction } from '../EditAction/EditAction';
import { SnackbarGoToTopAction } from '../SnackbarActions/SnackbarGoToTopAction';
import { SnackbarCloseAction } from '../SnackbarActions/SnackbarCloseAction';
import { getActionName } from '../FixtureTabs/helpers/getActionName';
import { MTDialog } from '../common/MTDialog';

export const EditActionDialog: FC<ActionDialogProps> = ({
  action,
  onClose,
  listRef,
  isScrollTopVisible,
}) => {
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const { enqueueSnackbar } = useSnackbar();
  const editActionDialogSnackbarAction = (snackbarId: SnackbarKey) =>
    listRef && isScrollTopVisible ? (
      <>
        <SnackbarGoToTopAction snackbarId={snackbarId} listRef={listRef} />
        <SnackbarCloseAction snackbarId={snackbarId} />
      </>
    ) : (
      <SnackbarCloseAction snackbarId={snackbarId} />
    );

  const onSuccess = () => {
    enqueueSnackbar('Action successfully edited', {
      variant: 'success',
      action: editActionDialogSnackbarAction,
    });
    onClose();
  };
  const onError = (error: any) => {
    /* TODO: remove any type */
    enqueueSnackbar(error ?? 'Failed to update the action', {
      variant: 'error',
    });
    onClose();
  };

  const generateTitle = () =>
    `${COMMON_STRING.EDIT_ACTION} - ${
      action.fixtureSeqNum
    }${` - ${getActionName(action.fixtureActionTypeId, fixtureConfig)} -`} ${
      action.clockTimeString
    } (${formatDate(action.timestamp)})`;

  return (
    <MTDialog
      aria-label={ARIA_LABEL.EDIT_ACTION_DIALOG}
      maxWidth='md'
      fullWidth
      open={true}
      onClose={onClose}
      title={generateTitle()}
    >
      <EditAction
        action={action}
        onCancel={onClose}
        onError={onError}
        onSuccess={onSuccess}
      />
    </MTDialog>
  );
};
