/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useSessionStorage } from 'usehooks-ts';
import { ScoringContext } from '@/contexts/scoring/context';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useScoringRate } from '@/service/hooks/useScoringRate';
import { ComparisonRating } from '@/components/ComparisonRating/ComparisonRating';
import { COMMON_STRING } from '@/constants/dictionary';
import {
  StyledMenuItem,
  StyledMenuList,
} from '@/components/common/StyledMenuItems';
import { getSport } from '@/service/utils/getSport';
import { FIXTURE_TYPE_ID, SPORT_ID } from '@/service/constants';
import { UIStateContext } from '@/contexts/UIState/context';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { ActionsTable } from '../ActionsTable/ActionsTable';
import { SlaBreachFixtureEvaluation } from '../SlaBreachFixtureEvaluation/SlaBreachFixtureEvaluation';

export const SS_QA_SUBTAB_KEY = 'MT-qa-subtabs-minder';

export enum QaSubPage {
  DataCollectorsEvaluation,
  SlaBreachFixtureEvaluation,
  SlaBreachActionEvaluation,
}

export const FixtureComparison = () => {
  const { setIsSlaBreachActionSelected } = useContext(UIStateContext);

  const {
    state: { fixtureId },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const token = useAuthToken();
  const { comparisonRating, isLoading } = useScoringRate({
    token,
    fixtureId,
  });

  const sport = getSport(fixtureConfig);
  const isSoccer = sport && sport.id === SPORT_ID.SOCCER;
  const isBasket = sport && sport.id === SPORT_ID.BASKETBALL;
  const isMatchOfficial =
    fixtureConfig?.fixture.type === FIXTURE_TYPE_ID.OFFICIAL;
  const isSlaBreachFixtureAvalible = isSoccer && isMatchOfficial;
  const isSlaBreachActionAvalible =
    (isSoccer && isMatchOfficial) || (isBasket && isMatchOfficial);

  const availableSubTabs = () => {
    const subTabs: QaSubPage[] = [];
    if (comparisonRating && !!comparisonRating?.length) {
      subTabs.push(QaSubPage.DataCollectorsEvaluation);
    }
    if (isSlaBreachFixtureAvalible) {
      subTabs.push(QaSubPage.SlaBreachFixtureEvaluation);
    }
    if (isSlaBreachActionAvalible) {
      subTabs.push(QaSubPage.SlaBreachActionEvaluation);
    }
    return subTabs;
  };

  const [storedSubTab, setStoredSubTab] = useSessionStorage<
    QaSubPage | undefined
  >(SS_QA_SUBTAB_KEY, QaSubPage.DataCollectorsEvaluation);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const availableTab = availableSubTabs();

    if (storedSubTab === undefined || !availableTab.includes(storedSubTab)) {
      setStoredSubTab(availableTab[0]);
    }
  }, [
    isLoading,
    comparisonRating,
    fixtureId,
    isSlaBreachActionAvalible,
    isSlaBreachFixtureAvalible,
  ]);

  useEffect(() => {
    if (storedSubTab !== undefined) {
      setIsSlaBreachActionSelected(
        storedSubTab === QaSubPage.SlaBreachActionEvaluation,
      );
    }
  }, [storedSubTab]);

  const isSubPageSelected = (subPage: QaSubPage) => subPage === storedSubTab;

  return (
    <PermissionsChecker name='FixturePage.QA.ExternalStats'>
      <Stack direction='row' flex='1' overflow='hidden'>
        <StyledMenuList>
          {!!comparisonRating?.length && (
            <PermissionsChecker name='FixturePage.QA.DataCollectorsEvaluation'>
              <StyledMenuItem
                selected={isSubPageSelected(QaSubPage.DataCollectorsEvaluation)}
                onClick={() => {
                  setStoredSubTab(QaSubPage.DataCollectorsEvaluation);
                }}
              >
                {COMMON_STRING.DATA_COLLECTORS_EVALUATION}
              </StyledMenuItem>
            </PermissionsChecker>
          )}
          {isSlaBreachFixtureAvalible && (
            <PermissionsChecker name='FixturePage.QA.SlaBreachFixtureEvaluation'>
              <StyledMenuItem
                selected={isSubPageSelected(
                  QaSubPage.SlaBreachFixtureEvaluation,
                )}
                onClick={() => {
                  setStoredSubTab(QaSubPage.SlaBreachFixtureEvaluation);
                }}
              >
                {COMMON_STRING.SLA_BREACH_FIXTURE_EVALUATION}
              </StyledMenuItem>
            </PermissionsChecker>
          )}
          {isSlaBreachActionAvalible && (
            <PermissionsChecker name='FixturePage.QA.SlaBreachActionEvaluation'>
              <StyledMenuItem
                selected={isSubPageSelected(
                  QaSubPage.SlaBreachActionEvaluation,
                )}
                onClick={() => {
                  setStoredSubTab(QaSubPage.SlaBreachActionEvaluation);
                }}
              >
                {COMMON_STRING.SLA_BREACH_ACTION_EVALUATION}
              </StyledMenuItem>
            </PermissionsChecker>
          )}
        </StyledMenuList>
        <Stack width={'100%'} overflow={'auto'}>
          {isSubPageSelected(QaSubPage.DataCollectorsEvaluation) &&
            !!comparisonRating?.length && (
              <PermissionsChecker name='FixturePage.QA.DataCollectorsEvaluation'>
                <ComparisonRating comparisonRating={comparisonRating} />
              </PermissionsChecker>
            )}
          {isSubPageSelected(QaSubPage.SlaBreachFixtureEvaluation) && (
            <PermissionsChecker name='FixturePage.QA.SlaBreachFixtureEvaluation'>
              <SlaBreachFixtureEvaluation />
            </PermissionsChecker>
          )}
          {isSubPageSelected(QaSubPage.SlaBreachActionEvaluation) && (
            <PermissionsChecker name='FixturePage.QA.SlaBreachActionEvaluation'>
              <ActionsTable />
            </PermissionsChecker>
          )}
        </Stack>
      </Stack>
    </PermissionsChecker>
  );
};
