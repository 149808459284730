import { ExportToXlsxProps } from '@/utils/types';

export const copyToClipboard = (data: string) => {
  return navigator.clipboard.writeText(data);
};

export const convertPascalCaseTextToSentence = (text?: string) =>
  text?.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

export const convertSnakeCaseTextToSentence = (text: string) =>
  text
    .replace(/^[-_]*(.)/, (_, str) => str.toUpperCase())
    .replace(/[-_]+(.)/g, (_, str) => ' ' + str.toUpperCase());

export function noOp() {}

export const exportToXlsx = async ({ fileName, data }: ExportToXlsxProps) => {
  try {
    const { utils, writeFile } = await import('xlsx');
    const workbook = utils.book_new();

    data.forEach((sheetData) =>
      utils.book_append_sheet(
        workbook,
        utils.json_to_sheet(sheetData.data),
        sheetData.name,
      ),
    );

    return writeFile(workbook, `${fileName}.xlsx`);
  } catch (e) {
    throw new Error('Failed to export data for XLSX report');
  }
};

export function isObject(obj: unknown): obj is Record<any, unknown> {
  return typeof obj === 'object' && obj != null;
}
