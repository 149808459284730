import { Stack, styled } from '@mui/material';

export const LineupsTabBox = styled(Stack)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  height: '100%',
}));

export const LineupsBox = styled(Stack)(({ theme }) => ({
  margin: 0,
  width: '100%',
  padding: theme.spacing(0, 2),
  minHeight: '60%',
  maxHeight: '100%',
  overflowY: 'auto',
  textAlign: 'center',
}));

export const LineupsActionsBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  minWidth: 960,
  minHeight: '75%',
  maxHeight: '100%',
  position: 'relative',
}));
