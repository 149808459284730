import { datadogLogs } from '@datadog/browser-logs';
import { ENVIRONMENT } from '@/constants/environment';
import envVariables from '@/envVariables';

export const initDatadogLogger = () => {
  datadogLogs.init({
    env: envVariables.env,
    clientToken: envVariables.datadogClientToken,
    site: envVariables.datadogSite,
    sessionSampleRate: 100,
    storeContextsAcrossPages: true,
    trackingConsent: 'granted',
    service: envVariables.datadogServiceName,
    forwardConsoleLogs:
      envVariables.env === ENVIRONMENT.PRODUCTION
        ? ['warn', 'error']
        : undefined,
    forwardErrorsToLogs: envVariables.env === ENVIRONMENT.PRODUCTION,

    beforeSend: (log) => {
      const streamNotFound = log.message.includes(
        'XHR error GET https://imgarena-prod-v2-ap.akamaized.net',
      );

      if (streamNotFound) return false;
      if (log.http?.status_code === 404) return false;

      return true;
    },
  });
};
