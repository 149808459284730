export class Konsole {
  static gradientBg =
    'background: linear-gradient(90deg, hsl(30deg 85% 50%), hsl(355deg 85% 50%));';
  static messageStyle = `${this.gradientBg} color: white; font-weight: bold; padding: 4px; text-shadow: -0.1em 0.1em 2px black;`;

  constructor(public name = '') {}

  prefix(type: string = '', label: string = '') {
    let prefix = `%c`;
    if (this.name) {
      prefix += `${this.name}>`;
    }
    if (type) {
      prefix += `${type}>`;
    }
    if (label) {
      prefix += `${label}`;
    }
    return prefix;
  }
  log(...args: any[]) {
    const hasMessage = typeof args[0] === 'string';
    console.log(
      this.prefix('', hasMessage ? args[0] : undefined),
      Konsole.messageStyle,
      ...(hasMessage ? args.slice(1) : args)
    );
  }
  warn(...args: any[]) {
    console.warn(this.prefix('warn'), Konsole.messageStyle, ...args);
  }
  error(...args: any[]) {
    console.error(this.prefix('error'), Konsole.messageStyle, ...args);
  }
  table(tableName: string, data?: any, columns?: string[]) {
    console.log(this.prefix(tableName), Konsole.messageStyle);
    console.table(data, columns);
  }
  group(label: string) {
    console.group(`${this.prefix()}${label}`, Konsole.messageStyle);
  }
  groupCollapsed(label: string) {
    console.groupCollapsed(`${this.prefix()}${label}`, Konsole.messageStyle);
  }
  groupEnd = console.groupEnd;
  dir(...args: any[]) {
    const hasMessage = typeof args[0] === 'string';
    console.log(
      this.prefix('dir', hasMessage ? args[0] : undefined),
      Konsole.messageStyle
    );
    console.dir(...(hasMessage ? args.slice(1) : args));
  }
}
