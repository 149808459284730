import { FixtureAction } from '@/service/types';

export interface VisualNotificationMember {
  showSnack: number;
  hideSnack: number;
  isActive: boolean;
}

export interface PlayersLeftVisualNotification {
  playerId: FixtureAction['playerId'];
  teamId: FixtureAction['teamId'];
  fixtureSeqNum: FixtureAction['fixtureSeqNum'];
}

export const VISUAL_NOTIFICATIONS_PROPERTIES = {
  FLAG_ON_FIELD: 'flagOnField',
  POSSIBLE_VAR: 'possibleVar',
  SUPERVISOR_CALL: 'supervisorCall',
  MARKET_OPENED: 'marketOpened',
  MARKET_CLOSED: 'marketClosed',
  VAR_UNDERWAY: 'varUnderway',
  START_TIME: 'startTime',
  PLAY_STOPPED: 'playStopped',
  PLAYER_LEFT: 'playerLeft',
};

export interface VisualNotifications {
  flagOnField: VisualNotificationMember;
  possibleVar: VisualNotificationMember;
  supervisorCall: VisualNotificationMember;
  varUnderway: VisualNotificationMember;
  startTime: VisualNotificationMember;
  playStopped: VisualNotificationMember;
  playerLeft: PlayersLeftVisualNotification[];
}

export type NotificationsFilterType = {
  actionType: number;
  sendType?: number;
};
