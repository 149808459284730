import { Button, CircularProgress, Stack } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

interface ConfirmButtonsProps {
  onSubmit: VoidFunction;
  onReject: VoidFunction;
  isLoading: boolean;
}

export const ConfirmButtons = ({
  onSubmit,
  onReject,
  isLoading,
}: ConfirmButtonsProps) => {
  return (
    <Stack flexDirection='row' gap={1} justifyContent='end'>
      <Button variant='text' onClick={onReject}>
        {COMMON_STRING.NO}
      </Button>
      <Button
        variant='contained'
        onClick={onSubmit}
        disabled={isLoading}
        startIcon={
          isLoading && <CircularProgress color={'inherit'} size={'1em'} />
        }
      >
        {COMMON_STRING.YES}
      </Button>
    </Stack>
  );
};
