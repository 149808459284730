import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { THEME_MODE } from '@/contexts/theme/constants';

interface StatsButtonProps {
  isActive: boolean;
}

export const StatsButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StatsButtonProps>(({ isActive = false, theme }) => ({
  color: isActive
    ? theme.palette.primary.main
    : theme.palette.mode === THEME_MODE.LIGHT
    ? 'rgba(0, 0, 0, 0.74)'
    : theme.palette.text.primary,
  border: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.divider}`,
  backgroundColor: isActive ? `${theme.palette.primary.main}15` : 'transparent',
  borderRadius: theme.spacing(2),
  fontSize: '0.8125rem',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: isActive
      ? `${theme.palette.primary.main}15`
      : theme.palette.action.hover,
    border: isActive
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.divider}`,
    cursor: isActive ? 'default' : 'pointer',
  },
  fontWeight: 400,
  padding: theme.spacing(0.5, 1.5),
}));
