import { Stack, Tabs, Tab, IconButton } from '@mui/material';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CLOSE_DIALOG_BUTTON_LABEL } from '@/components/common/DialogTitleWithClose';
import { PANEL, Panel } from '@/contexts/UIState/types';

type FiltersDrawerHeaderProps = {
  onClose: VoidFunction;
  value: Panel | null;
  handleChange:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
};

export const FiltersDrawerHeader: FC<FiltersDrawerHeaderProps> = ({
  onClose,
  value,
  handleChange,
}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Tabs value={value ?? PANEL.FILTERS} onChange={handleChange}>
        <Tab label='Filters' value={PANEL.FILTERS} />
        <Tab label='Templates' value={PANEL.FILTER_TEMPLATES} />
      </Tabs>
      <IconButton
        aria-label={CLOSE_DIALOG_BUTTON_LABEL}
        onClick={onClose}
        size='small'
        sx={{
          margin: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
