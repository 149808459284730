import { UNKNOWN_PLAYER_ID } from '@/constants';
import {
  FIXTURE_ACTION_SUBTYPE,
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';
import {
  ActionType,
  FixtureAction,
  FixtureConfig,
  FixtureSummary,
  MatchSummaryTeam,
  Player,
} from '@/service/types';
import { getPlayer } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import {
  EditActionState,
  GeneratePlayersForOptionsProps,
  EditActionPlayerOptions,
} from './types';

interface GenerateInitialStateProps {
  fixtureSummary: FixtureSummary | null;
  action: FixtureAction;
  fixtureConfig?: FixtureConfig;
}

interface isEditActionSaveButtonDisabledProps {
  editedAction: EditActionState;
  action: FixtureAction;
  isPlayerRequired: boolean;
  isChangeableActionTypeId: boolean;
  isChangeTeamAllowed: boolean;
  isSubTypeRequired: boolean;
}

export const generateInitialState = ({
  fixtureSummary,
  action,
  fixtureConfig,
}: GenerateInitialStateProps) => {
  const generateActionTypeOptions = () => {
    const defaultAction = fixtureConfig?.fixtureOptions?.actionButtons.find(
      (actionButton) =>
        actionButton.actionType.id === action.fixtureActionTypeId,
    );

    if (defaultAction) {
      const { changeableActionTypeIds, changeableActions } = defaultAction;
      if (changeableActionTypeIds?.length || changeableActions?.length) {
        const relatedActionTypeIds = new Set([
          ...(changeableActionTypeIds || []),
          ...(changeableActions?.map(({ actionTypeId }) => actionTypeId) || []),
        ]);

        const relatedActions =
          fixtureConfig?.fixtureOptions?.actionButtons.filter(
            ({ actionType: { id } }) => relatedActionTypeIds.has(id),
          );

        return [
          defaultAction.actionType,
          ...(relatedActions?.map(
            ({ actionType }) => actionType,
          ) as ActionType[]),
        ];
      }
    }

    return [defaultAction?.actionType] as ActionType[];
  };

  const additionalActionTypeOptions = generateActionTypeOptions();

  const actionType = additionalActionTypeOptions[0];

  const actionSubTypes = actionType?.subTypes as ActionType[];

  return {
    fixtureActionType: !actionType
      ? null
      : {
          ...actionType,
          subTypes: actionSubTypes,
        },
    fixtureSubActionType:
      actionType?.subTypes?.find(
        (subType) => subType?.id === action.fixtureActionSubTypeId,
      ) || null,
    teamId: action.teamId,
    player: getPlayer(action.playerId, fixtureConfig, fixtureSummary) as Player,
    additionalActionTypeOptions,
  };
};

export const actionsReducer = (
  state: EditActionState,
  newValue: Partial<EditActionState>,
): EditActionState => {
  return { ...state, ...newValue };
};

export const generateEditActionTeam = (
  action: FixtureAction,
  newAction: EditActionState,
  homeTeam: MatchSummaryTeam,
  awayTeam: MatchSummaryTeam,
) => {
  const isEditedActionSubTypeOwnGoal =
    newAction.fixtureSubActionType?.id === FIXTURE_ACTION_SUBTYPE.OWN_GOAL;
  const isHomeTeam = newAction.teamId === homeTeam.id;

  if (isEditedActionSubTypeOwnGoal) {
    return isHomeTeam ? awayTeam : homeTeam;
  }

  return isHomeTeam ? homeTeam : awayTeam;
};

export const generatePlayersForOptions = ({
  editedAction,
  fixtureSummary,
  action,
}: GeneratePlayersForOptionsProps) => {
  if (!fixtureSummary) return [];
  const { awayTeam, homeTeam } = fixtureSummary;

  const team = generateEditActionTeam(action, editedAction, homeTeam, awayTeam);

  return {
    teamId: team.id,
    teamName: team.teamName,
    players: [
      ...team.players,
      {
        firstName: '',
        fullName: 'Unknown',
        id: UNKNOWN_PLAYER_ID,
        isInCurrentLineup: true,
        isStartingSquad: false,
        lastName: '',
        shirtNumber: '',
      },
    ].sort(
      (a, b) =>
        Number(b.isInCurrentLineup) - Number(a.isInCurrentLineup) ||
        (a.shirtNumber && b.shirtNumber
          ? parseInt(a.shirtNumber) - parseInt(b.shirtNumber)
          : a.fullName > b.fullName
          ? 1
          : -1),
    ),
  } as EditActionPlayerOptions;
};

export const isEditActionSaveButtonDisabled = ({
  editedAction,
  action,
  isPlayerRequired,
  isSubTypeRequired,
  isChangeableActionTypeId,
  isChangeTeamAllowed,
}: isEditActionSaveButtonDisabledProps) => {
  if (isPlayerRequired && !editedAction.player) {
    return true;
  }

  if (
    !editedAction.fixtureActionType ||
    (isPlayerRequired && !editedAction.player)
  ) {
    return true;
  }

  if (
    isPlayerRequired &&
    (!editedAction.player || editedAction.player.id === action.playerId)
  ) {
    if (!isChangeableActionTypeId) {
      return true;
    }

    if (isChangeableActionTypeId) {
      if (action.fixtureActionTypeId === editedAction.fixtureActionType?.id) {
        if (!editedAction.fixtureActionType?.subTypes?.length) {
          return true;
        }

        if (
          editedAction.fixtureActionType?.subTypes?.length &&
          action.fixtureActionSubTypeId ===
            editedAction.fixtureSubActionType?.id
        ) {
          return true;
        }
      }
      if (
        action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.GOAL &&
        (action.fixtureActionSubTypeId
          ? action.fixtureActionSubTypeId ===
            editedAction.fixtureSubActionType?.id
          : action.fixtureActionSubTypeId === editedAction.fixtureSubActionType)
      ) {
        return true;
      }
    }
  }

  if (
    !editedAction.fixtureSubActionType &&
    editedAction.fixtureActionType?.subTypes?.length &&
    isSubTypeRequired
  ) {
    return true;
  }

  if (isChangeTeamAllowed) {
    if (isChangeableActionTypeId) {
      if (
        action.fixtureActionTypeId !== editedAction.fixtureActionType?.id ||
        (action.fixtureActionSubTypeId
          ? action.fixtureActionSubTypeId !==
            editedAction.fixtureSubActionType?.id
          : (action.fixtureActionSubTypeId ?? true) !==
            (editedAction.fixtureSubActionType ?? true))
      ) {
        return false;
      }
    }

    if (isPlayerRequired) {
      if (editedAction.player?.id !== action.playerId) {
        return false;
      }
    }

    return editedAction.teamId === action.teamId;
  }

  if (isPlayerRequired && editedAction.player!.id === action.playerId) {
    return false;
  }

  return (
    isChangeableActionTypeId &&
    action.fixtureActionTypeId === editedAction.fixtureActionType?.id &&
    (isSubTypeRequired
      ? !!(
          editedAction.fixtureSubActionType?.id &&
          action.fixtureActionSubTypeId === editedAction.fixtureSubActionType.id
        )
      : false) &&
    !isPlayerRequired
  );
};

const isEditableSendType = (action: FixtureAction) =>
  action.sendTypeId === MATCH_ACTION_SEND_TYPE.CONFIRMED ||
  action.sendTypeId === MATCH_ACTION_SEND_TYPE.UPDATED;

const isActionWithHighiestSequenceNumber = (
  action: FixtureAction,
  actions: FixtureAction[],
) => {
  return (
    actions
      .filter((item) => item.actionId === action.actionId)
      ?.sort(sequenceComparer)?.[0]?.id === action.id
  );
};

const sequenceComparer = (a: FixtureAction, b: FixtureAction) =>
  a.fixtureSeqNum > b.fixtureSeqNum ? -1 : 1;

export const getLastActionsForFixtureActionTypeId = (
  fixtureActionType: number,
  actions: FixtureAction[],
): FixtureAction[] => {
  return actions.filter(
    (action) =>
      action.fixtureActionTypeId === fixtureActionType &&
      isActionWithHighiestSequenceNumber(action, actions) &&
      isEditableSendType(action),
  );
};

export const playersAlreadyAssignedToEditedActionWithLimit = (
  editedAction: ActionType | null | undefined,
  actions: FixtureAction[] | undefined,
  fixtureConfig: FixtureConfig | undefined,
) => {
  if (!editedAction || !actions) {
    return [];
  }

  const actionButtonConfig = fixtureConfig?.fixtureOptions.actionButtons.find(
    (button) => button.actionType.id === editedAction?.id,
  );

  if (actionButtonConfig?.maxLimits?.perFixture?.player === 1) {
    const allActionsOfEditedActionType = getLastActionsForFixtureActionTypeId(
      editedAction.id,
      actions,
    );

    return allActionsOfEditedActionType.map((action) => action.playerId);
  }

  return [];
};

export const isChangeableActionTypeId = ({
  fixtureActionTypeId,
  fixtureConfig,
  checkGoal = false,
}: {
  fixtureActionTypeId: FixtureAction['fixtureActionTypeId'];
  fixtureConfig: FixtureConfig | undefined;
  checkGoal?: boolean;
}) =>
  !!fixtureConfig?.fixtureOptions.actionButtons.some(
    ({
      changeableActionTypeIds,
      actionType,
      subTypeRequired,
      changeableActions,
    }) =>
      (checkGoal && fixtureActionTypeId === FIXTURE_ACTION_TYPE.GOAL) ||
      (actionType.id === fixtureActionTypeId &&
        (changeableActionTypeIds?.length ||
          changeableActions?.length ||
          subTypeRequired)),
  );
