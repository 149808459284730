import { PaletteMode } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { PreferenceAction } from '@/contexts/preferences/types';
import { FixtureConfig } from '@/service/types';
import { THEME_MODE } from '@/contexts/theme/constants';

interface CreatePdfProps {
  currentTheme: PaletteMode;
  setTheme: PreferenceAction<PaletteMode>;
  periodLabel: string;
  reportDate: string;
  elementId: string;
  fixtureConfig?: FixtureConfig;
  openInNew?: boolean;
}
export const createPdf = async ({
  fixtureConfig,
  periodLabel,
  reportDate,
  elementId,
  openInNew,
  currentTheme,
  setTheme,
}: CreatePdfProps) => {
  if (currentTheme === THEME_MODE.DARK) await setTheme(THEME_MODE.LIGHT);
  const data = document.getElementById(elementId);

  html2canvas(data as HTMLElement).then((canvas) => {
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL('image/png');
    const pdfData = new jsPDF('p', 'mm', 'a4');
    const position = 0;
    pdfData.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

    const fileName =
      fixtureConfig?.fixture.name +
      '-' +
      periodLabel +
      '-' +
      reportDate +
      '.pdf';

    pdfData.setProperties({
      title: fileName,
    });

    if (openInNew)
      return window.open(pdfData.output('bloburl').toString(), '_blank');

    return pdfData.save(fileName);
  });
  if (currentTheme === THEME_MODE.DARK) await setTheme(currentTheme);
};
