import { TextField, Typography, Stack } from '@mui/material';
import { ChangeEvent, SetStateAction, Dispatch, KeyboardEvent } from 'react';
import { allowedCommentKeys, TEXT_FIELD_CONSTANCE } from '../constants';

interface EvaluationCommentFieldProps {
  comment: string;
  addComment: Dispatch<SetStateAction<string>>;
}

export const EvaluationCommentField = ({
  comment,
  addComment,
}: EvaluationCommentFieldProps) => {
  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= TEXT_FIELD_CONSTANCE.CHARACTERS_LIMIT) {
      addComment(event.target.value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (allowedCommentKeys.includes(event.key)) {
      return;
    }
    if (comment.length >= TEXT_FIELD_CONSTANCE.CHARACTERS_LIMIT) {
      event.preventDefault();
    }
  };

  return (
    <>
      <TextField
        multiline
        value={comment}
        placeholder={TEXT_FIELD_CONSTANCE.PLACEHOLDER}
        onChange={handleCommentChange}
        onKeyDown={handleKeyDown}
        minRows={6}
        maxRows={16}
      />
      <Stack alignItems='flex-end'>
        <Typography>
          {comment.length}/{TEXT_FIELD_CONSTANCE.CHARACTERS_LIMIT}
        </Typography>
        {comment.length === TEXT_FIELD_CONSTANCE.CHARACTERS_LIMIT && (
          <Typography color='error'>
            {TEXT_FIELD_CONSTANCE.CHARACTERS_REACHED}
          </Typography>
        )}
      </Stack>
    </>
  );
};
