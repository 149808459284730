import {
  calculateRemainingTime,
  isDisabled,
  validateCheckbox,
} from '@/components/Checklist/utils';
import { FixtureChecklistElement } from '@/service/types/checklist';
import {
  ChecklistCheckboxState,
  ChecklistContextState,
  ChecklistReducerAction,
  CHECKLIST_REDUCER_ACTION,
} from './types';

export const getDefaultCheckboxes = (
  checkboxes: FixtureChecklistElement[]
): ChecklistCheckboxState[] =>
  checkboxes
    .map(
      (checklistElementType): ChecklistCheckboxState => ({
        ...checklistElementType,
        isDisabled: true,
        isReadyToCheck: false,
        name: checklistElementType.value,
        warnings: [],
      })
    )
    .sort((a, b) => a.index - b.index);

export const getChecklistDefaultState = (): ChecklistContextState => {
  return [];
};

export const checklistReducer = (
  state: ChecklistContextState,
  dispatchedAction: ChecklistReducerAction
): ChecklistContextState => {
  const { action, payload } = dispatchedAction;
  const checkboxes = state.length > 0 ? state : [];
  switch (action) {
    case CHECKLIST_REDUCER_ACTION.CHECKBOXES:
      return getDefaultCheckboxes(payload);
    case CHECKLIST_REDUCER_ACTION.VALIDATE:
      const { actions, config, summary, fixtureActions } = payload;
      const remainingMinutes = Math.floor(
        calculateRemainingTime(new Date(config.fixture.startDateUTC)) /
          (1000 * 60)
      );

      return checkboxes.map((checkbox) => {
        const validationParams = {
          checkbox,
          remainingMinutes,
          actions,
          config,
          summary,
          fixtureActions,
        };
        const validationResult = validateCheckbox(validationParams);

        return {
          ...checkbox,
          isDisabled: isDisabled(validationParams),
          ...validationResult,
        };
      });

    default:
      return state;
  }
};
