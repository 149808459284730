export interface FixtureChecklistElement {
  elementId: string;
  fixtureId: string;
  index: number;
  isChecked: boolean;
  requiresConfirmation: boolean;
  timeTreshold: number | null;
  value: string;
  warning: string | null;
}

export const CHECKLIST_ELEMENT_INDEX = {
  ATTENDANCE_GEO: 1,
  ATTENDANCE_SELFIE: 2,
  MATCH_CONDITIONS: 3,
  TEAM_COLOURS: 4,
  LINEUPS: 5,
  ATTENDANCE_SCOREBOARD: 6,
  POST_MATCH_CHECK_COMPLETE: 7,
  SLA_BREACH_COMPLETE: 8,
} as const;

export const CHECKLIST_ELEMENT_NAME = {
  ATTENDANCE_GEO: 'Attendance - geolocation',
  ATTENDANCE_SELFIE: 'Attendance - selfie',
  MATCH_CONDITIONS: 'Match conditions',
  TEAM_COLOURS: 'Team colours',
  LINEUPS: 'Lineups',
  ATTENDANCE_SCOREBOARD: 'Attendance - scoreboard',
  POST_MATCH_CHECK_COMPLETE: 'Post match check complete',
  SLA_BREACH_COMPLETE: 'SLA breach complete',
} as const;
