import { PaletteMode } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FixtureAction } from '@/service/types';
import {
  ActionFiterType,
  TemplateFormData,
} from '@/components/ActionsTable/ActionFilters/Template/types';
import { ActionFilter } from '@/components/ActionsTable/ActionFilters/useActionsFilters';
import { API_METHOD } from '@/service/constants';
import {
  FilterSendType,
  SportType,
} from '@/components/ActionsTable/ActionFilters/types';
import { SoundId, SoundName } from '../notifications/types';
import { ActionConditionId } from './constants';

export type SoundSettings = {
  id: SoundId;
  name: SoundName;
  color: string;
  volume: number;
  muted: boolean;
  permissionName: string;
};

export type SoundsSettings = Record<SoundId, SoundSettings>;

export type SoundsPreferences = {
  volume: number;
  muted: boolean;
  sounds: SoundsSettings;
};

export type ActionSound = [FixtureAction['fixtureActionTypeId'], SoundId];
export type ActionSoundUpdateParam = [
  FixtureAction['fixtureActionTypeId'],
  SoundId | null,
];
export type ActionConditionSound = [ActionConditionId, SoundId];
export type ActionConditionSoundUpdateParam = [
  ActionConditionId,
  SoundId | null,
];

export type PreferencesValues = {
  themeMode: PaletteMode;

  soundsPreferences: SoundsPreferences;
  isSoundsPreferencesAppDefault: boolean;
  isSoundsPreferencesUserDefault: boolean;

  actionSounds: ActionSound[];
  isActionSoundsUserDefault: boolean;
  isActionSoundsAppDefault: boolean;
  actionConditionSounds: ActionConditionSound[];

  templates?: SportTemplate[];
  selectedTemplate?: TemplateFormData;
  selectedTemplateFilters?: ActionFilter[];
  dontShowFilterTemplateApplyWarning?: boolean;
  dontShowTemplateChangeWarning?: boolean;
};

export type PreferenceAction<T> = (value: T) => void;

export type PreferencesActions = {
  setThemeMode: PreferenceAction<PaletteMode>;

  updateSoundsPreferences: PreferenceAction<Partial<SoundsPreferences>>;
  updateSoundPreferences: PreferenceAction<{
    soundId: SoundId;
    soundUpdate: Partial<SoundSettings>;
  }>;
  saveUserDefaultSoundsPreferences: VoidFunction;
  restoreUserDefaultSoundsPreferences: VoidFunction;
  restoreAppDefaultSoundsPreferences: VoidFunction;

  restoreAppDefaultActionSounds: VoidFunction;
  restoreUserDefaultActionSounds: VoidFunction;
  updateUserDefaultActionSounds: VoidFunction;
  updateActionSounds: PreferenceAction<ActionSoundUpdateParam>;
  setCurrentFixtureActionTypeIds: Dispatch<SetStateAction<number[]>>;
  updateActionConditionSounds: PreferenceAction<ActionConditionSoundUpdateParam>;

  setSelectedTemplate: (
    template: TemplateFormData | undefined,
    sportId: number | undefined,
  ) => void;
  updateTemplate: (
    template: TemplateFormData,
    apiMethod: API_METHOD.POST | API_METHOD.DELETE,
  ) => void;
  setDontShowFilterTemplateApplyWarning: () => void;
  setDontShowTemplateChangeWarning: () => void;
  deleteTemplate: (templateId: string, sportId: number) => void;
  reorderTemplates: (templates: TemplateFormData[]) => void;
};

export type Preferences = PreferencesValues & {
  actions: PreferencesActions;
  sports: SportType[] | undefined;
  actionTypes: SportActionType[] | undefined;
  sendTypes: FilterSendType[] | undefined;
};

export type NotificationUpdateFn = Dispatch<SetStateAction<SoundsPreferences>>;

export type StoredPreferences = {
  templatesPreferences?: TemplatePreferences;
  //Add more properties here for any preferences that will be stored in Key Value store
};

export type TemplatePreferences = {
  sportTemplates?: SportTemplate[];
  dontShowFilterTemplateApplyWarning?: boolean;
  dontShowTemplateChangeWarning?: boolean;
};

export type SportTemplate = {
  sportId: number;
  templateFilters?: TemplateFormData[];
  selectedTemplateId?: string;
};

export enum PREFERENCE_TYPE {
  FILTER_TEMPLATE = 'Filter Template',
}

export type SportActionType = {
  sportId: number;
  sportName: string;
  actionTypes: ActionFiterType[];
};
