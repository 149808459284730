import { FixtureSummary } from '../types';

export function getAggregatesByTeam(fixtureSummary: FixtureSummary) {
  const homeTeamId = fixtureSummary.homeTeam.id;
  const awayTeamId = fixtureSummary.awayTeam.id;
  const { teamActionAggregates } = fixtureSummary;
  return [
    teamActionAggregates.find(({ teamId }) => teamId === homeTeamId),
    teamActionAggregates.find(({ teamId }) => teamId === awayTeamId),
  ];
}

export function getPeriodActionAggregate(
  fixtureSummary: FixtureSummary,
  actionTypeId: number
) {
  const [homeAggregates, awayAggregates] = getAggregatesByTeam(fixtureSummary);

  const homePeriodAggregates = homeAggregates
    ? homeAggregates.periodActionAggregates
    : [];
  const awayPeriodAggregates = awayAggregates
    ? awayAggregates.periodActionAggregates
    : [];

  const homePeriodActionAggregate = homePeriodAggregates.filter(
    ({ actionId }) => actionId === actionTypeId
  );
  const awayPeriodActionAggregate = awayPeriodAggregates.filter(
    ({ actionId }) => actionId === actionTypeId
  );

  return {
    homeTeam: homePeriodActionAggregate,
    awayTeam: awayPeriodActionAggregate,
  };
}

export function getGameActionAggregate(
  fixtureSummary: FixtureSummary,
  actionTypeId: number
) {
  const [homeAggregates, awayAggregates] = getAggregatesByTeam(fixtureSummary);
  const homeGameAggregates = homeAggregates
    ? homeAggregates.gameActionAggregates
    : [];
  const awayGameAggregates = awayAggregates
    ? awayAggregates.gameActionAggregates
    : [];

  return {
    homeTeam: homeGameAggregates.find(
      ({ actionId }) => actionId === actionTypeId
    ),
    awayTeam: awayGameAggregates.find(
      ({ actionId }) => actionId === actionTypeId
    ),
  };
}
