import { Dispatch, SetStateAction } from 'react';

type UIStateAction<T> = Dispatch<SetStateAction<T>>;

export const PANEL = {
  CHECKLIST: 'checklist',
  PREFERENCES: 'preferences',
  DATA_REVIEW: 'data-review',
  FILTERS: 'filters',
  STATUSES: 'statuses',
  KEYBOARD_SHORTCUTS: 'keyboard-shortcuts',
  FILTER_TEMPLATES: 'filter-templates',
  ADD_FILTER_TEMPLATE: 'add-filter-template',
} as const;
export type Panel = (typeof PANEL)[keyof typeof PANEL] | null;

export interface PanelHotkey {
  key: string;
  panel: Panel;
}

export type UIState = {
  openedPanel: Panel;
  setOpenedPanel: UIStateAction<Panel>;
  isFixtureInfoVisible: boolean;
  setFixtureInfoVisible: UIStateAction<boolean>;
  isDataCollectorsVisible: boolean;
  setIsDataCollectorsVisible: UIStateAction<boolean>;
  setHotkeysDisabled: UIStateAction<boolean>;
  showRowEditPopup: boolean;
  setShowRowEditPopup: UIStateAction<boolean>;
  isSlaBreachActionSelected: boolean;
  setIsSlaBreachActionSelected: UIStateAction<boolean>;
  showRowLatency: boolean;
  setShowRowLatency: UIStateAction<boolean>;
  setIsScoreboardVisible: UIStateAction<boolean>;
  isScoreboardVisible: boolean;
};
