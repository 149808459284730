import { SportActionType } from '@/contexts/preferences/types';
import { FixtureConfig, StaticFixtureActionType } from '../types';

const fixtureActionTypesMemo: {
  config: FixtureConfig | undefined;
  actionTypes: StaticFixtureActionType[];
} = {
  config: undefined,
  actionTypes: [],
};
const sortById = (a: StaticFixtureActionType, b: StaticFixtureActionType) => {
  return a.id - b.id;
};
const sortByName = (a: StaticFixtureActionType, b: StaticFixtureActionType) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};
export const getFixtureActionTypes = (
  fixtureConfig: FixtureConfig,
  sortBy: 'id' | 'name' = 'name'
) => {
  if (fixtureConfig === fixtureActionTypesMemo.config) {
    return fixtureActionTypesMemo.actionTypes;
  }
  const fixtureActionTypes = fixtureConfig.fixtureOptions.actionButtons.map(
    ({ actionType }) => ({
      id: actionType.id,
      name: actionType.name,
    })
  );

  const staticActionTypes = fixtureConfig.staticFixtureActions.filter(
    ({ id }) =>
      !fixtureActionTypes.some(
        ({ id: fixtureActionId }) => fixtureActionId === id
      )
  );
  fixtureActionTypesMemo.config = fixtureConfig;

  const sortFn = sortBy === 'id' ? sortById : sortByName;
  fixtureActionTypesMemo.actionTypes = [
    ...staticActionTypes,
    ...fixtureActionTypes,
  ].sort(sortFn);
  return fixtureActionTypesMemo.actionTypes;
};

export const getFixtureActionTypesFromApi = (
  sportsActionTypes: SportActionType[] | undefined,
  sportId: number | undefined,
  sortBy: 'id' | 'name' = 'name'
) => {
  if (!sportId || !sportsActionTypes) {
    return [];
  }
  const sortFn = sortBy === 'id' ? sortById : sortByName;
  const sportActionTypes = sportsActionTypes.find(
    (sportActions) => sportActions.sportId === sportId
  );
  if (!sportActionTypes) {
    return [];
  }

  return sportActionTypes.actionTypes.sort(sortFn);
};
