import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import chrome1 from '@/assets/images/sound-permission/chrome-1.png';
import chrome2 from '@/assets/images/sound-permission/chrome-2.png';
import firefox1 from '@/assets/images/sound-permission/firefox-1.png';
import safari1 from '@/assets/images/sound-permission/safari-1.png';
import safari2 from '@/assets/images/sound-permission/safari-2.png';
import edge1 from '@/assets/images/sound-permission/edge-1.png';
import edge2 from '@/assets/images/sound-permission/edge-2.png';
import edge3 from '@/assets/images/sound-permission/edge-3.png';
import edge4 from '@/assets/images/sound-permission/edge-4.png';
import edge5 from '@/assets/images/sound-permission/edge-5.png';
import edge6 from '@/assets/images/sound-permission/edge-6.png';

export const SS_KEY = 'MT-autoplay-blocked';
export type AutoplayDialogProps = {
  onClose: VoidFunction;
};

export const AutoplayDialog: FC<AutoplayDialogProps> = ({ onClose }) => {
  return (
    <>
      <DialogTitle>Audio permission</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>
          Audio notifications require your interaction to play automatically.
        </Typography>

        <Typography>
          If you don't want to see this popup, follow instructions below.
        </Typography>

        <Stack>
          <Accordion>
            <AccordionSummary>Google Chrome</AccordionSummary>
            <AccordionDetails>
              <img
                src={chrome1}
                loading='lazy'
                width='100%'
                alt='Chrome permission instruction step 1'
              />
              <img
                src={chrome2}
                loading='lazy'
                width='100%'
                alt='Chrome permission instruction step 2'
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Firefox</AccordionSummary>
            <AccordionDetails>
              <img
                src={firefox1}
                loading='lazy'
                width='100%'
                alt='Firefox permission instruction step 1'
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Safari</AccordionSummary>
            <AccordionDetails>
              <img
                src={safari1}
                loading='lazy'
                width='100%'
                alt='Safari permission instruction step 1'
              />
              <img
                src={safari2}
                loading='lazy'
                width='100%'
                alt='Safari permission instruction step 2'
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Edge</AccordionSummary>
            <AccordionDetails>
              <img
                src={edge1}
                loading='lazy'
                width='100%'
                alt='Edge permission instruction step 1'
              />
              <img
                src={edge2}
                loading='lazy'
                width='100%'
                alt='Edge permission instruction step 2'
              />
              <img
                src={edge3}
                loading='lazy'
                width='100%'
                alt='Edge permission instruction step 3'
              />
              <img
                src={edge4}
                loading='lazy'
                width='100%'
                alt='Edge permission instruction step 4'
              />
              <img
                src={edge5}
                loading='lazy'
                width='100%'
                alt='Edge permission instruction step 5'
              />
              <img
                src={edge6}
                loading='lazy'
                width='100%'
                alt='Edge permission instruction step 6'
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onClose}>
          {COMMON_STRING.OK}
        </Button>
      </DialogActions>
    </>
  );
};
