import { BasketballStatsTableData } from '@/components/BasketballStatsTable/types';
import {
  LEADS_TABLE_CELLS,
  LEADS_TABLE_KEYS,
} from '@/components/ScoresheetTables/LeadsTable/constants';
import { ScoresheetTableCell } from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import {
  KeyType,
  SubKeyType,
} from '@/components/ScoresheetTables/common/types';
import { ZoneStatistics } from '@/service/types';

interface GenerateLeadsTableCellProps {
  tableData?: BasketballStatsTableData;
  gameData?: BasketballStatsTableData;
  key: KeyType;
  index: string;
}

export const generateLeadsTableCell = ({
  tableData,
  gameData,
  key,
  index,
}: GenerateLeadsTableCellProps) => {
  const generateCellString = () => {
    if (!tableData && !gameData) return '';
    switch (key) {
      case LEADS_TABLE_KEYS.POINTS_IN_THE_PAINT:
        return `${
          (
            tableData?.statistics[
              LEADS_TABLE_CELLS[key]?.keys[0] as KeyType
            ] as ZoneStatistics
          )?.[LEADS_TABLE_CELLS[key]?.subKeys![0] as SubKeyType]
        } (${
          (
            tableData?.statistics[
              LEADS_TABLE_CELLS[key]?.keys[0] as KeyType
            ] as ZoneStatistics
          )?.[LEADS_TABLE_CELLS[key]?.subKeys![1] as SubKeyType]
        }/${
          (
            tableData?.statistics[
              LEADS_TABLE_CELLS[key]?.keys[0] as KeyType
            ] as ZoneStatistics
          )?.[LEADS_TABLE_CELLS[key]?.subKeys![2] as SubKeyType]
        }) ${
          (
            tableData?.statistics[
              LEADS_TABLE_CELLS[key]?.keys[0] as KeyType
            ] as ZoneStatistics
          )?.[LEADS_TABLE_CELLS[key]?.subKeys![3] as SubKeyType]
        }%`;
      case LEADS_TABLE_KEYS.BIGGEST_LEAD:
        return `${
          tableData?.statistics[LEADS_TABLE_CELLS[key]?.keys[0] as KeyType]
        } (${
          tableData?.statistics[LEADS_TABLE_CELLS[key]?.keys[1] as KeyType] ||
          ''
        })`;
      case LEADS_TABLE_KEYS.BIGGEST_SCORING_RUN:
        return `${
          tableData?.statistics[LEADS_TABLE_CELLS[key]?.keys[0] as KeyType]
        } (${
          tableData?.statistics[LEADS_TABLE_CELLS[key]?.keys[1] as KeyType] ||
          ''
        })`;
      case LEADS_TABLE_KEYS.LEAD_CHANGES:
      case LEADS_TABLE_KEYS.TIMES_TIED:
        return gameData?.statistics[LEADS_TABLE_CELLS[key]?.keys[0] as KeyType];
      case LEADS_TABLE_KEYS.TIME_WITH_LEAD:
        return tableData?.statistics[
          LEADS_TABLE_CELLS[key]?.keys[0] as KeyType
        ];
      default:
        return;
    }
  };

  return (
    <ScoresheetTableCell
      colSpan={LEADS_TABLE_CELLS[key]?.colSpan}
      key={`${key}-${index}`}
      sx={{
        textAlign: 'center',
      }}
    >
      {generateCellString() as string}
    </ScoresheetTableCell>
  );
};
