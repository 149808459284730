import { AmericanFootballStatsButtons } from '@/components/StatsButtons/AmericanFootballStatsButtons';
import { AmericanFootballStatsTable } from '@/components/AmericanFootballStatsTable/AmericanFootballStatsTable';
import { StyledStatisticsTabWrapper } from '@/components/common/StyledStatisticsTabWrapper';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export const FixtureAmericanFootballStats = () => {
  return (
    <PermissionsChecker name='FixturePage.AmericanFootballStats'>
      <StyledStatisticsTabWrapper>
        <AmericanFootballStatsButtons />
        <AmericanFootballStatsTable />
      </StyledStatisticsTabWrapper>
    </PermissionsChecker>
  );
};
