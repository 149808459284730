import useSWR from 'swr';
import { ApiError } from '@/service/ApiError';
import { HTTP_STATUS_CODE } from '@/service/constants';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '@/service/fetcher/monitoringToolService';

type UseSupportedSportsParams = {
  token: string | undefined;
};

export const useSupportedSports = ({ token }: UseSupportedSportsParams) => {
  const { data, error } = useSWR<number[], ApiError>(
    token
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.FixtureActions().SupportedSports.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status === HTTP_STATUS_CODE.NOT_FOUND ||
          e.status === HTTP_STATUS_CODE.UNAUTHORIZED
        )
          return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    },
  );

  return {
    error: error,
    isLoading: !data && !error,
    supportedSports: data,
  };
};
