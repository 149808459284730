import { FixtureConfig } from '../types';

export function getSport(fixtureConfig: FixtureConfig | undefined) {
  return (
    fixtureConfig &&
    fixtureConfig.fixture &&
    fixtureConfig.fixture.stage &&
    fixtureConfig.fixture.stage.season &&
    fixtureConfig.fixture.stage.season.competition &&
    fixtureConfig.fixture.stage.season.competition.sport
  );
}
