export type StatsButtonType = {
  id?: string;
  value?: string | number;
  isActive: boolean;
};

export type StatsValues = {
  quarterButtons: StatsButtonType[];
  teamButtons: StatsButtonType[];
  typeButtons: StatsButtonType[];
  statsTypeButtons?: StatsButtonType[];
  activeButtons: {
    quarter: StatsButtonType['value'];
    team: StatsButtonType['id'];
    type: StatsButtonType['value'];
    statsType: StatsButtonType['value'];
  };
};

export enum BUTTON_TYPES {
  QUARTER = 'quarter',
  TEAM = 'team',
  TYPE = 'type',
  STATS_TYPE = 'statsType',
}

export type Stats = StatsValues & {
  changeActiveButton: (
    actionButton: StatsButtonType,
    buttonType: BUTTON_TYPES
  ) => void;
};
