import { Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { useContext, useEffect } from 'react';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Download from '@mui/icons-material/Download';
import { QuarterButtons } from '@/components/StatsButtons/QuarterButtons';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';
import { createPdf } from '@/components/StatsButtons/utils';
import { FixtureConfig } from '@/service/types';
import { PreferencesContext } from '@/contexts/preferences/context';
import { COMMON_STRING } from '@/constants/dictionary';
import { SCORESHEET_ID } from '@/components/FixtureTabs/FixtureScoresheet';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

interface ScoresheetStatsButtonsProps {
  fixtureConfig?: FixtureConfig;
  periodLabel: string;
  reportDate: string;
}
export const ScoresheetStatsButtons = ({
  ...props
}: ScoresheetStatsButtonsProps) => {
  const {
    typeButtons,
    statsTypeButtons,
    changeActiveButton,
    activeButtons: { statsType, type },
  } = useContext(StatsContext);
  const {
    themeMode,
    actions: { setThemeMode },
  } = useContext(PreferencesContext);

  useEffect(() => {
    const defaultTypeButton = typeButtons.find(
      (typeButton) => typeButton.value === STATS_BUTTONS.TYPE.TEAM
    ) as StatsButtonType;
    const defaultStatsTypeButton = statsTypeButtons?.find(
      (statsTypeButton) =>
        statsTypeButton.value === STATS_BUTTONS.STATS.BOX_SCORE
    ) as StatsButtonType;
    if (
      type === STATS_BUTTONS.TYPE.PLAYER ||
      type === STATS_BUTTONS.TYPE.GAME
    ) {
      return changeActiveButton(defaultTypeButton, BUTTON_TYPES.TYPE);
    }
    if (!(statsType === STATS_BUTTONS.STATS.BOX_SCORE)) {
      return changeActiveButton(
        defaultStatsTypeButton,
        BUTTON_TYPES.STATS_TYPE
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsType, type]);

  const exportScoresheetHandler = (openInNew?: boolean) =>
    createPdf({
      ...props,
      openInNew,
      elementId: SCORESHEET_ID,
      currentTheme: themeMode,
      setTheme: setThemeMode,
    });

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <QuarterButtons />
        <Stack direction='row' p={0.5}>
          <PermissionsChecker name='FixturePage.Scoresheet.OpenInNewTab'>
            <Tooltip title={COMMON_STRING.OPEN_IN_A_NEW_TAB} arrow>
              <IconButton
                color='primary'
                onClick={() => exportScoresheetHandler(true)}
              >
                <OpenInNew />
              </IconButton>
            </Tooltip>
          </PermissionsChecker>
          <PermissionsChecker name='FixturePage.Scoresheet.Download'>
            <Tooltip title={COMMON_STRING.DOWNLOAD} arrow>
              <IconButton
                color='primary'
                onClick={() => exportScoresheetHandler()}
              >
                <Download />
              </IconButton>
            </Tooltip>
          </PermissionsChecker>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};
