import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  generateInitialMarketsState,
  generateMarketsPerActionList,
  isMarketOpen,
  isSeparateMarketOpen,
} from '@/contexts/markets/utils';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useSwitchMarketActionTypesConfig } from '@/service/hooks/useSwitchMarketActionTypesConfig';
import { ScoringContext } from '../scoring/context';
import { MarketsContext } from './context';
import { MarketsContextValue } from './types';

export const MarketsProvider: FC<PropsWithChildren> = ({ children }) => {
  const token = useAuthToken();
  const {
    state: { fixtureActions, newFixtureActions },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const { marketRelatedActionTypes } = useSwitchMarketActionTypesConfig({
    token,
  });

  const marketsReducer = (
    state: MarketsContextValue,
    newValue: Partial<MarketsContextValue>,
  ): MarketsContextValue => {
    return { ...state, ...newValue };
  };

  const defaultState: MarketsContextValue = {
    allMarkets: false,
    marketsPerAction: undefined,
    marketsPerActionList: undefined,
    marketRelatedActionTypes: undefined,
  };

  const [marketsState, dispatchMarkets] = useReducer(
    marketsReducer,
    defaultState,
  );

  useEffect(() => {
    if (!fixtureActions?.actions || !!newFixtureActions.length) return;

    return dispatchMarkets(generateInitialMarketsState(fixtureActions.actions));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureActions]);

  useEffect(() => {
    dispatchMarkets({
      marketRelatedActionTypes,
    });
  }, [marketRelatedActionTypes]);

  useEffect(() => {
    dispatchMarkets({
      marketsPerActionList: generateMarketsPerActionList(fixtureConfig),
    });
  }, [fixtureConfig]);

  useEffect(() => {
    if (!marketsState.marketsPerActionList) return;

    dispatchMarkets({
      marketsPerAction: isSeparateMarketOpen(
        fixtureActions?.actions,
        marketsState.marketsPerActionList,
      ),
    });
  }, [fixtureActions?.actions, marketsState.marketsPerActionList]);

  useEffect(() => {
    if (!newFixtureActions.length) return;

    return dispatchMarkets({
      allMarkets: isMarketOpen(fixtureActions?.actions),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFixtureActions]);

  return (
    <MarketsContext.Provider value={marketsState}>
      {children}
    </MarketsContext.Provider>
  );
};
