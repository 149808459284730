import { Slider, Stack, Typography } from '@mui/material';
import { convertSecondsToTime } from '@/utils/convertSecondsToTime/convertSecondsToTime';
import { COMMON_STRING } from '@/constants/dictionary';

interface StreamingTimelineProps {
  currentTime: number;
  actionInStreamTimestamp: number;
  maxActionInStreamTimestamp: number;
  onChangeTimeline: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void;
}

export const StreamingTimeline = ({
  currentTime,
  actionInStreamTimestamp,
  maxActionInStreamTimestamp,
  onChangeTimeline,
}: StreamingTimelineProps) => {
  return (
    <Stack
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      gap={2}
    >
      <Typography>
        {convertSecondsToTime(currentTime - actionInStreamTimestamp)}
      </Typography>
      <Slider
        aria-label={COMMON_STRING.TIMELINE}
        value={currentTime}
        step={0.001}
        min={actionInStreamTimestamp}
        max={maxActionInStreamTimestamp}
        onChange={onChangeTimeline}
      />
      <Typography>
        {convertSecondsToTime(
          maxActionInStreamTimestamp - actionInStreamTimestamp
        )}
      </Typography>
    </Stack>
  );
};
