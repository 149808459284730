import { Divider } from '@mui/material';
import { AdvancedStatsTable } from '@/components/AdvancedStatsTable/AdvancedStatsTable';
import { AdvancedStatsButtons } from '@/components/StatsButtons/AdvancedStatsButtons';
import { StyledStatisticsTabWrapper } from '@/components/common/StyledStatisticsTabWrapper';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export const FixtureAdvancedStats = () => {
  return (
    <PermissionsChecker name='FixturePage.AdvancedStats'>
      <StyledStatisticsTabWrapper>
        <AdvancedStatsButtons />
        <Divider />
        <AdvancedStatsTable />
      </StyledStatisticsTabWrapper>
    </PermissionsChecker>
  );
};
