import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface StreamingBoxProps {
  isStreamingTab: boolean;
}

export const StreamingBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isStreamingTab',
})<StreamingBoxProps>(({ theme, isStreamingTab }) => ({
  overflow: 'auto',
  flex: 1,
  display: !isStreamingTab ? 'none' : 'block',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.getContrastText(theme.palette.common.black),
}));
