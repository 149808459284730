import useSWR from 'swr';
import { NotificationsConfiguration } from '../types';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';

export interface UseNotificationsConfigurationParams {
  token: string | undefined;
  fixtureId?: string;
}
export const useNotificationsConfiguration = ({
  token,
  fixtureId,
}: UseNotificationsConfigurationParams) => {
  const {
    data: notificationsConfiguration,
    error,
    mutate,
  } = useSWR<NotificationsConfiguration | null, ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint:
            SERVICE_ENDPOINT.Fixture(fixtureId).notificationsConfiguration.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    }
  );

  return {
    notificationsConfiguration,
    error,
    isLoading: !notificationsConfiguration && !error,
    mutate,
  };
};
