import { useContext, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Favicon from 'react-favicon';
import { useEffectOnce } from 'usehooks-ts';
import { Collapse, Divider, Stack } from '@mui/material';
import { ScoringContext } from '@/contexts/scoring/context';
import { FixtureInfo } from 'src/components/FixtureInfo';
import { FixtureSummary } from '@/components/FixtureSummary';
import { UIStateContext } from '@/contexts/UIState/context';
import { useUnseenActionsNumber } from '@/contexts/scoring/useUnseenActionsNumber';
import { ScorersPanel } from '@/components/ScorerData/ScorersPanel';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { LoadingOverlay } from '@/components/common/LoadingOverlay';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { USER_ROLE_NAME } from '@/service/constants';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useIsScorerFixture } from '@/service/hooks/useIsScorerFixture';
import { FixtureMain } from '@/components/FixtureMain';
import {
  DOCUMENT_TITLE,
  DOCUMENT_TITLE_SHORT,
  FAVICON_URLS,
} from './constants';

export const Fixture = () => {
  const [favicons, setFavicons] = useState<string | string[]>(
    FAVICON_URLS.DEFAULT,
  );
  const token = useAuthToken();
  const {
    state: { fixtureId, fixtureSummary },
  } = useContext(ScoringContext);
  const { isFixtureInfoVisible, isDataCollectorsVisible, isScoreboardVisible } =
    useContext(UIStateContext);
  const { isLoading, roleName } = useContext(RolePermissionsContext);
  const { data: isAssignedScorerToFixture, isLoading: isScorerLoading } =
    useIsScorerFixture({
      token,
      fixtureId,
    });

  const homeTeamName = fixtureSummary?.homeTeam?.teamName || '';
  const awayTeamName = fixtureSummary?.awayTeam?.teamName || '';
  const unseenActionsNumber = useUnseenActionsNumber();

  const [isVisible, setIsVisible] = useState(true);
  useEffectOnce(() => {
    const onVisibilityChange = function (this: Document) {
      setIsVisible(this.visibilityState === 'visible');
    };

    window.document.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      window.document.removeEventListener(
        'visibilitychange',
        onVisibilityChange,
      );
  });

  useEffect(() => {
    if (unseenActionsNumber && !isVisible) {
      setFavicons([FAVICON_URLS.DEFAULT, FAVICON_URLS.NEW_ACTION]);
    }
    if (isVisible) {
      setFavicons(FAVICON_URLS.DEFAULT);
    }
  }, [isVisible, unseenActionsNumber]);

  useEffect(() => {
    const cleanup = () => {
      document.title = DOCUMENT_TITLE;
    };
    const hasTeamNames = homeTeamName && awayTeamName;
    if (!hasTeamNames) return cleanup;

    const titleWithTeams = `${DOCUMENT_TITLE_SHORT} - ${homeTeamName} vs ${awayTeamName}`;

    if (!unseenActionsNumber) {
      document.title = titleWithTeams;
      return cleanup;
    }

    if (unseenActionsNumber) {
      document.title = `(${unseenActionsNumber}) ${titleWithTeams}`;
      return cleanup;
    }
  }, [unseenActionsNumber, homeTeamName, awayTeamName]);

  if (isLoading || isScorerLoading) {
    return <LoadingOverlay isLoading />;
  }

  const isScorer =
    roleName === USER_ROLE_NAME.MAIN_SCORER ||
    roleName === USER_ROLE_NAME.BACKUP_SCORER;

  return (
    <>
      <Favicon url={favicons} />
      <PermissionsChecker
        name='FixturePage'
        additionalAccessRule={isScorer ? isAssignedScorerToFixture : true}
        withMessage
      >
        <Stack width='100%'>
          <FixtureInfo fixtureId={fixtureId} isOpen={isFixtureInfoVisible} />
          {isFixtureInfoVisible && isDataCollectorsVisible && (
            <Divider flexItem></Divider>
          )}

          <ScorersPanel isOpen={isDataCollectorsVisible} />
        </Stack>

        <Container
          maxWidth={false}
          disableGutters
          sx={{
            px: (theme) => theme.spacing(1.5),
          }}
        >
          <Collapse in={isScoreboardVisible}>
            <Paper elevation={5}>
              <FixtureSummary />
            </Paper>
          </Collapse>
        </Container>

        <Paper
          elevation={5}
          sx={{
            flexGrow: '1',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <FixtureMain />
        </Paper>
      </PermissionsChecker>
    </>
  );
};
