import { SOUND_NAME } from '@/contexts/notifications/constants';
import { UserPermissions } from '@/service/types';
import { CHECKLIST_ELEMENT_INDEX } from '@/service/types/checklist';

export const hasPermissionsToElement = (
  permissionName: string,
  permissions: UserPermissions | undefined,
) => {
  const pathPermissions = permissions?.find(
    (permission) => permission?.name === permissionName,
  );

  if (!pathPermissions) {
    return false;
  }

  return !!pathPermissions.visible;
};

export const getCheckListCheckboxPermissionName = (index: number) => {
  switch (index) {
    case CHECKLIST_ELEMENT_INDEX.ATTENDANCE_GEO:
      return 'FixturePage.HeaderPanel.Checklist.AttendanceGeolocation';
    case CHECKLIST_ELEMENT_INDEX.ATTENDANCE_SELFIE:
      return 'FixturePage.HeaderPanel.Checklist.AttendanceSelfie';
    case CHECKLIST_ELEMENT_INDEX.MATCH_CONDITIONS:
      return 'FixturePage.HeaderPanel.Checklist.MatchConditions';
    case CHECKLIST_ELEMENT_INDEX.TEAM_COLOURS:
      return 'FixturePage.HeaderPanel.Checklist.TeamColors';
    case CHECKLIST_ELEMENT_INDEX.LINEUPS:
      return 'FixturePage.HeaderPanel.Checklist.Lineups';
    case CHECKLIST_ELEMENT_INDEX.ATTENDANCE_SCOREBOARD:
      return 'FixturePage.HeaderPanel.Checklist.AttendanceScoreboard';
    case CHECKLIST_ELEMENT_INDEX.POST_MATCH_CHECK_COMPLETE:
      return 'FixturePage.HeaderPanel.Checklist.PostMatchCheckComplete';
    case CHECKLIST_ELEMENT_INDEX.SLA_BREACH_COMPLETE:
      return 'FixturePage.HeaderPanel.Checklist.SlaBreachComplete';

    default:
      return '';
  }
};

export const getSoundPermissionName = (soundName: string): string => {
  switch (soundName) {
    case SOUND_NAME[0]:
      return 'PreferencesPage.SoundPreferences.Siren';
    case SOUND_NAME[1]:
      return 'PreferencesPage.SoundPreferences.DingDing';
    case SOUND_NAME[2]:
      return 'PreferencesPage.SoundPreferences.Whistle';
    case SOUND_NAME[3]:
      return 'PreferencesPage.SoundPreferences.Crowd';
    case SOUND_NAME[4]:
      return 'PreferencesPage.SoundPreferences.Fail';
    case SOUND_NAME[5]:
      return 'PreferencesPage.SoundPreferences.Flick';
    default:
      return '';
  }
};
