import { useContext, useMemo } from 'react';
import { Theme, useTheme } from '@mui/material';
import { ScoringContext } from '@/contexts/scoring/context';
import { getSport } from '@/service/utils/getSport';
import {
  FIXTURE_ACTION_TYPE,
  FIXTURE_TYPE,
  SPORT_ID,
} from '@/service/constants';
import {
  FixtureConfig,
  FixtureConfigOptions,
  PERIOD_DETAILS_TYPE,
} from '@/service/types';

export type FixtureInfoDisplay = {
  label: FIXTURE_INFO_LABEL;
  firstLine?: string;
  secondLine?: string;
  events?: FixtureInfoDisplayChip[];
  competitionChips?: FixtureInfoDisplayChip[];
};

type FixtureInfoDisplayChip = {
  chipName: CHIP_NAME;
  color: string;
  visible: boolean;
};

export enum CHIP_NAME {
  VAR = 'VAR',
  EXTRA_TIME = 'Extra time',
  PENALTY_SHOOTOUT = 'Penalty shootout',
  PERIOD_FORMAT = 'Period format',
  NO_LINEUPS = 'No lineups',
}

export enum FIXTURE_INFO_LABEL {
  NAME = 'Name',
  TYPE = 'Type',
  START_DATE_AND_TIME = 'Start date & time',
  SPORT = 'Sport',
  COMPETITION = 'Competition',
  VENUE = 'Venue',
  CITY = 'City',
  COUNTRY = 'Country',
  VAR = 'VAR',
  EVENTS = 'Events',
}

const SOCCER_VAR_ACTIONS = [
  FIXTURE_ACTION_TYPE.POSSIBLE_VAR,
  FIXTURE_ACTION_TYPE.NO_VIDEO_CHECK,
  FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
  FIXTURE_ACTION_TYPE.VIDEO_CHECK_RESULT,
];

export const getFixtureDetails = (
  fixture: FixtureConfig['fixture'],
  theme: Theme,
  fixtureOptions: FixtureConfigOptions,
  actionTypeId: number[] | undefined,
  sportId: number,
): Array<FixtureInfoDisplay> => {
  const date = new Date(fixture.startDateUTC);
  const dateString = date.toLocaleDateString(undefined, {
    dateStyle: 'medium',
  });
  const timeString = date.toLocaleTimeString(undefined, {
    timeStyle: 'short',
  });
  const { location } = fixture.venue;
  return [
    { label: FIXTURE_INFO_LABEL.NAME, firstLine: fixture.name },
    { label: FIXTURE_INFO_LABEL.TYPE, firstLine: FIXTURE_TYPE[fixture.type] },
    {
      label: FIXTURE_INFO_LABEL.START_DATE_AND_TIME,
      firstLine: dateString,
      secondLine: timeString,
    },
    {
      label: FIXTURE_INFO_LABEL.SPORT,
      firstLine: fixture.stage.season.competition?.sport.name || '',
    },
    {
      label: FIXTURE_INFO_LABEL.COMPETITION,
      firstLine: fixture.stage.season.competition?.name || '',
      competitionChips: [
        {
          chipName: getPeriodFormat(sportId, fixtureOptions) as CHIP_NAME,
          color: theme.palette.primary.main,
          visible: isVisible(CHIP_NAME.PERIOD_FORMAT, sportId, fixtureOptions),
        },
        {
          chipName: CHIP_NAME.NO_LINEUPS,
          color: theme.palette.primary.main,
          visible: isVisible(CHIP_NAME.NO_LINEUPS, sportId, fixtureOptions),
        },
      ],
    },
    {
      label: FIXTURE_INFO_LABEL.EVENTS,
      events: [
        {
          chipName: CHIP_NAME.VAR,
          color: getColor(
            CHIP_NAME.VAR,
            theme,
            fixtureOptions,
            actionTypeId,
            sportId,
          ),
          visible: isVisible(CHIP_NAME.VAR, sportId),
        },
        {
          chipName: CHIP_NAME.EXTRA_TIME,
          color: getColor(
            CHIP_NAME.EXTRA_TIME,
            theme,
            fixtureOptions,
            actionTypeId,
            sportId,
          ),
          visible: isVisible(CHIP_NAME.EXTRA_TIME, sportId),
        },
        {
          chipName: CHIP_NAME.PENALTY_SHOOTOUT,
          color: getColor(
            CHIP_NAME.PENALTY_SHOOTOUT,
            theme,
            fixtureOptions,
            actionTypeId,
            sportId,
          ),
          visible: isVisible(CHIP_NAME.PENALTY_SHOOTOUT, sportId),
        },
      ],
    },
    { label: FIXTURE_INFO_LABEL.VENUE, firstLine: fixture.venue.name },
    { label: FIXTURE_INFO_LABEL.CITY, firstLine: location.name },
    {
      label: FIXTURE_INFO_LABEL.COUNTRY,
      firstLine: (location.country && location.country.name) || '',
    },
  ];
};

const getColor = (
  event: CHIP_NAME,
  theme: Theme,
  fixtureOptions: FixtureConfigOptions,
  actionTypeId: number[] | undefined,
  sportId: number,
): string => {
  switch (event) {
    case CHIP_NAME.VAR:
      const isSoccer = sportId === SPORT_ID.SOCCER;
      const isBasketballOrAmericanFootball = [
        SPORT_ID.BASKETBALL,
        SPORT_ID.AMERICAN_FOOTBALL,
      ].includes(sportId);

      const isActiveVar = isSoccer
        ? !actionTypeId?.some((id) => SOCCER_VAR_ACTIONS.includes(id))
        : isBasketballOrAmericanFootball;

      return isActiveVar
        ? theme.palette.snackbarColors.possibleVar.backgroundColor
        : theme.palette.secondary.light;

    case CHIP_NAME.EXTRA_TIME:
      return fixtureOptions.sportRules.periodDetails?.some(
        (periodDetail) => periodDetail.type === PERIOD_DETAILS_TYPE.OVERTIME,
      )
        ? theme.palette.primary.main
        : theme.palette.secondary.light;

    case CHIP_NAME.PENALTY_SHOOTOUT:
      return fixtureOptions.sportRules.periodDetails?.some(
        (periodDetail) =>
          periodDetail.type === PERIOD_DETAILS_TYPE.PENALTY_SHOOTOUT,
      )
        ? theme.palette.primary.main
        : theme.palette.secondary.light;

    default:
      return theme.palette.secondary.light;
  }
};

const getPeriodFormat = (
  sportId: number,
  fixtureOptions: FixtureConfigOptions,
) => {
  if (
    ![SPORT_ID.SOCCER, SPORT_ID.BASKETBALL].includes(sportId) ||
    !fixtureOptions?.sportRules.customPeriods
  )
    return '';

  if (sportId === SPORT_ID.SOCCER) {
    const initialSoccerClock = fixtureOptions?.sportRules.periodDetails.find(
      ({ seq }) => seq === 2,
    )?.initialClock;

    if (initialSoccerClock !== undefined) {
      return `2 x ${initialSoccerClock / 60} min`;
    }
  }

  const basketballPeriods = fixtureOptions?.sportRules.periodDetails.length;
  const timePeriodBasket =
    fixtureOptions?.sportRules.periodDetails[0].initialClock;

  return timePeriodBasket !== undefined
    ? `${basketballPeriods === 3 ? '2' : '4'} x ${timePeriodBasket / 60} min`
    : '';
};

const isVisible = (
  event: CHIP_NAME,
  sportId: number,
  fixtureOptions?: FixtureConfigOptions,
): boolean => {
  switch (event) {
    case CHIP_NAME.VAR:
      return [
        SPORT_ID.SOCCER,
        SPORT_ID.BASKETBALL,
        SPORT_ID.AMERICAN_FOOTBALL,
      ].some((sport) => sport === sportId);
    case CHIP_NAME.EXTRA_TIME:
      return [SPORT_ID.SOCCER, SPORT_ID.AMERICAN_FOOTBALL].some(
        (sport) => sport === sportId,
      );
    case CHIP_NAME.PENALTY_SHOOTOUT:
      return sportId === SPORT_ID.SOCCER;
    case CHIP_NAME.PERIOD_FORMAT:
      return (
        ([SPORT_ID.SOCCER, SPORT_ID.BASKETBALL].includes(sportId) &&
          fixtureOptions?.sportRules.customPeriods) ??
        false
      );
    case CHIP_NAME.NO_LINEUPS:
      return (
        sportId === SPORT_ID.SOCCER &&
        fixtureOptions?.sportRules.activePlayers === 0
      );
    default:
      return false;
  }
};

export const getVarVisibilityAndColor = (
  sportId: number,
  theme: Theme,
  actionTypeId?: number[],
) => {
  const isSoccer = sportId === SPORT_ID.SOCCER;
  const isBasketballOrAmericanFootball = [
    SPORT_ID.BASKETBALL,
    SPORT_ID.AMERICAN_FOOTBALL,
  ].includes(sportId);

  const isActiveVar = isSoccer
    ? !actionTypeId?.some((id) => SOCCER_VAR_ACTIONS.includes(id))
    : isBasketballOrAmericanFootball;

  const backgroundColor = isActiveVar
    ? theme.palette.snackbarColors.possibleVar.backgroundColor
    : theme.palette.secondary.light;

  return {
    visibleVar: isSoccer || isBasketballOrAmericanFootball,
    isActiveVar,
    backgroundColor,
  };
};

export const useFixtureDetails = () => {
  const {
    fixtureConfigState: { fixtureConfig, error, isLoading, mutate },
  } = useContext(ScoringContext);
  const theme = useTheme();

  const fixtureDetails = useMemo(() => {
    return {
      details: fixtureConfig
        ? getFixtureDetails(
            fixtureConfig?.fixture,
            theme,
            fixtureConfig.fixtureOptions,
            fixtureConfig.fixtureOptions.sportRules.disableActionTypeIdButtons,
            getSport(fixtureConfig)?.id ?? 0,
          )
        : null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureConfig]);

  return {
    data: fixtureDetails,
    error,
    isLoading,
    mutate,
  };
};
