export enum FilterTemplateTeam {
  None = 'None',
  HomeTeam = 'Home Team',
  AwayTeam = 'Away Team',
}

export enum FilterTemplateSendType {
  PENDING = 'Pending',
  UPDATED = 'Updated',
  CANCELLED = 'Cancelled',
  DELETED = 'Deleted',
  CONFIRMED = 'Confirmed',
}

export type ActionTypeItem = {
  label: string;
  value: number;
};

export type RadioLabelsType = {
  unset?: string;
  falsy: string;
  truthy?: string;
  unknown?: string;
};

export type SportType = { id: number; name: string };
export type FilterSendType = { id: number; name: string };
