import { RouteObject } from 'react-router-dom';
import { Home } from '@/pages/Home';
import { Fixture } from '@/pages/Fixture';
import { App } from '@/App';
import { ROUTE_PATHS } from '@/router/constants';
import { AuthProvider } from '@/contexts/auth';
import { PreferencesProvider } from '@/contexts/preferences/provider';
import { Login } from '@/pages/Login';

export const routes: RouteObject[] = [
  {
    path: ROUTE_PATHS.OKTA_LOGIN_CALLBACK,
    element: (
      <AuthProvider>
        <PreferencesProvider>
          <Login />
        </PreferencesProvider>
      </AuthProvider>
    ),
  },
  {
    path: ROUTE_PATHS.HOME,
    element: <App />,
    children: [
      {
        path: ROUTE_PATHS.FIXTURE,
        element: <Fixture />,
      },
      { path: '', element: <Home /> },
    ],
  },
];
