import { useContext } from 'react';
import { ComparisonTableActions } from '@/components/ComparisonTable/ComparisonTableActions';
import { ComparisonTable } from '@/components/ComparisonTable/ComparisonTable';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  PostMatchQaUpdateMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { SUCCESS_STRING } from '@/constants/dictionary';
import { getComparisonData } from '@/service/hooks/useFixturePostMatchCheck';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { StyledStatisticsTabWrapper } from '@/components/common/StyledStatisticsTabWrapper';

export const FixtureExternalStats = () => {
  const {
    state: { fixtureId, fixtureSummary, postMatchQa },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { dispatch: updatePostMatchQa, isLoading } =
    useDispatchWithResponse<PostMatchQaUpdateMsg>(
      SCORING_WORKER_HOST_ACTION.UPDATE_POST_MATCH_QA
    );

  const comparisonData = getComparisonData(postMatchQa, fixtureSummary);

  const onUpdatePostMatchQa = () =>
    updatePostMatchQa(
      { fixtureId },
      { successMessage: SUCCESS_STRING.COMPARISON_DATA_SUCCESSFULLY_UPDATED }
    );

  return (
    <PermissionsChecker name='FixturePage.QA.ExternalStats'>
      <StyledStatisticsTabWrapper sx={{ px: 2, py: 1, gap: 2 }}>
        <ComparisonTableActions
          isUpdating={isLoading}
          refreshData={onUpdatePostMatchQa}
          comparisonData={comparisonData}
          providerUrl={postMatchQa?.externalStatisticsProviderUrl}
          teamNames={{
            homeTeam: fixtureSummary?.homeTeam.teamName,
            awayTeam: fixtureSummary?.awayTeam.teamName,
          }}
        />
        <ComparisonTable comparisonData={comparisonData} />
      </StyledStatisticsTabWrapper>
    </PermissionsChecker>
  );
};
