import {
  GameStatistics,
  PlayerGameStatistics,
  PlayerPeriodStatistics,
  TeamGameStatistics,
  TeamPeriodStatistics,
  ZoneStatistics,
} from '@/service/types';

export interface BasketballStatsTableData {
  statistics: TeamGameStatistics &
    PlayerGameStatistics &
    PlayerPeriodStatistics &
    GameStatistics &
    TeamPeriodStatistics;
  teamId?: string;
  playerId?: string;
}

export interface BasketballStatsHeader {
  key: keyof (PlayerGameStatistics &
    PlayerPeriodStatistics &
    TeamGameStatistics &
    TeamPeriodStatistics &
    GameStatistics);
  subKey?: keyof ZoneStatistics;
  value: string;
  shortValue: string;
  visible: boolean;
}

export const BASKETBALL_STATS_RESULTS = {
  YES: 'Yes',
  NO: 'No',
};
