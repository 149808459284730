import {
  Button,
  Divider,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { ScoringContext } from '@/contexts/scoring/context';
import { getSport } from '@/service/utils/getSport';
import { COMMON_STRING } from '@/constants/dictionary';
import { API_METHOD } from '@/service/constants';
import { PreferencesContext } from '@/contexts/preferences/context';
import { ActionFiltersTemplateForm } from './ActionFiltersTemplateForm';
import { TemplateFormData } from './types';
import { DisplayTemplateAccordions } from './DisplayTemplateAccordions';

export const NEW_TEMPLATE_ID = 'new-template';

type DisplayTemplatesProps = {
  onCancel?: VoidFunction;
  sx?: SxProps<Theme>;
  editFromDrawer?: boolean;
  addNewTemplate?: boolean;
};

export const DisplayTemplates: FC<DisplayTemplatesProps> = ({
  onCancel,
  sx,
  editFromDrawer,
  addNewTemplate: addNewTemplateFromSwitcher,
}) => {
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const {
    templates,
    sports,
    actions: { updateTemplate, deleteTemplate },
  } = useContext(PreferencesContext);

  const [templateToEditId, setTemplateToEditId] = useState<
    { id: string; sportId: number } | undefined
  >(undefined);

  const sport = getSport(fixtureConfig);

  useEffectOnce(() => {
    if (addNewTemplateFromSwitcher && sport) {
      addNewTemplate(sport.id);
    }
  });

  const getTemplate = useCallback(
    (id: string | undefined, sportId: number | undefined) => {
      if (id === NEW_TEMPLATE_ID) {
        return {
          id: id,
          sportId: sportId,
        } as TemplateFormData;
      }
      const templateFromSport = templates?.find(
        (template) => template.sportId === sportId,
      );
      return templateFromSport?.templateFilters?.find(
        (template) => template.id === id,
      );
    },
    [templates],
  );

  const templateToEdit = useMemo(
    () => getTemplate(templateToEditId?.id, templateToEditId?.sportId),
    [getTemplate, templateToEditId],
  );

  const onEdit = (id: string, sportId: number) => {
    setTemplateToEditId({ id, sportId });
  };

  const onConfirmEdit = async (template: TemplateFormData) => {
    await updateTemplate(template, API_METHOD.POST);
    setTemplateToEditId(undefined);
  };

  const onEditCancel = () => {
    setTemplateToEditId(undefined);
  };

  const addNewTemplate = (sportId: number) => {
    setTemplateToEditId({ id: NEW_TEMPLATE_ID, sportId });
  };

  const getSportTemplates = (sportId: number) =>
    templates?.filter((template) => template.sportId === sportId) ?? [];

  const templatesBySport = () => {
    if (editFromDrawer) {
      if (!sport) {
        return [];
      }

      return [
        {
          sportName: sport.name,
          sportId: sport.id,
          templates: getSportTemplates(sport.id),
        },
      ];
    }

    return (
      sports?.map((sport) => ({
        sportName: sport.name,
        sportId: sport.id,
        templates: getSportTemplates(sport.id),
      })) ?? []
    );
  };

  return (
    <Stack sx={{ height: '100%', ...sx }}>
      {templateToEditId && templateToEdit ? (
        <ActionFiltersTemplateForm
          sportId={templateToEditId.sportId}
          template={templateToEdit}
          onSave={onConfirmEdit}
          onCancel={onEditCancel}
          editFromDrawer={editFromDrawer}
        />
      ) : (
        templatesBySport().map(({ templates, sportId, sportName }, index) => (
          <Stack
            key={sportId}
            alignItems='stretch'
            justifyContent='space-between'
            sx={{ height: editFromDrawer ? '100%' : undefined }}
          >
            <Stack
              px={2}
              flex={1}
              sx={{ overflowY: editFromDrawer ? 'auto' : undefined }}
              data-testid={sportName}
            >
              <Typography sx={{ fontWeight: 700, ml: 2, mt: 3, mb: 1 }}>
                {sportName.toUpperCase()}
              </Typography>
              {templates?.map((template) => (
                <DisplayTemplateAccordions
                  key={template.sportId}
                  sport={sport}
                  templates={template.templateFilters ?? []}
                  selectedTemplateId={template.selectedTemplateId}
                  onEdit={onEdit}
                  onDelete={deleteTemplate}
                />
              ))}
              <Divider />
              <Link
                href='#'
                underline='none'
                onClick={() => addNewTemplate(sportId)}
                sx={{
                  ml: 5,
                  my: 2,
                  width: 130,
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {`+ ${COMMON_STRING.NEW_TEMPLATE}`}
              </Link>
              <Divider />
            </Stack>
            <Stack p={2} gap={1} direction='row' justifyContent='space-between'>
              {onCancel && (
                <Button onClick={onCancel}>{COMMON_STRING.CLOSE}</Button>
              )}
            </Stack>
          </Stack>
        ))
      )}
    </Stack>
  );
};
