import { datadogLogs } from '@datadog/browser-logs';
import { ENVIRONMENT } from '@/constants/environment';
import { LoggerStatusType } from '@/workers/scoring/types';
import envVariables from '@/envVariables';

const team = 'sps-msfp-monitoring-tool';
const supportedLevel: LoggerStatusType | undefined =
  envVariables.env === ENVIRONMENT.PRODUCTION
    ? 'info'
    : envVariables.env === ENVIRONMENT.LOCAL
    ? undefined
    : 'warn';

export const ddApplicationLogger = datadogLogs.createLogger(
  'applicationLogger',
  {
    level: supportedLevel,
    handler: ['http'],
    context: {
      team: team,
      source: 'browser',
    },
  },
);

export const ddSocketLogger = datadogLogs.createLogger('socketLogger', {
  level: supportedLevel,
  handler: ['http'],
  context: {
    team: team,
    source: 'browser',
  },
});
