import { FixtureConfig } from '@/service/types';

export const getActionSubTypeName = (
  actionSubTypeId: number | null,
  fixtureConfig: FixtureConfig | undefined
) => {
  if (
    !fixtureConfig ||
    !fixtureConfig.fixtureOptions.actionButtons ||
    actionSubTypeId === null
  ) {
    return '';
  }

  const actionButton = fixtureConfig.fixtureOptions.actionButtons.find(
    (ab) =>
      ab.actionType.subTypes != null &&
      ab.actionType.subTypes.some((s) => s.id === actionSubTypeId)
  );
  if (actionButton) {
    return (
      actionButton.actionType?.subTypes?.find((s) => s.id === actionSubTypeId)
        ?.name ?? ''
    );
  }
  return '';
};
