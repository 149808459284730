import { styled } from '@mui/material/styles';
import { Button, Grid, Stack, TextField } from '@mui/material';

interface ComparisonBoxProps {
  dashedBorder?: boolean;
}

export const ComparisonBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'dashedBorder',
})<ComparisonBoxProps>(({ dashedBorder = true, theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: dashedBorder ? `1px dashed ${theme.palette.divider}` : 'none',
  gap: 3,
}));

export const ComparisonGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  margin: 0,
  width: '100%',
  borderBottom: `1px dashed ${theme.palette.divider}`,
}));

export const AddRatingButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  width: '100%',
  insetBlockEnd: 0,
  backgroundColor: theme.palette.addRating.addRatingOverlayColor,
  border: `2px dashed`,
  borderColor: theme.palette.addRating.addRatingOverlayBorderColor,
  '&:hover': {
    backgroundColor: theme.palette.addRating.addRatingOverlayHoverColor,
  },
}));

export const NumberTextField = styled(TextField)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    opacity: 1,
  },
  width: '75px',
}));
