import { Stack, Zoom, Fab } from '@mui/material';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { useContext, MouseEvent, MutableRefObject, useMemo, FC } from 'react';
import { ViewportList, ViewportListRef } from 'react-viewport-list';
import { ScoringContext } from '@/contexts/scoring/context';
import { SCORING_REDUCER_ACTION } from '@/contexts/scoring/types';
import type { FixtureAction, FixtureConfig } from '@/service/types';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { Order, SortBy } from '@/utils/sort/useSort';
import { getComparator, stableSort } from '@/utils/sort/utils';
import {
  ActionsTableContextType,
  ActionsTableContext,
} from './context/ActionsTableContext';
import {
  findDeleteAction,
  isActionDeleted,
  isActionUpdated,
  scrollToTop,
} from './utils';
import { NON_PROPERTY_FILTER } from './ActionFilters/constants';
import { ActionRow } from './ActionRow';

export const SCROLL_TO_TOP_OFFSET = 500;
interface ActionsTableBodyProps {
  onTableRightClick: (event: MouseEvent<HTMLDivElement>) => void;
  onTableClick: (event: MouseEvent<HTMLTableSectionElement>) => void;
  fixtureConfig?: FixtureConfig;
  viewportRef: MutableRefObject<HTMLDivElement | null>;
  isScrollTopVisible: boolean;
  /**
   * Necessary to view entire last item on actions list
   * when floating action button (scroll to top) is visible.
   */
  fabSpacing: { top?: string; bottom?: string };
  listRef: MutableRefObject<ViewportListRef | null>;
  sportId?: number;
  setHoverAction: (action: FixtureAction | undefined) => void;
  sortBy: SortBy;
  sortOrder: Order;
}

export const ActionsTableBody: FC<ActionsTableBodyProps> = ({
  onTableRightClick,
  onTableClick,
  fixtureConfig,
  viewportRef,
  isScrollTopVisible,
  fabSpacing,
  listRef,
  sportId,
  sortBy,
  sortOrder,
  setHoverAction,
}) => {
  const {
    state: { fixtureSummary, newFixtureActions, deletedActionIds },
    dispatch,
  } = useContext(ScoringContext);

  const { actions, displayActions, dispatchFlagUpdate, filters } =
    useContext<ActionsTableContextType>(ActionsTableContext);

  const {
    isLoading: isActionsFlagUpdating,
    pendingRequests: currentFlagsUpdating,
  } = dispatchFlagUpdate;

  const isFlagUpdating = (action: FixtureAction) => {
    return (
      isActionsFlagUpdating &&
      currentFlagsUpdating.length > 0 &&
      currentFlagsUpdating.some(([requestId, payload]) => {
        return payload.fixtureActionId === action.id;
      })
    );
  };

  const isNewAction = (actionId: string) => {
    if (!newFixtureActions.length) return false;
    return newFixtureActions.some(({ id }) => id === actionId);
  };

  const observer = useMemo(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const row = entry.target as HTMLDivElement;
          row.classList.add('animation-blink');
          const actionId = row.dataset.actionId;
          if (!actionId) return;
          dispatch({
            action: SCORING_REDUCER_ACTION.NEW_ACTION_SEEN,
            payload: actionId,
          });
        }
      });
    };
    const observerEl = viewportRef.current;
    return new IntersectionObserver(observerCallback, {
      root: observerEl,
      threshold: 1,
      rootMargin: `${fabSpacing.top} 0px ${fabSpacing.bottom} 0px`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportRef.current, dispatch]);

  const shouldBeLineThrough = (action: FixtureAction) =>
    isActionDeleted(action, findDeleteAction(deletedActionIds, action)) ||
    isActionUpdated(action, actions!);

  const isHideUpdatedFilterFound = () => {
    return filters.some(
      (filter) =>
        filter.nonPropertyFilter === NON_PROPERTY_FILTER.HIDE_UPDATED_ACTIONS,
    );
  };

  const getActions = () => {
    if (!isHideUpdatedFilterFound()) {
      return displayActions;
    }
    return displayActions.filter((action) => !shouldBeLineThrough(action));
  };

  const sortedActions = stableSort(
    getActions(),
    getComparator(sortOrder, sortBy),
  );

  return (
    <Stack
      onContextMenu={onTableRightClick}
      onClick={onTableClick}
      aria-label={ARIA_LABEL.ACTIONS_TABLE_BODY}
    >
      <ViewportList
        viewportRef={viewportRef}
        ref={listRef}
        items={sortedActions}
      >
        {(action) => (
          <ActionRow
            key={action.id}
            action={action}
            fixtureConfig={fixtureConfig}
            fixtureSummary={fixtureSummary}
            isUpdating={isFlagUpdating(action)}
            isNewAction={isNewAction(action.id)}
            observer={observer}
            sportId={sportId}
            lineThrough={shouldBeLineThrough(action)}
            setHoverAction={setHoverAction}
          />
        )}
      </ViewportList>
      <Zoom in={isScrollTopVisible}>
        <Fab
          color='primary'
          size='small'
          aria-label='scroll to top'
          onClick={() => scrollToTop(listRef)}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(3),
            bottom: (theme) => theme.spacing(2),
          }}
        >
          <KeyboardArrowUp />
        </Fab>
      </Zoom>
    </Stack>
  );
};
