import { red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';
import Marquee from 'react-fast-marquee';
import { useTheme } from '@mui/material/styles';
import { useContext, useMemo } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { MarketsContext } from '@/contexts/markets/context';
import { ScoringContext } from '@/contexts/scoring/context';

export const ClosedMarketInfo = () => {
  const theme = useTheme();

  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const { allMarkets, marketsPerAction } = useContext(MarketsContext);

  const isOpenedAllSeparateMarkets = useMemo(
    () =>
      marketsPerAction
        ? !Object.values(marketsPerAction).reduce(
            (count, marketPerAction) => count + !marketPerAction.isMarketOpen,
            0,
          )
        : true,
    [marketsPerAction],
  );

  if ((allMarkets && isOpenedAllSeparateMarkets) || !fixtureId) return null;

  return (
    <Marquee
      style={{
        backgroundColor: red[600],
        color: theme.palette.getContrastText(red[600]),
        height: '1.5rem',
        alignItems: 'center',
      }}
      autoFill
    >
      <Stack flexDirection='row' gap={2} alignItems='center' pl={2}>
        <WarningAmberIcon sx={{ width: '0.6em', height: '0.6em' }} />
        <Typography whiteSpace='nowrap' fontSize={14}>
          {!isOpenedAllSeparateMarkets && allMarkets
            ? COMMON_STRING.DATA_UNDER_REVIEW
            : COMMON_STRING.MARKET_IS_CLOSED}
        </Typography>
      </Stack>
    </Marquee>
  );
};
