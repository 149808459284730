import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';
import { ScoringContext } from '@/contexts/scoring/context';
import { ARIA_LABEL } from '@/constants/ariaLabel';

export const GameState = () => {
  const {
    state: { fixtureActions },
  } = useContext(ScoringContext);

  const [gameState, setGameState] = useState<string>('');

  useEffect(() => {
    if (!fixtureActions?.actions) return;

    const americanFootballGameState =
      fixtureActions.actions.find((action) => action.sportSpecificFixtureState)
        ?.sportSpecificFixtureState?.summary ?? '';

    setGameState(americanFootballGameState);
  }, [fixtureActions]);

  if (!gameState.length) return null;

  return (
    <Box
      aria-label={ARIA_LABEL.GAME_STATE}
      sx={{
        px: 1,
        pt: 0.5,
        pb: 0.5,
        border: `1px solid currentcolor`,
        textAlign: 'center',
      }}
    >
      <Typography variant='body2'>
        {`${COMMON_STRING.GAME_STATE}: ${gameState}`}
      </Typography>
    </Box>
  );
};
