import { useContext } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import { StreamingContext } from '@/contexts/streaming/context';
import { COMMON_STRING } from '@/constants/dictionary';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { NoStream } from './NoStream';

export const FixtureStreaming = () => {
  const {
    streamUrl,
    switchPictureInPictureMode,
    pictureInPicture,
    streamStartDate,
    endTimer,
    timerDuration,
  } = useContext(StreamingContext);

  if (streamUrl && endTimer) {
    return (
      <PermissionsChecker name='FixturePage.Streaming.PictureInPicture'>
        <Box textAlign='end'>
          <Tooltip
            title={COMMON_STRING.OPEN_PICTURE_IN_PICTURE_MODE}
            arrow
            placement='left'
          >
            <IconButton
              color='primary'
              onClick={() => switchPictureInPictureMode(!pictureInPicture)}
            >
              <PictureInPictureAltIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </PermissionsChecker>
    );
  }
  return <NoStream time={timerDuration} streamStartDate={streamStartDate} />;
};
