import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

export const useNetworkStatus = () => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const onOffline = () => setOnlineStatus(false);
  const onOnline = () => setOnlineStatus(true);
  useEffectOnce(() => {
    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);
    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  });

  return onlineStatus;
};
