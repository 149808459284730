import { MenuItem, MenuList, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { THEME_MODE } from '@/contexts/theme/constants';

export const STYLED_MENU_LIST_WIDTH = '160px';
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  whiteSpace: 'normal',
  fontSize: 14,
  fontWeight: '500',
  borderRadius: 5,
  color:
    theme.palette.mode === THEME_MODE.LIGHT
      ? alpha(theme.palette.common.black, 0.6)
      : alpha(theme.palette.common.white, 0.6),
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

export const StyledMenuList = styled(MenuList)(({ theme }) => ({
  width: STYLED_MENU_LIST_WIDTH,
  padding: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.tableBorderColor}`,
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));
