import { IconButton, Stack, Tooltip } from '@mui/material';
import Download from '@mui/icons-material/Download';
import OpenInNew from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CSVLink } from 'react-csv';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FixturePostMatchCheckStatistics } from '@/service/types';
import {
  getComparisonCSVData,
  getComparisonCSVHeaders,
} from '@/utils/comparison/formatComparisonDataForCSV';
import { COMMON_STRING } from '@/constants/dictionary';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

interface ComparisonTableActionsProps {
  isUpdating: boolean;
  refreshData: VoidFunction;
  comparisonData: (FixturePostMatchCheckStatistics | undefined)[];
  providerUrl?: string;
  teamNames: { awayTeam?: string; homeTeam?: string };
}

export const ComparisonTableActions = ({
  isUpdating,
  refreshData,
  comparisonData,
  providerUrl,
  teamNames,
}: ComparisonTableActionsProps) => {
  const [csvData, setCsvData] = useState<object[]>([]);

  return (
    <Stack flexDirection='row' justifyContent='end'>
      <PermissionsChecker name='FixturePage.QA.ExternalStats.Refresh'>
        <Tooltip title={COMMON_STRING.REFRESH} arrow>
          <span>
            <IconButton
              color='primary'
              onClick={refreshData}
              disabled={isUpdating}
              aria-label={ARIA_LABEL.REFRESH_BUTTON}
            >
              {isUpdating ? (
                <CircularProgress
                  size='1em'
                  color='inherit'
                  sx={{ p: '0.25em' }}
                />
              ) : (
                <RefreshIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </PermissionsChecker>
      <PermissionsChecker name='FixturePage.QA.ExternalStats.GoToExternalStats'>
        {providerUrl && (
          <Tooltip title={COMMON_STRING.GO_TO_EXTERNAL_STATISTICS} arrow>
            <IconButton color='primary' href={providerUrl} target='_blank'>
              <OpenInNew />
            </IconButton>
          </Tooltip>
        )}
      </PermissionsChecker>
      <PermissionsChecker name='FixturePage.QA.ExternalStats.ExportCSV'>
        <CSVLink
          headers={getComparisonCSVHeaders()}
          data={csvData}
          onClick={() => setCsvData(getComparisonCSVData(comparisonData))}
          filename={`${teamNames.homeTeam}_v_${teamNames.awayTeam}_csv_comparison`}
        >
          <Tooltip title={COMMON_STRING.EXPORT_CSV} arrow>
            <IconButton color='primary'>
              <Download />
            </IconButton>
          </Tooltip>
        </CSVLink>
      </PermissionsChecker>
    </Stack>
  );
};
