import { Divider, Drawer } from '@mui/material';
import { useContext } from 'react';
import { TabPanel } from '@/components/common/TabPanel';
import { UIStateContext } from '@/contexts/UIState/context';
import { PANEL } from '@/contexts/UIState/types';
import {
  ActionsTableContextType,
  ActionsTableContext,
} from '../context/ActionsTableContext';
import { ActionFiltersForm } from './ActionFiltersForm';
import { FiltersDrawerHeader } from './FiltersDrawerHeader';
import { DisplayTemplates } from './Template/DisplayTemplates';

const DRAWER_WIDTH = '650px';

export const FiltersDrawer = () => {
  const { openedPanel, setOpenedPanel } = useContext(UIStateContext);

  const { filters, setFilters } =
    useContext<ActionsTableContextType>(ActionsTableContext);

  const onClose = () => setOpenedPanel(null);

  const drawerHeaderValue =
    openedPanel === PANEL.ADD_FILTER_TEMPLATE
      ? PANEL.FILTER_TEMPLATES
      : openedPanel;

  return (
    <Drawer
      anchor='right'
      open={
        openedPanel === PANEL.FILTERS ||
        openedPanel === PANEL.FILTER_TEMPLATES ||
        openedPanel === PANEL.ADD_FILTER_TEMPLATE
      }
      onClose={onClose}
      keepMounted
    >
      <FiltersDrawerHeader
        onClose={onClose}
        value={drawerHeaderValue}
        handleChange={(_event, newValue) => setOpenedPanel(newValue)}
      />
      <Divider />
      <TabPanel
        name={PANEL.FILTERS}
        value={openedPanel?.toString() ?? ''}
        sx={{ overflow: 'hidden' }}
      >
        <ActionFiltersForm
          filters={filters}
          onApply={(newFilters) => {
            setFilters(newFilters);
            onClose();
          }}
          onCancel={onClose}
          drawerWidth={DRAWER_WIDTH}
        />
      </TabPanel>
      <TabPanel
        name={PANEL.FILTER_TEMPLATES}
        value={openedPanel?.toString() ?? ''}
        sx={{ overflow: 'hidden' }}
      >
        <DisplayTemplates
          sx={{ width: DRAWER_WIDTH }}
          editFromDrawer={true}
          onCancel={onClose}
        />
      </TabPanel>
      <TabPanel
        name={PANEL.ADD_FILTER_TEMPLATE}
        value={openedPanel?.toString() ?? ''}
        sx={{ overflow: 'hidden' }}
      >
        <DisplayTemplates
          sx={{ width: DRAWER_WIDTH }}
          editFromDrawer={true}
          onCancel={onClose}
          addNewTemplate={true}
        />
      </TabPanel>
    </Drawer>
  );
};
