import { FC } from 'react';
import Comment from '@mui/icons-material/Comment';
import { FixtureAction } from '@/service/types';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { BUTTON_NAME } from './constants';

export interface CommentCellProps {
  action: FixtureAction;
}

export const CommentCell: FC<CommentCellProps> = ({ action }) => {
  const noComment =
    !action.fixtureActionComments ||
    action.fixtureActionComments.length === 0 ||
    !action.fixtureActionComments[0].comment;

  if (noComment) {
    return <span />;
  }

  const previewComment = `Comment: ${action.fixtureActionComments[0].comment}`;

  return (
    <CustomTooltip title={previewComment}>
      <AnimatedIconButton
        name={BUTTON_NAME.COMMENT}
        size='small'
        aria-label={ARIA_LABEL.COMMENTS}
      >
        <Comment fontSize='small' />
      </AnimatedIconButton>
    </CustomTooltip>
  );
};
