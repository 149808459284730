import { useContext } from 'react';
import { ButtonsWrapper } from '@/components/StatsButtons/ButtonsWrapper';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsButton } from '@/components/StatsButtons/common';
import { BUTTON_TYPES } from '@/contexts/stats/types';

interface TypeButtonsProps {
  gameStatsType?: boolean;
  americanFootball?: boolean;
}

export const TypeButtons = ({
  gameStatsType,
  americanFootball,
}: TypeButtonsProps) => {
  const { typeButtons, changeActiveButton } = useContext(StatsContext);

  return (
    <ButtonsWrapper>
      {typeButtons
        .filter((typeButton) =>
          !gameStatsType
            ? typeButton.value !== STATS_BUTTONS.TYPE.GAME
            : typeButton
        )
        .map((typeButton) => (
          <StatsButton
            isActive={typeButton.isActive}
            variant={'outlined'}
            disableRipple={typeButton.isActive}
            onClick={() => changeActiveButton(typeButton, BUTTON_TYPES.TYPE)}
            key={typeButton.id}
            disabled={
              americanFootball && typeButton.id === STATS_BUTTONS.TYPE.PLAYER
            }
          >
            {typeButton.value}
          </StatsButton>
        ))}
    </ButtonsWrapper>
  );
};
