import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  AddFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { ScoringContext } from '@/contexts/scoring/context';
import { COMMON_STRING, SUCCESS_STRING } from '@/constants/dictionary';
import { MarketSwitch } from '@/components/Navbar/MarketSwitch';
import { MarketsContext } from '@/contexts/markets/context';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { MTDialog } from '../common/MTDialog';
import { ConfirmationButtonSet } from '../common/ConfirmationButtonSet';

export const MARKET_SWITCH_ARIA_LABEL = 'market-switch';
export const MARKET_INDICATOR_TEST_ID = 'market-indicator';

export const MarketStatus = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const {
    state: { fixtureId, fixtureActions },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { dispatch: addAction } = useDispatchWithResponse<AddFixtureActionMsg>(
    SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION,
  );

  const { allMarkets } = useContext(MarketsContext);

  const onPopupConfirm = async () => {
    setConfirmDisabled(true);
    await submitMarketAction(!allMarkets);
    setPopupOpen(false);
    setConfirmDisabled(false);
  };

  const submitMarketAction = (currentlyClosed: boolean) =>
    addAction(
      {
        fixtureId,
        fixtureActionTypeId: currentlyClosed
          ? FIXTURE_ACTION_TYPE.OPEN_MARKET
          : FIXTURE_ACTION_TYPE.CLOSE_MARKET,
        withLastFixtureActionParams: true,
      },
      {
        successMessage: SUCCESS_STRING.ACTION_SUCCESSFULLY_ADDED,
      },
    );

  if (!fixtureId) {
    return null;
  }

  if (!fixtureActions?.actions) {
    return <LoadingOverlay isLoading />;
  }

  return (
    <>
      <Stack
        alignItems='center'
        width='min-content'
        pt={0.5}
        pb={0.75}
        px={1}
        gap={0.75}
      >
        <Typography variant='overline' lineHeight={1} whiteSpace='nowrap'>
          {COMMON_STRING.ALL_MARKETS}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <MarketSwitch
                checked={allMarkets}
                onChange={() => setPopupOpen(true)}
                data-testid={MARKET_INDICATOR_TEST_ID}
              />
            }
            label={''}
            sx={{ margin: 0 }}
          />
        </FormGroup>
      </Stack>

      <MTDialog
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        title={COMMON_STRING.CONFIRM_CLOSE_MARKET(allMarkets)}
      >
        <ConfirmationButtonSet
          onConfirm={onPopupConfirm}
          shouldTitle={false}
          confirmMessage={COMMON_STRING.ARE_YOU_SURE_YOU_WANT_TO_OPEN_MARKET(
            allMarkets,
          )}
          onClose={() => setPopupOpen(false)}
          disabled={confirmDisabled}
        />
      </MTDialog>
    </>
  );
};
