import { styled } from '@mui/material/styles';
import { Table, TableContainer, TableHead, TableRow } from '@mui/material';
import { ActiveColumnType } from '@/components/StatsTableCells/types';

interface StyledColProps {
  index: number;
  activeColumn: ActiveColumnType;
}

export const TableRowWithHover = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    background: `${theme.palette.primary.main}15`,
  },
}));

export const StyledCol = styled('col', {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'activeColumn',
})<StyledColProps>(({ index, activeColumn = false, theme }) => ({
  backgroundColor:
    index === activeColumn ? `${theme.palette.primary.main}15` : 'transparent',
}));

export const StatsTableContainer = styled(TableContainer)(() => ({
  width: '100%',
}));

export const StatsTable = styled(Table)(() => ({
  borderCollapse: 'separate',
}));

export const StatsTableHead = styled(TableHead)(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 3,
}));
