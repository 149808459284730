import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import Avatar from '@mui/material/Avatar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FixtureScorerRating } from '@/service/types';
import { ComparisonBox } from '@/components/ComparisonRating/common';
import { useSlaBreachAllComments } from '@/service/hooks/useSlaBreachAllComments';
import { COMMON_STRING } from '@/constants/dictionary';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';
import { AnimatedIconButton } from '../common/AnimatedIconButton';

interface ComparisonRatingCommentProps {
  newRating: FixtureScorerRating | undefined;
  setNewRating: Dispatch<SetStateAction<FixtureScorerRating | undefined>>;
}

export const ComparisonRatingComment = ({
  newRating,
  setNewRating,
}: ComparisonRatingCommentProps) => {
  const { authState } = useOktaAuth();
  const username = authState?.idToken?.claims.name ?? '';

  const { slaBreachAllComments, error } = useSlaBreachAllComments();
  const isDisabledCommentButton =
    slaBreachAllComments?.comment === '' || !!error;

  const userInitials = useMemo(() => {
    if (!username) return;
    const [[first], [second]] = username.split(' ');
    return first + second;
  }, [username]);

  return (
    <ComparisonBox gap={2} textTransform='uppercase'>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography>{COMMON_STRING.ADD_COMMENT}</Typography>
        <CustomTooltip title={COMMON_STRING.COMPARISON_COMMENT_TOOLTIP_TITLE}>
          <span>
            <AnimatedIconButton
              aria-label='evaluation-copy'
              disabled={isDisabledCommentButton}
              onClick={() => {
                setNewRating({
                  ...newRating,
                  comment: slaBreachAllComments?.comment,
                });
              }}
            >
              <ContentCopyIcon />
            </AnimatedIconButton>
          </span>
        </CustomTooltip>
      </Stack>
      <TextField
        id='comment'
        multiline
        value={newRating?.comment || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setNewRating({ ...newRating, comment: event.target.value });
        }}
        minRows={6}
        maxRows={16}
        InputProps={{
          style: { padding: '1rem 0.875rem 1rem 2.25rem' },
          startAdornment: (
            <InputAdornment position='start'>
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: '0.75rem',
                  position: 'absolute',
                  top: (theme) => theme.spacing(2),
                  left: (theme) => theme.spacing(1.5),
                }}
              >
                {userInitials}
              </Avatar>
            </InputAdornment>
          ),
        }}
      />
    </ComparisonBox>
  );
};
