import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { COLLECTION_RANK_IDS } from '@/service/constants';

export const RankInfo = () => {
  const theme = useTheme<Theme>();
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);

  if (!fixtureSummary?.rank) {
    return null;
  }

  return (
    <Typography
      component='p'
      borderRadius={theme.spacing(0.5)}
      color={
        fixtureSummary.rank.value === COLLECTION_RANK_IDS.SILVER
          ? theme.palette.common.black
          : theme.palette.common.white
      }
      p={theme.spacing(0.25, 0.5)}
      fontSize='0.75rem'
      lineHeight={1.67}
      textTransform='capitalize'
      aria-label={ARIA_LABEL.RANK_INFO}
      sx={{ background: theme.palette.rank[fixtureSummary.rank.value] }}
    >
      {fixtureSummary.rank.displayValue}
    </Typography>
  );
};
