import { useContext, useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import ReactPlayer from 'react-player';
import { Stack } from '@mui/material';
import { OnProgressProps } from 'react-player/base';
import { ActionDialogProps } from '@/components/ActionsDialog/types';
import { ScoringContext } from '@/contexts/scoring/context';
import { StreamingContext } from '@/contexts/streaming/context';
import { MTDialog } from '@/components/common/MTDialog';
import { StreamingVolumeSlider } from '@/components/StreamingDialog/StreamingVolumeSlider';
import { StreamingTimeline } from '@/components/StreamingDialog/StreamingTimeline';
import { StreamingPlayerActionButtons } from '@/components/StreamingDialog/StreamingPlayerActionButtons';
import {
  generateStreamingDialogTitle,
  getPositionInSecondsToPlayStreamFrom,
  getStartOfStreamTimestamp,
} from '@/components/StreamingDialog/utils';

export const StreamingDialog = ({ action, onClose }: ActionDialogProps) => {
  const { fixtureActionTypeId, timestamp, fixtureSeqNum, clockTimeString } =
    action;
  const {
    fixtureConfigState: { fixtureConfig },
    state: { fixtureSummary },
  } = useContext(ScoringContext);
  const { streamUrl } = useContext(StreamingContext);

  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const [startOfStreamTimestamp, setStartOfStreamTimestamp] = useState(0);
  const [actionInStreamTimestamp, setActionInStreamTimestamp] = useState(0);
  const [maxActionInStreamTimestamp, setMaxActionInStreamTimestamp] =
    useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const isFinishedAction = currentTime >= maxActionInStreamTimestamp;

  const fixtureStartTime = fixtureSummary?.periods.find(
    (period) => period.periodNumber === 1
  )?.startTimestamp;

  useEffect(() => {
    if (!streamUrl) return;
    const hlsStream = new Hls();
    hlsStream.loadSource(streamUrl);

    // eslint-disable-next-line import/no-named-as-default-member
    hlsStream.on(Hls.Events.LEVEL_LOADED, (event, data) =>
      getStartOfStreamTimestamp(data, setStartOfStreamTimestamp)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!startOfStreamTimestamp) return;

    setActionInStreamTimestamp(
      getPositionInSecondsToPlayStreamFrom(
        action,
        startOfStreamTimestamp,
        fixtureStartTime
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOfStreamTimestamp]);

  useEffect(() => {
    seekToStart();
    setMaxActionInStreamTimestamp(actionInStreamTimestamp + 60);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionInStreamTimestamp]);

  const onPlay = () => {
    if (isFinishedAction) {
      return seekToStart();
    }

    setPlaying(!playing);
  };

  const onForwardTenSeconds = () => {
    const resultTimestamp = +currentTime.toFixed(0) + 10;

    if (resultTimestamp >= maxActionInStreamTimestamp) {
      return (playerRef.current as any)?.seekTo(maxActionInStreamTimestamp);
    }

    return (playerRef.current as any)?.seekTo(resultTimestamp);
  };

  const onReplayTenSeconds = () => {
    const resultTimestamp = currentTime - 10;

    if (resultTimestamp <= actionInStreamTimestamp) {
      return seekToStart();
    }

    return (playerRef.current as any)?.seekTo(resultTimestamp);
  };

  const onClickVolume = (newVolume: number) => setVolume(newVolume);

  const onChangeTimeline = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      (playerRef.current as any)?.seekTo(+newValue.toFixed(0));
      setCurrentTime(+newValue.toFixed(0));
    }
  };

  const seekToStart = () => {
    if (!actionInStreamTimestamp) return;

    (playerRef.current as any)?.seekTo(actionInStreamTimestamp);
    setPlaying(true);
    setCurrentTime(actionInStreamTimestamp);
  };

  const onProgress = ({ playedSeconds }: OnProgressProps) => {
    if (maxActionInStreamTimestamp === +playedSeconds.toFixed(0)) {
      setPlaying(false);
    }
    if (currentTime < actionInStreamTimestamp) {
      return seekToStart();
    }
    setCurrentTime(+playedSeconds.toFixed(0));
  };

  return (
    <MTDialog
      open={true}
      onClose={onClose}
      title={generateStreamingDialogTitle(
        timestamp,
        fixtureActionTypeId,
        fixtureSeqNum,
        clockTimeString,
        fixtureConfig
      )}
      maxWidth='md'
      fullWidth
      sx={{ justifyContent: 'left' }}
    >
      <Stack gap={1} width='100%'>
        <ReactPlayer
          volume={volume}
          ref={playerRef}
          url={streamUrl}
          playing={playing}
          onStart={() => setPlaying(!(currentTime < actionInStreamTimestamp))}
          progressInterval={200}
          pip={false}
          onProgress={onProgress}
          stopOnUnmount={true}
          width='100%'
          height='100%'
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
              },
            },
          }}
        />
        <Stack>
          <Stack
            flexDirection='row'
            gap={0.5}
            alignItems='center'
            justifyContent='space-between'
          >
            <StreamingPlayerActionButtons
              playing={playing}
              isFinishedAction={isFinishedAction}
              onReplayTenSeconds={onReplayTenSeconds}
              onForwardTenSeconds={onForwardTenSeconds}
              onPlay={onPlay}
            />
            <StreamingVolumeSlider
              volume={volume}
              onClickVolume={onClickVolume}
            />
          </Stack>
          <StreamingTimeline
            currentTime={currentTime}
            actionInStreamTimestamp={actionInStreamTimestamp}
            maxActionInStreamTimestamp={maxActionInStreamTimestamp}
            onChangeTimeline={onChangeTimeline}
          />
        </Stack>
      </Stack>
    </MTDialog>
  );
};
