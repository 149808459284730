import { Divider } from '@mui/material';
import { BasketballStatsButtons } from '@/components/StatsButtons/BasketballStatsButtons';
import { BasketballStatsTable } from '@/components/BasketballStatsTable/BasketballStatsTable';
import { StyledStatisticsTabWrapper } from '@/components/common/StyledStatisticsTabWrapper';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export const FixtureBasketballStats = () => {
  return (
    <PermissionsChecker name='FixturePage.BasketballStats'>
      <StyledStatisticsTabWrapper>
        <BasketballStatsButtons />
        <Divider />
        <BasketballStatsTable />
      </StyledStatisticsTabWrapper>
    </PermissionsChecker>
  );
};
