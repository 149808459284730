import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { COMMON_STRING } from '@/constants/dictionary';
import { NumberTextField } from '@/components/ComparisonRating/common';
import { SemiBoldTypography } from '@/components/common/Semiboldtypography';
import { LatencyTime } from '../ActionEvaluationDialog';

interface LatencyCounterProps {
  latencyTime: LatencyTime;
  changeLatency: Dispatch<SetStateAction<LatencyTime>>;
}

export const COUNTER_LABEL = {
  MIN: 'input-latency-min',
  SEC: 'input-latency-sec',
};

export const LatencyCounter = ({
  latencyTime,
  changeLatency,
}: LatencyCounterProps) => {
  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: 'seconds' | 'minutes',
  ) => {
    let newValue = Number(event.target.value);

    if (field === 'seconds' && newValue > 59) {
      const additionalMinutes = Math.floor(newValue / 60);
      newValue %= 60;

      const newMinutes = latencyTime.minutes + additionalMinutes;
      changeLatency((prevLatencyTime) => ({
        ...prevLatencyTime,
        minutes: Math.min(newMinutes, 60),
        [field]: newValue,
      }));
    } else {
      if (newValue > 60) {
        newValue = 60;
      }
      changeLatency((prevLatencyTime) => ({
        ...prevLatencyTime,
        [field]: newValue,
      }));
    }
  };

  const onMinutesInput = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    if (newValue > 60) {
      event.preventDefault();
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      <AddIcon />
      <NumberTextField
        sx={{ width: '4em' }}
        type='number'
        value={latencyTime.minutes}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onTimeChange(event, 'minutes')
        }
        onInput={onMinutesInput}
        InputProps={{
          inputProps: {
            max: 60,
            min: 0,
            step: 1,
            'aria-label': COUNTER_LABEL.MIN,
          },
        }}
      />
      <SemiBoldTypography>{COMMON_STRING.MIN}</SemiBoldTypography>
      <NumberTextField
        sx={{ width: '4em' }}
        type='number'
        value={latencyTime.seconds}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onTimeChange(event, 'seconds')
        }
        InputProps={{
          inputProps: {
            max: 59,
            min: 0,
            step: 1,
            'aria-label': COUNTER_LABEL.SEC,
          },
        }}
      />
      <SemiBoldTypography>{COMMON_STRING.SEC}</SemiBoldTypography>
    </Stack>
  );
};
