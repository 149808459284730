import { Stack } from '@mui/system';
import { FC, useContext, useReducer } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import { FixtureAction } from '@/service/types';
import {
  AddFixtureActionMsg,
  DeleteFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
  UpdateFixtureActionMsg,
} from '@/workers/scoring/types';
import {
  FIXTURE_ACTION_SUBTYPE,
  FIXTURE_ACTION_TYPE,
} from '@/service/constants';
import { ActionSelect } from './ActionSelect';
import { EditButtons } from './EditButtons';
import { PlayersSelect } from './PlayersSelect';
import {
  actionsReducer,
  generateInitialState,
  isChangeableActionTypeId,
  isEditActionSaveButtonDisabled,
  playersAlreadyAssignedToEditedActionWithLimit,
} from './utils';
import { TeamSelect } from './TeamSelect';
import { EditActionState } from './types';

export type EditActionProps = {
  action: FixtureAction;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
  onError: (error?: unknown) => void;
};

export const EditAction: FC<EditActionProps> = ({
  action,
  onError,
  onSuccess,
  onCancel,
}) => {
  const isOwnGoalActionSubType =
    action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.GOAL &&
    action.fixtureActionSubTypeId === FIXTURE_ACTION_SUBTYPE.OWN_GOAL;
  const {
    state: { fixtureId, fixtureSummary, fixtureActions },
    fixtureConfigState: { fixtureConfig },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const [editedAction, updateEditedAction] = useReducer(
    actionsReducer,
    generateInitialState({
      fixtureConfig,
      fixtureSummary,
      action,
    }),
  );
  const { dispatch: updateAction, isLoading } =
    useDispatchWithResponse<UpdateFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.UPDATE_FIXTURE_ACTION,
    );

  const { dispatch: addAction, isLoading: isLoadingAddAction } =
    useDispatchWithResponse<AddFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION,
    );

  const { dispatch: deleteAction, isLoading: isLoadingDeleteAction } =
    useDispatchWithResponse<DeleteFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.DELETE_FIXTURE_ACTION,
    );

  const isChangeableAction = isChangeableActionTypeId({
    fixtureActionTypeId: action.fixtureActionTypeId,
    fixtureConfig,
    checkGoal: true,
  });

  const isPlayerRequired =
    !!fixtureConfig?.fixtureOptions.actionButtons.some(
      ({ playerRequired, actionType }) =>
        actionType.id === editedAction.fixtureActionType?.id && playerRequired,
    ) && !!fixtureConfig?.fixtureOptions.sportRules.activePlayers;

  const isSubTypeRequired = !!fixtureConfig?.fixtureOptions.actionButtons.some(
    ({ subTypeRequired, actionType }) =>
      actionType.id === editedAction.fixtureActionType?.id && subTypeRequired,
  );

  const isChangeTeamAllowed =
    !!fixtureConfig?.fixtureOptions.actionButtons.some(
      ({ changeableTeam, actionType }) =>
        actionType.id === editedAction.fixtureActionType?.id && changeableTeam,
    );

  const submit = async () => {
    if (
      action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY ||
      action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER
    ) {
      return await deleteAction(
        { fixtureId, fixtureActionId: action.id },
        { showSnackbar: false },
      )
        .catch(onError)
        .then(
          async () =>
            await addAction(
              {
                fixtureId,
                fixtureActionTypeId: action.fixtureActionTypeId,
                fixtureActionSubTypeId: editedAction.fixtureSubActionType?.id,
                withLastFixtureActionParams: false,
              },
              {
                showSnackbar: false,
              },
            ),
        )
        .then(onSuccess);
    }

    return await updateAction(
      {
        fixtureId: fixtureId,
        fixtureActionId: action.id,
        newPlayerId: isPlayerRequired ? editedAction.player?.id : undefined,
        newTeamId: editedAction.teamId,
        newFixtureActionTypeId: editedAction.fixtureActionType?.id,
        newFixtureActionSubtypeId: editedAction.fixtureSubActionType?.id,
      },
      {
        showSnackbar: false,
      },
    )
      .catch(onError)
      .then(onSuccess);
  };

  const playersToDisableInOptions = () =>
    playersAlreadyAssignedToEditedActionWithLimit(
      editedAction.fixtureActionType,
      fixtureActions?.actions,
      fixtureConfig,
    );

  const getOpositeTeam = (selectedTeamId: string): string | undefined => {
    return fixtureConfig?.fixture?.teams.find(
      (team) => team.id !== selectedTeamId,
    )?.id;
  };

  const shouldChangeTeamToOposite = (actionTypeId: number | undefined) => {
    return false;
    // TODO : left for future use with the config setting
    // const previousActionTypeId = action.fixtureActionTypeId;
    //
    // switch (previousActionTypeId) {
    //   case FIXTURE_ACTION_TYPE.POSSIBLE_REBOUND:
    //     return (
    //       actionTypeId === FIXTURE_ACTION_TYPE.DEFENSIVE_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.DEFENSIVE_TEAM_REBOUND
    //     );
    //
    //   case FIXTURE_ACTION_TYPE.OFFENSIVE_TEAM_REBOUND:
    //     return (
    //       actionTypeId === FIXTURE_ACTION_TYPE.DEFENSIVE_TEAM_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.DEFENSIVE_REBOUND
    //     );
    //
    //   case FIXTURE_ACTION_TYPE.OFFENSIVE_REBOUND:
    //     return (
    //       actionTypeId === FIXTURE_ACTION_TYPE.DEFENSIVE_TEAM_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.DEFENSIVE_REBOUND
    //     );
    //
    //   case FIXTURE_ACTION_TYPE.DEFENSIVE_TEAM_REBOUND:
    //     return (
    //       actionTypeId === FIXTURE_ACTION_TYPE.OFFENSIVE_TEAM_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.OFFENSIVE_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.NO_REBOUND
    //     );
    //
    //   case FIXTURE_ACTION_TYPE.DEFENSIVE_REBOUND:
    //     return (
    //       actionTypeId === FIXTURE_ACTION_TYPE.OFFENSIVE_TEAM_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.OFFENSIVE_REBOUND ||
    //       actionTypeId === FIXTURE_ACTION_TYPE.NO_REBOUND
    //     );
    //
    //   default:
    //     return false;
    // }
  };

  const onActionChanged = (updated: Partial<EditActionState>) => {
    let changeTeamObject = undefined;

    if (
      shouldChangeTeamToOposite(updated.fixtureActionType?.id) &&
      isChangeTeamAllowed
    ) {
      changeTeamObject = {
        teamId: getOpositeTeam(action.teamId!),
        player: null,
      };
    }

    updateEditedAction({ ...updated, ...changeTeamObject });
  };

  return (
    <Stack gap={1.5} flex='1 0 0'>
      {isChangeableAction && (
        <ActionSelect
          action={action}
          editedAction={editedAction}
          isOwnGoalActionSubType={isOwnGoalActionSubType}
          fixtureConfig={fixtureConfig}
          onChange={onActionChanged}
        />
      )}
      {isChangeTeamAllowed && (
        <TeamSelect
          action={action}
          editedAction={editedAction}
          fixtureConfig={fixtureConfig}
          onChange={updateEditedAction}
        />
      )}
      {isPlayerRequired && (
        <PlayersSelect
          editedAction={editedAction}
          onChange={updateEditedAction}
          action={action}
          fixtureConfig={fixtureConfig}
          playersIdsToDisableInOptions={playersToDisableInOptions()}
        />
      )}
      <EditButtons
        handleClose={onCancel}
        onSubmit={submit}
        disabled={isEditActionSaveButtonDisabled({
          editedAction,
          action,
          isPlayerRequired,
          isSubTypeRequired,
          isChangeableActionTypeId: isChangeableAction,
          isChangeTeamAllowed,
        })}
        isLoading={isLoading || isLoadingAddAction || isLoadingDeleteAction}
      />
    </Stack>
  );
};
