import { CircleFlag } from 'react-circle-flags';
import { Box } from '@mui/system';
import { EmojiStyle, Emoji } from 'emoji-picker-react';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { MTEmojiType } from '../types';

export type MTEmojiProps = {
  emoji?: MTEmojiType | null;
  size?: number;
};

export const MTEmoji = ({ emoji, size = 24 }: MTEmojiProps) => {
  if (!emoji?.code && !emoji?.country) {
    return null;
  }

  if (emoji.country) {
    return (
      <Box
        mr={0.75}
        display={'flex'}
        alignItems={'center'}
        aria-label={ARIA_LABEL.EMOJI_FLAG}
      >
        <CircleFlag
          countryCode={emoji.country.toLowerCase()}
          width={size}
          height={size}
        />
      </Box>
    );
  }

  return (
    <Box
      mr={0.75}
      display={'flex'}
      alignItems={'center'}
      aria-label={ARIA_LABEL.EMOJI}
    >
      <Emoji unified={emoji.code!} size={size} emojiStyle={EmojiStyle.APPLE} />
    </Box>
  );
};
