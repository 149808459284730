import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Fragment, useContext, useState } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { enqueueSnackbar } from 'notistack';
import {
  COMMON_STRING,
  DICTIONARY,
  SUCCESS_STRING,
} from '@/constants/dictionary';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  AddFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { MarketsContext } from '@/contexts/markets/context';
import { MarketSwitchBig } from '@/components/Navbar/MarketSwitch';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { PANEL } from '@/contexts/UIState/types';
import { UIStateContext } from '@/contexts/UIState/context';
import { DialogTitleWithClose } from '../common/DialogTitleWithClose';
import { MTDialog } from '../common/MTDialog';
import { ConfirmationButtonSet } from '../common/ConfirmationButtonSet';

export enum ALL_SWITCH {
  OVER = 'over',
  UNDERWAY = 'underway',
}

interface ConfirmSwitchAction {
  isConfirmSwitch: boolean;
  switchAction?: ALL_SWITCH;
}

export const Market = () => {
  const {
    state: { fixtureId },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const { openedPanel, setOpenedPanel } = useContext(UIStateContext);

  const { marketsPerActionList, marketsPerAction } = useContext(MarketsContext);

  const { dispatch: addAction } = useDispatchWithResponse<AddFixtureActionMsg>(
    SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION,
  );

  const [confirmSwitchAction, setConfirmSwitchAction] =
    useState<ConfirmSwitchAction>({
      isConfirmSwitch: false,
    });

  const onCloseConfirmation = () => {
    setConfirmSwitchAction({
      isConfirmSwitch: false,
    });
  };

  const onCloseDrawer = () => {
    onCloseConfirmation();
    setOpenedPanel(null);
  };

  const openCloseSeparateMarketHandler = async (
    isMarketOpen: boolean,
    marketId: number,
    showNotification = true,
  ) => {
    const fixtureActionTypeId = isMarketOpen
      ? FIXTURE_ACTION_TYPE.DATA_REVIEW_UNDERWAY
      : FIXTURE_ACTION_TYPE.DATA_REVIEW_OVER;

    const fixtureActionSubTypeId = Number(`${fixtureActionTypeId}${marketId}`);

    await addAction({
      fixtureId,
      fixtureActionTypeId,
      fixtureActionSubTypeId,
      withLastFixtureActionParams: true,
    });
    if (showNotification) {
      enqueueSnackbar(SUCCESS_STRING.ACTION_SUCCESSFULLY_ADDED, {
        variant: 'success',
      });
    }
  };

  if (!marketsPerAction || !marketsPerActionList) {
    return null;
  }

  const handleAllSwitches = async (over?: ALL_SWITCH) => {
    if (over === ALL_SWITCH.OVER) {
      for (const market of marketsPerActionList) {
        if (!marketsPerAction[market.id].isMarketOpen) {
          await openCloseSeparateMarketHandler(false, market.id, false);
        }
      }
    } else {
      for (const market of marketsPerActionList) {
        if (marketsPerAction[market.id].isMarketOpen) {
          await openCloseSeparateMarketHandler(true, market.id, false);
        }
      }
    }
    enqueueSnackbar(SUCCESS_STRING.ACTION_SUCCESSFULLY_ADDED, {
      variant: 'success',
    });
  };

  const allMarketChecker = Object.values(marketsPerAction).filter(
    ({ isMarketOpen }) => !!isMarketOpen,
  );

  return (
    <>
      <Drawer
        anchor='right'
        open={openedPanel === PANEL.DATA_REVIEW}
        onClose={onCloseDrawer}
        keepMounted
      >
        <Stack justifyContent={'center'}>
          <DialogTitleWithClose close={onCloseDrawer}>
            {DICTIONARY.COMMON.DATA_REVIEW_OF_ACTION_TYPES}
          </DialogTitleWithClose>
          <Divider />

          <Stack py={2} px={1.5} gap={2}>
            <Stack
              justifyContent='space-between'
              flexDirection='row'
              alignItems='center'
            >
              <Typography
                fontWeight={(theme) => theme.typography.caption.fontWeight}
              >
                {COMMON_STRING.DATA_POINTS}
              </Typography>
              <ButtonGroup sx={{ borderColor: 0 }} variant='contained'>
                <Button
                  disabled={
                    allMarketChecker.length === marketsPerActionList.length
                  }
                  onClick={() =>
                    setConfirmSwitchAction({
                      isConfirmSwitch: true,
                      switchAction: ALL_SWITCH.OVER,
                    })
                  }
                >
                  {COMMON_STRING.OVER}
                </Button>
                <Button
                  disabled={!allMarketChecker.length}
                  onClick={() =>
                    setConfirmSwitchAction({
                      isConfirmSwitch: true,
                      switchAction: ALL_SWITCH.UNDERWAY,
                    })
                  }
                >
                  {COMMON_STRING.UNDERWAY}
                </Button>
              </ButtonGroup>
            </Stack>
            <Divider />

            {marketsPerActionList?.map((market) => (
              <Fragment key={market.id}>
                <Stack
                  justifyContent='space-between'
                  flexDirection='row'
                  alignItems='center'
                >
                  <Typography variant='body1'>{market.name}</Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <MarketSwitchBig
                          onClick={() =>
                            openCloseSeparateMarketHandler(
                              marketsPerAction[market.id].isMarketOpen,
                              market.id,
                            )
                          }
                          checked={marketsPerAction[market.id]?.isMarketOpen}
                        />
                      }
                      label={''}
                      sx={{ margin: 0 }}
                    />
                  </FormGroup>
                </Stack>
                <Divider />
              </Fragment>
            ))}
          </Stack>
        </Stack>
      </Drawer>

      <MTDialog
        open={confirmSwitchAction.isConfirmSwitch}
        onClose={onCloseConfirmation}
        title={COMMON_STRING.CONFIRM_DATA_POINTS_TITLE(
          confirmSwitchAction.switchAction,
        )}
      >
        <ConfirmationButtonSet
          onConfirm={() => {
            handleAllSwitches(confirmSwitchAction.switchAction);
            onCloseConfirmation();
          }}
          shouldTitle={false}
          confirmMessage={COMMON_STRING.CONFIRM_ALL_SWITCH_ACTION(
            confirmSwitchAction.switchAction,
          )}
          onClose={onCloseConfirmation}
        />
      </MTDialog>
    </>
  );
};
