import { styled } from '@mui/material';
import { FC } from 'react';

export interface KeyboardButtonProps {
  theKey: string | JSX.Element;
}
const KeyboardButtonSpan = styled('span')<KeyboardButtonProps>(({
  theme,
  theKey,
}) => {
  const keyOnly = typeof theKey === 'string' && theKey.length === 1;

  return {
    display: keyOnly ? 'inline-block' : 'flex',
    boxSizing: 'content-box',
    minWidth: 'min(1em, 0.75rem)',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'min(1em, 0.75rem)',
    aspectRatio: keyOnly ? '1/1' : 'none',
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius,
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: 'white',
    background: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
  };
});
export const KeyboardButton: FC<KeyboardButtonProps> = ({ theKey }) => {
  return <KeyboardButtonSpan theKey={theKey}>{theKey}</KeyboardButtonSpan>;
};
