import {
  FixtureConfig,
  FixtureSummary,
  PlayerGameStatistics,
  PlayerPeriodStatistics,
} from '@/service/types';
import { UNKNOWN_PLAYER_ID } from '@/constants';
import { PlayerRow } from '@/components/ScoresheetTables/PlayersTable/Rows/PlayerRow';
import { getPlayer } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';

interface GeneratePlayerRowProps {
  statistics: PlayerGameStatistics & PlayerPeriodStatistics;
  playerId: string;
  fixtureSummary: FixtureSummary | null;
  teamId: string;
  fixtureConfig?: FixtureConfig;
}
export const generatePlayerRow = ({
  statistics,
  playerId,
  fixtureConfig,
  fixtureSummary,
  teamId,
}: GeneratePlayerRowProps) => {
  if (playerId === UNKNOWN_PLAYER_ID) {
    return null;
  }

  const player = getPlayer(playerId, fixtureConfig, fixtureSummary);

  return (
    <PlayerRow
      player={player}
      statistics={statistics}
      key={`${playerId}-${teamId}`}
    />
  );
};
