import { FixtureConfig } from '@/service/types';

export const getActionName = (
  actionTypeId: number,
  fixtureConfig: FixtureConfig | undefined
) => {
  if (!fixtureConfig || !fixtureConfig.fixtureOptions.actionButtons) {
    return actionTypeId.toString();
  }
  const actionButton = fixtureConfig.fixtureOptions.actionButtons.find(
    (ab) => ab.actionType.id === actionTypeId
  );
  if (actionButton) {
    return actionButton.actionType.name;
  }

  const staticActionType = fixtureConfig.staticFixtureActions.find(
    ({ id }) => id === actionTypeId
  );
  if (staticActionType) {
    return staticActionType.name;
  }

  return actionTypeId.toString();
};
