import { CircularProgress, IconButton } from '@mui/material';
import { FC } from 'react';
import Flag from '@mui/icons-material/Flag';
import { isActionFlagged } from '@/service/utils/actionConditions';
import { FixtureAction } from '@/service/types';
import { FIXTURE_ACTION_FLAG_STATE } from '@/service/constants';
import { BUTTON_NAME } from './constants';

export interface FlagCellProps {
  action: FixtureAction;
  isUpdating: boolean;
}

export const FlagCell: FC<FlagCellProps> = ({ action, isUpdating }) => {
  if (!isActionFlagged(action)) return <span />;
  if (isUpdating) return <CircularProgress size='1.5em' />;

  return (
    <IconButton name={BUTTON_NAME.FLAG} size='small' aria-label='flag'>
      <Flag
        fontSize='small'
        color={
          action.flag!.state === FIXTURE_ACTION_FLAG_STATE.CONFIRMED
            ? 'success'
            : undefined
        }
      />
    </IconButton>
  );
};
