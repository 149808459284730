import { SnackbarKey, useSnackbar } from 'notistack';
import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

export interface SnackbarCloseActionProps {
  snackbarId: SnackbarKey;
  onAfterClose?: VoidFunction;
}
export const SnackbarCloseAction = ({
  snackbarId,
  onAfterClose,
}: SnackbarCloseActionProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      color='inherit'
      onClick={() => {
        closeSnackbar(snackbarId);
        onAfterClose?.();
      }}
    >
      <Close fontSize='small' />
    </IconButton>
  );
};
