import {
  Dispatch,
  FC,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useCallback,
} from 'react';
import { TableBody } from '@mui/material';
import { ActiveColumnType } from '@/components/StatsTableCells/types';
import {
  tableMouseOutHandler,
  tableMouseOverHandler,
} from '@/components/StatsTableCells/utils';

interface StatsTableBodyProps {
  colGroupRef: RefObject<HTMLTableColElement>;
  activeColumn: ActiveColumnType;
  setActiveColumn: Dispatch<SetStateAction<ActiveColumnType>>;
}

export const StatsTableBody: FC<PropsWithChildren<StatsTableBodyProps>> = ({
  colGroupRef,
  activeColumn,
  setActiveColumn,
  children,
}) => {
  const onTableMouseOut = useCallback(
    () => tableMouseOutHandler(activeColumn, setActiveColumn, colGroupRef),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeColumn]
  );

  const onTableMouseOver = useCallback(
    (e: MouseEvent & ReactMouseEvent<HTMLTableSectionElement, MouseEvent>) =>
      tableMouseOverHandler(e, colGroupRef, setActiveColumn),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <TableBody onMouseOut={onTableMouseOut} onMouseOver={onTableMouseOver}>
      {children}
    </TableBody>
  );
};
