import { Box, BoxProps } from '@mui/material';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { FC } from 'react';
import SportsIcon from '@mui/icons-material/Sports';
import { Icon } from '@mdi/react';
import { mdiAlphaP, mdiFlagTriangle } from '@mdi/js';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  FIXTURE_ACTION_SUBTYPE,
  FIXTURE_ACTION_TYPE,
} from '@/service/constants';
import { FixtureAction } from '@/service/types';
import { isInteger } from '@/utils/number';
import { ACTION_ICON_ID } from './constants';

type ActionIconsProp = {
  action: FixtureAction;
  isSlaBreachTable?: boolean;
};

export const CardIcon: FC<BoxProps> = (props) => {
  return <Box width='0.75em' height='1em' borderRadius='0.14em' {...props} />;
};

const SLA_BREACH_ACTION_TYPE_ICON: Record<number, JSX.Element> = {
  [FIXTURE_ACTION_TYPE.PENALTY_AWARDED]: (
    <Icon
      data-testid={ACTION_ICON_ID.PENALTY_AWARDED}
      path={mdiAlphaP}
      size={1.1}
    />
  ),
  [FIXTURE_ACTION_TYPE.CORNER_AWARDED]: (
    <Icon
      data-testid={ACTION_ICON_ID.CORNER_AWARDED}
      path={mdiFlagTriangle}
      size={0.7}
    />
  ),
  [FIXTURE_ACTION_TYPE.CLOSE_MARKET]: <WarningAmberIcon fontSize='inherit' />,
  [FIXTURE_ACTION_TYPE.KICK_OFF]: <SportsIcon fontSize='inherit' />,
};

const ACTION_TYPE_ICON: Record<number, JSX.Element> = {
  [FIXTURE_ACTION_TYPE.GOAL]: <SportsSoccerIcon fontSize='inherit' />,
  [FIXTURE_ACTION_TYPE.YELLOW_CARD]: (
    <CardIcon
      data-testid={ACTION_ICON_ID.YELLOW_CARD}
      sx={{ bgcolor: (theme) => theme.palette.iconsColors.yellowCard }}
    />
  ),
  [FIXTURE_ACTION_TYPE.RED_CARD]: (
    <CardIcon
      data-testid={ACTION_ICON_ID.RED_CARD}
      sx={{ bgcolor: (theme) => theme.palette.iconsColors.redCard }}
    />
  ),
};

const ACTION_SUBTYPE_ICON: Record<number, JSX.Element> = {
  [FIXTURE_ACTION_SUBTYPE.SECOND_YELLOW_CARD]: (
    <CardIcon
      data-testid={ACTION_ICON_ID.SECOND_YELLOW_CARD}
      sx={{
        backgroundImage: (theme) =>
          `linear-gradient(to bottom right, ${theme.palette.iconsColors.redCard} 50%, ${theme.palette.iconsColors.yellowCard} 50%)`,
      }}
    />
  ),
};

export const ActionIcons: FC<ActionIconsProp> = ({
  action,
  isSlaBreachTable,
}) => {
  const actionTypeIcon =
    (isInteger(action.fixtureActionSubTypeId) &&
      ACTION_SUBTYPE_ICON[action.fixtureActionSubTypeId]) ||
    ACTION_TYPE_ICON[action.fixtureActionTypeId] ||
    (isSlaBreachTable &&
      SLA_BREACH_ACTION_TYPE_ICON[action.fixtureActionTypeId]);

  if (!actionTypeIcon) return null;

  return (
    <Box
      width='1.50em'
      height='1.50em'
      sx={{
        border: (theme) => `1px solid ${theme.palette.iconsColors.borderColor}`,
      }}
      borderRadius='0.28em'
      display='flex'
      alignItems='center'
      justifyContent='center'
      fontSize='1rem'
    >
      {actionTypeIcon}
    </Box>
  );
};
