import {
  AdvancedStatsData,
  AdvancedStatsTableHeader,
  AdvancedStatsTableHeaderCell,
} from '@/components/AdvancedStatsTable/types';
import { StatsButtonType } from '@/contexts/stats/types';
import {
  FixtureConfig,
  TeamActionAggregates,
  TeamGameStatistics,
  TeamStatistics,
} from '@/service/types';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { COMMON_STRING } from '@/constants/dictionary';

interface BallPossesionProps {
  teamId: AdvancedStatsData['teamId'];
  teamStatistics?: TeamStatistics[];
}

interface FilterAdvancedTableDataProps {
  activeQuarter: StatsButtonType['value'];
  activeType: StatsButtonType['value'];
  activeTeam: StatsButtonType['id'];
  actions?: TeamActionAggregates[];
  homeTeamId: string | undefined;
}
export const filterAdvancedTableData = ({
  activeQuarter,
  activeType,
  activeTeam,
  actions,
  homeTeamId,
}: FilterAdvancedTableDataProps) => {
  if (!actions || !actions.length) {
    return;
  }

  const isAllQuarter = activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL;

  if (activeType === STATS_BUTTONS.TYPE.PLAYER) {
    return actions
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playerActionAggregates.map((player) => ({
        statistics: isAllQuarter
          ? player?.gameActionAggregates
          : player?.periodActionAggregates.filter(
              (period) => period.period === activeQuarter,
            ),
        playerId: player.playerId,
      }));
  }
  if (activeType === STATS_BUTTONS.TYPE.TEAM) {
    const stats = actions.map((teamStatistics) => ({
      statistics: isAllQuarter
        ? teamStatistics?.gameActionAggregates
        : teamStatistics.periodActionAggregates?.filter(
            (period) => period.period === activeQuarter,
          ),
      teamId: teamStatistics.teamId,
    }));
    if (homeTeamId) {
      return [
        ...stats.filter((statistics) => statistics.teamId === homeTeamId),
        ...stats.filter((statistics) => statistics.teamId !== homeTeamId),
      ];
    } else {
      return stats;
    }
  }
};

export const generateAdvancedStatsAllSportsHeader = (
  statsData: AdvancedStatsData[],
) => {
  const uniqueHeaders = new Map<number, Set<number | undefined>>();

  for (const data of statsData) {
    if (data.statistics) {
      for (const statistics of data.statistics) {
        const { actionId, subActionAggregates } = statistics;

        if (!uniqueHeaders.has(actionId)) {
          uniqueHeaders.set(actionId, new Set<number | undefined>());
        }

        for (const subAction of subActionAggregates) {
          if (subAction.subActionId !== -1) {
            uniqueHeaders.get(actionId)!.add(subAction.subActionId);
          }
        }
      }
    }
  }

  const sortedActionIds = Array.from(uniqueHeaders.keys()).sort();

  return sortedActionIds.map((actionId) => {
    const subActions = Array.from(uniqueHeaders.get(actionId)!.values()).sort();
    return { actionId, subActions };
  }) as AdvancedStatsTableHeader;
};

export const generateAdvancedStatsBasketballHeader = (
  type: StatsButtonType['value'],
  fixtureConfig: FixtureConfig | undefined,
) => {
  const headerCellsWithoutSubTypes =
    generateAdvancedStatsBasketballHeaderWithoutSubtypes(type);

  return headerCellsWithoutSubTypes.map((headerCell) => {
    const actionTypeFromConfig =
      fixtureConfig?.fixtureOptions.actionButtons.find(
        (actionButton) => actionButton.actionType.id === headerCell.actionId,
      );

    if (!actionTypeFromConfig?.actionType.subTypes) {
      return headerCell;
    } else {
      const subActionsList = actionTypeFromConfig.actionType.subTypes.map(
        (subType) => subType.id,
      );

      subActionsList.push(headerCell.actionId);
      return {
        ...headerCell,
        subActions: subActionsList,
      };
    }
  });
};

export const generateAdvancedStatsBasketballHeaderWithoutSubtypes = (
  type: StatsButtonType['value'],
) => {
  const header: AdvancedStatsTableHeaderCell[] = [
    {
      actionId: FIXTURE_ACTION_TYPE['2PT_MADE'],
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE['2PT_MISSED'],
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      actionId: FIXTURE_ACTION_TYPE['2PT_SHOT'],
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      actionId: FIXTURE_ACTION_TYPE['3PT_MADE'],
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE['3PT_MISSED'],
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      actionId: FIXTURE_ACTION_TYPE['3PT_SHOT'],
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.FT_AWARDED,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.FT_MADE,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.FT_MISSED,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.OFFENSIVE_REBOUND,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.DEFENSIVE_REBOUND,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.ASSIST,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.STEAL,
      visible: type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.TURNOVER,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.PLAYER_FOUL,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.OUT_OF_GAME,
      visible:
        type === STATS_BUTTONS.TYPE.TEAM || type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.NO_REBOUND,
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.NO_ASSIST,
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      actionId: FIXTURE_ACTION_TYPE.NO_FT_AWARDED,
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
  ];
  return header.filter(({ visible }) => visible);
};

export const getBallPossesion = ({
  teamId,
  teamStatistics,
}: BallPossesionProps) => {
  const team = teamStatistics?.find((teamStatistics) => {
    return teamStatistics.teamId === teamId;
  });
  const ballPossession = (team?.gameStatistics as TeamGameStatistics)
    ?.ballPossessionPercentage;
  return ballPossession ? ballPossession + '%' : COMMON_STRING.NOT_AVAILABLE;
};
