import { styled } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';

interface LabelWithDividerProps {
  label: string;
}

interface IsInCurrenLineupSpanProps {
  isInCurrentLineup: boolean;
}

export const FixtureLineupTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '1rem',
  textTransform: 'uppercase',
  padding: theme.spacing(1, 0),
}));

export const TeamNameTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '1.25rem',
  marginBottom: theme.spacing(2),
}));

export const LineupFirstName = styled('span')(({ theme }) => ({
  textTransform: 'capitalize',
}));

export const LineupLastName = styled('span')(({ theme }) => ({
  textTransform: 'uppercase',
}));

export const LineupShirtNumber = styled('div')(({ theme }) => ({
  minWidth: '3ch',
  color: theme.palette.grey[600],
  textTransform: 'uppercase',
  fontSize: '1rem',
  textAlign: 'center',
}));

export const LineupIndicator = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isInCurrentLineup',
})<IsInCurrenLineupSpanProps>(({ theme, isInCurrentLineup }) => ({
  width: '0.75rem',
  height: '0.75rem',
  borderRadius: '50%',
  backgroundColor: isInCurrentLineup ? '#4da54a' : '#df0000',
  content: '""',
}));

export const LabelWithDivider = ({ label }: LabelWithDividerProps) => {
  return (
    <div>
      <FixtureLineupTypography>{label}</FixtureLineupTypography>
      <Divider />
    </div>
  );
};
