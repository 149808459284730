import { FixtureAction } from '@/service/types';
import { MATCH_ACTION_SEND_TYPE } from '@/service/constants';
import { DeletedActionId } from '@/contexts/scoring/types';

export const generateDeletedActionIds = (actions?: FixtureAction[]) =>
  [...new Set(actions?.map((action) => action.actionId))]
    .map((actionId) => {
      const deletedAction = actions?.find(
        (action) =>
          action.actionId === actionId &&
          action.sendTypeId === MATCH_ACTION_SEND_TYPE.DELETED
      );

      const updatedAction = actions?.find(
        (action) =>
          action.actionId === actionId &&
          action.sendTypeId === MATCH_ACTION_SEND_TYPE.UPDATED
      );

      return deletedAction
        ? {
            actionId,
            deletedSeqNum: deletedAction?.fixtureSeqNum,
            updatedSeqNum: updatedAction?.fixtureSeqNum || -1,
          }
        : null;
    })
    .filter((action) => action !== null) as DeletedActionId[];
