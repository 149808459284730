import { useState, useEffect, useContext } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { konsole } from '@/utils/konsole';
import { FixtureAction } from '@/service/types';
import { AnyPrimitive } from '@/types/common';
import { ScoringContext } from '@/contexts/scoring/context';
import { API_METHOD } from '@/service/constants';
import { PreferencesContext } from '@/contexts/preferences/context';
import { getSport } from '@/service/utils/getSport';
import { FILTERS_HISTORY_MAX_LENGTH, NON_PROPERTY_FILTER } from './constants';
import {
  convertFiltersToTemplate,
  convertTemplateToFilters,
  filterActions,
} from './utils';

export interface ActionFilter {
  property?: keyof FixtureAction;
  nonPropertyFilter?: (typeof NON_PROPERTY_FILTER)[keyof typeof NON_PROPERTY_FILTER];
  value: AnyPrimitive;
  displayName: string;
  displayValue: string;
  exclude?: boolean;
  isFromTemplate?: boolean;
}

export interface UseActionsFiltersProps {
  actions?: FixtureAction[];
  initialFilters?: ActionFilter[];
  excludeTemplates?: boolean;
}
export const SS_HISTORY_KEY = 'MT-filters-history';

export const useActionsFilters = ({
  actions,
  initialFilters,
  excludeTemplates,
}: UseActionsFiltersProps) => {
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const {
    selectedTemplate,
    actions: { setSelectedTemplate, updateTemplate },
  } = useContext(PreferencesContext);
  const [displayActions, setDisplayActions] = useState<FixtureAction[]>(
    actions || [],
  );
  const [filtersHistory, setFiltersHistory] = useSessionStorage<
    ActionFilter[][]
  >(SS_HISTORY_KEY, []);

  const [filters, setNewFilters] = useState<ActionFilter[]>(
    initialFilters ?? filtersHistory.at(-1) ?? [],
  );

  const [templateFilters, setTemplateFilters] = useState<ActionFilter[]>([]);

  useEffect(() => {
    if (!actions) return;
    if (!filters.length) return setDisplayActions(actions);
    setDisplayActions(filterActions(filters, actions));
  }, [actions, filters]);

  useEffect(() => {
    if (excludeTemplates) {
      return;
    }

    const templateFilters = convertTemplateToFilters(
      selectedTemplate,
      fixtureConfig,
    );
    setTemplateFilters(templateFilters);

    if ((selectedTemplate && templateFilters.length > 0) || !selectedTemplate) {
      setFilters([...templateFilters]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, fixtureConfig, excludeTemplates]);

  const setTemplateToNone = () => {
    setSelectedTemplate(undefined, getSport(fixtureConfig)?.id);
  };

  const undoFilters = () => {
    if (!filtersHistory || !filtersHistory.length) return;
    const newHistory = [...filtersHistory];
    newHistory.pop();
    const prevFilters = newHistory.at(-1) || [];
    setNewFilters(prevFilters);
    setFiltersHistory(newHistory);
    konsole.groupCollapsed('Filters');
    konsole.table('New Filters', prevFilters);
    konsole.log('Filters history', newHistory);
    konsole.groupEnd();
  };

  const setFilters = (newFilters: ActionFilter[]) => {
    newFilters.forEach((filter) => {
      filter.isFromTemplate = filter.isFromTemplate
        ? true
        : templateFilters.some((templateFilter) =>
            isSameFilter(templateFilter, filter),
          );
    });
    const newHistory = [...filtersHistory, newFilters];
    newHistory.length > FILTERS_HISTORY_MAX_LENGTH && newHistory.shift();
    setFiltersHistory(newHistory);
    setNewFilters(newFilters);
    konsole.groupCollapsed('Filters');
    konsole.table('New Filters', newFilters);
    konsole.log('Filters history', newHistory);
    konsole.groupEnd();
  };

  const onFilterRemove = (filterIndex: number) => {
    if (!filters || !filters.length) return;
    const filterToRemoveFromTemplate = { ...filters[filterIndex] };
    const newFilters = [...filters];
    newFilters.splice(filterIndex, 1);
    setFilters(newFilters);
    const currentTemplateFilters = templateFilters.filter(
      (template) =>
        template.property !== filterToRemoveFromTemplate.property ||
        (template.property === filterToRemoveFromTemplate.property &&
          template.value !== filterToRemoveFromTemplate.value),
    );

    const currentNonTemplateFilters = filters.filter(
      (item) => !item.isFromTemplate,
    );

    setTemplateFilters(currentTemplateFilters);

    if (!currentTemplateFilters.length && !currentNonTemplateFilters.length) {
      setTemplateToNone();
    }
  };

  const clearFilters = () => {
    setFilters([...templateFilters]);
  };

  const addFiltersToTemplate = () => {
    selectedTemplate &&
      updateTemplate(
        convertFiltersToTemplate(
          {
            id: selectedTemplate.id,
            sportId: selectedTemplate.sportId,
            templateName: selectedTemplate.templateName,
            emoji: selectedTemplate.emoji,
          },
          filters,
          fixtureConfig,
        ),
        API_METHOD.POST,
      );
  };

  const resetTemplate = () => {
    const templateFilters = convertTemplateToFilters(
      selectedTemplate,
      fixtureConfig,
    );
    setFilters(templateFilters);
    setTemplateFilters(templateFilters);
  };

  const isSameFilter = (templateFilter: ActionFilter, filter: ActionFilter) => {
    return (
      templateFilter.property === filter.property &&
      templateFilter.value === filter.value &&
      !!templateFilter.exclude === !!filter.exclude
    );
  };

  return {
    actions,
    displayActions,
    filters,
    filtersHistory,
    undoFilters,
    setFilters,
    onFilterRemove,
    clearFilters,
    addFiltersToTemplate,
    resetTemplate,
  };
};
