import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import Download from '@mui/icons-material/Download';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { DICTIONARY } from '@/constants/dictionary';
import { exportToXlsx } from '@/utils';
import { ExportToXlsxProps, SheetData } from '@/utils/types';

interface ExportToXlsxButtonProps extends Pick<ExportToXlsxProps, 'fileName'> {
  getData: () => SheetData[];
}

export const ExportToXlsxButton = ({
  fileName,
  getData,
}: ExportToXlsxButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) return <CircularProgress size='2.25em' sx={{ p: '0.5em' }} />;

  return (
    <Tooltip title={DICTIONARY.COMMON.EXPORT_XLSX}>
      <IconButton
        color='primary'
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          exportToXlsx({ fileName, data: getData() })
            .catch((e) =>
              enqueueSnackbar(e.message, {
                variant: 'error',
              })
            )
            .finally(() => setIsLoading(false));
        }}
      >
        <Download fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};
