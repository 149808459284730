import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import type { FixtureAction } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SCORING_WORKER_HOST_ACTION,
  UpdateActionFlagMsg,
} from '@/workers/scoring/types';
import { useSlaBreachActionEvaluation } from '@/service/hooks/useSlaBreachActionEvaluation';
import { UIStateContext } from '@/contexts/UIState/context';
import { UIState } from '@/contexts/UIState/types';
import {
  ActionFilter,
  useActionsFilters,
} from '../ActionFilters/useActionsFilters';
import {
  FILTER_DISPLAY_NAME,
  GENERIC_FILTER_VALUE,
} from '../ActionFilters/constants';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from './ActionsTableContext';

export interface FilterApiState {
  hasLatency: boolean | '';
  hasMistake: boolean | '';
}

export interface ActionsTableContextProviderProps extends PropsWithChildren {
  initialFilters?: ActionFilter[];
  actions?: FixtureAction[];
  excludeTemplates?: boolean;
}

export const ActionsTableProvider = ({
  initialFilters,
  actions,
  children,
  excludeTemplates,
}: ActionsTableContextProviderProps) => {
  const {
    state: { fixtureId },
    useDispatchManyWithResponse,
  } = useContext(ScoringContext);
  const { isSlaBreachActionSelected } = useContext<UIState>(UIStateContext);

  const [filterApiState, setFilterApiState] = useState<FilterApiState>({
    hasLatency: '',
    hasMistake: '',
  });

  const queryParams: Record<string, boolean> = {};
  if (filterApiState.hasLatency !== '') {
    queryParams.hasLatency = filterApiState.hasLatency;
  }
  if (filterApiState.hasMistake !== '') {
    queryParams.hasMistake = filterApiState.hasMistake;
  }

  const { mutate, slaBreachEvaluation } = useSlaBreachActionEvaluation({
    fixtureId,
    queryParams: !!Object.keys(queryParams).length ? queryParams : undefined,
  });

  const actionsFilters = useActionsFilters({
    initialFilters,
    actions: isSlaBreachActionSelected ? slaBreachEvaluation : actions,
    excludeTemplates,
  });

  const handleSlaBreachApiFilters = () => {
    if (!actions) {
      return;
    }

    const getFilterValue = (filterName: string) => {
      const filter = actionsFilters.filters.find(
        (item) => item.displayName === filterName,
      );
      return filter?.value === GENERIC_FILTER_VALUE.TRUTHY
        ? true
        : filter?.value === GENERIC_FILTER_VALUE.FALSY
        ? false
        : '';
    };

    const hasLatencyValue = getFilterValue(FILTER_DISPLAY_NAME.LATENCY);
    const hasMistakeValue = getFilterValue(FILTER_DISPLAY_NAME.MISTAKE);

    setFilterApiState({
      hasLatency: hasLatencyValue,
      hasMistake: hasMistakeValue,
    });

    mutate();
  };

  useEffect(() => {
    handleSlaBreachApiFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsFilters.filters, actions]);

  const dispatchFlagUpdate = useDispatchManyWithResponse<UpdateActionFlagMsg>(
    SCORING_WORKER_HOST_ACTION.UPDATE_ACTION_FLAG,
  );
  const providerValue: ActionsTableContextType = {
    ...actionsFilters,
    dispatchFlagUpdate,
    excludeTemplates,
  };

  return (
    <ActionsTableContext.Provider value={providerValue}>
      {children}
    </ActionsTableContext.Provider>
  );
};
