import { Button, Stack, Typography } from '@mui/material';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SearchBar } from '@/components/common/SearchBar';
import { COMMON_STRING } from '@/constants/dictionary';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  getFixtureActionTypes,
  getFixtureActionTypesFromApi,
} from '@/service/utils/getFixtureActionTypes';
import { StaticFixtureActionType } from '@/service/types';
import { generateDataForColumns } from '@/utils/columnWrap/columnWrapGenerator';
import { PreferencesContext } from '@/contexts/preferences/context';
import { FILER_TEMPLATE_COLUMN_QUANTITY } from '../constants';
import { CHANGE_MODE_BUTTON_LABEL } from '../AutocompleteFilter';
import { ActionTypeFilterChip } from './ActionTypeFilterChip';
import { GridWrap } from './GridWrap';
import { ActionFiterType, HideableFilterItem } from './types';

type ActionstFiltersTemplateActionsProps = {
  selectedTypes: HideableFilterItem<ActionFiterType> | undefined;
  setSelectedTypes: (values: HideableFilterItem<ActionFiterType>) => void;
  editFromDrawer: boolean | undefined;
  sportId: number | undefined;
};

export const ActionstFiltersTemplateActions: FC<
  ActionstFiltersTemplateActionsProps
> = ({ selectedTypes, setSelectedTypes, editFromDrawer, sportId }) => {
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const { actionTypes: actionTypesFromApi } = useContext(PreferencesContext);

  const actionTypes = useMemo(() => {
    if (!editFromDrawer) {
      return getFixtureActionTypesFromApi(actionTypesFromApi, sportId);
    }

    if (!fixtureConfig) return [];
    return getFixtureActionTypes(fixtureConfig);
  }, [actionTypesFromApi, editFromDrawer, fixtureConfig, sportId]);

  const [columns, setColumns] = useState<StaticFixtureActionType[][]>();
  const [search, setSearch] = useState<string>('');
  const [hidden, setHidden] = useState<boolean>(selectedTypes?.hidden ?? false);

  const generateColumnsData = useCallback(
    (
      listOfTheTypesPerSport: StaticFixtureActionType[],
    ): StaticFixtureActionType[][] => {
      const data = [
        ...listOfTheTypesPerSport.filter((action) =>
          action.name.toLowerCase().includes(search.toLowerCase()),
        ),
      ];

      return generateDataForColumns(data, FILER_TEMPLATE_COLUMN_QUANTITY);
    },
    [search],
  );

  useEffect(() => {
    setColumns(generateColumnsData(actionTypes));
  }, [actionTypes, generateColumnsData]);

  if (!columns) {
    return null;
  }

  const isFilterSelected = (actionType: ActionFiterType) => {
    return !!selectedTypes?.values.find((type) => type.id === actionType.id);
  };

  const onSelect = (actionType: ActionFiterType, selected: boolean) => {
    if (selected) {
      setSelectedTypes({
        values: [
          ...(selectedTypes?.values ?? []),
          { id: actionType.id, name: actionType.name },
        ],
        hidden,
      });
      return;
    }
    setSelectedTypes({
      values:
        selectedTypes?.values.filter((item) => item.id !== actionType.id) ?? [],
      hidden,
    });
  };

  const onVisibilityClick = () => {
    setSelectedTypes({
      values: selectedTypes?.values ?? [],
      hidden: !hidden,
    });
    setHidden(!hidden);
  };

  return (
    <Stack data-testid={'search-action'}>
      <SearchBar
        label={COMMON_STRING.SEARCH_ACTIONS}
        onSearch={setSearch}
        sx={{ mb: 2 }}
      />
      <Stack direction={'row'} alignItems={'center'} sx={{ mb: 1 }} gap={1}>
        <Typography sx={{ fontSize: 12 }}>
          {COMMON_STRING.ACTION_TYPES}
        </Typography>
        <Button
          variant='text'
          color='inherit'
          size='small'
          sx={{
            width: '9ch',
            justifyContent: 'flex-start',
          }}
          onClick={onVisibilityClick}
          startIcon={hidden ? <VisibilityOff /> : <Visibility />}
          aria-label={CHANGE_MODE_BUTTON_LABEL}
        >
          {hidden ? 'Hide' : 'Show'}
        </Button>
      </Stack>
      <Stack
        direction={'row'}
        gap={3}
        sx={{ pr: 4 }}
        data-testid={'actions-list'}
      >
        <GridWrap>
          {Array.from(Array(FILER_TEMPLATE_COLUMN_QUANTITY).keys()).map(
            (columnIndex) => (
              <Stack key={columnIndex} gap={1} justifyItems='start'>
                {columns[columnIndex].map((actionType) => (
                  <ActionTypeFilterChip
                    key={actionType.name}
                    actionType={actionType}
                    isSelected={isFilterSelected(actionType)}
                    onClick={onSelect}
                  />
                ))}
              </Stack>
            ),
          )}
        </GridWrap>
      </Stack>
    </Stack>
  );
};
