import { IconButton, Stack, Tooltip } from '@mui/material';
import Replay10Icon from '@mui/icons-material/Replay10';
import ReplayIcon from '@mui/icons-material/Replay';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Forward10Icon from '@mui/icons-material/Forward10';
import { COMMON_STRING } from '@/constants/dictionary';

interface StreamingPlayerActionButtonsProps {
  playing: boolean;
  isFinishedAction: boolean;
  onReplayTenSeconds: VoidFunction;
  onForwardTenSeconds: VoidFunction;
  onPlay: VoidFunction;
}

export const StreamingPlayerActionButtons = ({
  playing,
  isFinishedAction,
  onReplayTenSeconds,
  onForwardTenSeconds,
  onPlay,
}: StreamingPlayerActionButtonsProps) => {
  return (
    <Stack flexDirection='row' gap={0.5} width='100%'>
      <Tooltip
        title={COMMON_STRING.REPLAY_TEN_SECONDS}
        placement='bottom'
        arrow
      >
        <IconButton color='primary' onClick={onReplayTenSeconds}>
          <Replay10Icon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          isFinishedAction
            ? COMMON_STRING.REPLAY
            : playing
            ? COMMON_STRING.PAUSE
            : COMMON_STRING.PLAY
        }
        placement='bottom'
        arrow
      >
        <IconButton color='primary' onClick={onPlay}>
          {isFinishedAction ? (
            <ReplayIcon />
          ) : playing ? (
            <PauseIcon />
          ) : (
            <PlayArrowIcon />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip
        title={COMMON_STRING.FORWARD_TEN_SECONDS}
        placement='bottom'
        arrow
      >
        <IconButton color='primary' onClick={onForwardTenSeconds}>
          <Forward10Icon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
