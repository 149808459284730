import { StatsButtonType } from '@/contexts/stats/types';

export const STATS_BUTTONS = {
  QUARTER: {
    ALL: {
      LABEL: 'All',
      VALUE: 'All',
    },
  },
  TYPE: {
    PLAYER: 'Player',
    TEAM: 'Team',
    GAME: 'Game',
  },
  STATS: {
    GAME: 'Game',
    BOX_SCORE: 'Box Score',
    PLAYER_TO_TEAM_RATIO: 'Player to team ratio',
    PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT: 'Player to team ratio when on court',
    TEAM_STATS_WHEN_PLAYER_ON_COURT: 'Team stats when player is on court',
    ZONE_STATS: 'Zone Stats',
    PASSING: 'Passing',
    RUSHING: 'Rushing',
    RECEIVING: 'Receiving',
    DEFENSIVE: 'Defensive',
    SPECIAL_TEAMS_PUNTING: 'Special Teams - Punting',
    SPECIAL_TEAMS_ALL_RETURNS: 'Special Teams - All returns',
    SPECIAL_TEAMS_KICKOFFS: 'Special Teams - Kickoffs',
  },
};

export const ALL_QUARTER_BUTTON: StatsButtonType = {
  id: STATS_BUTTONS.QUARTER.ALL.LABEL,
  isActive: true,
  value: STATS_BUTTONS.QUARTER.ALL.VALUE,
};
