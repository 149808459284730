import { NOT_UPDATED_ACTION_STATS_NAME } from '@/components/StatsCompareTable/constants';
import { FixtureAction } from '../types';

export interface BasketNotUpdatedActions {
  possibleAssists: FixtureAction[];
  possibleRebounds: FixtureAction[];
  unknownPlayers: FixtureAction[];
}

export interface NotUpdatedActionsItem {
  name: string;
  actionValue: number;
  icon?: JSX.Element;
}

export const getBasketActionStats = (
  notUpdatedActions: BasketNotUpdatedActions,
): NotUpdatedActionsItem[] => {
  return [
    {
      name: NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_ASSISTS,
      actionValue: notUpdatedActions.possibleAssists.length ?? 0,
    },
    {
      name: NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_REBOUNDS,
      actionValue: notUpdatedActions.possibleRebounds.length ?? 0,
    },
    {
      name: NOT_UPDATED_ACTION_STATS_NAME.UNKNOWN_PLAYER,
      actionValue: notUpdatedActions.unknownPlayers.length ?? 0,
    },
  ];
};
