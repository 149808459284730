import { useSnackbar } from 'notistack';
import { Button, CircularProgress } from '@mui/material';
import { orange } from '@mui/material/colors';
import { COMMON_STRING, SUCCESS_STRING } from '@/constants/dictionary';
import { SnackbarCloseActionProps } from '@/components/SnackbarActions/SnackbarCloseAction';
import {
  ActionOf,
  AddFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { UseDispatchWithResponse } from '@/contexts/scoring/createUseDispatchWithResponse';
import { ScoringState } from '@/contexts/scoring/types';

interface SnackbarPlayStoppedActionProps extends SnackbarCloseActionProps {
  fixtureId: ScoringState['fixtureId'];
  useDispatchWithResponse: <T>(
    action: ActionOf<T>
  ) => UseDispatchWithResponse<T>;
}

export const SnackbarPlayStoppedAction = ({
  snackbarId,
  fixtureId,
  useDispatchWithResponse,
}: SnackbarPlayStoppedActionProps) => {
  const { closeSnackbar } = useSnackbar();

  const { dispatch: addAction, isLoading } =
    useDispatchWithResponse<AddFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION
    );

  const submitPlayResumedAction = async () =>
    addAction(
      {
        fixtureId,
        fixtureActionTypeId: FIXTURE_ACTION_TYPE.PLAY_RESUMED,
        withLastFixtureActionParams: true,
      },
      {
        successMessage: SUCCESS_STRING.ACTION_SUCCESSFULLY_ADDED,
      }
    )
      .catch(() => closeSnackbar(snackbarId))
      .then(() => closeSnackbar(snackbarId));

  return (
    <Button
      disabled={isLoading}
      variant='text'
      startIcon={
        isLoading && <CircularProgress color={'inherit'} size={'1em'} />
      }
      onClick={submitPlayResumedAction}
      sx={{ color: orange[100] }}
    >
      {COMMON_STRING.PLAY_RESUMED}
    </Button>
  );
};
