import { FC, useContext, useMemo } from 'react';
import {
  IconButton,
  Divider,
  Stack,
  Paper,
  Collapse,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { DICTIONARY } from '@/constants/dictionary';
import { useOpsUiUrl } from '@/service/hooks/useOpsUiUrl';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { useFixtureDetails } from '@/service/hooks/useFixtureDetails';
import { hasPermissionsToElement } from '@/components/PermissionsChecker/utils';
import { FixtureDetailsChip } from '@/components/common/FixtureDetailsChip';
export interface FixtureInfoProps {
  fixtureId: string;
  isOpen: boolean;
}

export const FixtureInfo: FC<FixtureInfoProps> = ({ fixtureId, isOpen }) => {
  const { permissions } = useContext(RolePermissionsContext);
  const { data: fixtureInfo, error } = useFixtureDetails();
  const { data: opsUiUrl } = useOpsUiUrl({
    fixtureId,
  });
  const errorTitle = error?.title;
  const errorMessage = error?.message;
  const content = useMemo(() => {
    if (errorMessage || errorTitle) {
      return (
        <Box>
          {errorTitle && (
            <Typography variant='overline'>{errorTitle}</Typography>
          )}
          {errorMessage && (
            <Typography fontWeight={700} variant='body1'>
              {errorMessage}
            </Typography>
          )}
        </Box>
      );
    }

    if (!fixtureInfo.details) return <CircularProgress />;

    return fixtureInfo.details?.map(
      ({ label, firstLine, secondLine, events, competitionChips }, column) => (
        <Box
          key={label}
          py={1}
          flex='1 1 0'
          style={column === 0 ? { flexGrow: 2 } : undefined}
          data-testid='fixture-info-column'
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography py={1} variant='caption' lineHeight={1}>
              {label}
            </Typography>
            {column === 0 &&
              !!opsUiUrl &&
              hasPermissionsToElement(
                'FixturePage.HeaderPanel.FixtureInfo.DDEAccess',
                permissions,
              ) && (
                <IconButton
                  target='_blank'
                  href={opsUiUrl}
                  size='small'
                  color='primary'
                >
                  <OpenInNew fontSize='inherit' />
                </IconButton>
              )}
          </Stack>
          {events ? (
            <Stack direction='row' gap={0.35} flexWrap='wrap'>
              {events?.map(
                (event) =>
                  event.visible && (
                    <FixtureDetailsChip
                      key={`event-${event.chipName}`}
                      label={event.chipName}
                      backgroundColor={event.color}
                    />
                  ),
              )}
            </Stack>
          ) : (
            <Stack gap={0.5}>
              <Typography lineHeight={1.1} variant='body1'>
                {firstLine}
              </Typography>
              <Typography lineHeight={1.1} variant='body1'>
                {secondLine}
              </Typography>
            </Stack>
          )}
          {competitionChips && (
            <Stack gap={0.35}>
              {competitionChips.map(
                ({ visible, chipName, color }) =>
                  visible && (
                    <FixtureDetailsChip
                      key={chipName}
                      label={chipName}
                      backgroundColor={color}
                    />
                  ),
              )}
            </Stack>
          )}
        </Box>
      ),
    );
  }, [errorMessage, errorTitle, fixtureInfo, opsUiUrl, permissions]);

  return (
    <Paper sx={{ width: '100%' }} square>
      <Collapse in={isOpen}>
        <Stack
          direction='row'
          justifyContent='stretch'
          divider={<Divider orientation='vertical' flexItem />}
          gap={1}
          px={1}
        >
          {!fixtureId ? DICTIONARY.ERROR.FIXTURE_ID_MISSING : content}
        </Stack>
      </Collapse>
    </Paper>
  );
};
