import { useSnackbar } from 'notistack';
import { Button, CircularProgress } from '@mui/material';
import { orange } from '@mui/material/colors';
import { COMMON_STRING, SUCCESS_STRING } from '@/constants/dictionary';
import {
  ActionOf,
  AddFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { UseDispatchWithResponse } from '@/contexts/scoring/createUseDispatchWithResponse';
import { ScoringState } from '@/contexts/scoring/types';
import { FixtureAction } from '@/service/types';
import { SnackbarCloseActionProps } from './SnackbarCloseAction';

export interface SnackbarActionProps extends SnackbarCloseActionProps {
  playerId?: FixtureAction['playerId'];
  teamId?: FixtureAction['teamId'];
  fixtureId: ScoringState['fixtureId'];
  useDispatchWithResponse: <T>(
    action: ActionOf<T>,
  ) => UseDispatchWithResponse<T>;
}

export const SnackbarPlayerLeft = ({
  playerId,
  teamId,
  snackbarId,
  fixtureId,
  useDispatchWithResponse,
}: SnackbarActionProps) => {
  const { closeSnackbar } = useSnackbar();

  const { dispatch: addAction, isLoading } =
    useDispatchWithResponse<AddFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION,
    );

  const submitAction = async () =>
    addAction(
      {
        fixtureId,
        teamId,
        playerId,
        fixtureActionTypeId: FIXTURE_ACTION_TYPE.PLAYER_RETURNED,
        withLastFixtureActionParams: true,
      },
      {
        successMessage: SUCCESS_STRING.ACTION_SUCCESSFULLY_ADDED,
      },
    )
      .catch(() => closeSnackbar(snackbarId))
      .then(() => closeSnackbar(snackbarId));

  return (
    <Button
      disabled={isLoading}
      variant='text'
      startIcon={
        isLoading && <CircularProgress color={'inherit'} size={'1em'} />
      }
      onClick={submitAction}
      sx={{ color: orange[100] }}
    >
      {COMMON_STRING.PLAYER_RETURNED}
    </Button>
  );
};
