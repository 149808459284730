import { Badge, Box, Button, Tooltip } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { PANEL } from '@/contexts/UIState/types';
import { COMMON_STRING, DICTIONARY } from '@/constants/dictionary';
import { UIStateContext } from '@/contexts/UIState/context';
import { LightDivider } from '@/components/common/LightDivider';
import { ScoringContext } from '@/contexts/scoring/context';
import { MarketsContext } from '@/contexts/markets/context';
import { KeyboardButton } from '@/components/common/KeyboardButton';

export const MarketButton: FC = () => {
  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const { setOpenedPanel } = useContext(UIStateContext);

  const { marketsPerAction, marketsPerActionList } = useContext(MarketsContext);

  const closedMarketsCount = useMemo(
    () =>
      !marketsPerAction
        ? 0
        : Object.values(marketsPerAction).filter(
            (marketPerAction) => !marketPerAction.isMarketOpen,
          ).length,
    [marketsPerAction],
  );

  if (!fixtureId || !marketsPerAction || !marketsPerActionList) return null;

  return (
    <>
      <Box pr={1.5}>
        <Tooltip
          arrow
          title={
            <>
              {DICTIONARY.COMMON.DATA_REVIEW}
              <KeyboardButton theKey='m' />
            </>
          }
        >
          <Badge badgeContent={closedMarketsCount} color='error'>
            <Button
              variant='contained'
              size='small'
              sx={{
                backgroundColor: (theme) => theme.palette.primary.light,
              }}
              onClick={() => setOpenedPanel(PANEL.DATA_REVIEW)}
            >
              {COMMON_STRING.DATA_REVIEW}
            </Button>
          </Badge>
        </Tooltip>
      </Box>

      <LightDivider orientation='vertical' flexItem />
    </>
  );
};
