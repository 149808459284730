import { FC, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { MapMarker } from '@/components/Map/MapMarker';
import envVariables from '@/envVariables';
import { Map } from './Map';

type MapWrapperProps = {
  coordinates: { lng: number; lat: number };
};

export const MapWrapper: FC<MapWrapperProps> = ({ coordinates }) => {
  const currentZoom = 14;
  const centerPosition = {
    lat: coordinates.lat,
    lng: coordinates.lng,
  };
  const [mapRef, setMapRef] = useState();
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
  const markerPosition = {
    lat: coordinates.lat,
    lng: coordinates.lng,
  };
  const [map, setMap] = useState<google.maps.Map>();

  if (mapRef && !map) {
    setMap(
      new google.maps.Map(mapRef, {
        zoom: currentZoom,
        center: { lat: coordinates.lat, lng: coordinates.lng },
        zoomControl: true,
        gestureHandling: 'cooperative',
      }),
    );
  }

  if (map && !geocoder) {
    setGeocoder(new google.maps.Geocoder());
  }

  return (
    <Wrapper
      apiKey={envVariables.googleMapsKey}
      libraries={['places']}
      region='GB'
      language='en-GB'
    >
      <Map
        center={centerPosition}
        map={map}
        setMapRef={setMapRef}
        zoom={currentZoom}
      >
        {markerPosition && <MapMarker position={markerPosition} />}
      </Map>
    </Wrapper>
  );
};
