import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  generateQuarterButtons,
  generateStatsTypeButtons,
  generateTeamButtons,
  generateTypeButtons,
} from '@/contexts/stats/utils';
import { getSport } from '@/service/utils/getSport';
import { StatsValues, Stats, StatsButtonType, BUTTON_TYPES } from './types';
import { StatsContext } from './context';

export const StatsProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    state: { fixtureSummary },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const sportId = getSport(fixtureConfig)?.id as number;

  const [statsTypeButtons, setStatsTypeButtons] = useState<StatsButtonType[]>();
  const [quarterButtons, setQuarterButtons] = useState<StatsButtonType[]>();
  const [typeButtons, setTypeButtons] = useState<StatsButtonType[]>();
  const [teamButtons, setTeamButtons] = useState<StatsButtonType[]>(
    generateTeamButtons({
      homeTeam: {
        id: fixtureSummary?.homeTeam.id,
        name: fixtureSummary?.homeTeam.teamName,
      },
      awayTeam: {
        id: fixtureSummary?.awayTeam.id,
        name: fixtureSummary?.awayTeam.teamName,
      },
    }),
  );

  const activeQuarterButton: StatsValues['activeButtons']['quarter'] = useMemo(
    () => quarterButtons?.find((quarter) => quarter.isActive)?.value,
    [quarterButtons],
  );
  const activeTypeButton: StatsValues['activeButtons']['type'] = useMemo(
    () => typeButtons?.find((type) => type.isActive)?.value,
    [typeButtons],
  );
  const activeTeamButton: StatsValues['activeButtons']['team'] = useMemo(
    () => teamButtons.find((team) => team.isActive)?.id,
    [teamButtons],
  );
  const activeStatsTypeButton: StatsValues['activeButtons']['statsType'] =
    useMemo(
      () => statsTypeButtons?.find((statType) => statType.isActive)?.value,
      [statsTypeButtons],
    );

  useEffect(
    () => {
      setQuarterButtons(
        generateQuarterButtons(fixtureSummary?.periods || [], sportId),
      );
      setTypeButtons(generateTypeButtons(sportId));
      setTeamButtons(
        generateTeamButtons({
          homeTeam: {
            id: fixtureSummary?.homeTeam.id,
            name: fixtureSummary?.homeTeam.teamName,
          },
          awayTeam: {
            id: fixtureSummary?.awayTeam.id,
            name: fixtureSummary?.awayTeam.teamName,
          },
        }),
      );
      setStatsTypeButtons(generateStatsTypeButtons(sportId));
    },
    // These dependencies are important. If they update, check to see if the filters are not updated after the new action.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fixtureSummary?.periods.length,
      sportId,
      fixtureSummary?.homeTeam.id,
      fixtureSummary?.awayTeam.id,
    ],
  );

  const changeActiveButton = (
    actionButton: StatsButtonType,
    buttonType: BUTTON_TYPES,
  ) => {
    if (actionButton.isActive) return;
    const createNewButtonsArray = (buttons: StatsButtonType[]) => {
      return buttons.map((button) =>
        actionButton.value === button.value
          ? { ...button, isActive: true }
          : { ...button, isActive: false },
      );
    };
    switch (buttonType) {
      case BUTTON_TYPES.QUARTER:
        return quarterButtons
          ? setQuarterButtons(createNewButtonsArray(quarterButtons))
          : null;
      case BUTTON_TYPES.TYPE:
        return typeButtons
          ? setTypeButtons(createNewButtonsArray(typeButtons))
          : null;
      case BUTTON_TYPES.TEAM:
        return setTeamButtons(createNewButtonsArray(teamButtons));
      case BUTTON_TYPES.STATS_TYPE:
        return statsTypeButtons
          ? setStatsTypeButtons(createNewButtonsArray(statsTypeButtons))
          : null;
    }
  };

  const providerValue: Stats = {
    quarterButtons: quarterButtons as StatsButtonType[],
    statsTypeButtons: statsTypeButtons,
    typeButtons: typeButtons as StatsButtonType[],
    teamButtons,
    activeButtons: {
      quarter: activeQuarterButton,
      team: activeTeamButton,
      type: activeTypeButton,
      statsType: activeStatsTypeButton,
    },
    changeActiveButton,
  };

  return (
    <StatsContext.Provider value={providerValue}>
      {children}
    </StatsContext.Provider>
  );
};
