import { Typography, Stack, TextField, Autocomplete } from '@mui/material';
import { useContext, useMemo, FC, useEffect } from 'react';
import EastIcon from '@mui/icons-material/East';
import { ScoringContext } from '@/contexts/scoring/context';
import { UNKNOWN_PLAYER_ID } from '@/constants';
import { Player } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { getPlayerNameAndNumber } from '../FixtureTabs/helpers/getPlayerNameAndNumber';
import { generatePlayersForOptions } from './utils';
import { EditActionPlayerOptions, EditActionSelectProps } from './types';

export const PlayersSelect: FC<EditActionSelectProps> = ({
  editedAction,
  onChange,
  action,
  fixtureConfig,
  playersIdsToDisableInOptions,
}) => {
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);

  const defaultPlayerName = useMemo(
    () =>
      getPlayerNameAndNumber(action.playerId, fixtureConfig, fixtureSummary) ||
      'Unknown',
    [action, fixtureConfig, fixtureSummary],
  );

  useEffect(() => {
    if (action.teamId !== editedAction.teamId) {
      onChange({
        player: null,
      });
    } else {
      onChange({
        player: playersForOptions.players.find(
          (player) => player.id === action.playerId,
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedAction.teamId, action.teamId, action.playerId, onChange]);

  const playersForOptions = useMemo(
    () =>
      generatePlayersForOptions({
        editedAction,
        fixtureSummary,
        action,
      }) as EditActionPlayerOptions,
    [editedAction, action, fixtureSummary],
  );

  const generatePlayerLabel = (
    id: Player['id'],
    fullName: Player['fullName'],
    shirtNumber: Player['shirtNumber'],
  ) =>
    id !== UNKNOWN_PLAYER_ID && shirtNumber
      ? `${shirtNumber}. ${fullName}`.toLowerCase()
      : fullName.toLowerCase();

  return (
    <>
      <Typography variant='subtitle2'>{COMMON_STRING.PLAYER}</Typography>
      <Stack direction='row' alignItems='center' gap={2}>
        <Stack flexDirection='row' flex='3 0 0'>
          <Typography textTransform='capitalize'>
            {defaultPlayerName.toLowerCase()}
          </Typography>
        </Stack>

        <EastIcon
          sx={{
            fontSize: '1.8rem',
            color: (theme) => theme.palette.iconsColors.borderColor,
          }}
        />

        <Stack flex='5 0 0 '>
          <Autocomplete
            size='small'
            value={editedAction.player}
            options={playersForOptions.players}
            getOptionDisabled={(option) =>
              playersIdsToDisableInOptions?.some(
                (playerId) => playerId === option.id,
              ) ?? false
            }
            groupBy={(option) =>
              option.isInCurrentLineup ? `Lineup` : `Substitute`
            }
            getOptionLabel={({ id, fullName, shirtNumber }) =>
              generatePlayerLabel(id, fullName, shirtNumber)
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) =>
              onChange({
                player: newValue,
              })
            }
            renderOption={(props, { id, fullName, shirtNumber }) => (
              <li
                {...props}
                style={{ paddingLeft: '1.5em', textTransform: 'capitalize' }}
              >
                {generatePlayerLabel(id, fullName, shirtNumber)}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={COMMON_STRING.PLAYER} />
            )}
            renderGroup={(params) => (
              <Stack key={params.key}>
                <Typography sx={{ pl: '1em', fontWeight: 'bold' }}>
                  {params.group}
                </Typography>
                <Typography
                  sx={{ pl: '1.25em', fontSize: '0.75em', fontWeight: 'bold' }}
                >
                  {playersForOptions.teamName}
                </Typography>
                {params.children}
              </Stack>
            )}
            sx={{ '.MuiAutocomplete-input': { textTransform: 'capitalize' } }}
          />
        </Stack>
      </Stack>
    </>
  );
};
