import { ARIA_LABEL } from '@/constants/ariaLabel';
import { DICTIONARY } from '@/constants/dictionary';
import { MTDialog } from '../common/MTDialog';
import { KeyboardShortcuts } from '../KeyboardShortcuts/KeyboardShortcuts';

interface ShortcutDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

export const ShortcutDialog = ({ onClose, open }: ShortcutDialogProps) => {
  return (
    <MTDialog
      aria-label={ARIA_LABEL.SHORTCUT_DIALOG}
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={onClose}
      title={DICTIONARY.COMMON.KEYBOARD_SHORTCUTS}
    >
      <KeyboardShortcuts />
    </MTDialog>
  );
};
