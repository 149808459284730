export const getValueFromKey = <T>(
  value: keyof T,
  obj: T
): T[keyof T] | undefined => {
  return obj[value];
};

export const getKeyFromValue = <T>(
  value: T[keyof T],
  obj: T
): keyof T | undefined => {
  for (const key in obj) {
    if (
      obj[key]?.toString().toLowerCase() === value?.toString().toLowerCase()
    ) {
      return key;
    }
  }
  return undefined;
};
