import { StackProps, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { PreferencesTabSectionItem } from './types';

interface PreferencesTabSectionProps extends StackProps {
  tabSection: PreferencesTabSectionItem;
}

export const PreferencesTabSection: FC<PreferencesTabSectionProps> = ({
  tabSection,
  children,
}) => {
  return (
    <Stack gap={1}>
      <Typography variant='caption' marginLeft={1.5}>
        {tabSection.name}
      </Typography>
      {children}
    </Stack>
  );
};
