import { Dialog, DialogContent } from '@mui/material';

import { DialogTitleWithClose } from '@/components/common/DialogTitleWithClose';
import { Preferences } from '@/components/Preferences/Preferences';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

interface PreferencesDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

export const PreferencesDialog = ({
  open,
  onClose,
}: PreferencesDialogProps) => {
  return (
    <PermissionsChecker name='PreferencesPage'>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        PaperProps={{
          sx: {
            height: '100%',
          },
          elevation: 5,
        }}
      >
        <DialogTitleWithClose close={onClose}>Preferences</DialogTitleWithClose>

        <DialogContent dividers sx={{ display: 'flex', padding: 0 }}>
          <Preferences />
        </DialogContent>
      </Dialog>
    </PermissionsChecker>
  );
};
