import { Typography } from '@mui/material';
import { THEME_MODE } from '@/contexts/theme/constants';

interface InfoMessageProps {
  message: string;
}

export const InfoMessage = ({ message }: InfoMessageProps) => {
  return (
    <Typography
      variant='h5'
      textAlign='center'
      m={2}
      sx={{
        color: (theme) =>
          theme.palette.mode === THEME_MODE.LIGHT
            ? theme.palette.common.black
            : theme.palette.common.white,
      }}
    >
      {message}
    </Typography>
  );
};
