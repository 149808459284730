import { ToggleButtonProps, alpha } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { ColoredToggleButton } from '@/components/common/ColoredButton';

export type ActionSoundFilterButtonProps = ToggleButtonProps & {
  soundColor: string;
};

export const ActionSoundFilterButton: FC<ActionSoundFilterButtonProps> = ({
  value,
  soundColor,
  children,
  ...restProps
}) => {
  /**
   * This is intentionally a style property instead of sx.
   * Sounds can have different colors in each session,
   * So we can't generate styles upfront.
   */
  const dynamicStyle = {
    '--color': soundColor,
    '--backgroundColor': alpha(soundColor, 0.16),
    '--backgroundColorHover': alpha(soundColor, 0.24),
  } as CSSProperties;

  return (
    <ColoredToggleButton value={value} style={dynamicStyle} {...restProps}>
      {children}
    </ColoredToggleButton>
  );
};
