import { createContext } from 'react';
import { ScoringContextValues } from './types';

const ScoringContext = createContext<ScoringContextValues>({
  fixtureConfigState: {},
  supportedSportsState: {},
} as ScoringContextValues);
ScoringContext.displayName = 'ScoringContext';

export { ScoringContext };
