import { differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import { useState, useMemo, useEffect, useCallback } from 'react';

interface UseTimerReturnType {
  endTimer: boolean;
  timerDuration: number;
}

export const useTimer = (
  endTimerUtc?: string | null,
  whithinTriggerSecTime?: number,
): UseTimerReturnType => {
  const [timerDuration, setTimerDuration] = useState<number>(0);
  const [endTimer, setEndTimer] = useState<boolean>(false);
  const timeDuration = 1000;

  const endTime = useMemo(
    () => (!endTimerUtc ? undefined : new Date(endTimerUtc)),
    [endTimerUtc],
  );

  const updateTimer = useCallback(() => {
    if (!endTime || !whithinTriggerSecTime) {
      return;
    }
    const currentTime = new Date();
    const millisecondsDifference = differenceInMilliseconds(
      endTime,
      currentTime,
    );

    const secondsDifference = differenceInSeconds(endTime, currentTime);
    if (millisecondsDifference > whithinTriggerSecTime * timeDuration) {
      setTimerDuration(0);
      setEndTimer(false);
    } else if (
      millisecondsDifference <= whithinTriggerSecTime * timeDuration &&
      millisecondsDifference > 0
    ) {
      setEndTimer(false);
      setTimerDuration(secondsDifference);
    } else {
      setEndTimer(true);
      setTimerDuration(0);
    }
  }, [endTime, whithinTriggerSecTime]);

  useEffect(() => {
    if (!endTimerUtc) {
      setTimerDuration(0);
      return;
    }
    updateTimer();
    const intervalId = setInterval(updateTimer, timeDuration);
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimerUtc, updateTimer]);

  return { endTimer, timerDuration };
};
