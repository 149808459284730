import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useContext } from 'react';
import { StatsContext } from '@/contexts/stats/context';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';

interface StatsTypeSelectProps {
  fullWidth?: boolean;
}

export const StatsTypeSelect = ({ fullWidth = true }: StatsTypeSelectProps) => {
  const {
    statsTypeButtons,
    changeActiveButton,
    activeButtons: { statsType },
  } = useContext(StatsContext);
  const onChangeHandler = (event: SelectChangeEvent<StatsButtonType>) => {
    const selectedStatsType = statsTypeButtons?.find(
      (statsTypeButton) => event.target.value === statsTypeButton.value,
    ) as StatsButtonType;
    changeActiveButton(selectedStatsType, BUTTON_TYPES.STATS_TYPE);
  };
  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{ minWidth: (theme) => theme.spacing(31.5) }}
    >
      <InputLabel>Stats Type</InputLabel>
      <Select
        value={statsType as StatsButtonType | undefined}
        label='Stats Type'
        onChange={onChangeHandler}
        size='small'
        SelectDisplayProps={{
          style: {
            height: '1em',
            minHeight: '1em',
            fontSize: '1em',
          },
        }}
      >
        {statsTypeButtons?.map((statsButton) => (
          <MenuItem value={statsButton.value} key={statsButton.id}>
            {statsButton.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
