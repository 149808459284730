import { Box, Stack } from '@mui/material';
import { useContext, useEffect, useReducer } from 'react';
import {
  MATCH_ACTION_SEND_TYPE,
  FIXTURE_ACTION_TYPE,
  SPORT_ID,
} from '@/service/constants';
import { isPlayerUnknown } from '@/service/utils/actionConditions';
import {
  BasketNotUpdatedActions,
  getBasketActionStats,
} from '@/service/utils/getBasketActionStats';
import { ScoringContext } from '@/contexts/scoring/context';
import { getSport } from '@/service/utils/getSport';
import {
  HockeytNotUpdatedActions,
  getHockeyActionStats,
} from '@/service/utils/getHockeyActionStats';
import { COMMON_STRING } from '@/constants/dictionary';
import {
  findDeleteAction,
  isActionDeleted,
  isActionUpdated,
} from '../ActionsTable/utils';
import { StatsStack, NotUpdatedActionsRow } from './StyledStatsComponents';
import {
  basketStatsIconItems,
  getInitialState,
  hockeyStatsIconItems,
} from './constants';

interface Action {
  type: 'updateActions';
  payload: BasketNotUpdatedActions | HockeytNotUpdatedActions;
}

export const NotUpdatedActionStats = () => {
  const {
    state: { fixtureActions, deletedActionIds },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const sport = getSport(fixtureConfig);
  const isHockey = sport && sport.id === SPORT_ID.ICE_HOCKEY;
  const isBasket = sport && sport.id === SPORT_ID.BASKETBALL;

  const initialState = getInitialState(isBasket);

  const reducer = (
    state: BasketNotUpdatedActions | HockeytNotUpdatedActions,
    action: Action,
  ) => {
    switch (action.type) {
      case 'updateActions':
        return action.payload;
      default:
        return state;
    }
  };

  const [notUpdatedActions, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!fixtureActions) return;

    const updatedActions = fixtureActions.actions.reduce<
      BasketNotUpdatedActions | HockeytNotUpdatedActions
    >((acc, action) => {
      const isUnknownPlayer = isPlayerUnknown(action.playerId);
      const isUpdated = isActionUpdated(action, fixtureActions.actions);
      const deleteAction = findDeleteAction(deletedActionIds, action);
      const isDeletedAction = isActionDeleted(action, deleteAction);

      const shouldAddUknownPlayer =
        isUnknownPlayer &&
        !isUpdated &&
        !isDeletedAction &&
        ![
          MATCH_ACTION_SEND_TYPE.DELETED,
          MATCH_ACTION_SEND_TYPE.PENDING,
          MATCH_ACTION_SEND_TYPE.CANCELLED,
        ].includes(action.sendTypeId);

      const shouldAddActions =
        !isUpdated &&
        !isDeletedAction &&
        ![
          MATCH_ACTION_SEND_TYPE.DELETED,
          MATCH_ACTION_SEND_TYPE.CANCELLED,
        ].includes(action.sendTypeId);

      if (shouldAddActions) {
        if (
          [
            FIXTURE_ACTION_TYPE.POSSIBLE_ASSIST,
            FIXTURE_ACTION_TYPE.POSSIBLE_ASSIST_ICE_HOCKEY,
          ].includes(action.fixtureActionTypeId)
        ) {
          acc.possibleAssists.push(action);
        } else if (
          isBasket &&
          action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.POSSIBLE_REBOUND
        ) {
          (acc as BasketNotUpdatedActions).possibleRebounds.push(action);
        } else if (
          isHockey &&
          action.fixtureActionTypeId ===
            FIXTURE_ACTION_TYPE.POSSIBLE_GOALKEEPER_SAVES
        ) {
          (acc as HockeytNotUpdatedActions).possibleGoalkeeperSaves.push(
            action,
          );
        }
      }

      if (shouldAddUknownPlayer) {
        acc.unknownPlayers.push(action);
      }

      return acc;
    }, initialState);

    dispatch({ type: 'updateActions', payload: updatedActions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureActions?.actions]);

  const notUpdatetedActionStats = isBasket
    ? getBasketActionStats(notUpdatedActions as BasketNotUpdatedActions)
    : getHockeyActionStats(notUpdatedActions as HockeytNotUpdatedActions);

  return (
    <Stack
      px={1}
      fontSize='0.85em'
      alignItems='end'
      flexDirection='row'
      overflow='auto'
    >
      <StatsStack>{COMMON_STRING.ACTION}</StatsStack>

      <Box display='flex'>
        {notUpdatetedActionStats?.map(({ name, actionValue }) => {
          return (
            <NotUpdatedActionsRow
              key={name}
              name={name}
              actionValue={actionValue}
              icon={
                isBasket
                  ? basketStatsIconItems(name)
                  : hockeyStatsIconItems(name)
              }
            />
          );
        })}
      </Box>
    </Stack>
  );
};
