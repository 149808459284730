import { Dispatch, SetStateAction, useContext } from 'react';
import { Button, Stack, useTheme } from '@mui/material';
import { FixtureScorerRating } from '@/service/types';
import { API_METHOD } from '@/service/constants';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useScoringRate } from '@/service/hooks/useScoringRate';
import {
  calculateOverallScore,
  compareRatings,
} from '@/components/ComparisonRating/utils';
import { ScoringContext } from '@/contexts/scoring/context';
import { COMMON_STRING } from '@/constants/dictionary';

interface ComparisonRatingButtonsProps {
  rating: FixtureScorerRating | undefined;
  newRating: FixtureScorerRating | undefined;
  setNewRating: Dispatch<SetStateAction<FixtureScorerRating | undefined>>;
}

export const ComparisonRatingButtons = ({
  rating,
  newRating,
  setNewRating,
}: ComparisonRatingButtonsProps) => {
  const token = useAuthToken();
  const theme = useTheme();
  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const { submitChanges } = useScoringRate({ token, fixtureId });

  const hasChanges = !!(rating && newRating)
    ? compareRatings(rating, newRating)
    : false;

  return (
    <Stack
      padding={theme.spacing(2, 3)}
      flexDirection='row'
      justifyContent={!!rating ? 'space-between' : 'end'}
    >
      {!!(rating && newRating) && (
        <Button
          aria-hidden={!newRating}
          variant='text'
          onClick={() => submitChanges(newRating, rating, API_METHOD.DELETE)}
        >
          {COMMON_STRING.REMOVE}
        </Button>
      )}

      <Stack flexDirection='row' gap={2}>
        <Button
          variant='contained'
          disabled={hasChanges}
          onClick={() =>
            !!(!rating && newRating)
              ? submitChanges(newRating, [], API_METHOD.POST)
              : !!(rating && newRating)
              ? submitChanges(newRating, rating, API_METHOD.PUT)
              : null
          }
        >
          {!!rating ? COMMON_STRING.SAVE : COMMON_STRING.ADD}
        </Button>
        <Button
          variant='outlined'
          disabled={hasChanges}
          onClick={() =>
            !!rating
              ? setNewRating(calculateOverallScore(rating))
              : setNewRating(undefined)
          }
        >
          {COMMON_STRING.CANCEL}
        </Button>
      </Stack>
    </Stack>
  );
};
