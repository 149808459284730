import { FC, useContext, PropsWithChildren, ReactElement } from 'react';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { COMMON_STRING } from '@/constants/dictionary';
import { InfoMessage } from '../common/InfoMessage';
import { hasPermissionsToElement } from './utils';

interface PermissionsCheckerProps {
  name: string;
  withMessage?: boolean;
  accessDeniedComponent?: ReactElement;
  additionalAccessRule?: boolean;
}

export const PermissionsChecker: FC<
  PropsWithChildren<PermissionsCheckerProps>
> = ({
  name,
  accessDeniedComponent,
  withMessage,
  additionalAccessRule,
  children,
}) => {
  const { permissions } = useContext(RolePermissionsContext);

  const allowAdditionalAccessRule =
    additionalAccessRule !== undefined ? additionalAccessRule : true;

  if (hasPermissionsToElement(name, permissions) && allowAdditionalAccessRule) {
    return <>{children}</>;
  }

  if (accessDeniedComponent) {
    return <>{accessDeniedComponent}</>;
  }

  if (withMessage) {
    return <InfoMessage message={COMMON_STRING.ACCESS_DENIED_TEXT} />;
  }

  return null;
};
