import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { ScoringContext } from '@/contexts/scoring/context';
import { useSlaBreachRules } from '@/service/hooks/useSlaBreachRules';
import {
  DeleteActionLatencyEvaluation,
  SCORING_WORKER_HOST_ACTION,
  SetActionLatencyEvaluationMsg,
} from '@/workers/scoring/types';
import { useSlaBreachActionEvaluation } from '@/service/hooks/useSlaBreachActionEvaluation';
import { MTDialog } from '../common/MTDialog';
import { ActionDialogProps } from '../ActionsDialog/types';
import { SemiBoldTypography } from '../common/Semiboldtypography';
import {
  convertTimeTicks,
  convertToTicks,
  generateEvaluationSubTitle,
  getSlaEvaluationSelect,
} from './utils';
import { MISTAKE_RULE } from './constants';
import { EvaluationSelect } from './common/EvaluationSelect';
import { EvaluationCommentField } from './common/EvaluationCommentField';
import { LatencyCounter } from './common/LatencyCounter';
import { EvaluationButtons } from './common/EvaluationButtons';

export interface LatencyTime {
  minutes: number;
  seconds: number;
}

export const ActionEvaluationDialog = ({
  action,
  onClose,
}: ActionDialogProps) => {
  const {
    state: { fixtureId },
    fixtureConfigState: { fixtureConfig },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { dispatch: setLatencyEvaluation } =
    useDispatchWithResponse<SetActionLatencyEvaluationMsg>(
      SCORING_WORKER_HOST_ACTION.SET_ACTION_LATENCY_EVALUATION,
    );

  const { dispatch: deleteActionEvaluation } =
    useDispatchWithResponse<DeleteActionLatencyEvaluation>(
      SCORING_WORKER_HOST_ACTION.DELETE_LATENCY_EVALUATION,
    );

  const { mutate } = useSlaBreachActionEvaluation({
    fixtureId,
  });
  const { slaBreachRules } = useSlaBreachRules();

  const sortedRules = slaBreachRules?.sort((a, b) =>
    a.displayRuleName.localeCompare(b.displayRuleName),
  );
  const [latencyTime, setLatencyTime] = useState<LatencyTime>(
    convertTimeTicks(action?.slaBreachSummary?.latencyTicks),
  );
  const [slaRules, setSlaRules] = useState(sortedRules);
  const [comment, setComment] = useState<string>('');
  const [selectItem, setSelectItem] = useState(
    getSlaEvaluationSelect(action, slaRules),
  );
  const isDisabledSubmit =
    selectItem === MISTAKE_RULE.NONE &&
    latencyTime.minutes === 0 &&
    latencyTime.seconds === 0;

  useEffect(() => {
    setSlaRules(slaBreachRules);
  }, [slaBreachRules]);

  useEffect(() => {
    if (slaBreachRules && !!slaBreachRules.length) {
      const validItem = getSlaEvaluationSelect(action, slaRules);
      setSelectItem(validItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, slaBreachRules]);

  useEffect(() => {
    setComment(action.slaBreachSummary?.evaluationReasonNote || '');
  }, [action.slaBreachSummary?.evaluationReasonNote]);

  const onSubmit = async () =>
    await setLatencyEvaluation({
      fixtureId: fixtureId,
      id: action.id,
      evaluationRuleId: 9,
      latencyTicks: convertToTicks(latencyTime),
      actionTimestamp: action.timestamp,
      evaluationReasonNote: selectItem === MISTAKE_RULE.OTHER ? comment : '',
      evaluationReason: selectItem,
    })
      .then(() => mutate())
      .finally(() => onClose());

  const onDelete = async () =>
    await deleteActionEvaluation({
      fixtureId: fixtureId,
      id: action.id,
    })
      .then(() => mutate())
      .finally(() => onClose());

  const onSelectChangeHandler = (event: SelectChangeEvent) => {
    const selectedRuleType = slaRules?.find(
      (item) => event.target.value === item.displayRuleName,
    )?.displayRuleName;
    if (selectedRuleType) {
      setSelectItem(selectedRuleType);
    } else {
      setSelectItem(MISTAKE_RULE.NONE);
    }
  };

  if (!slaBreachRules?.length) {
    return null;
  }

  return (
    <MTDialog
      aria-label={ARIA_LABEL.ACTION_EVALUATION_DIALOG}
      open={true}
      onClose={onClose}
      title={COMMON_STRING.ACTION_EVALUATION}
      maxWidth='xs'
      fullWidth
    >
      <Stack width='100%' gap={1}>
        <Typography variant='subtitle2'>
          {generateEvaluationSubTitle({ action, fixtureConfig })}
        </Typography>
        <SemiBoldTypography>{COMMON_STRING.LATENCY}</SemiBoldTypography>
        <LatencyCounter
          latencyTime={latencyTime}
          changeLatency={setLatencyTime}
        />
        <SemiBoldTypography>{COMMON_STRING.MISTAKE}</SemiBoldTypography>
        <EvaluationSelect
          value={selectItem}
          onChange={onSelectChangeHandler}
          options={slaRules}
        />
        {selectItem === MISTAKE_RULE.OTHER && (
          <EvaluationCommentField addComment={setComment} comment={comment} />
        )}

        <EvaluationButtons
          onClose={onClose}
          onSubmit={onSubmit}
          onDelete={onDelete}
          disabledDelete={!action.slaBreachSummary}
          disabledSubmit={isDisabledSubmit}
        />
      </Stack>
    </MTDialog>
  );
};
