import { Chip } from '@mui/material';
import { FC } from 'react';
import { StaticFixtureActionType } from '@/service/types';
import { ARIA_LABEL } from '@/constants/ariaLabel';

type ActionTypeFilterChipProps = {
  actionType: StaticFixtureActionType;
  isSelected: boolean;
  onClick: (actionType: StaticFixtureActionType, selected: boolean) => void;
};

export const ActionTypeFilterChip: FC<ActionTypeFilterChipProps> = ({
  actionType,
  isSelected,
  onClick,
}) => {
  return (
    <Chip
      aria-label={ARIA_LABEL.ACTION_TYPE_FILTER_CHIP}
      sx={{
        width: 'fit-content',
        borderRadius: 1.5,
        height: 24,
      }}
      onClick={() => onClick(actionType, !isSelected)}
      label={actionType.name}
      variant={isSelected ? 'outlined' : 'filled'}
      color={isSelected ? 'primary' : 'default'}
    />
  );
};
