import { styled } from '@mui/material/styles';
import { TableCell, TableRow } from '@mui/material';

export const ScoresheetTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0, 3.5),
  border: `1px solid ${theme.palette.tableBorderColor}`,
  fontWeight: 600,
  textAlign: 'center',
}));

export const ScoresheetTableBodyRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.tableHeaderColor,
  },
}));

export const ScoresheetTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0, 3.5),
  border: `1px solid ${theme.palette.tableBorderColor}`,
}));

export const ScoresheetPlayerTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  border: `1px solid ${theme.palette.tableBorderColor}`,
  textAlign: 'center',
}));

export const ScoresheetMainPlayerTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(0.25),
  padding: theme.spacing(0, 0, 0, 0.5),
  border: `1px solid ${theme.palette.tableBorderColor}`,
}));
