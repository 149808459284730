import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import {
  CollectionStatusId,
  CoverageLevelId,
  FixtureStatusId,
} from '@/service/constants';
import {
  COLLECTION_STATUS_OPTIONS,
  COVERAGE_LEVEL_OPTIONS,
  FIXTURE_STATUS_OPTIONS,
} from '@/components/StatusesEditDialog/constants';
import { StatusReason } from '@/service/types';
import { ARIA_LABEL } from '@/constants/ariaLabel';

interface StatusChangeInfoProps {
  shortStatusTypeName: string;
  newStatusName: string;
}

interface ActionTableStatusesChangeInfoProps {
  fixtureStatusId: FixtureStatusId;
  fixtureStatusReasonCode: StatusReason['code'];
  collectionStatusId: CollectionStatusId;
  collectionStatusReasonCode: StatusReason['code'];
  coverageLevelId: CoverageLevelId;
  coverageLevelReasonCode: StatusReason['code'];
}

const StatusChangeTypography = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: 1.35,
}));

const StatusChangeInfo = ({
  shortStatusTypeName,
  newStatusName,
}: StatusChangeInfoProps) => {
  return (
    <Stack
      flexDirection='row'
      lineHeight={1.35}
      gap={0.5}
      aria-label={ARIA_LABEL.STATUS_CHANGE_INFO_WRAPPER}
    >
      {shortStatusTypeName}
      <StatusChangeTypography>{'>'}</StatusChangeTypography>
      <StatusChangeTypography>{newStatusName}</StatusChangeTypography>
    </Stack>
  );
};

export const StatusesChangeInfo = ({
  fixtureStatusId,
  fixtureStatusReasonCode,
  collectionStatusId,
  collectionStatusReasonCode,
  coverageLevelId,
  coverageLevelReasonCode,
}: ActionTableStatusesChangeInfoProps) => {
  return (
    <Stack
      lineHeight={1.35}
      fontSize='0.75rem'
      fontWeight='bold'
      flexDirection='column'
      gap={0.5}
      mt={1}
    >
      {fixtureStatusReasonCode !== null && (
        <StatusChangeInfo
          shortStatusTypeName='FS'
          newStatusName={FIXTURE_STATUS_OPTIONS[fixtureStatusId]?.name}
        />
      )}
      {collectionStatusReasonCode !== null && (
        <StatusChangeInfo
          shortStatusTypeName='CS'
          newStatusName={COLLECTION_STATUS_OPTIONS[collectionStatusId]?.name}
        />
      )}
      {coverageLevelReasonCode !== null && (
        <StatusChangeInfo
          shortStatusTypeName='CL'
          newStatusName={COVERAGE_LEVEL_OPTIONS[coverageLevelId]?.name}
        />
      )}
    </Stack>
  );
};
