import { FC } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { ChecklistWarning } from '@/contexts/checklist/types';

export interface CheckboxWarningProps {
  warnings: ChecklistWarning[];
}

export const CheckboxWarning: FC<CheckboxWarningProps> = ({ warnings }) => {
  if (warnings.length === 0) return <></>;
  const warningContent = (
    <Box>
      {warnings.map(({ name, message }) => (
        <Typography variant='body2' key={name}>
          {message}
        </Typography>
      ))}
    </Box>
  );
  return (
    <Tooltip title={warningContent} arrow>
      <WarningAmber color='error' />
    </Tooltip>
  );
};
