import { useContext } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { CircularProgress, useTheme } from '@mui/material';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SimpleIndicator,
  indicatorSize,
  indicatorSpacing,
} from './SimpleIndicator';

export const WSIndicator = () => {
  const {
    state: { wsConnection },
  } = useContext(ScoringContext);

  const theme = useTheme();
  const isConnected = wsConnection === HubConnectionState.Connected;
  const isSimpleIndicator =
    isConnected || wsConnection === HubConnectionState.Disconnected;

  return (
    <>
      {isSimpleIndicator ? (
        <SimpleIndicator isConnected={isConnected} />
      ) : (
        <CircularProgress
          size={indicatorSize(theme)}
          sx={{ m: indicatorSpacing(theme) }}
        />
      )}
    </>
  );
};
