import { useState } from 'react';
import { TABLE_SORT_ORDER } from '@/constants/dictionary';
import { COLUMNS } from '@/components/ActionsTable/constants';

export type Order = 'asc' | 'desc';

export interface SortBy {
  primary?: string;
  secondary?: string;
}

export const useSort = () => {
  const [sortBy, setSortBy] = useState<SortBy>({
    primary: COLUMNS.SEQ.sortAttr,
    secondary: '',
  });
  const [sortOrder, setSortOrder] = useState<Order>(TABLE_SORT_ORDER.DESC);

  return { sortBy, setSortBy, sortOrder, setSortOrder };
};
