import { Stack, SxProps, Theme, alpha, useTheme } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { THEME_MODE } from '@/contexts/theme/constants';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { PreferencesContext } from '@/contexts/preferences/context';
import { COMMON_STRING } from '@/constants/dictionary';
import { ScoringContext } from '@/contexts/scoring/context';
import { getSport } from '@/service/utils/getSport';
import { ActionsTableContext } from '@/components/ActionsTable/context/ActionsTableContext';
import { TemplateConfirmationDialog } from '../TemplateConfirmationDialog';
import { MTEmoji } from '../Emoji/MTEmoji';
import { TemplateFormData } from '../types';

type TemplateSwitcherItemProps = {
  template: TemplateFormData | undefined;
};

export const TemplateSwitcherItem: FC<TemplateSwitcherItemProps> = ({
  template,
}) => {
  const {
    palette: { grey },
    palette: { mode },
    palette: {
      background: { paper },
    },
  } = useTheme();

  const {
    selectedTemplate,
    selectedTemplateFilters,
    dontShowTemplateChangeWarning,
    actions: { setSelectedTemplate, setDontShowTemplateChangeWarning },
  } = useContext(PreferencesContext);

  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const { filters } = useContext(ActionsTableContext);

  const [showChangingTemplateConfirm, setShowChangingTemplateConfirm] =
    useState(false);

  const sport = getSport(fixtureConfig);
  const isSelected = template?.id === selectedTemplate?.id;

  const onTabClick = () => {
    if (selectedTemplate === template) {
      return;
    }

    if (
      dontShowTemplateChangeWarning ||
      (filters.every((filter) => filter.isFromTemplate) &&
        filters.filter((filter) => filter.isFromTemplate).length ===
          selectedTemplateFilters?.length)
    ) {
      setSelectedTemplate(template, sport?.id);
    } else {
      setShowChangingTemplateConfirm(true);
    }
  };

  const onConfirmTemplateChangeClick = (dontShowAgain: boolean) => {
    setSelectedTemplate(template, sport?.id);
    setShowChangingTemplateConfirm(false);
    if (dontShowAgain) {
      setDontShowTemplateChangeWarning();
    }
  };

  const onConfirmTemplateChangeCancel = () => {
    setShowChangingTemplateConfirm(false);
  };

  const style: SxProps<Theme> | undefined = isSelected
    ? {
        backgroundColor: mode === THEME_MODE.LIGHT ? paper : alpha(paper, 0.5),
        borderTopRightRadius: 8,
        borderTopLeftRadius: template ? 8 : 0,
        borderBottom: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: mode === THEME_MODE.LIGHT ? grey[200] : grey[800],
      }
    : undefined;

  return (
    <>
      <Stack
        aria-label={ARIA_LABEL.TEMPLATE_SWITCHER_ITEM}
        sx={{
          height: 30,
          px: 2,
          cursor: 'pointer',
          userSelect: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          color: mode === THEME_MODE.LIGHT ? grey[600] : grey[400],
          fontSize: 14,
          minWidth: 90,
          ...style,
        }}
        onClick={onTabClick}
      >
        <Stack direction='row'>
          {template?.emoji && <MTEmoji emoji={template.emoji} size={16} />}
          {template?.templateName ?? COMMON_STRING.BASIC_TABLE}
        </Stack>
      </Stack>

      <TemplateConfirmationDialog
        onConfirm={onConfirmTemplateChangeClick}
        onCancel={onConfirmTemplateChangeCancel}
        showDialog={showChangingTemplateConfirm}
        title={COMMON_STRING.CHANGING_TEMPLATE}
        dialogText={COMMON_STRING.CHANGING_TEMPLATE_BODY}
      />
    </>
  );
};
