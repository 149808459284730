import {
  Box,
  StackProps,
  Stack,
  SxProps,
  Tooltip,
  Divider,
  Theme,
  alpha,
  TooltipProps,
} from '@mui/material';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { SOCCER_SUMMARY_STATS_ACTION_NAME } from '@/service/utils/getSoccerSummaryStats';
import { NotUpdatedActionsItem } from '@/service/utils/getBasketActionStats';
import { statsIconItems } from './constants';

export interface StatsCompareItem {
  name: string;
  internalValue: string | number | null;
  externalValue: string | number | null;
}

export interface StatsCompareTableProps {
  reverse?: boolean;
  stats: StatsCompareItem[];
  refreshData?: VoidFunction;
  isUpdating?: boolean;
}

export const WrapperStats: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box display='flex' overflow='auto'>
      {children}
    </Box>
  );
};

export const StatsStack: FC<StackProps> = (props) => (
  <Stack
    alignItems={'center'}
    p={0.5}
    fontWeight={500}
    fontSize='0.85em'
    {...props}
  />
);

const getRowStyle = (hasMismatch: boolean): SxProps<Theme> | undefined =>
  hasMismatch
    ? {
        borderLeft: ({ palette }) =>
          `0.2em solid ${palette.statsCompare.borderColor}`,
        background: ({ palette }) =>
          alpha(palette.statsCompare.backgroundgColor, 0.75),
        ':first-of-type': {
          borderTopLeftRadius: '0.5em',
          borderTopRightRadius: '0.5em',
        },

        ':last-of-type': {
          borderBottomLeftRadius: '0.5em',
          borderBottomRightRadius: '0.5em',
        },
      }
    : undefined;

export const CustomTooltip: FC<TooltipProps> = ({
  children,
  title,
  placement = 'top',
  ...rest
}) => (
  <Tooltip
    title={title}
    arrow
    placement={placement}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -8],
            },
          },
        ],
      },
    }}
    {...rest}
  >
    {children as ReactElement}
  </Tooltip>
);

export const StatsCompareRow: FC<StatsCompareItem> = ({
  name,
  internalValue,
  externalValue,
}) => {
  const hasMismatch =
    name !== SOCCER_SUMMARY_STATS_ACTION_NAME.DANGEROUS_ATTACKS
      ? internalValue !== null &&
        externalValue !== null &&
        internalValue !== externalValue
      : false;

  const internalVal = internalValue ?? COMMON_STRING.NOT_AVAILABLE;
  const externalVal = externalValue ?? COMMON_STRING.NOT_AVAILABLE;
  const mismatchStyle = getRowStyle(hasMismatch);
  const sx: SxProps<Theme> = {
    ...mismatchStyle,
  };

  return (
    <Stack justifyContent={'end'} width={'2.1em'}>
      <CustomTooltip title={name}>
        <Stack alignItems={'center'} p={0.5} fontSize={'1em'} sx={sx}>
          {statsIconItems(name, hasMismatch)}
        </Stack>
      </CustomTooltip>
      <StatsStack alignItems={'center'} sx={sx} aria-label={`${name}-internal`}>
        {internalVal}
      </StatsStack>
      <Divider flexItem />
      <StatsStack sx={sx} aria-label={`${name}-external`}>
        {externalVal}
      </StatsStack>
    </Stack>
  );
};

export const NotUpdatedActionsRow: FC<NotUpdatedActionsItem> = ({
  name,
  actionValue,
  icon,
}) => {
  const notUpdatedActionStyle = getRowStyle(actionValue > 0);
  const sx: SxProps<Theme> = {
    ...notUpdatedActionStyle,
  };

  return (
    <Stack justifyContent={'end'} width={'2.1em'}>
      <CustomTooltip title={name}>
        <Stack alignItems={'center'} p={0.5} fontSize={'1em'} sx={sx}>
          {icon}
        </Stack>
      </CustomTooltip>

      <StatsStack alignItems={'center'} aria-label={`${name}-internal`} sx={sx}>
        {actionValue}
      </StatsStack>
    </Stack>
  );
};
