import {
  Children,
  cloneElement,
  CSSProperties,
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/material';

type MapProps = {
  map?: google.maps.Map;
  children?: ReactNode;
  setMapRef?: any;
  onIdle?: (map: google.maps.Map) => void;
  onMapClick?: (e: google.maps.MapMouseEvent) => void;
  style?: CSSProperties;
} & google.maps.MapOptions;

enum MapEventsEnum {
  IDLE = 'idle',
  CLICK = 'click',
}

export const Map: FC<MapProps> = ({
  onIdle,
  children,
  onMapClick,
  map,
  setMapRef,
  style,
  ...options
}) => {
  const [usedEvents, setUsedEvents] = useState<MapEventsEnum[]>([]);

  useEffect(() => {
    if (map) {
      if (onIdle) {
        map.addListener(MapEventsEnum.IDLE, () => onIdle(map));
        setUsedEvents((event) => [...event, MapEventsEnum.IDLE]);
      }
      if (onMapClick) {
        map.addListener(MapEventsEnum.CLICK, onMapClick);
        setUsedEvents((e) => [...e, MapEventsEnum.CLICK]);
      }
      if (options) {
        map.setOptions(options);
      }
    }

    return () => {
      if (map) {
        usedEvents.forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName)
        );
      }
    };
  }, [map, onMapClick, onIdle, options, usedEvents]);

  return (
    <>
      <Box
        ref={(mapRef) => setMapRef?.(mapRef)}
        id='google-map'
        sx={{ width: '100%', aspectRatio: '16/9' }}
        style={style}
      />

      {Children.map(
        children,
        (child) =>
          child &&
          cloneElement(
            child as ReactElement<any, string | JSXElementConstructor<any>>,
            {
              map,
              onIdle,
            }
          )
      )}
    </>
  );
};
