import { Box, Divider, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { COMMON_STRING } from '@/constants/dictionary';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';

import {
  StatsCompareTableProps,
  CustomTooltip,
  StatsCompareRow,
  StatsStack,
  WrapperStats,
} from './StyledStatsComponents';

export const StatsCompareTable: FC<StatsCompareTableProps> = ({
  reverse,
  stats,
  refreshData,
  isUpdating,
}) => {
  return (
    <PermissionsChecker name='FixturePage.ScoreboardPanel.ExternalStatistics'>
      <WrapperStats>
        <Stack
          margin='auto' // This fixes the justify-content: 'center' bug with overflow
          px={1}
          marginBottom={'0.5em'}
          fontSize='0.85em'
          sx={{
            alignItems: 'end',
            flexDirection: reverse ? 'row-reverse' : 'row',
          }}
        >
          <Stack textAlign={reverse ? 'left' : 'right'}>
            <span>
              <CustomTooltip
                title={COMMON_STRING.REFRESH}
                placement={reverse ? 'top-start' : 'top-end'}
              >
                <IconButton
                  color='primary'
                  onClick={refreshData}
                  disableRipple
                  sx={{ p: 0 }}
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <CircularProgress
                      size='0.85em'
                      color='inherit'
                      sx={{ p: '0.25em' }}
                    />
                  ) : (
                    <RefreshIcon sx={{ fontSize: '0.85em' }} />
                  )}
                </IconButton>
              </CustomTooltip>
            </span>

            <StatsStack>Internal</StatsStack>

            <Divider flexItem />

            <StatsStack>External</StatsStack>
          </Stack>

          <Box display='flex'>
            {stats.map(({ name, internalValue, externalValue }) => {
              return (
                <StatsCompareRow
                  key={name}
                  name={name}
                  internalValue={internalValue}
                  externalValue={externalValue}
                />
              );
            })}
          </Box>
        </Stack>
      </WrapperStats>
    </PermissionsChecker>
  );
};
