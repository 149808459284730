import {
  FixtureAction,
  FixtureConfig,
  FixtureSummary,
  MatchSummaryTeam,
} from '@/service/types';

export const getTeamName = (
  teamId: FixtureAction['teamId'],
  fixtureConfig: FixtureConfig | undefined,
  fixtureSummary: FixtureSummary | null,
) => {
  if (teamId === null) return '';
  const teamNameFromSummary = getTeamFromMatchSummary(teamId, fixtureSummary);
  if (teamNameFromSummary) return teamNameFromSummary;
  if (!fixtureConfig) return '';

  let team =
    fixtureConfig.homeSquad?.team.id === teamId
      ? fixtureConfig.homeSquad.team
      : null;
  if (!team) {
    team =
      fixtureConfig.awaySquad?.team.id === teamId
        ? fixtureConfig.awaySquad.team
        : null;
  }
  if (!team) return '';

  return team.name;
};

export const getShortTeamName = (team: MatchSummaryTeam) => {
  return (
    team?.teamShortCode ??
    team.teamName.substring(0, Math.min(team.teamName.length, 3))
  );
};

const getTeamFromMatchSummary = (
  teamId: FixtureAction['teamId'],
  fixtureSummary: FixtureSummary | null,
) => {
  if (teamId === null || !fixtureSummary) return '';
  let team =
    fixtureSummary.homeTeam.id === teamId ? fixtureSummary.homeTeam : null;
  if (!team) {
    team =
      fixtureSummary.awayTeam.id === teamId ? fixtureSummary.awayTeam : null;
  }
  if (!team) return '';

  return team.teamName;
};
