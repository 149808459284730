import {
  ScoresheetMainPlayerTableCell,
  ScoresheetPlayerTableCell,
  ScoresheetTableBodyRow,
} from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import { RowProps } from '@/components/ScoresheetTables/common/types';
import { COMMON_STRING } from '@/constants/dictionary';

export const CoachRow = ({ teamStatistics }: RowProps) => {
  return (
    <ScoresheetTableBodyRow>
      <ScoresheetMainPlayerTableCell colSpan={2}>
        {COMMON_STRING.COACH}
      </ScoresheetMainPlayerTableCell>
      <ScoresheetPlayerTableCell colSpan={10} />
      <ScoresheetPlayerTableCell>
        {teamStatistics?.offensiveTeamRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.defensiveTeamRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.teamRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell />
      <ScoresheetPlayerTableCell>
        {teamStatistics?.turnovers}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell colSpan={7} />
    </ScoresheetTableBodyRow>
  );
};
