import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext } from 'react';
import { convertPascalCaseTextToSentence } from '@/utils';
import {
  ScoresheetTableBodyRow,
  ScoresheetTableCell,
  ScoresheetTableHeaderCell,
} from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import { POINTS_TABLE_KEYS } from '@/components/ScoresheetTables/PointsTable/constants';
import { ScoresheetTableProps } from '@/components/ScoresheetTables/common/types';
import { StatsContext } from '@/contexts/stats/context';

export const PointsTable = ({ tableData }: ScoresheetTableProps) => {
  const { teamButtons } = useContext(StatsContext);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <ScoresheetTableHeaderCell key={'points-keys-column'} />
            {teamButtons.map((team, index) => (
              <ScoresheetTableHeaderCell key={`points-team-column-${index}`}>
                {team.value}
              </ScoresheetTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {POINTS_TABLE_KEYS.map((key) => (
            <ScoresheetTableBodyRow key={key}>
              <ScoresheetTableCell>
                {convertPascalCaseTextToSentence(key)}
              </ScoresheetTableCell>
              {teamButtons.map((teamButton, index) => (
                <ScoresheetTableCell
                  sx={{
                    textAlign: 'center',
                  }}
                  key={`${index}-${key}`}
                >
                  {tableData
                    ?.find((team) => team.teamId === teamButton.id)
                    ?.statistics[key]?.toString() || ''}
                </ScoresheetTableCell>
              ))}
            </ScoresheetTableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
