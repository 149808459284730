export const COMPARISON_COLUMNS = {
  SOURCE: {
    name: 'Source',
    width: '10%',
  },
  GOAL: {
    name: 'Goal',
    width: '6%',
  },
  GOAL_DISALLOWED: {
    name: 'Goal Disallowed',
    width: '6%',
  },
  YELLOW_CARD: {
    name: 'Yellow Card',
    width: '6%',
  },
  RED_CARD: {
    name: 'Red Card',
    width: '6%',
  },
  ATTACK: {
    name: 'Attack',
    width: '6%',
  },
  DANGEROUS_ATTACK: {
    name: 'Dangerous Attack',
    width: '6%',
  },
  PENALTY_AWARDED: {
    name: 'Penalty Awarded',
    width: '6%',
  },
  PENALTY_MISSED: {
    name: 'Penalty Missed',
    width: '6%',
  },
  FREE_KICK: {
    name: 'Free Kick',
    width: '6%',
  },
  SHOT_ON_TARGET: {
    name: 'Shot On Target',
    width: '6%',
  },
  SHOT_OFF_TARGET: {
    name: 'Shot Off Target',
    width: '6%',
  },
  CORNER: {
    name: 'Corner',
    width: '6%',
  },
  SHOT_BLOCKED: {
    name: 'Shot Blocked',
    width: '6%',
  },
  OFFSIDE: {
    name: 'Offside',
    width: '6%',
  },
  THROW_IN: {
    name: 'Throw In',
    width: '6%',
  },
};

export const COMPARISON_TABLE_CLASS_NAMES = {
  ROW: 'comparison-row',
  CELL: 'comparison-cell',
};
