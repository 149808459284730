import { Divider, Stack } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { PREFERENCES_TABS } from './constants';
import { PreferencesTabName } from './types';
import { PreferencesTabSection } from './PreferencesTabSection';
import { PreferencesTabButton } from './PreferencesTabButton';

export interface PreferencesTabsProps {
  currentTab: PreferencesTabName;
  onChange: (e: SyntheticEvent, value: PreferencesTabName) => void;
}

const a11yProps = (id: string) => {
  return {
    id: `tab-${id}`,
    'aria-controls': `tabpanel-${id}`,
  };
};

export const PreferencesTabs: FC<PreferencesTabsProps> = ({
  onChange,
  currentTab,
}) => {
  const isCurrentTab = (tabName: PreferencesTabName) => tabName === currentTab;
  return (
    <Stack
      sx={{
        gap: 1,
        borderRight: 1,
        borderColor: (theme) => theme.palette.divider,
        paddingY: 1,
        minWidth: 'max-content',
        height: '100%',
      }}
    >
      {PREFERENCES_TABS.map((tabSection, index, array) => (
        <PreferencesTabSection key={tabSection.name} tabSection={tabSection}>
          <Stack paddingX={1}>
            {tabSection.tabs.map((tab) => (
              <PreferencesTabButton
                key={tab.name}
                icon={tab.icon}
                isActive={isCurrentTab(tab.name)}
                onClick={(e) => onChange(e, tab.name)}
                {...a11yProps(tab.ariaName)}
              >
                {tab.name}
              </PreferencesTabButton>
            ))}
          </Stack>
          {array.length - 1 !== index && <Divider flexItem />}
        </PreferencesTabSection>
      ))}
    </Stack>
  );
};
