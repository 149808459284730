import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { TemplateFormData } from '@/components/ActionsTable/ActionFilters/Template/types';
import { ActionFilter } from '@/components/ActionsTable/ActionFilters/useActionsFilters';
import { convertTemplateToFilters } from '@/components/ActionsTable/ActionFilters/utils';
import { usePreferencesApi } from '@/components/Preferences/usePreferencesApi';
import { API_METHOD } from '@/service/constants';
import { useFiltersOptionsApi } from '@/components/Preferences/useFiltersOptionsApi';
import { getSport } from '@/service/utils/getSport';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { useAuthToken } from '../auth/useAuthToken';
import { PREFERENCE_TYPE, SportTemplate } from './types';
import {
  getOtherSportsTemplates,
  getOtherTemplates,
  getSportTemplate,
} from './utils';

export const useTemplatePreferences = () => {
  const token = useAuthToken();
  const { data, error, updatePreferences } = usePreferencesApi({ token });
  const { sports, actionTypes, sendTypes } = useFiltersOptionsApi({ token });
  const { fixtureId } = useParams<{ fixtureId: string }>();
  const { fixtureConfig } = useFixtureConfig({ token, fixtureId });

  const dontShowFilterTemplateApplyWarning =
    !!data?.templatesPreferences?.dontShowFilterTemplateApplyWarning;

  const dontShowTemplateChangeWarning =
    !!data?.templatesPreferences?.dontShowTemplateChangeWarning;

  const [templates, setTemplates] = useState<SportTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateFormData | undefined
  >();
  const [selectedTemplateFilters, setSelectedTemplateFilters] =
    useState<ActionFilter[]>();

  useEffect(() => {
    setTemplates(data?.templatesPreferences?.sportTemplates ?? []);
  }, [data]);

  useEffect(() => {
    const sportTemplate = getSportTemplate(data, getSport(fixtureConfig)?.id);
    const template = sportTemplate?.templateFilters?.find(
      (template) => template.id === sportTemplate.selectedTemplateId,
    );

    setSelectedTemplate(template);
  }, [data, fixtureConfig]);

  useEffect(() => {
    setSelectedTemplateFilters(
      convertTemplateToFilters(selectedTemplate, fixtureConfig),
    );
  }, [fixtureConfig, selectedTemplate]);

  const setDontShowFilterTemplateApplyWarning = () => {
    if (!data) {
      return;
    }

    const preferences = {
      ...data,
      templatesPreferences: {
        sportTemplates: [...(data?.templatesPreferences?.sportTemplates ?? [])],
        dontShowFilterTemplateApplyWarning: true,
      },
    };

    updatePreferences(preferences, API_METHOD.POST);
  };

  const setDontShowTemplateChangeWarning = () => {
    if (!data) {
      return;
    }

    const preferences = {
      ...data,
      templatesPreferences: {
        ...data.templatesPreferences,
        sportTemplates: [...(data?.templatesPreferences?.sportTemplates ?? [])],
        dontShowTemplateChangeWarning: true,
      },
    };

    updatePreferences(preferences, API_METHOD.POST);
  };

  const updateTemplate = (
    template: TemplateFormData,
    apiMethod: API_METHOD.POST | API_METHOD.DELETE,
  ) => {
    let templatesRest = getOtherSportsTemplates(data, template.sportId);
    const templatesForCurrentSport = getSportTemplate(data, template.sportId);

    let currentSportTemplate = {
      ...templatesForCurrentSport,
      sportId: template.sportId,
      templateFilters: getOtherTemplates(data, template.sportId, template.id),
    };

    if (API_METHOD.POST) {
      if (currentSportTemplate.templateFilters) {
        currentSportTemplate.templateFilters.push(template);
      } else {
        currentSportTemplate = {
          sportId: template.sportId,
          templateFilters: [template],
        };
      }
    }

    const preferences = {
      ...data,
      templatesPreferences: {
        ...data?.templatesPreferences,
        sportTemplates: [...templatesRest, currentSportTemplate],
      },
    };

    updatePreferences(preferences, apiMethod, PREFERENCE_TYPE.FILTER_TEMPLATE);
  };

  const deleteTemplate = (templateId: string, sportId: number) => {
    let templatesRest = getOtherSportsTemplates(data, sportId);
    const templatesForCurrentSport = getSportTemplate(data, sportId);

    let currentSportTemplate = {
      ...templatesForCurrentSport,
      sportId: sportId,
      templateFilters: getOtherTemplates(data, sportId, templateId),
    };

    const preferences = {
      ...data,
      templatesPreferences: {
        ...data?.templatesPreferences,
        sportTemplates: [...templatesRest, currentSportTemplate],
      },
    };

    updatePreferences(
      preferences,
      API_METHOD.DELETE,
      PREFERENCE_TYPE.FILTER_TEMPLATE,
    );
  };

  const onTemplateSelect = (
    template: TemplateFormData | undefined,
    sportId: number | undefined,
  ) => {
    if (!data) {
      return;
    }

    const currentSportTemplates = getSportTemplate(data, sportId);
    const otherSportsTemplates = getOtherSportsTemplates(data, sportId);

    if (currentSportTemplates) {
      currentSportTemplates.selectedTemplateId = template?.id;
    } else {
      return;
    }

    const preferences = {
      ...data,
      templatesPreferences: {
        ...data?.templatesPreferences,
        sportTemplates: [...otherSportsTemplates, currentSportTemplates],
      },
    };

    updatePreferences(preferences, API_METHOD.POST);
  };

  const reorderTemplates = async (newOrderTemplates: TemplateFormData[]) => {
    if (!newOrderTemplates.length) {
      return;
    }

    const sportId = newOrderTemplates[0].sportId;
    let templatesRest = getOtherSportsTemplates(
      data,
      newOrderTemplates[0].sportId,
    );

    const currentSportTemplates = getSportTemplate(data, sportId);

    const preferences = {
      ...data,
      templatesPreferences: {
        ...data?.templatesPreferences,
        sportTemplates: [
          ...templatesRest,
          {
            ...currentSportTemplates,
            templateFilters: newOrderTemplates,
          },
        ],
      },
    };

    updatePreferences(preferences, API_METHOD.POST);
  };

  return {
    error,
    isLoading: !data && !error,
    templates,
    selectedTemplate,
    selectedTemplateFilters,
    dontShowFilterTemplateApplyWarning,
    dontShowTemplateChangeWarning,
    setSelectedTemplate: onTemplateSelect,
    updateTemplate,
    setDontShowFilterTemplateApplyWarning,
    setDontShowTemplateChangeWarning,
    deleteTemplate,
    reorderTemplates,
    sports,
    actionTypes,
    sendTypes,
  };
};
