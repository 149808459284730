import { FormEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Favicon from 'react-favicon';
import { useEffectOnce } from 'usehooks-ts';
import { Paper, Button, TextField } from '@mui/material';
import { COMMON_STRING, ERROR_STRING } from '@/constants/dictionary';
import { DOCUMENT_TITLE, FAVICON_URLS } from '@/pages/constants';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { LoadingOverlay } from '@/components/common/LoadingOverlay';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { checkUUID } from '@/utils/checkUUID/checkUUID';
import homeStyle from './Home.module.css';

const FIXTURE_ID = 'fixture-id';
export const Home = () => {
  const [fixtureId, setFixtureId] = useState('');
  const isValid = checkUUID(fixtureId);
  const isError = !isValid && !!fixtureId.length;
  const navigate = useNavigate();
  const { isLoading } = useContext(RolePermissionsContext);

  useEffectOnce(() => {
    document.title = DOCUMENT_TITLE;
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/${fixtureId}`);
  };

  if (isLoading) {
    return <LoadingOverlay isLoading />;
  }

  return (
    <PermissionsChecker name='HomePage' withMessage>
      <Favicon url={FAVICON_URLS.DEFAULT} />
      <PermissionsChecker name='HomePage.FindFixture'>
        <Paper elevation={1} sx={{ p: 2, width: '100%', maxWidth: 600 }}>
          <form className={homeStyle.form} onSubmit={onSubmit}>
            <TextField
              error={isError}
              fullWidth={true}
              variant='standard'
              type='text'
              value={fixtureId}
              onChange={(e) => setFixtureId(e.target.value.replace(/\s/g, ''))}
              name={FIXTURE_ID}
              id={FIXTURE_ID}
              label={COMMON_STRING.FIXTURE_ID}
              helperText={isError && ERROR_STRING.INCORRECT_FIXTURE}
              placeholder={COMMON_STRING.FIXTURE_ID_PLACEHOLDER}
            />
            <Button variant='contained' type='submit' disabled={!isValid}>
              {COMMON_STRING.SUBMIT}
            </Button>
          </form>
        </Paper>
      </PermissionsChecker>
    </PermissionsChecker>
  );
};
