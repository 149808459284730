import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsButtonType } from '@/contexts/stats/types';
import { AdvancedStatistics } from '@/service/types';
import {
  AmericanFootballStatsHeader,
  AmericanFootballStatsTableData,
} from '@/components/AmericanFootballStatsTable/types';

interface FilterAmericanFootballTableDataProps {
  activeQuarter: StatsButtonType['value'];
  activeType: StatsButtonType['value'];
  activeTeam: StatsButtonType['id'];
  advancedSportStatistics: AdvancedStatistics | null;
  homeTeamId: string | undefined;
}

interface GenerateStatisticsValueProps {
  statistics: AmericanFootballStatsTableData;
  key: AmericanFootballStatsHeader['key'];
  subKeys?: AmericanFootballStatsHeader['subKeys'];
}

export const filterAmericanFootballTableData = ({
  activeQuarter,
  activeType,
  activeTeam,
  advancedSportStatistics,
  homeTeamId,
}: FilterAmericanFootballTableDataProps) => {
  if (!advancedSportStatistics) return;

  const isAllQuarter = activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL;

  if (activeType === STATS_BUTTONS.TYPE.PLAYER) {
    return advancedSportStatistics?.teamsStatistics
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playersStatistics?.map((player) => {
        return {
          statistics: isAllQuarter
            ? player.gameStatistics
            : player?.periodsStatistics.find(
                (period) => period.period === activeQuarter,
              ),
          playerId: player.playerId,
        };
      });
  }

  if (activeType === STATS_BUTTONS.TYPE.TEAM) {
    const stats = advancedSportStatistics?.teamsStatistics.map(
      (teamStatistics) => {
        return {
          statistics: isAllQuarter
            ? teamStatistics.gameStatistics
            : teamStatistics?.periodsStatistics?.find(
                (period) => period.period === activeQuarter,
              ),
          teamId: teamStatistics.teamId,
        };
      },
    );

    if (homeTeamId) {
      return [
        ...stats.filter((statistics) => statistics.teamId === homeTeamId),
        ...stats.filter((statistics) => statistics.teamId !== homeTeamId),
      ];
    } else {
      return stats;
    }
  }
};

export const generateAmericanFootballStatsHeader = (
  statsType: StatsButtonType['value'],
  type: StatsButtonType['value'],
) => {
  const header: AmericanFootballStatsHeader[] = [
    {
      key: 'firstDowns',
      value: '1st Downs',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'thirdDownEfficiency',
      value: '3rd Down Efficiency',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'fourthDownEfficiency',
      value: '4th Down Efficiency',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'yardsTotal',
      value: 'Total Yards',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingYards',
      value: 'Passing Yards',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingAttempts',
      value: 'Passing Attempts',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingAverage',
      value: 'Yards per Pass',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'rushingYards',
      value: 'Rushing Yards',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'rushingAttempts',
      value: 'Rushing Attempts',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'rushingAverage',
      value: 'Yards per Rush',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'penaltiesNumber',
      subKeys: ['penaltiesNumber', 'penaltiesYards'],
      value: 'Penalties',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'turnovers',
      value: 'Turnovers',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'fumblesLost',
      value: 'Fumbles Lost',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'interceptions',
      value: 'Interceptions',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'timeOfPossession',
      value: 'Time of Possession',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingCompletions',
      value: 'Completions',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingAttempts',
      value: 'Attempts',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingYards',
      value: 'Yards',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingTouchdowns',
      value: 'Touchdowns',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingInterceptions',
      value: 'Interceptions',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingLongest',
      value: 'Longest Pass',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'offenseSacksAllowed',
      value: 'Sacks',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'rushingAttempts',
      value: 'Attempts',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingYards',
      value: 'Yards Gained',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingTouchdowns',
      value: 'Touchdowns',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingLongest',
      value: 'Longest Run',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingAverage',
      value: 'Average Distance per Run',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'receivingReceptions',
      value: 'Receptions',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'receivingYards',
      value: 'Yards',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'receivingTouchdowns',
      value: 'Touchdowns',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'receivingLongest',
      value: 'Longest Reception',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'defenseTacklesSolo',
      value: 'Solo Tackle',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseTacklesAssisted',
      value: 'Assisted Tackle',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseTacklesForLoss',
      value: 'Tackle For Loss',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseForcedFumbles',
      value: 'Forced Fumble',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseFumbleRecoveries',
      value: 'Fumble Recovery',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseInterceptions',
      value: 'Interception',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'punting',
      value: 'Punts',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingYards',
      value: 'Yards',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingAverage',
      value: 'Average Punt Length',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingLongest',
      value: 'Longest Punt',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingInside20',
      value: 'Punts downed inside opponents 20',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingTouchbacks',
      value: 'Touchbacks',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingReturns',
      value: 'Punts Returned',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'puntingReturnYardage',
      value: 'Punts Yards',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'kickPuntReturnsYards',
      value: 'Kickoffs Yards',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'kickPuntReturnsLongest',
      value: 'Kickoffs Longest Return',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'kickoffsKickoffs',
      value: 'Number of Kicks',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsYards',
      value: 'Total Yards',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsTouchbacks',
      value: 'Touchbacks',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsOutOfBounds',
      value: 'Out of Bounds',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsAverage',
      value: 'Average Length',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
  ];
  return header.filter((header) => header.visible);
};

export const generateStatisticsValue = ({
  statistics,
  key,
  subKeys,
}: GenerateStatisticsValueProps) => {
  if (!subKeys) return statistics.statistics[key];

  return subKeys.map((subKey) => statistics.statistics[subKey]).join('-');
};
