import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import { DICTIONARY } from '@/constants/dictionary';

export const getOS = () => {
  const operationSystems: { [key: string]: string } = {
    mac: DICTIONARY.COMMON.MAC,
    win: DICTIONARY.COMMON.WINDOWS,
    linux: DICTIONARY.COMMON.LINUX,
  };

  const userAgent = navigator.userAgent.toLowerCase();

  for (const os in operationSystems) {
    if (userAgent.includes(os)) {
      return operationSystems[os];
    }
  }
  return DICTIONARY.COMMON.UNKNOWN;
};

export const getKey = (os: string) =>
  os === DICTIONARY.COMMON.MAC ? (
    <KeyboardCommandKeyIcon fontSize='inherit' />
  ) : (
    DICTIONARY.COMMON.CTRL_KEY
  );
