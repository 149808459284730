import { NOT_UPDATED_ACTION_STATS_NAME } from '@/components/StatsCompareTable/constants';
import { FixtureAction } from '../types';
import { NotUpdatedActionsItem } from './getBasketActionStats';

export interface HockeytNotUpdatedActions {
  possibleAssists: FixtureAction[];
  possibleGoalkeeperSaves: FixtureAction[];
  unknownPlayers: FixtureAction[];
}

export const getHockeyActionStats = (
  notUpdatedActions: HockeytNotUpdatedActions,
): NotUpdatedActionsItem[] => {
  return [
    {
      name: NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_ASSISTS,
      actionValue: notUpdatedActions.possibleAssists.length ?? 0,
    },
    {
      name: NOT_UPDATED_ACTION_STATS_NAME.POSSIBLE_GOALKEEPER_SAVES,
      actionValue: notUpdatedActions.possibleGoalkeeperSaves.length ?? 0,
    },
    {
      name: NOT_UPDATED_ACTION_STATS_NAME.UNKNOWN_PLAYER,
      actionValue: notUpdatedActions.unknownPlayers.length ?? 0,
    },
  ];
};
