import { ChangeEvent, ChangeEventHandler, FC, useRef } from 'react';
import { IconButton, styled } from '@mui/material';

const swatchStyle = {
  width: '100%',
  height: '100%',
  padding: '0',
  borderRadius: '50%',
  border: 'none',
};
export const ColorInput = styled('input')({
  '--size': '2.25em',
  width: 'var(--size)',
  height: 'var(--size)',
  padding: '0',
  borderRadius: '50%',
  position: 'relative',
  appearance: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',

  '&::-webkit-color-swatch-wrapper': {
    padding: 0,
  },
  '&::-webkit-color-swatch': swatchStyle,
  '&::-moz-color-swatch': swatchStyle,
});
export type ColorPickerProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, newValue: string) => void;
};

export const ColorPicker: FC<ColorPickerProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event, event.target.value);
  };

  const onButtonClick = () => inputRef.current && inputRef.current.click();

  return (
    <IconButton size='small' onClick={onButtonClick}>
      <ColorInput
        ref={inputRef}
        type='color'
        value={value}
        onChange={changeHandler}
      />
    </IconButton>
  );
};
