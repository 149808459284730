import { ButtonProps, Button, alpha } from '@mui/material';
import { ReactNode, FC } from 'react';

interface PreferencesTabButtonProps extends ButtonProps {
  icon: ReactNode;
  isActive: boolean;
}

export const PreferencesTabButton: FC<PreferencesTabButtonProps> = (props) => {
  const { children, icon, isActive, ...buttonProps } = props;
  return (
    <Button
      startIcon={icon}
      color={isActive ? 'primary' : 'inherit'}
      sx={{
        justifyContent: 'flex-start',
        textTransform: 'unset',
        background: (theme) =>
          isActive ? alpha(theme.palette.primary.main, 0.16) : 'none',
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
