import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { SimpleIndicator } from './SimpleIndicator';

type ConnectionStatusBaseProps = {
  label: string;
  successColor?: string;
  errorColor?: string;
};
type ConnectionStatusSimpleProps = ConnectionStatusBaseProps & {
  isConnected: boolean;
  customIndicator?: never;
};
type ConnectionStatusCustomIndicatorProps = ConnectionStatusBaseProps & {
  isConnected?: never;
  customIndicator: ReactNode;
};

export type ConnectionStatusProps = (
  | ConnectionStatusSimpleProps
  | ConnectionStatusCustomIndicatorProps
) &
  Pick<StackProps, 'datatype'>;

export const ConnectionStatus = ({
  isConnected = false,
  customIndicator = false,
  label,
  successColor,
  errorColor,
  ...stackProps
}: ConnectionStatusProps) => {
  const indicator = customIndicator ? (
    customIndicator
  ) : (
    <SimpleIndicator
      isConnected={isConnected}
      successColor={successColor}
      errorColor={errorColor}
      aria-label={ARIA_LABEL.SIMPLE_INDICATOR}
    />
  );
  return (
    <Stack
      {...stackProps}
      alignItems='center'
      width='min-content'
      py={0.75}
      px={1}
      gap={0.5}
    >
      <Typography variant='overline' lineHeight={1}>
        {label}
      </Typography>
      {indicator}
    </Stack>
  );
};
