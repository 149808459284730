import FilterList from '@mui/icons-material/FilterList';
import FilterListOff from '@mui/icons-material/FilterListOff';
import Undo from '@mui/icons-material/Undo';
import { Tooltip, IconButton } from '@mui/material';
import { FC, useContext } from 'react';
import { PANEL } from '@/contexts/UIState/types';
import { DICTIONARY } from '@/constants/dictionary';
import { KeyboardButton } from '@/components/common/KeyboardButton';
import { UIStateContext } from '@/contexts/UIState/context';
import { ActionsTableContext } from '../context/ActionsTableContext';

export type FilterButtonsProps = {
  disableFilters: boolean;
};

export const FilterButtons: FC<FilterButtonsProps> = ({
  disableFilters = false,
}) => {
  const { setOpenedPanel } = useContext(UIStateContext);
  const { filters, filtersHistory, clearFilters, undoFilters } =
    useContext(ActionsTableContext);

  if (disableFilters) return <></>;
  return (
    <>
      {!!filters.filter((item) => !item.isFromTemplate).length && (
        <Tooltip title={DICTIONARY.COMMON.CLEAR_FILTERS}>
          <IconButton color='primary' onClick={clearFilters}>
            <FilterListOff fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
      {!!filtersHistory.length && (
        <Tooltip title={DICTIONARY.COMMON.UNDO_FILTER}>
          <IconButton color='primary' onClick={undoFilters}>
            <Undo fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={
          <>
            {DICTIONARY.COMMON.FILTERS}
            <KeyboardButton theKey='f' />
          </>
        }
      >
        <IconButton
          color='primary'
          onClick={() => setOpenedPanel(PANEL.FILTERS)}
        >
          <FilterList fontSize='small' />
        </IconButton>
      </Tooltip>
    </>
  );
};
