import envVariables from '@/envVariables';
import { API_METHOD } from '../constants';
import { ApiError } from '../ApiError';
import {
  FetchFromMTServiceOptions as FetchFromServiceOptions,
  makeUrlWithParams,
} from './monitoringToolService';

export const USER_PREFERENCES_ENDPOINT = {
  Preferences: '/mt-user-preferences',
} as const;

export const fetchFromUserPreferencesService = async ({
  token,
  endpoint,
  queryParams,
}: FetchFromServiceOptions) => {
  const url = makeUrlWithParams(
    envVariables.userPreferencesApi + endpoint,
    queryParams,
  );
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: API_METHOD.GET,
  });

  if (!res.ok && res.status !== 210) {
    throw await ApiError.parseFromResponse(res);
  }
  const contentType = res.headers.get('content-type');
  if (!contentType || !contentType.includes('application/json'))
    return res.text();
  return res.json();
};
