import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { KeyboardButton } from '@/components/common/KeyboardButton';
import { KeyboardShortcutCells, SHORTCUTS_SECTION } from './constants';
import { ShortcutItem } from './ShortcutItem';
import { getKey, getOS } from './utils';

export const KeyboardShortcuts = () => {
  const os = getOS();
  const theKey = getKey(os);

  const renderShortcutItems = (section: string) =>
    KeyboardShortcutCells.filter((cell) => cell.section === section).map(
      ({ label, value }, index, array) => (
        <Fragment key={label}>
          <ShortcutItem label={label} value={value} />
          {index !== array.length - 1 && <Divider />}
        </Fragment>
      ),
    );

  return (
    <Stack width='100%' gap={2}>
      <Typography display='flex' alignItems='center'>
        <KeyboardButton theKey={theKey} />+
        <KeyboardButton theKey='/' />
        {String.fromCharCode(160)}to toggle this dialog
      </Typography>

      {Object.values(SHORTCUTS_SECTION).map((section) => (
        <TableContainer key={section} component={Paper}>
          <Table key={section} component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    py: 0,
                    px: 1,
                    bgcolor: (theme) => theme.palette.action.disabled,
                  }}
                >
                  {section}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderShortcutItems(section)}</TableBody>
          </Table>
        </TableContainer>
      ))}
    </Stack>
  );
};
