import { FIXTURE_ACTION_TYPE } from '../../service/constants';
import { FixtureAction } from '../../service/types';

// Utilities common for worker and main thread.

export const CHECKLIST_CRUCIAL_ACTION_TYPE_ID = {
  MATCH_CONDITIONS: FIXTURE_ACTION_TYPE.MATCH_CONDITIONS,
  TEAM_COLOURS: FIXTURE_ACTION_TYPE.TEAM_COLOURS,
  LINEUPS: FIXTURE_ACTION_TYPE.LINEUPS,
  DEVICE_LOCATION: FIXTURE_ACTION_TYPE.DEVICE_LOCATION,
  POST_MATCH_CHECK_COMPLETE: FIXTURE_ACTION_TYPE.POST_MATCH_CHECK_COMPLETE,
};
export const checklistCrucialActionTypeIds = Object.values(
  CHECKLIST_CRUCIAL_ACTION_TYPE_ID
);
export const isChecklistCrucialAction = (action: FixtureAction) =>
  checklistCrucialActionTypeIds.includes(action.fixtureActionTypeId);
