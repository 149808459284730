import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { HubConnectionState } from '@microsoft/signalr';
import {
  addLogChangeCallback,
  getIsLogEnabled,
  konsole,
} from '@/utils/konsole';
import { useNetworkStatus } from '@/utils/useNetworkStatus';
import {
  SCORING_WORKER_ACTION,
  SCORING_WORKER_HOST_ACTION,
  ScoringAPIWorker,
  ScoringWorkerMsgEvent,
} from '@/workers/scoring/types';
import ScoringWorkerUrl from '@/workers/scoring/worker?worker&url';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { generateDeletedActionIds } from '@/contexts/scoring/generateDeletedActionIds';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { useSupportedSports } from '@/service/hooks/useSupportedSports';
import { ddSocketLogger } from '@/datadogLoggers';
import { NotificationsContext } from '../notifications/context';
import { PreferencesContext } from '../preferences/context';
import { ACTION_CONDITION_FUNCTION } from '../preferences/constants';
import { ScoringContext } from './context';
import { scoringDefaultState, scoringReducer } from './reducer';
import { SCORING_REDUCER_ACTION } from './types';
import { createUseDispatchWithResponse } from './createUseDispatchWithResponse';
import { createUseDispatchManyWithResponse } from './createUseDispatchManyWithResponse';
import { useStateChangeSounds } from './useStateChangeSounds';

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    import.meta.hot?.invalidate();
  });
}
function getWorkerUrl() {
  const [workerFilePath, workerQueryString] = ScoringWorkerUrl.split('?');
  const workerSearchParams = new URLSearchParams(workerQueryString || '');
  workerSearchParams.set('log', `${getIsLogEnabled()}`);
  return workerFilePath + '?' + workerSearchParams.toString();
}

export const ScoringProvider: FC<PropsWithChildren> = ({ children }) => {
  const { fixtureId } = useParams<{ fixtureId: string }>();

  const isOnline = useNetworkStatus();
  const { play: playSound } = useContext(NotificationsContext);
  const { actionSounds, actionConditionSounds } =
    useContext(PreferencesContext);

  const token = useAuthToken();

  const fixtureConfigState = useFixtureConfig({ token, fixtureId });
  const supportedSportsState = useSupportedSports({ token });

  const [state, dispatch] = useReducer(
    scoringReducer,
    { isOnline },
    scoringDefaultState,
  );

  useStateChangeSounds({ state, playSound });

  const deletedActionIds = useMemo(
    () => generateDeletedActionIds(state.fixtureActions?.actions),
    [state.fixtureActions?.actions],
  );

  const worker: ScoringAPIWorker = useMemo(() => {
    const worker = new Worker(getWorkerUrl(), { type: 'module' });
    addLogChangeCallback((enabled) =>
      worker.postMessage({
        action: SCORING_WORKER_HOST_ACTION.LOG_ENABLE,
        payload: enabled,
      }),
    );
    return worker;
  }, []);

  const useDispatchWithResponse = useMemo(
    () => createUseDispatchWithResponse(worker),
    [worker],
  );
  const useDispatchManyWithResponse = useMemo(
    () => createUseDispatchManyWithResponse(worker),
    [worker],
  );

  useEffectOnce(() => {
    worker.addEventListener('message', (e) => {
      const { data } = e;
      konsole.groupCollapsed(`receivedMsg: ${data.action}`);
      konsole.log(data.action, data.payload);
      konsole.groupEnd();
      dispatch(data);
    });

    dispatch({
      action: SCORING_REDUCER_ACTION.WORKER_INIT,
      payload: { worker },
    });

    return () => {
      worker.postMessage({
        action: SCORING_WORKER_HOST_ACTION.KILL,
        payload: null,
      });
      worker.terminate();
      dispatch({
        action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID,
        payload: {
          fixtureId: '',
        },
      });
      dispatch({
        action: SCORING_WORKER_ACTION.WORKER_READY,
        payload: { isWorkerReady: false },
      });
    };
  });

  useEffect(() => {
    if (token && worker) {
      worker.postMessage({
        action: SCORING_WORKER_HOST_ACTION.TOKEN,
        payload: token,
      });
    }
  }, [token, worker]);

  useEffect(() => {
    if (!worker) return;
    const soundMessageListener = (e: ScoringWorkerMsgEvent) => {
      if (e.data.action !== SCORING_WORKER_ACTION.ACTION_ADDED) {
        return;
      }
      const action = e.data.payload;

      const actionConditionSound = actionConditionSounds.find(
        ([actionConditionId]) => {
          const conditionFn = ACTION_CONDITION_FUNCTION[actionConditionId];
          return conditionFn(action);
        },
      );
      if (actionConditionSound) {
        return playSound(actionConditionSound[1]);
      }

      const actionTypeSound = actionSounds.find(
        ([actionTypeId]) => actionTypeId === action.fixtureActionTypeId,
      );
      if (actionTypeSound) {
        return playSound(actionTypeSound[1]);
      }
    };

    worker.addEventListener('message', soundMessageListener);
    return () => {
      worker.removeEventListener('message', soundMessageListener);
    };
  }, [actionSounds, worker, playSound, actionConditionSounds]);

  useEffect(() => {
    const fixtureIdParam = fixtureId || '';
    if (
      state.isWorkerReady &&
      state.wsConnection === HubConnectionState.Connected
    ) {
      dispatch({
        action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID,
        payload: { fixtureId: fixtureIdParam, oldFixtureId: state.fixtureId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureId, state.isWorkerReady, state.wsConnection]);

  useEffect(() => {
    if (isOnline !== state.isOnline) {
      dispatch({
        action: SCORING_REDUCER_ACTION.NETWORK,
        payload: { isOnline },
      });
    }
  }, [isOnline, state.isOnline]);

  useEffect(() => {
    if (!worker) return;

    const loggerListener = (e: ScoringWorkerMsgEvent) => {
      if (e.data.action !== SCORING_WORKER_ACTION.LOG) {
        return;
      }

      const {
        data: { payload },
      } = e;

      const { message, messageContext } = payload;

      ddSocketLogger.log(message, messageContext, payload.level);
    };

    worker.addEventListener('message', loggerListener);

    return () => {
      worker.removeEventListener('message', loggerListener);
    };
  }, [worker]);

  if (!state.isWorkerReady) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <ScoringContext.Provider
      value={{
        state: { ...state, deletedActionIds },
        fixtureConfigState,
        supportedSportsState,
        dispatch,
        useDispatchWithResponse,
        useDispatchManyWithResponse,
      }}
    >
      {children}
    </ScoringContext.Provider>
  );
};
