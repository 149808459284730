import { Chip } from '@mui/material';
import { ARIA_LABEL } from '@/constants/ariaLabel';

interface FixtureDetailsChipProps {
  label: string;
  backgroundColor: string;
}

export const FixtureDetailsChip = ({
  label,
  backgroundColor,
}: FixtureDetailsChipProps) => {
  return (
    <Chip
      size='small'
      sx={{
        color: (theme) => theme.palette.getContrastText(backgroundColor),
        width: 'fit-content',
        backgroundColor: backgroundColor,
        borderRadius: (theme) => theme.spacing(2),
        px: (theme) => theme.spacing(0.5),
      }}
      aria-label={ARIA_LABEL.FIXTURE_DETAILS_CHIP}
      label={label}
    />
  );
};
