import React from 'react';
import { FixtureActions } from '@/components/FixtureTabs/FixtureActions';
import { FixtureLineups } from '@/components/FixtureTabs/FixtureLineups';
import { FixtureComparison } from '@/components/FixtureTabs/FixtureComparison';
import { SPORT_ID } from '@/service/constants';
import { hasPermissionsToElement } from '@/components/PermissionsChecker/utils';
import { UserPermissions } from '@/service/types';
import { FixtureStatistics } from '@/components/FixtureTabs/FixtureStatistics';
import { FixtureStreaming } from '../FixtureStreaming/FixtureStreaming';

export interface FixtureTabsType {
  name: string;
  component: JSX.Element;
  isVisible: boolean;
}

interface GenerateTabsOptions {
  sportId?: SPORT_ID;
  permissions: UserPermissions;
}

export const MAIN_TAB_NAME = {
  MATCH_DATA: 'Match Data',
  LINEUPS: 'Lineups',
  STATISTICS: 'Statistics',
  QA: 'Quality Assurance',
  STREAMING: 'Streaming',
} as const;
export type MainTabName = (typeof MAIN_TAB_NAME)[keyof typeof MAIN_TAB_NAME];

export const generateTabs = ({ sportId, permissions }: GenerateTabsOptions) => {
  const tabs: FixtureTabsType[] = [
    {
      name: MAIN_TAB_NAME.MATCH_DATA,
      component: <FixtureActions />,
      isVisible: hasPermissionsToElement('FixturePage.MatchData', permissions),
    },
    {
      name: MAIN_TAB_NAME.LINEUPS,
      component: <FixtureLineups />,
      isVisible: hasPermissionsToElement('FixturePage.Lineups', permissions),
    },
    {
      name: MAIN_TAB_NAME.STATISTICS,
      component: <FixtureStatistics />,
      isVisible: hasPermissionsToElement('FixturePage.Statistics', permissions),
    },
    {
      name: MAIN_TAB_NAME.STREAMING,
      component: <FixtureStreaming />,
      isVisible:
        (sportId === SPORT_ID.SOCCER ||
          sportId === SPORT_ID.BASKETBALL ||
          sportId === SPORT_ID.AMERICAN_FOOTBALL) &&
        hasPermissionsToElement('FixturePage.Streaming', permissions),
    },
    {
      name: MAIN_TAB_NAME.QA,
      component: <FixtureComparison />,
      isVisible:
        hasPermissionsToElement('FixturePage.QA', permissions) &&
        (sportId === SPORT_ID.SOCCER || sportId === SPORT_ID.BASKETBALL),
    },
  ].filter((tab) => tab.isVisible);

  return tabs;
};
