import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ENVIRONMENT } from '@/constants/environment';
import { getEnvColor } from '@/contexts/theme/utils';
import envVariables from '@/envVariables';

export const EnvironmentInfo = () => {
  const theme = useTheme<Theme>();
  const env = envVariables.env;
  const { DEV, STAGING, SANDBOX } = ENVIRONMENT;

  if ([DEV, STAGING, SANDBOX].includes(env)) {
    const envColor = getEnvColor(theme, env);
    return (
      <Typography
        component='p'
        bgcolor={envColor}
        borderRadius={theme.spacing(0.5)}
        color={theme.palette.getContrastText(envColor)}
        p={theme.spacing(0.25, 0.5)}
        fontSize='0.75rem'
        lineHeight={1.67}
      >
        {env}
      </Typography>
    );
  }
  return null;
};
