import { IconButton } from '@mui/material';
import { FC, useContext } from 'react';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { FixtureAction } from '@/service/types';
import { checkIsStreamedSport } from '@/contexts/streaming/utils';
import { ScoringContext } from '@/contexts/scoring/context';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { StreamingContext } from '@/contexts/streaming/context';
import { BUTTON_NAME } from './constants';

export interface StreamCellProps {
  action: FixtureAction;
}

export const StreamCell: FC<StreamCellProps> = ({
  action: { fixtureActionTypeId },
}) => {
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const { streamUrl, endTimer } = useContext(StreamingContext);

  const blockedStreamActions = [
    FIXTURE_ACTION_TYPE.TEAM_COLOURS,
    FIXTURE_ACTION_TYPE.MATCH_CONDITIONS,
    FIXTURE_ACTION_TYPE.DEVICE_LOCATION,
    FIXTURE_ACTION_TYPE.LINEUPS,
    FIXTURE_ACTION_TYPE.PLAYERS_READY,
  ];

  const isBlockedAction = blockedStreamActions.includes(fixtureActionTypeId);

  if (
    !checkIsStreamedSport(fixtureConfig) ||
    isBlockedAction ||
    !streamUrl ||
    !endTimer
  ) {
    return <span />;
  }

  return (
    <IconButton name={BUTTON_NAME.STREAM} size='small' aria-label='stream'>
      <SmartDisplayIcon fontSize='small' />
    </IconButton>
  );
};
