import { Button, CircularProgress } from '@mui/material';
import { orange } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import {
  ActionOf,
  AddFixtureActionMsg,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { COMMON_STRING, SUCCESS_STRING } from '@/constants/dictionary';
import { UseDispatchWithResponse } from '@/contexts/scoring/createUseDispatchWithResponse';
import { ScoringState } from '@/contexts/scoring/types';
import { SnackbarCloseActionProps } from './SnackbarCloseAction';

export interface VarUnderwayActionProps extends SnackbarCloseActionProps {
  fixtureId: ScoringState['fixtureId'];
  useDispatchWithResponse: <T>(
    action: ActionOf<T>
  ) => UseDispatchWithResponse<T>;
}

export const SnackbarVarUnderwayAction = ({
  fixtureId,
  useDispatchWithResponse,
  snackbarId,
}: VarUnderwayActionProps) => {
  const { closeSnackbar } = useSnackbar();
  const { dispatch: addAction, isLoading } =
    useDispatchWithResponse<AddFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION
    );

  const varOverAction = () => {
    addAction(
      {
        fixtureId,
        fixtureActionTypeId: FIXTURE_ACTION_TYPE.VAR_OVER,
        withLastFixtureActionParams: true,
      },
      {
        successMessage: SUCCESS_STRING.ACTION_SUCCESSFULLY_ADDED,
      }
    )
      .catch(() => closeSnackbar(snackbarId))
      .then(() => {
        closeSnackbar(snackbarId);
      });
  };

  return (
    <Button
      disabled={isLoading}
      variant='text'
      startIcon={
        isLoading && <CircularProgress color={'inherit'} size={'1em'} />
      }
      onClick={varOverAction}
      sx={{ color: orange[100] }}
    >
      {COMMON_STRING.VAR_OVER}
    </Button>
  );
};
