import { styled, Switch } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { COMMON_STRING } from '@/constants/dictionary';

export const MarketSwitch = styled(Switch)(({ theme }) => ({
  width: 65,
  height: 16,
  padding: 0,
  '& .MuiSwitch-track': {
    backgroundColor: red[600],
    opacity: 1,
    borderRadius: 22 / 2,
    width: 65,
    height: 16,

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 12,
      height: 12,
    },
    '&::before': {
      right: 34,
      top: 7,
      content: `'${COMMON_STRING.CLOSED}'`,
      fontSize: 10,
      fontWeight: '600',
    },
    '&::after': {
      content: '""',
    },
  },

  '& .MuiSwitch-switchBase': {
    color: red[900],
    '&:hover': { backgroundColor: 'transparent' },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 12,
      height: 12,
      position: 'absolute',
      left: 2,
      top: 2,
    },

    '&.Mui-checked': {
      color: green[900],
      '&:hover': { backgroundColor: 'transparent' },
      '& .MuiSwitch-thumb': {
        position: 'absolute',
        left: 31,
        top: 2,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: green[600],
        opacity: 1,
        '&::after': {
          left: 3,
          top: 7,
          content: `'${COMMON_STRING.OPENED}'`,
          fontSize: 10,
          fontWeight: '600',
        },
        '&::before': {
          content: '""',
        },
      },
    },
  },
}));

export const MarketSwitchBig = styled(MarketSwitch)(({ theme }) => ({
  width: 120,
  height: 24,
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    width: 120,
    height: 24,

    '&::before': {
      right: 81,
      top: 7,
      content: `'${COMMON_STRING.UNDERWAY}'`,
      fontSize: 15,
      color: theme.palette.getContrastText(red[600]),
    },
  },

  '& .MuiSwitch-switchBase': {
    '& .MuiSwitch-thumb': {
      left: 4,
      width: 18,
      height: 18,
      top: 3,
    },

    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        left: 78,
        top: 3,
      },
      '& + .MuiSwitch-track': {
        '&::after': {
          left: 5,
          fontSize: 15,
          content: `'${COMMON_STRING.OVER}'`,
          color: theme.palette.getContrastText(green[600]),
          fontWeight: '600',
        },
      },
    },
  },
}));
