import {
  Button,
  ButtonProps,
  Palette,
  Theme,
  ToggleButton,
  alpha,
  darken,
  styled,
  useTheme,
} from '@mui/material';
import { CSSProperties, FC } from 'react';

const toggleButtonStyles = (theme: Theme) => ({
  '--color': theme.palette.primary.main,
  '--backgroundColor': alpha(theme.palette.primary.main, 0.16),
  '--backgroundHover': alpha(theme.palette.primary.main, 0.24),
  color: 'var(--color)',
  '&.Mui-selected': {
    color: 'var(--color)',
    borderColor: 'var(--color)',
    backgroundColor: 'var(--backgroundColor)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'var(--backgroundColorHover)',
  },
});
export const ColoredToggleButton = styled(ToggleButton)(({ theme }) =>
  toggleButtonStyles(theme),
);

const containedButtonStyles = (theme: Theme) => ({
  '--color': theme.palette.primary.contrastText,
  '--backgroundColor': theme.palette.primary.main,
  '--backgroundColorHover': theme.palette.primary.dark,
  color: 'var(--color)',
  backgroundColor: 'var(--backgroundColor)',
  '&:hover': {
    backgroundColor: 'var(--backgroundColorHover)',
  },
});
const outlinedButtonStyles = (theme: Theme) => ({
  '--color': theme.palette.primary.main,
  '--backgroundColorHover': alpha(theme.palette.primary.dark, 0.08),
  color: 'var(--color)',
  borderColor: 'var(--color)',
  '&:hover': {
    backgroundColor: 'var(--backgroundColorHover)',
    borderColor: 'inherit',
  },
});
const textButtonStyles = (theme: Theme) => ({
  '--color': theme.palette.primary.main,
  '--backgroundColorHover': alpha(theme.palette.primary.dark, 0.08),
  color: 'var(--color)',
  '&:hover': {
    backgroundColor: 'var(--backgroundColorHover)',
  },
});

const ColorStyledButton = styled(Button)<ButtonProps>(({ theme, variant }) => {
  if (variant === 'contained') return containedButtonStyles(theme);
  if (variant === 'outlined') return outlinedButtonStyles(theme);
  if (variant === 'text') return textButtonStyles(theme);
  return {};
});
export type ColoredButtonProps = ButtonProps & {
  customColor?: string;
};

const getDynamicStyle = (
  props: ColoredButtonProps,
  palette: Palette,
): CSSProperties => {
  const { variant, customColor, style } = props;
  const color = customColor ?? palette.primary.main;

  switch (variant) {
    case 'contained':
      return {
        '--color': palette.getContrastText(color),
        '--backgroundColor': color,
        '--backgroundColorHover': darken(color, 0.24),
        ...style,
      } as CSSProperties;
    case 'outlined':
      return {
        '--color': color,
        '--backgroundColorHover': alpha(color, 0.08),
        ...style,
      } as CSSProperties;
    case 'text':
    default:
      return {
        '--color': color,
        '--backgroundColorHover': alpha(color, 0.08),
        ...style,
      } as CSSProperties;
  }
};

export const ColoredButton: FC<ColoredButtonProps> = (props) => {
  const { palette } = useTheme();
  const { customColor, ...restProps } = props;
  const dynamicStyle = getDynamicStyle(props, palette);
  return <ColorStyledButton {...restProps} style={dynamicStyle} />;
};
