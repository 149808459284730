import { RowProps } from '@/components/ScoresheetTables/common/types';
import {
  ScoresheetMainPlayerTableCell,
  ScoresheetPlayerTableCell,
  ScoresheetTableBodyRow,
} from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import { COMMON_STRING } from '@/constants/dictionary';

export const TotalsRow = ({ teamStatistics }: RowProps) => {
  return (
    <ScoresheetTableBodyRow>
      <ScoresheetMainPlayerTableCell colSpan={2}>
        {COMMON_STRING.TOTALS}
      </ScoresheetMainPlayerTableCell>
      <ScoresheetPlayerTableCell>-</ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.totalPoints}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.fieldGoalsMade}/{teamStatistics?.fieldGoalAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.fieldGoalsPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.twoPointersMade}/{teamStatistics?.twoPointerAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.twoPointersPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.threePointersMade}/
        {teamStatistics?.threePointerAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.threePointersPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.freeThrowsMade}/{teamStatistics?.freeThrowAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.freeThrowsPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.offensiveRebounds !== undefined &&
        teamStatistics?.offensiveTeamRebounds !== undefined
          ? teamStatistics.offensiveRebounds +
            teamStatistics.offensiveTeamRebounds
          : ''}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.defensiveRebounds !== undefined &&
        teamStatistics.defensiveTeamRebounds !== undefined
          ? teamStatistics.defensiveRebounds +
            teamStatistics.defensiveTeamRebounds
          : ''}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.totalReboundsWithTeamRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.assists}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.turnovers}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.steals}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.blocks}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.blocksAgainst}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.personalFouls}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {teamStatistics?.foulsAgainst}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>-</ScoresheetPlayerTableCell>
    </ScoresheetTableBodyRow>
  );
};
