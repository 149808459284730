import { LoginCallback } from '@okta/okta-react';
import { LoginError } from '@/pages/LoginError';

export const Login = () => {
  const error = new URLSearchParams(window.location.search).get('error');
  const errorDescription = new URLSearchParams(window.location.search).get(
    'error_description',
  );

  return !!error && !!errorDescription ? (
    <LoginError error={error} errorDescription={errorDescription} />
  ) : (
    <LoginCallback />
  );
};
