import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Stack } from '@mui/material';
import { FC, useContext, useEffect, useRef } from 'react';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { StatusesChangeInfo } from '@/components/ActionsTable/StatusesChangeInfo/StatusesChangeInfo';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import { getPlayerNameAndNumber } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { MATCH_ACTION_SEND_TYPE_NAME } from '@/constants';
import { FIXTURE_ACTION_TYPE, SPORT_ID } from '@/service/constants';
import { FixtureAction, FixtureConfig, FixtureSummary } from '@/service/types';
import { formatDate } from '@/utils/formatDate/formatDate';
import { getPeriodName } from '@/components/FixtureTabs/helpers/getPeriodName';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from '@/components/ActionsTable/context/ActionsTableContext';
import { StreamCell } from '@/components/ActionsTable/StreamCell';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { getSport } from '@/service/utils/getSport';
import { UIStateContext } from '@/contexts/UIState/context';
import { UIState } from '@/contexts/UIState/types';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';
import { MISTAKE_RULE } from '../ActionEvaluationDialog/constants';
import { FILTER_PROPERTY } from './ActionFilters/constants';
import { ActionsTableCell } from './ActionsTableCell';
import { CommentCell } from './CommentCell';
import { FlagCell } from './FlagCell';
import { BUTTON_NAME, COLUMNS, TABLE_CLASS_NAMES } from './constants';
import { getRowBackgroundStyle, getTeamColor } from './utils';
import { ActionIcons } from './ActionIcons';
import { LatencyCell } from './LatencyCell';

export type ActionRowProps = {
  action: FixtureAction;
  fixtureConfig?: FixtureConfig;
  fixtureSummary: FixtureSummary | null;
  isNewAction: boolean;
  isUpdating: boolean;
  observer: IntersectionObserver;
  sportId?: number;
  lineThrough: boolean;
  setHoverAction: (action: FixtureAction | undefined) => void;
};

export const ActionRow: FC<ActionRowProps> = ({
  action,
  fixtureConfig,
  fixtureSummary,
  isNewAction,
  isUpdating,
  observer,
  sportId,
  lineThrough,
  setHoverAction,
}) => {
  const { actions } = useContext<ActionsTableContextType>(ActionsTableContext);
  const { isSlaBreachActionSelected } = useContext<UIState>(UIStateContext);
  const sport = getSport(fixtureConfig);
  const isAmericanFootball = sport && sport.id === SPORT_ID.AMERICAN_FOOTBALL;
  const isVolleyball =
    sport &&
    (sport.id === SPORT_ID.VOLLEYBALL ||
      sport?.id === SPORT_ID.BEACH_VOLLEYBALL);
  const isDownDistanceSummary =
    isAmericanFootball && action?.sportSpecificFixtureState;
  const evaluationReason = action.slaBreachSummary?.evaluationReason;
  const evaluationTitle =
    action.slaBreachSummary?.evaluationReason === MISTAKE_RULE.OTHER
      ? `Mistake: ${action.slaBreachSummary.evaluationReasonNote}`
      : `Mistake: ${action.slaBreachSummary?.evaluationReason}`;

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef && isNewAction) {
      observer.observe(currentRef);
    }
    if (currentRef && !isNewAction) {
      observer.unobserve(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isNewAction, observer]);

  const generateScoreLabel = () => {
    if (isDownDistanceSummary) {
      return action.sportSpecificFixtureState?.summary;
    }

    if (isVolleyball && action.periodScore) {
      return `${action.periodScore.homeTeamScore}:${action.periodScore.awayTeamScore}`;
    }

    return (
      action.score &&
      `${action.score.homeTeamScore}:${action.score.awayTeamScore}`
    );
  };

  const generateScoreSubLabel = () => {
    if (
      (isDownDistanceSummary || (isVolleyball && action.periodScore)) &&
      action.score
    ) {
      return `${action.score.homeTeamScore}:${action.score.awayTeamScore}`;
    }

    return '';
  };

  return (
    <Stack
      aria-label={ARIA_LABEL.ACTION_ROW}
      onMouseOver={() => setHoverAction(action)}
      onMouseOut={() => setHoverAction(undefined)}
      ref={ref}
      direction='row'
      data-action-id={action.id}
      className={`${TABLE_CLASS_NAMES.ROW}`}
      sx={{
        opacity: !lineThrough ? 1 : 0.5,
        alignItems: 'stretch',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
      style={{
        ...getRowBackgroundStyle(getTeamColor(action.teamId, fixtureSummary)),
      }}
    >
      <ActionsTableCell
        width={COLUMNS.SEQ.width}
        columnName={COLUMNS.SEQ.name}
        lineThrough={lineThrough}
      >
        {action.fixtureSeqNum}
      </ActionsTableCell>
      <ActionsTableCell
        width={COLUMNS.SEND_TYPE.width}
        label={MATCH_ACTION_SEND_TYPE_NAME[action.sendTypeId]}
        columnName={COLUMNS.SEND_TYPE.name}
        filterProperty={FILTER_PROPERTY.SEND_TYPE_ID}
        filterValue={action.sendTypeId}
        filterValueType='number'
        color={({ palette }) => palette.status[action.sendTypeId]}
        fontWeight={600}
        lineThrough={lineThrough}
      />
      <ActionsTableCell
        width={COLUMNS.CLOCK_TIME.width}
        columnName={COLUMNS.CLOCK_TIME.name}
        label={action.clockTimeString}
        sublabel={formatDate(action.timestamp)}
        lineThrough={lineThrough}
      />
      <ActionsTableCell
        width={COLUMNS.PERIOD.width}
        label={getPeriodName({
          period: action.period,
          fixtureSeqNum: action.fixtureSeqNum,
          actions,
          sportId,
        })}
        columnName={COLUMNS.PERIOD.name}
        filterProperty={FILTER_PROPERTY.PERIOD}
        filterValue={action.period}
        filterValueType='number'
        lineThrough={lineThrough}
      />
      <ActionsTableCell
        alignItems='flex-end'
        width={COLUMNS.ACTION_ICONS.width}
      >
        <ActionIcons
          action={action}
          isSlaBreachTable={isSlaBreachActionSelected}
        />
      </ActionsTableCell>
      <ActionsTableCell
        width={COLUMNS.ACTION.width}
        label={getActionName(action.fixtureActionTypeId, fixtureConfig)}
        sublabel={getActionSubTypeName(
          action.fixtureActionSubTypeId,
          fixtureConfig,
        )}
        columnName={COLUMNS.ACTION.name}
        filterValue={action.fixtureActionTypeId}
        filterProperty={FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID}
        filterValueType='number'
        lineThrough={lineThrough}
      >
        {action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.STATUS_UPDATE && (
          <StatusesChangeInfo
            fixtureStatusId={action.metadata?.fixtureStatus}
            fixtureStatusReasonCode={action.metadata?.fixtureStatusReasonCode}
            collectionStatusId={action.metadata?.collectionStatus}
            collectionStatusReasonCode={
              action.metadata?.collectionStatusReasonCode
            }
            coverageLevelId={action.metadata?.coverageLevel}
            coverageLevelReasonCode={action.metadata?.coverageLevelReasonCode}
          />
        )}
      </ActionsTableCell>
      <ActionsTableCell
        width={COLUMNS.TEAM.width}
        label={getTeamName(action.teamId, fixtureConfig, fixtureSummary)}
        columnName={COLUMNS.TEAM.name}
        filterProperty={FILTER_PROPERTY.TEAM_ID}
        filterValue={action.teamId}
        lineThrough={lineThrough}
      />
      <ActionsTableCell
        width={COLUMNS.PLAYER.width}
        label={getPlayerNameAndNumber(
          action.playerId,
          fixtureConfig,
          fixtureSummary,
        ).toLowerCase()}
        columnName={COLUMNS.PLAYER.name}
        filterProperty={FILTER_PROPERTY.PLAYER_ID}
        filterValue={action.playerId}
        lineThrough={lineThrough}
        capitalizedCell
      />
      <ActionsTableCell
        label={generateScoreLabel()}
        sublabel={generateScoreSubLabel()}
        width={COLUMNS.SCORE.width}
        lineThrough={lineThrough}
      />
      {isSlaBreachActionSelected && (
        <PermissionsChecker
          name='FixturePage.QA.SlaBreachActionEvaluation'
          accessDeniedComponent={<span />}
        >
          <ActionsTableCell alignItems={'center'} width={COLUMNS.LATENCY.width}>
            <LatencyCell action={action} />
          </ActionsTableCell>
          <ActionsTableCell
            alignItems='flex-end'
            width={COLUMNS.MISTAKE_COMMENT_ICON.width}
          >
            {evaluationReason &&
              action.slaBreachSummary?.evaluationReason !==
                MISTAKE_RULE.NONE && (
                <CustomTooltip sx={{ zIndex: 0 }} title={evaluationTitle}>
                  <AnnouncementIcon color='action' fontSize='small' />
                </CustomTooltip>
              )}
          </ActionsTableCell>
        </PermissionsChecker>
      )}

      <PermissionsChecker name='FixturePage.MatchData'>
        <ActionsTableCell
          display='grid'
          gridTemplateColumns='repeat(4, 1fr)'
          alignItems='center'
          justifyItems='center'
          justifyContent='space-between'
          sx={{ px: 0.25, width: COLUMNS.MORE.width }}
        >
          <PermissionsChecker
            name='FixturePage.MatchData.Stream'
            accessDeniedComponent={<span />}
          >
            <StreamCell action={action} />
          </PermissionsChecker>
          <PermissionsChecker
            name='FixturePage.MatchData.ConfirmFlag'
            accessDeniedComponent={<span />}
          >
            <FlagCell action={action} isUpdating={isUpdating} />
          </PermissionsChecker>
          <PermissionsChecker
            name='FixturePage.MatchData.Comment'
            accessDeniedComponent={<span />}
          >
            <CommentCell action={action} />
          </PermissionsChecker>

          <PermissionsChecker
            name='FixturePage.MatchData.KebabMenu'
            accessDeniedComponent={<span />}
          >
            <IconButton name={BUTTON_NAME.MORE} size='small' aria-label='more'>
              <MoreVert fontSize='small' />
            </IconButton>
          </PermissionsChecker>
        </ActionsTableCell>
      </PermissionsChecker>
    </Stack>
  );
};
