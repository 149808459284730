import { OptionsWithExtraProps } from 'notistack';
import {
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';

export const VISUAL_NOTIFICATIONS_FILTERS = {
  FLAG_ON_FIELD: {
    showFilters: [
      { actionType: FIXTURE_ACTION_TYPE.FLAG_ON_FIELD_TYPE },
      {
        actionType: FIXTURE_ACTION_TYPE.PENALTY,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.PENALTY,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PENALTY,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.FLAG_ON_FIELD_TYPE,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
  POSSIBLE_VAR: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.POSSIBLE_VAR,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.POSSIBLE_VAR,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.NO_VIDEO_CHECK,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.NO_VIDEO_CHECK,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.NO_VIDEO_CHECK,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VAR_OVER,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_RESULT,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.POSSIBLE_VAR,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
  VAR_UNDERWAY: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VAR_OVER,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VAR_OVER,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VAR_OVER,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
    ],
  },
  MARKET_CLOSE: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.CLOSE_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.CLOSE_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.OPEN_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.OPEN_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.CLOSE_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
  MARKET_OPEN: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.OPEN_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.OPEN_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.CLOSE_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.CLOSE_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.OPEN_MARKET,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
  PLAY_STOPPED: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.PLAY_STOPPED,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAY_STOPPED,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAY_RESUMED,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.PLAY_RESUMED,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAY_RESUMED,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAY_STOPPED,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
  PLAYER_LEFT: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.PLAYER_LEFT,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAYER_LEFT,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAYER_RETURNED,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.PLAYER_RETURNED,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAYER_RETURNED,
        sendType: MATCH_ACTION_SEND_TYPE.UPDATED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.PLAYER_LEFT,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
};

export const DEFAULT_VISUAL_NOTIFICATIONS_SNACKBAR_OPTIONS: OptionsWithExtraProps<'info'> =
  {
    variant: 'info',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    persist: true,
    autoHideDuration: null,
    hideIconVariant: true,
    preventDuplicate: true,
  };
