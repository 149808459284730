import { Stack, Typography } from '@mui/material';
import { FixtureScoreInfoTeam } from '@/components/FixtureScore/FixtureScoreStyledComponents';

interface ScoresheetScoreProps {
  homeTeam: {
    score?: number;
    name?: string;
  };
  awayTeam: {
    score?: number;
    name?: string;
  };
  period: string;
}

export const ScoresheetScore = ({
  homeTeam,
  awayTeam,
  period,
}: ScoresheetScoreProps) => {
  return (
    <Stack
      gap={1}
      flexDirection='row'
      justifyContent='center'
      alignItems='start'
    >
      <FixtureScoreInfoTeam>{homeTeam.name}</FixtureScoreInfoTeam>
      <Stack minWidth={(theme) => theme.spacing(10)} textAlign='center'>
        <Typography
          align='center'
          fontWeight={700}
          lineHeight={1}
          fontSize='1.5rem'
        >
          {homeTeam.score} - {awayTeam.score}
        </Typography>
        <Typography variant='body2'>{period}</Typography>
      </Stack>
      <FixtureScoreInfoTeam>{awayTeam.name}</FixtureScoreInfoTeam>
    </Stack>
  );
};
