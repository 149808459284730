import { Stack, Typography } from '@mui/material';
import { KeyBoardShortcutsCell } from './constants';

export const ShortcutItem = ({ label, value }: KeyBoardShortcutsCell) => (
  <Stack
    alignItems='center'
    justifyContent='space-between'
    direction='row'
    px={1}
  >
    <Typography fontSize='0.9em'>{label}</Typography>
    <Typography>{value}</Typography>
  </Stack>
);
