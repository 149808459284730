import useSWR from 'swr';
import { FixtureConfig } from '../types';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';

export interface UseFixtureConfigParams {
  token: string | undefined;
  fixtureId?: string;
}
export const useFixtureConfig = ({
  token,
  fixtureId,
}: UseFixtureConfigParams) => {
  const {
    data: fixtureConfig,
    error,
    mutate,
  } = useSWR<FixtureConfig, ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Fixture(fixtureId).fixtureConfig.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
      revalidateOnFocus: false,
    }
  );

  return {
    fixtureConfig,
    error,
    isLoading: !fixtureConfig && !error,
    mutate,
  };
};
