import Backdrop from '@mui/material/Backdrop';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { FC } from 'react';

export const LOADING_OVERLAY_ARIA_LABEL = 'loading-overlay';

interface LoadingOverlayProps extends CircularProgressProps {
  isLoading: boolean;
}

export const overlayBackgroundColorStyle = {
  backgroundColor: 'hsla(0, 0%, 50%, 0.25)',
};

export const LoadingOverlay: FC<LoadingOverlayProps> = (props) => {
  const { isLoading, ...progressProps } = props;
  return (
    <Backdrop
      open={isLoading}
      sx={{ position: 'absolute', ...overlayBackgroundColorStyle }}
      aria-label={LOADING_OVERLAY_ARIA_LABEL}
    >
      <CircularProgress {...progressProps} />
    </Backdrop>
  );
};
