import { useSnackbar } from 'notistack';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SUCCESS_STRING } from '@/constants/dictionary';
import { SnackbarCloseActionProps } from '@/components/SnackbarActions/SnackbarCloseAction';
import {
  AcknowledgeSupervisorCallMsg,
  ActionOf,
  SCORING_WORKER_HOST_ACTION,
} from '@/workers/scoring/types';
import { UseDispatchWithResponse } from '@/contexts/scoring/createUseDispatchWithResponse';
import { ScoringState } from '@/contexts/scoring/types';
import { FixtureSupervisorCall } from '@/service/types';

interface SnackbarAcknowledgeSupervisorCallActionProps
  extends SnackbarCloseActionProps {
  fixtureId: ScoringState['fixtureId'];
  scorerId: FixtureSupervisorCall['scorerId'];
  useDispatchWithResponse: <T>(
    action: ActionOf<T>
  ) => UseDispatchWithResponse<T>;
}

export const SnackbarAcknowledgeSupervisorCallAction = ({
  snackbarId,
  fixtureId,
  scorerId,
  useDispatchWithResponse,
}: SnackbarAcknowledgeSupervisorCallActionProps) => {
  const { closeSnackbar } = useSnackbar();

  const { dispatch: acknowledgeSupervisorCall, isLoading } =
    useDispatchWithResponse<AcknowledgeSupervisorCallMsg>(
      SCORING_WORKER_HOST_ACTION.ACKNOWLEDGE_SUPERVISOR_CALL
    );

  const submitSupervisorCallAcknowledge = async () =>
    acknowledgeSupervisorCall(
      {
        fixtureId,
        scorerId,
      },
      {
        successMessage:
          SUCCESS_STRING.SUPERVISOR_CALL_SUCCESSFULLY_ACKNOWLEDGED,
      }
    )
      .catch(() => closeSnackbar(snackbarId))
      .then(() => closeSnackbar(snackbarId));

  return (
    <IconButton
      color='inherit'
      onClick={submitSupervisorCallAcknowledge}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size='1em' color='inherit' />
      ) : (
        <CloseIcon />
      )}
    </IconButton>
  );
};
