import { useContext } from 'react';
import { ButtonsWrapper } from '@/components/StatsButtons/ButtonsWrapper';
import { StatsContext } from '@/contexts/stats/context';
import { StatsButton } from '@/components/StatsButtons/common';
import { BUTTON_TYPES } from '@/contexts/stats/types';

export const TeamButtons = () => {
  const { teamButtons, changeActiveButton } = useContext(StatsContext);
  return (
    <ButtonsWrapper>
      {teamButtons?.map((teamButton) => (
        <StatsButton
          isActive={teamButton.isActive}
          variant={'outlined'}
          disableRipple={teamButton.isActive}
          key={teamButton.id}
          onClick={() => changeActiveButton(teamButton, BUTTON_TYPES.TEAM)}
        >
          {teamButton.value}
        </StatsButton>
      ))}
    </ButtonsWrapper>
  );
};
