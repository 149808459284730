import { Typography } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

interface ScoresheetDateProps {
  date: string;
}

export const ScoresheetDate = ({ date }: ScoresheetDateProps) => {
  return (
    <Typography variant='body2' textAlign='end'>
      {COMMON_STRING.REPORT_GENERATED}: {date}
    </Typography>
  );
};
