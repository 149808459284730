import {
  ChecklistWarning,
  ChecklistCheckboxState,
} from '@/contexts/checklist/types';
import {
  FIXTURE_TYPE_ID,
  MATCH_ACTION_SEND_TYPE,
  SCORING_APP_STATUS,
  SPORT_ID,
} from '@/service/constants';
import {
  Actions,
  FixtureAction,
  FixtureConfig,
  FixtureSummary,
  Player,
} from '@/service/types';
import { CHECKLIST_ELEMENT_INDEX } from '@/service/types/checklist';
import { getDifferenceByProp } from '@/utils/array';
import { CHECKLIST_CRUCIAL_ACTION_TYPE_ID } from '@/utils/checklist';
import { getSport } from '@/service/utils/getSport';
import { CHECKLIST_WARNING } from './constants';
// Importing itself for nested functions spying in unit tests
import * as utils from './utils';

const {
  DEVICE_LOCATION,
  LINEUPS,
  MATCH_CONDITIONS,
  POST_MATCH_CHECK_COMPLETE,
  TEAM_COLOURS,
} = CHECKLIST_CRUCIAL_ACTION_TYPE_ID;

export function isDeviceLocationSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === DEVICE_LOCATION,
  );
}
export function isMatchConditionsSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === MATCH_CONDITIONS,
  );
}
export function isTeamColourSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === TEAM_COLOURS,
  );
}
export function isPostMatchCheckCompleteSent(actions: FixtureAction[]) {
  const [latestPostMatchCheckAction] = actions
    .filter(
      ({ fixtureActionTypeId }) =>
        fixtureActionTypeId === POST_MATCH_CHECK_COMPLETE,
    )
    .sort((a, b) => b.fixtureSeqNum - a.fixtureSeqNum);

  return (
    !!latestPostMatchCheckAction &&
    [MATCH_ACTION_SEND_TYPE.CONFIRMED, MATCH_ACTION_SEND_TYPE.UPDATED].includes(
      latestPostMatchCheckAction.sendTypeId,
    )
  );
}
export function isLineupsSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === LINEUPS,
  );
}

export function composeMissingPlayersNames(players: Player[]) {
  return players.reduce<string>((playersString, player, index) => {
    if (index !== 0) playersString += ', ';
    playersString += player.fullName;

    return playersString;
  }, '');
}

const missingPlayersMemo: {
  players: Player[];
  config?: FixtureConfig;
  summary?: FixtureSummary;
} = { players: [] };
export function getMissingPlayers(
  config: FixtureConfig,
  summary: FixtureSummary,
): Player[] {
  if (
    missingPlayersMemo.config === config &&
    missingPlayersMemo.summary === summary
  ) {
    return missingPlayersMemo.players;
  }

  const homePlayersOutOfSquad = getDifferenceByProp<Player>(
    summary.homeTeam?.players,
    config.homeSquad?.players,
    'id',
  );
  const awayPlayersOutOfSquad = getDifferenceByProp<Player>(
    summary.awayTeam?.players,
    config.awaySquad?.players,
    'id',
  );
  missingPlayersMemo.config = config;
  missingPlayersMemo.summary = summary;

  const missingHomePlayersMemo = Array.isArray(homePlayersOutOfSquad)
    ? homePlayersOutOfSquad
    : [];
  const missingAwayPlayersMemo = Array.isArray(awayPlayersOutOfSquad)
    ? awayPlayersOutOfSquad
    : [];

  return (missingPlayersMemo.players = [
    ...missingHomePlayersMemo,
    ...missingAwayPlayersMemo,
  ]);
}

export function calculateRemainingTime(startDate: Date) {
  return startDate.getTime() - new Date().getTime();
}

export interface CheckboxValidateParams {
  checkbox: ChecklistCheckboxState;
  remainingMinutes: number;
  actions?: FixtureAction[];
  config?: FixtureConfig;
  summary?: FixtureSummary;
  fixtureActions: Actions | null;
}
export type CheckboxValidateResult = Partial<ChecklistCheckboxState> & {
  isReadyToCheck: boolean;
  warnings: ChecklistWarning[];
};

export function validateCheckbox({
  checkbox,
  remainingMinutes,
  actions,
  config,
  summary,
}: CheckboxValidateParams): CheckboxValidateResult {
  const sport = getSport(config);
  const isSoccer = sport && sport.id === SPORT_ID.SOCCER;
  const isMatchOfficial = config?.fixture.type === FIXTURE_TYPE_ID.OFFICIAL;
  const isSlaBreachAvalible = isSoccer && isMatchOfficial;
  const result: CheckboxValidateResult = {
    isReadyToCheck: false,
    warnings: [],
    requiresConfirmation: false,
    isDisabled: false,
  };
  if (!actions) return result;

  const currentCheckboxWarning = checkbox.warning
    ? {
        name: checkbox.warning,
        message: checkbox.warning,
      }
    : null;

  switch (checkbox.index) {
    case CHECKLIST_ELEMENT_INDEX.ATTENDANCE_GEO:
      result.isReadyToCheck = utils.isDeviceLocationSent(actions);
      break;
    case CHECKLIST_ELEMENT_INDEX.MATCH_CONDITIONS:
      result.isReadyToCheck = utils.isMatchConditionsSent(actions);
      break;
    case CHECKLIST_ELEMENT_INDEX.TEAM_COLOURS:
      result.isReadyToCheck = utils.isTeamColourSent(actions);
      break;
    case CHECKLIST_ELEMENT_INDEX.LINEUPS:
      if (!(config && summary)) return result;
      const isLineupActionSent = utils.isLineupsSent(actions);
      const missingPlayers = utils.getMissingPlayers(config, summary);
      result.isReadyToCheck = isLineupActionSent && !missingPlayers.length;
      if (missingPlayers.length > 0) {
        const squadWarning = Object.assign({}, CHECKLIST_WARNING.LINEUPS_SQUAD);
        squadWarning.message +=
          utils.composeMissingPlayersNames(missingPlayers);
        result.warnings = [squadWarning, ...result.warnings];
      }
      break;
    case CHECKLIST_ELEMENT_INDEX.POST_MATCH_CHECK_COMPLETE:
      result.isReadyToCheck = utils.isPostMatchCheckCompleteSent(actions);
      result.requiresConfirmation =
        checkbox.isChecked || !utils.isPostMatchCheckCompleteSent(actions);
      result.isDisabled = isMatchNotCompleted(summary);
      break;

    case CHECKLIST_ELEMENT_INDEX.SLA_BREACH_COMPLETE:
      result.requiresConfirmation = true;
      result.isDisabled = !isSlaBreachAvalible || isMatchNotCompleted(summary);
      break;
  }

  if (
    !checkbox.isChecked &&
    !result.isReadyToCheck &&
    checkbox.timeTreshold !== null &&
    remainingMinutes < checkbox.timeTreshold &&
    currentCheckboxWarning !== null
  ) {
    result.warnings = [...result.warnings, currentCheckboxWarning];
  }

  return result;
}

export function isDisabled({
  checkbox,
  summary,
}: CheckboxValidateParams): boolean {
  if (checkbox.index === CHECKLIST_ELEMENT_INDEX.POST_MATCH_CHECK_COMPLETE) {
    return isMatchNotCompleted(summary);
  }
  return false;
}

export const isMatchNotCompleted = (summary: FixtureSummary | undefined) => {
  return !!summary
    ? summary.currentStatus !== SCORING_APP_STATUS.COMPLETED
    : true;
};

export const showError = (
  enqueueSnackbar: (message: string, options: any) => void,
  message: string,
) => {
  return () => {
    enqueueSnackbar(message, { variant: 'error' });
  };
};
