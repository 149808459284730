import {
  FixturePostMatchCheckStatistics,
  PostMatchCheckAction,
} from '@/service/types';
import { COMPARISON_COLUMNS } from '@/components/ComparisonTable/constants';
import { COMMON_STRING } from '@/constants/dictionary';

let comparisonCSVHeaders: { label: string; key: string }[] | null = null;

export const getComparisonCSVHeaders = () => {
  comparisonCSVHeaders =
    comparisonCSVHeaders ||
    Object.values(COMPARISON_COLUMNS).map(({ name }) => ({
      label: name,
      key: name.toLowerCase().replace(/\s/g, ''),
    }));
  return comparisonCSVHeaders;
};

export const getComparisonCSVData = (
  csvData: (FixturePostMatchCheckStatistics | undefined)[]
) => {
  const generateFieldValue = (
    overallValue: PostMatchCheckAction['overallValue'],
    supervisorsValue: PostMatchCheckAction['supervisorsValue']
  ) => {
    return overallValue !== undefined && supervisorsValue
      ? `${overallValue} (${supervisorsValue})`
      : overallValue !== undefined && !supervisorsValue
      ? overallValue
      : COMMON_STRING.NOT_AVAILABLE;
  };

  const data = csvData.map((data) => {
    return {
      source: `${data?.statsType || COMMON_STRING.NOT_AVAILABLE} ${
        data?.teamName || COMMON_STRING.NOT_AVAILABLE
      }`,
      goal: generateFieldValue(
        data?.goal?.overallValue,
        data?.goal?.supervisorsValue
      ),
      goaldisallowed: generateFieldValue(
        data?.goalDisallowed?.overallValue,
        data?.goalDisallowed?.supervisorsValue
      ),
      yellowcard: generateFieldValue(
        data?.yellowCard?.overallValue,
        data?.yellowCard?.supervisorsValue
      ),
      redcard: generateFieldValue(
        data?.redCard?.overallValue,
        data?.redCard?.supervisorsValue
      ),
      attacks: generateFieldValue(
        data?.attack?.overallValue,
        data?.attack?.supervisorsValue
      ),
      dangerousattacks: generateFieldValue(
        data?.dangerousAttack?.overallValue,
        data?.dangerousAttack?.supervisorsValue
      ),
      penaltyawarded: generateFieldValue(
        data?.penaltyAwarded?.overallValue,
        data?.penaltyAwarded?.supervisorsValue
      ),
      penaltymissed: generateFieldValue(
        data?.penaltyMissed?.overallValue,
        data?.penaltyMissed?.supervisorsValue
      ),
      freekicks: generateFieldValue(
        data?.freeKick?.overallValue,
        data?.freeKick?.supervisorsValue
      ),
      shotsontarget: generateFieldValue(
        data?.shotOnTarget?.overallValue,
        data?.shotOnTarget?.supervisorsValue
      ),
      shotsofftarget: generateFieldValue(
        data?.shotOffTarget?.overallValue,
        data?.shotOffTarget?.supervisorsValue
      ),
      corner: generateFieldValue(
        data?.corner?.overallValue,
        data?.corner?.supervisorsValue
      ),
      blocked: generateFieldValue(
        data?.blocked?.overallValue,
        data?.blocked?.supervisorsValue
      ),
      offsides: generateFieldValue(
        data?.offside?.overallValue,
        data?.offside?.supervisorsValue
      ),
      throwin: generateFieldValue(
        data?.throwIn?.overallValue,
        data?.throwIn?.supervisorsValue
      ),
    };
  });
  return data;
};
