import { crowd, dingDing, fail, siren, whistle, flick } from '@/assets/sounds';
import { SoundId } from './types';

export const SOUND_ID = {
  SIREN: 0,
  DING_DING: 1,
  WHISTLE: 2,
  CROWD: 3,
  FAIL: 4,
  FLICK: 5,
} as const;

export const SOUND_SRC: { [index in SoundId]: string } = {
  [SOUND_ID.SIREN]: siren,
  [SOUND_ID.DING_DING]: dingDing,
  [SOUND_ID.WHISTLE]: whistle,
  [SOUND_ID.CROWD]: crowd,
  [SOUND_ID.FAIL]: fail,
  [SOUND_ID.FLICK]: flick,
} as const;

export const SOUND_NAME = {
  [SOUND_ID.SIREN]: 'Siren',
  [SOUND_ID.DING_DING]: 'Ding-ding',
  [SOUND_ID.WHISTLE]: 'Whistle',
  [SOUND_ID.CROWD]: 'Crowd',
  [SOUND_ID.FAIL]: 'Fail',
  [SOUND_ID.FLICK]: 'Flick',
} as const;
