import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { Dialog } from '@mui/material';
import { konsole } from '@/utils/konsole';
import { AutoplayDialog } from '@/components/AutoplayDialog/AutoplayDialog';
import { PreferencesContext } from '../preferences/context';
import { NotificationsContext } from './context';
import { SoundId, SoundsAudio } from './types';
import { SOUND_NAME, SOUND_SRC } from './constants';

export const AUDIO_PERMISSIONS_TEST_ID = 'audio-permissions';

export const createAudioMap = (srcMap: { [index: string]: string }) => {
  return Object.entries(srcMap).reduce(
    (acc, [name, src]) => {
      acc[name] = new Audio(src);
      return acc;
    },
    {} as { [index: string]: HTMLAudioElement },
  );
};

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { soundsPreferences } = useContext(PreferencesContext);

  const soundsAudio = useMemo(
    () => createAudioMap(SOUND_SRC) as SoundsAudio,
    [],
  );

  const [isAudioBlocked, setIsAudioBlocked] = useState<boolean>(false);
  const onAudioPlayFail = useCallback((error: any) => {
    if (error.name === 'NotAllowedError') {
      setIsAudioBlocked(true);
    }
  }, []);
  useEffectOnce(() => {
    new Audio().play().catch(onAudioPlayFail);
  });

  const play = useCallback(
    (soundId: SoundId) => {
      const audio = soundsAudio[soundId];
      if (!audio) {
        const error = new Error(
          `Unable to play notification: ${SOUND_NAME[soundId]}`,
        );
        konsole.error(error);
        return Promise.reject(error);
      }
      audio.pause();
      audio.currentTime = 0;
      return audio.play().catch(onAudioPlayFail);
    },
    [soundsAudio, onAudioPlayFail],
  );

  useEffect(() => {
    Object.values(soundsPreferences.sounds).forEach(({ id, volume }) => {
      const audioVolume = volume * soundsPreferences.volume;
      soundsAudio[id].volume = audioVolume;
    });
  }, [soundsPreferences.sounds, soundsPreferences.volume, soundsAudio]);

  const providerValue = {
    play,
  };

  return (
    <NotificationsContext.Provider value={providerValue}>
      <Dialog
        open={isAudioBlocked}
        fullWidth
        maxWidth='sm'
        data-testid={AUDIO_PERMISSIONS_TEST_ID}
      >
        <AutoplayDialog onClose={() => setIsAudioBlocked(false)} />
      </Dialog>
      {children}
    </NotificationsContext.Provider>
  );
};
