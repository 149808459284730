import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import MusicNote from '@mui/icons-material/MusicNote';
import MusicOff from '@mui/icons-material/MusicOff';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { Stack, Button, IconButton } from '@mui/material';
import { FC, useContext, useEffect, useReducer } from 'react';
import { useDebounce } from 'usehooks-ts';
import { VolumeSlider } from '@/components/common/VolumeSlider';
import { ColorPicker } from '@/components/common/ColorPicker';
import { NotificationsContext } from '@/contexts/notifications/context';
import { PreferencesContext } from '@/contexts/preferences/context';
import { SoundSettings } from '@/contexts/preferences/types';

export type SoundSettingEditProps = {
  sound: SoundSettings;
};
export const LABEL = {
  PLAY: 'Play sound',
  MUTE: 'Mute sound',
};

export const SoundSettingsEdit: FC<SoundSettingEditProps> = ({ sound }) => {
  const { play } = useContext(NotificationsContext);
  const {
    actions: { updateSoundPreferences },
  } = useContext(PreferencesContext);
  const [soundSettings, updateSoundSettings] = useReducer<
    (current: SoundSettings, update: Partial<SoundSettings>) => SoundSettings
  >(
    (current, update) => ({
      ...current,
      ...update,
    }),
    sound
  );

  const newColor = useDebounce(soundSettings.color);
  const newVolume = useDebounce(soundSettings.volume);
  const newMuted = useDebounce(soundSettings.muted);

  useEffect(() => {
    updateSoundPreferences({
      soundId: sound.id,
      soundUpdate: { color: newColor },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newColor, updateSoundPreferences]);

  useEffect(() => {
    if (newVolume === sound.volume) return;
    updateSoundPreferences({
      soundId: sound.id,
      soundUpdate: { volume: newVolume },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVolume, updateSoundPreferences]);

  useEffect(() => {
    if (newMuted === sound.muted) return;
    updateSoundPreferences({
      soundId: sound.id,
      soundUpdate: { muted: newMuted },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMuted, updateSoundPreferences]);

  useEffect(() => {
    updateSoundSettings(sound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound.color, sound.volume, sound.muted]);

  const MuteIcon = soundSettings.muted ? MusicOff : MusicNote;
  return (
    <>
      <Stack>
        <Button
          key={sound.id}
          aria-label={LABEL.PLAY}
          color='inherit'
          variant='text'
          startIcon={<PlayCircleOutline />}
          onClick={() => play(sound.id)}
          sx={{
            flex: '1 1 0',
            minWidth: 'max-content',
            justifyContent: 'flex-start',
          }}
        >
          {sound.name}
        </Button>
      </Stack>
      <Stack direction='row' alignItems='center' gap={1}>
        <IconButton
          aria-label={LABEL.MUTE}
          onClick={() => updateSoundSettings({ muted: !soundSettings.muted })}
        >
          <MuteIcon />
        </IconButton>
        <VolumeUp />
        <VolumeSlider
          disabled={soundSettings.muted}
          value={soundSettings.volume}
          onChange={(e, newVolume) =>
            updateSoundSettings({ volume: newVolume as number })
          }
          aria-label={`${sound.name}-volume`}
        />
      </Stack>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <ColorPicker
          value={soundSettings.color}
          onChange={(e, newColor) => updateSoundSettings({ color: newColor })}
        />
      </Stack>
    </>
  );
};
