import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Close from '@mui/icons-material/Close';
import { FixtureConfigTeam, Player } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import { UNKNOWN_PLAYER_ID, UNKNOWN_PLAYER_NAME } from '@/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { getFixtureActionTypes } from '@/service/utils/getFixtureActionTypes';
import { getSport } from '@/service/utils/getSport';
import { getPeriodName } from '@/components/FixtureTabs/helpers/getPeriodName';
import { PreferencesContext } from '@/contexts/preferences/context';
import { COLUMNS } from '../constants';
import { ActionFilter } from './useActionsFilters';
import {
  FILTER_DISPLAY_NAME,
  FILTER_PROPERTY,
  GenericFilterValue,
  GENERIC_FILTER_VALUE,
  NON_PROPERTY_FILTER,
  FILTER_DISPLAY_VALUE,
} from './constants';
import { AutocompleteFilter } from './AutocompleteFilter';
import { GenericFilterRadios } from './GenericFilterRadios';
import { isInFilters, getHomeOrAwayTeam } from './utils';
import { FilterTemplateTeam } from './types';

export interface ActionFiltersFormProps {
  filters: ActionFilter[];
  onApply: (newFilters: ActionFilter[]) => void;
  onCancel: () => void;
  drawerWidth: string;
}

export const makePlayerFilter = (
  playerRadio: GenericFilterValue,
  selectedPlayers: ActionFilter[],
): ActionFilter[] => {
  if (playerRadio === GENERIC_FILTER_VALUE.UNSET) return [];
  if (playerRadio === GENERIC_FILTER_VALUE.UNKNOWN)
    return [
      {
        property: FILTER_PROPERTY.PLAYER_ID,
        value: UNKNOWN_PLAYER_ID,
        displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
        displayValue: COMMON_STRING.UNKNOWN,
      },
    ];
  if (playerRadio === GENERIC_FILTER_VALUE.FALSY) {
    return [
      {
        property: FILTER_PROPERTY.PLAYER_ID,
        value: undefined,
        displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
        displayValue: COMMON_STRING.NONE,
      },
    ];
  }
  if (selectedPlayers.length > 0) return selectedPlayers;
  return [
    {
      property: FILTER_PROPERTY.PLAYER_ID,
      value: GENERIC_FILTER_VALUE.TRUTHY,
      displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
      displayValue: COMMON_STRING.ASSIGNED,
    },
  ];
};

export const ActionFiltersForm: FC<ActionFiltersFormProps> = ({
  filters,
  onApply,
  onCancel,
  drawerWidth,
}) => {
  const {
    state: { fixtureSummary, fixtureActions },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);
  const { selectedTemplate, sendTypes } = useContext(PreferencesContext);

  const [actionId, setActionId] = useState('');
  const [selectedActionTypes, setSelectedActionTypes] = useState<
    ActionFilter[]
  >([]);
  const [teams, setTeams] = useState<
    {
      id: string | null;
      name: string;
      checked: boolean;
    }[]
  >([]);
  const [selectedPlayers, setSelectedPlayers] = useState<ActionFilter[]>([]);
  const [selectedSendTypeIds, setSelectedSendTypeIds] = useState<
    ActionFilter[]
  >([]);

  const [selectedPeriods, setSelectedPeriods] = useState<ActionFilter[]>([]);
  const [playerRadio, setPlayerRadio] = useState<GenericFilterValue>('');
  const [flagRadio, setFlagRadio] = useState<GenericFilterValue>('');
  const [commentRadio, setCommentRadio] = useState<GenericFilterValue>('');
  const [actionsNotUpdatedRadio, setActionsNotUpdatedRadio] =
    useState<GenericFilterValue>('');
  const [slaLatency, setSlaLatency] = useState<GenericFilterValue>('');
  const [slaMistake, setSlaMistake] = useState<GenericFilterValue>('');

  const onActionTypesChange = (event: any, newValue: ActionFilter[]) => {
    setSelectedActionTypes(newValue);
  };

  const onSelectedPlayersChange = (event: any, newValue: ActionFilter[]) => {
    setSelectedPlayers(newValue);
  };
  const onSelectedSendTypeIdsChange = (
    event: any,
    newValue: ActionFilter[],
  ) => {
    setSelectedSendTypeIds(newValue);
  };

  const onSelectedPeriodChange = (_: any, newValue: ActionFilter[]) => {
    setSelectedPeriods(newValue);
  };

  const onTeamChecked = (index: number, checked: boolean) => {
    const newTeams = [...teams];
    newTeams[index].checked = checked;
    setTeams(newTeams);
  };

  const actionTypeOptions: ActionFilter[] = useMemo(() => {
    if (!fixtureConfig) return [];
    return getFixtureActionTypes(fixtureConfig).map(
      ({ id, name }): ActionFilter => ({
        value: id,
        displayValue: name,
        property: FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
        displayName: FILTER_DISPLAY_NAME.FIXTURE_ACTION_TYPE_ID,
      }),
    );
  }, [fixtureConfig]);

  const sendTypeOptions: ActionFilter[] = useMemo(() => {
    if (!sendTypes) return [];
    return sendTypes.map(({ id, name }) => ({
      value: id,
      displayValue: name,
      property: FILTER_PROPERTY.SEND_TYPE_ID,
      displayName: FILTER_DISPLAY_NAME.SEND_TYPE_ID,
    }));
  }, [sendTypes]);

  const matchPeriodOptions = useMemo(() => {
    const sportId = getSport(fixtureConfig);
    const periodOptions = Array.from(
      new Set(
        fixtureActions?.actions.map(({ period, fixtureSeqNum }) =>
          JSON.stringify({
            property: FILTER_PROPERTY.PERIOD,
            value: period,
            displayName: FILTER_DISPLAY_NAME.PERIOD,
            displayValue: getPeriodName({
              period: period,
              fixtureSeqNum: fixtureSeqNum,
              actions: fixtureActions.actions,
              sportId: sportId?.id,
            }),
          }),
        ),
      ),
    )
      .map((period) => JSON.parse(period))
      .sort((a, b) => a.value - b.value);

    return periodOptions;
  }, [fixtureActions, fixtureConfig]);

  const playersOptions: ActionFilter[] = useMemo(() => {
    if (!fixtureSummary) return [];
    const allPlayers = [
      ...fixtureSummary.homeTeam.players,
      ...fixtureSummary.awayTeam.players,
    ];
    if (fixtureConfig) {
      const configPlayers = [
        ...fixtureConfig.homeSquad.players,
        ...fixtureConfig.awaySquad.players,
      ];
      configPlayers.forEach((configPlayer) => {
        if (
          allPlayers.some(
            (summaryPlayer) => summaryPlayer.id === configPlayer.id,
          )
        ) {
          return;
        }
        allPlayers.push(configPlayer);
      });
    }
    const unknownPlayer = {
      id: UNKNOWN_PLAYER_ID,
      fullName: UNKNOWN_PLAYER_NAME,
    } as Player;
    allPlayers.push(unknownPlayer);

    return allPlayers.map((player) => ({
      property: FILTER_PROPERTY.PLAYER_ID,
      value: player.id,
      displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
      displayValue: player.shirtNumber
        ? `${player.shirtNumber}. ${player.fullName}`
        : player.fullName,
    }));
  }, [fixtureSummary, fixtureConfig]);

  useEffect(() => {
    if (!fixtureConfig) return;
    const { homeSquad, awaySquad } = fixtureConfig;
    const newTeams = [
      {
        id: null,
        name: 'None',
        checked: isInFilters(filters, FILTER_PROPERTY.TEAM_ID, null),
      },
      {
        id: homeSquad.team.id,
        name: homeSquad.team.name,
        checked: isInFilters(
          filters,
          FILTER_PROPERTY.TEAM_ID,
          homeSquad.team.id,
        ),
      },
      {
        id: awaySquad.team.id,
        name: awaySquad.team.name,
        checked: isInFilters(
          filters,
          FILTER_PROPERTY.TEAM_ID,
          awaySquad.team.id,
        ),
      },
    ];
    setTeams(newTeams);
  }, [fixtureConfig, filters]);

  useEffect(() => {
    const newSelectedActionTypes = filters.filter(
      ({ property }) => property === FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
    );
    setSelectedActionTypes(newSelectedActionTypes);

    const newSelectedPlayers = filters.filter(
      ({ property, value }) =>
        property === FILTER_PROPERTY.PLAYER_ID &&
        value !== undefined &&
        value !== GENERIC_FILTER_VALUE.TRUTHY,
    );
    setSelectedPlayers(newSelectedPlayers);

    const newSendTypeIds = filters.filter(
      ({ property }) => property === FILTER_PROPERTY.SEND_TYPE_ID,
    );
    setSelectedSendTypeIds(newSendTypeIds);

    const newPeriod = filters.filter(
      ({ property }) => property === FILTER_PROPERTY.PERIOD,
    );
    setSelectedPeriods(newPeriod);
  }, [filters]);

  useEffect(() => {
    const newActionId = filters.find(
      ({ property }) => property === FILTER_PROPERTY.ACTION_ID,
    );
    if (!newActionId) {
      setActionId('');
    } else if (
      newActionId.value !== actionId &&
      typeof newActionId.value === 'string'
    ) {
      setActionId(newActionId.value);
    }

    const playerFilter = filters.find(
      ({ property }) => property === FILTER_PROPERTY.PLAYER_ID,
    );
    const playerValue: GenericFilterValue = !playerFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : playerFilter.value === UNKNOWN_PLAYER_ID
      ? GENERIC_FILTER_VALUE.UNKNOWN
      : playerFilter.value === undefined
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    playerValue !== playerRadio && setPlayerRadio(playerValue);

    const flagFilter = filters.find(
      ({ property }) => property === FILTER_PROPERTY.FLAG,
    );
    const flagValue: GenericFilterValue = !flagFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : flagFilter.value === GENERIC_FILTER_VALUE.FALSY
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    flagValue !== flagRadio && setFlagRadio(flagValue);

    const commentFilter = filters.find(
      ({ property }) => property === FILTER_PROPERTY.COMMENT,
    );
    const commentValue: GenericFilterValue = !commentFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : commentFilter.value === GENERIC_FILTER_VALUE.FALSY
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    commentValue !== commentRadio && setCommentRadio(commentValue);

    const hideUpdatedActionsFilter = filters.find(
      ({ nonPropertyFilter }) =>
        nonPropertyFilter === NON_PROPERTY_FILTER.HIDE_UPDATED_ACTIONS,
    );
    const hideUpdatedActionsFilterValue: GenericFilterValue =
      !hideUpdatedActionsFilter
        ? GENERIC_FILTER_VALUE.FALSY
        : GENERIC_FILTER_VALUE.TRUTHY;
    hideUpdatedActionsFilterValue !== actionsNotUpdatedRadio &&
      setActionsNotUpdatedRadio(hideUpdatedActionsFilterValue);

    const slaLatencyFilter = filters.find(
      ({ nonPropertyFilter }) =>
        nonPropertyFilter === NON_PROPERTY_FILTER.LATENCY,
    );
    const slaLatencyValue: GenericFilterValue = !slaLatencyFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : slaLatencyFilter.value === GENERIC_FILTER_VALUE.FALSY
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    slaLatencyValue !== slaLatency && setSlaLatency(slaLatencyValue);

    const slaMistakeFilter = filters.find(
      ({ nonPropertyFilter }) =>
        nonPropertyFilter === NON_PROPERTY_FILTER.MISTAKE,
    );
    const slaMistakeValue: GenericFilterValue = !slaMistakeFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : slaMistakeFilter.value === GENERIC_FILTER_VALUE.FALSY
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    slaMistakeValue !== slaMistake && setSlaMistake(slaMistakeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onConfirm = () => {
    const actionIdFilter: ActionFilter[] = actionId
      ? [
          {
            property: FILTER_PROPERTY.ACTION_ID,
            value: actionId,
            displayName: FILTER_DISPLAY_NAME.ACTION_ID,
            displayValue: actionId,
            isFromTemplate:
              selectedTemplate?.actions?.values.some(
                (action) => action.id.toString() === actionId,
              ) &&
              selectedActionTypes.some((action) => action.exclude) ===
                selectedTemplate?.actions.hidden,
          },
        ]
      : [];

    const actionIdsFilter: ActionFilter[] =
      selectedActionTypes.length > 0
        ? selectedActionTypes.map((item) => ({
            ...item,
            isFromTemplate:
              selectedTemplate?.actions?.values.some(
                (action) => action.id === item.value,
              ) &&
              selectedActionTypes.some((action) => action.exclude) ===
                selectedTemplate?.actions.hidden,
          }))
        : [];

    const selectedSendTypeIdsFilter: ActionFilter[] =
      !!selectedSendTypeIds.length
        ? selectedSendTypeIds.map((item) => ({
            ...item,
            isFromTemplate:
              selectedTemplate?.sendTypes?.values.some(
                (sendType) => sendType === item.displayValue,
              ) &&
              selectedSendTypeIds.some((sendType) => sendType.exclude) ===
                selectedTemplate?.sendTypes.hidden,
          }))
        : [];

    const teamsFilters: ActionFilter[] = teams
      .filter(({ checked }) => checked)
      .map((team) => ({
        displayValue: team.name,
        displayName: FILTER_DISPLAY_NAME.TEAM_ID,
        property: FILTER_PROPERTY.TEAM_ID,
        value: team.id || null,
        isFromTemplate: selectedTemplate?.team?.some((templateTeam) => {
          if (templateTeam === FilterTemplateTeam.None) {
            return templateTeam === team.name;
          }

          return (
            (
              getHomeOrAwayTeam(
                templateTeam as string,
                fixtureConfig,
              ) as FixtureConfigTeam
            )?.id === team.id
          );
        }),
      }));

    const flagFilter: ActionFilter[] =
      flagRadio === GENERIC_FILTER_VALUE.UNSET
        ? []
        : [
            {
              property: FILTER_PROPERTY.FLAG,
              value: flagRadio,
              displayName: FILTER_DISPLAY_NAME.FLAG,
              displayValue:
                flagRadio === GENERIC_FILTER_VALUE.TRUTHY
                  ? FILTER_DISPLAY_VALUE.FLAG.FLAGGED
                  : FILTER_DISPLAY_VALUE.FLAG.NOT_FLAGGED,
              isFromTemplate: selectedTemplate?.flag === flagRadio,
            },
          ];

    const commentFilter: ActionFilter[] =
      commentRadio === GENERIC_FILTER_VALUE.UNSET
        ? []
        : [
            {
              property: FILTER_PROPERTY.COMMENT,
              value: commentRadio,
              displayName: FILTER_DISPLAY_NAME.COMMENT,
              displayValue:
                commentRadio === GENERIC_FILTER_VALUE.TRUTHY
                  ? FILTER_DISPLAY_VALUE.COMMENT.WITH_COMMENT
                  : FILTER_DISPLAY_VALUE.COMMENT.WITH_OUT_COMMENT,
              isFromTemplate: selectedTemplate?.comment === commentRadio,
            },
          ];

    const playerFilter = makePlayerFilter(playerRadio, selectedPlayers);
    const actionsUpdatedFilter: ActionFilter[] =
      actionsNotUpdatedRadio === GENERIC_FILTER_VALUE.FALSY
        ? []
        : [
            {
              nonPropertyFilter: NON_PROPERTY_FILTER.HIDE_UPDATED_ACTIONS,
              value: actionsNotUpdatedRadio,
              displayName: FILTER_DISPLAY_NAME.ACTIONS_UPDATED,
              displayValue:
                actionsNotUpdatedRadio === GENERIC_FILTER_VALUE.TRUTHY
                  ? COMMON_STRING.ONLY_NOT_UPDATED
                  : COMMON_STRING.ALL,
            },
          ];

    const slaLatencyFilter: ActionFilter[] =
      slaLatency === GENERIC_FILTER_VALUE.UNSET
        ? []
        : [
            {
              nonPropertyFilter: NON_PROPERTY_FILTER.LATENCY,
              value: slaLatency,
              displayName: FILTER_DISPLAY_NAME.LATENCY,
              displayValue:
                slaLatency === GENERIC_FILTER_VALUE.TRUTHY
                  ? FILTER_DISPLAY_VALUE.LATENCY.WITH_LATENCY
                  : FILTER_DISPLAY_VALUE.LATENCY.WITH_OUT_LATENCY,
              isFromTemplate: selectedTemplate?.latency === slaLatency,
            },
          ];

    const slaMistakeFilter: ActionFilter[] =
      slaMistake === GENERIC_FILTER_VALUE.UNSET
        ? []
        : [
            {
              nonPropertyFilter: NON_PROPERTY_FILTER.MISTAKE,
              value: slaMistake,
              displayName: FILTER_DISPLAY_NAME.MISTAKE,
              displayValue:
                slaMistake === GENERIC_FILTER_VALUE.TRUTHY
                  ? FILTER_DISPLAY_VALUE.MISTAKE.WITH_MISTAKE
                  : FILTER_DISPLAY_VALUE.MISTAKE.WITH_OUT_MISTAKE,
              isFromTemplate: selectedTemplate?.mistake === slaMistake,
            },
          ];

    const composedFilters = [
      ...actionIdFilter,
      ...actionIdsFilter,
      ...selectedSendTypeIdsFilter,
      ...selectedPeriods,
      ...teamsFilters,
      ...playerFilter,
      ...flagFilter,
      ...commentFilter,
      ...actionsUpdatedFilter,
      ...slaLatencyFilter,
      ...slaMistakeFilter,
    ];

    onApply(composedFilters);
  };

  return (
    <Stack
      height='100%'
      alignItems='stretch'
      justifyContent='space-between'
      width={drawerWidth}
      maxWidth='90vw'
      divider={<Divider flexItem />}
    >
      <Stack
        py={2}
        gap={2}
        flex={1}
        overflow='auto'
        divider={<Divider flexItem />}
      >
        <Stack px={2} gap={1.5}>
          <Typography variant='subtitle2'>{COMMON_STRING.FILTER_BY}</Typography>
          <TextField
            type='text'
            size='small'
            value={actionId}
            onChange={(e) => setActionId(e.target.value)}
            label={COMMON_STRING.ACTION_ID}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {actionId && (
                    <IconButton
                      hidden={!actionId}
                      onClick={() => setActionId('')}
                    >
                      <Close />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <AutocompleteFilter
            options={actionTypeOptions}
            onChange={onActionTypesChange}
            selectedOptions={selectedActionTypes}
            label={COMMON_STRING.ACTION_TYPE}
          />

          <AutocompleteFilter
            options={sendTypeOptions}
            selectedOptions={selectedSendTypeIds}
            onChange={onSelectedSendTypeIdsChange}
            label={COMMON_STRING.SEND_TYPE}
          />

          <AutocompleteFilter
            options={matchPeriodOptions}
            selectedOptions={selectedPeriods}
            onChange={onSelectedPeriodChange}
            label={COLUMNS.PERIOD.name}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>{COMMON_STRING.TEAM}</Typography>
          <FormGroup>
            {teams.map((team, index) => (
              <FormControlLabel
                key={team.id || ''}
                label={team.name}
                control={
                  <Checkbox
                    size='small'
                    onChange={(_, checked) => onTeamChecked(index, checked)}
                    checked={team.checked}
                  />
                }
              />
            ))}
          </FormGroup>
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>{COMMON_STRING.PLAYER}</Typography>
          <GenericFilterRadios
            value={playerRadio}
            onValueChange={setPlayerRadio}
            labels={{
              unset: COMMON_STRING.ALL,
              falsy: COMMON_STRING.NONE,
              truthy: COMMON_STRING.ASSIGNED,
              unknown: COMMON_STRING.UNKNOWN,
            }}
          />

          <AutocompleteFilter
            options={playersOptions}
            onChange={onSelectedPlayersChange}
            selectedOptions={selectedPlayers}
            label={COMMON_STRING.PLAYERS}
            disabled={playerRadio !== GENERIC_FILTER_VALUE.TRUTHY}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>
            {FILTER_DISPLAY_NAME.COMMENT}
          </Typography>
          <GenericFilterRadios
            value={commentRadio}
            onValueChange={setCommentRadio}
            labels={{
              unset: COMMON_STRING.ALL,
              falsy: FILTER_DISPLAY_VALUE.COMMENT.WITH_OUT_COMMENT,
              truthy: FILTER_DISPLAY_VALUE.COMMENT.WITH_COMMENT,
            }}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>
            {FILTER_DISPLAY_NAME.FLAG}
          </Typography>
          <GenericFilterRadios
            value={flagRadio}
            onValueChange={setFlagRadio}
            labels={{
              unset: COMMON_STRING.ALL,
              falsy: FILTER_DISPLAY_VALUE.FLAG.NOT_FLAGGED,
              truthy: FILTER_DISPLAY_VALUE.FLAG.FLAGGED,
            }}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>{COMMON_STRING.ACTIONS}</Typography>
          <GenericFilterRadios
            value={actionsNotUpdatedRadio}
            onValueChange={setActionsNotUpdatedRadio}
            labels={{
              falsy: COMMON_STRING.ALL,
              truthy: COMMON_STRING.ONLY_NOT_UPDATED,
            }}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>{COMMON_STRING.LATENCY}</Typography>
          <GenericFilterRadios
            value={slaLatency}
            onValueChange={setSlaLatency}
            labels={{
              unset: COMMON_STRING.ALL,
              truthy: FILTER_DISPLAY_VALUE.LATENCY.WITH_LATENCY,
              falsy: FILTER_DISPLAY_VALUE.LATENCY.WITH_OUT_LATENCY,
            }}
          />
        </Stack>
        <Stack px={2}>
          <Typography variant='subtitle2'>
            {FILTER_DISPLAY_NAME.MISTAKE}
          </Typography>
          <GenericFilterRadios
            value={slaMistake}
            onValueChange={setSlaMistake}
            labels={{
              unset: COMMON_STRING.ALL,
              truthy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_MISTAKE,
              falsy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_OUT_MISTAKE,
            }}
          />
        </Stack>
      </Stack>

      <Stack p={2} gap={1} direction='row' justifyContent='space-between'>
        <Button variant='text' onClick={onCancel}>
          {COMMON_STRING.CANCEL}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={onConfirm}
          sx={{ flex: 1 }}
        >
          {COMMON_STRING.APPLY}
        </Button>
      </Stack>
    </Stack>
  );
};
