import {
  Actions,
  FixtureAction,
  FixtureConfig,
  FixtureSummary,
} from '@/service/types';
import { FixtureChecklistElement } from '@/service/types/checklist';

export interface ChecklistWarning {
  name: string;
  message: string;
}

export interface ChecklistCheckboxState {
  elementId: string;
  name: string;
  isChecked: boolean;
  isReadyToCheck: boolean;
  isDisabled: boolean;
  warnings: ChecklistWarning[];
  requiresConfirmation: boolean;
  index: number;
  timeTreshold: number | null;
  warning: string | null;
}

export enum CHECKLIST_REDUCER_ACTION {
  CHECKBOXES = 'checkboxes',
  VALIDATE = 'validate',
}

export type ChecklistCheckboxesAction = {
  action: CHECKLIST_REDUCER_ACTION.CHECKBOXES;
  payload: FixtureChecklistElement[];
};
export type ChecklistValidateAction = {
  action: CHECKLIST_REDUCER_ACTION.VALIDATE;
  payload: {
    actions: FixtureAction[];
    config: FixtureConfig;
    summary: FixtureSummary;
    fixtureActions: Actions;
  };
};

export type ChecklistReducerAction =
  | ChecklistCheckboxesAction
  | ChecklistValidateAction;

export type ChecklistContextState = ChecklistCheckboxState[];

export interface ChecklistContextValue {
  checkboxes: ChecklistContextState;
}
