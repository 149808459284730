import { FC, PropsWithChildren, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { SnackbarCloseAction } from '@/components/SnackbarActions/SnackbarCloseAction';

export const SnacksProvider: FC<PropsWithChildren> = ({ children }) => {
  const snackRef = useRef<SnackbarProvider>(null);
  return (
    <SnackbarProvider
      ref={snackRef}
      autoHideDuration={5000}
      maxSnack={6}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      action={(snackbarId) => <SnackbarCloseAction snackbarId={snackbarId} />}
    >
      {children}
    </SnackbarProvider>
  );
};
