import { useOktaAuth } from '@okta/okta-react';
import { Button, Stack, Typography, Paper, Container } from '@mui/material';
import { convertSnakeCaseTextToSentence } from '@/utils';
import { COMMON_STRING } from '@/constants/dictionary';

interface LoginErrorProps {
  error: string;
  errorDescription: string;
}

export const LoginError = ({ error, errorDescription }: LoginErrorProps) => {
  const { oktaAuth } = useOktaAuth();

  const onSignOut = () => oktaAuth.signOut();

  return (
    <Container maxWidth='sm' sx={{ py: 8 }}>
      <Paper sx={{ height: 'calc(50vh - 64px)', overflowY: 'auto', p: 4 }}>
        <Stack spacing={3} sx={{ height: '100%' }}>
          <Typography variant='h5' color='error'>
            {convertSnakeCaseTextToSentence(error)}
          </Typography>
          <Typography>{errorDescription}</Typography>
          <Typography variant='body2'>
            To access another account, click the logout button, then log in with
            the other account.
          </Typography>
          <Button onClick={onSignOut}>{COMMON_STRING.LOGOUT}</Button>
        </Stack>
      </Paper>
    </Container>
  );
};
