export type MTDev = {
  log: boolean;
};

export const SS_KEY = 'MT-log';
export const WELCOME_MESSAGE =
  '%cHello MT Dev.\nTo enable logs please set the window._mt.log = true.\nYou can proceed afterwards or reload the page if you missed some logs before that :)';

declare global {
  interface Window {
    _mt: MTDev;
  }
}
