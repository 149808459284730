import { MTDialog } from '@/components/common/MTDialog';
import { MapWrapper } from '@/components/Map/MapWrapper';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionDialogProps } from '@/components/ActionsDialog/types';

export const LocationDialog = ({ action, onClose }: ActionDialogProps) => {
  return (
    <MTDialog
      open={true}
      onClose={onClose}
      title={COMMON_STRING.DEVICE_LOCATION}
      maxWidth='xl'
      fullWidth
    >
      <MapWrapper
        coordinates={{
          lat: action!.metadata!.latitude,
          lng: action!.metadata!.longitude,
        }}
      />
    </MTDialog>
  );
};
