import useSWR from 'swr';
import { enqueueSnackbar } from 'notistack';
import {
  SERVICE_ENDPOINT,
  fetchFromMTService,
} from '../fetcher/monitoringToolService';
import { HTTP_STATUS_CODE } from '../constants';
import { ApiError } from '../ApiError';
import { UseFixtureConfigParams } from './useFixtureConfig';

export const useIsScorerFixture = ({
  token,
  fixtureId,
}: UseFixtureConfigParams) => {
  const { data, error } = useSWR<boolean, ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Fixture(fixtureId).isMyFixture.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
    }
  );

  return {
    data,
    error,
    isLoading: data === undefined && !error,
  };
};
