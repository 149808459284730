import { Button, DialogActions } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

interface StatusesButtonsProps {
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  disabled: boolean;
}
export const StatusesButtons = ({
  disabled,
  onSubmit,
  onClose,
}: StatusesButtonsProps) => {
  return (
    <DialogActions>
      <Button onClick={onClose} variant='text'>
        {COMMON_STRING.CANCEL}
      </Button>
      <Button disabled={disabled} variant='contained' onClick={onSubmit}>
        {COMMON_STRING.SAVE}
      </Button>
    </DialogActions>
  );
};
