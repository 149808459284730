import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  RefObject,
  SetStateAction,
} from 'react';
import { ActiveColumnType } from '@/components/StatsTableCells/types';

export const tableMouseOverHandler = (
  e: MouseEvent & ReactMouseEvent<HTMLTableSectionElement, MouseEvent>,
  colGroupRef: RefObject<HTMLTableColElement>,
  setActiveColumn: Dispatch<SetStateAction<ActiveColumnType>>
) => {
  const element = e.target as HTMLTableCellElement;
  const td =
    element.tagName === 'TD'
      ? element
      : (e.composedPath().find((el) => {
          return (el as HTMLTableCellElement).tagName === 'TD';
        }) as HTMLTableCellElement);
  if (!td || colGroupRef.current === null) return;
  colGroupRef.current.children[td.cellIndex].classList.add('hover');
  setActiveColumn(td.cellIndex);
};

export const tableMouseOutHandler = (
  activeColumn: ActiveColumnType,
  setActiveColumn: Dispatch<SetStateAction<ActiveColumnType>>,
  colGroupRef: RefObject<HTMLTableColElement>
) => {
  if (activeColumn === null || colGroupRef.current === null) return;
  colGroupRef.current.children[activeColumn].classList.remove('hover');
  setActiveColumn(null);
};
