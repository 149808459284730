import { MATCH_ACTION_SEND_TYPE_NAME, UNKNOWN_PLAYER_ID } from '@/constants';
import {
  FixtureAction,
  FixtureConfig,
  FixtureConfigTeam,
} from '@/service/types';
import { AnyPrimitive } from '@/types/common';
import { getKeyFromValue, getValueFromKey } from '@/utils/typeUtils';
import { SportTemplate } from '@/contexts/preferences/types';
import { makePlayerFilter } from './ActionFiltersForm';
import {
  FilterProperty,
  FILTER_DISPLAY_NAME,
  FILTER_PROPERTY,
  GenericFilterValue,
  GENERIC_FILTER_VALUE,
  RADIO_COMMENT_LABELS,
  RADIO_FLAG_LABELS,
  RADIO_ACTIONS_UPDATED,
  NON_PROPERTY_FILTER,
  RADIO_SLA_LATENCY,
  RADIO_SLA_MISTAKE,
} from './constants';
import {
  ActionFiterType,
  PlayerFilterValue,
  PlayerFilterValueArray,
  TemplateFormData,
} from './Template/types';
import { FilterTemplateSendType, FilterTemplateTeam } from './types';
import { ActionFilter } from './useActionsFilters';

export type PropertyFilters = {
  [index in keyof FixtureAction]?: PropertyFilter;
};
export type PropertyFilter = {
  values: any[];
  exclude: boolean;
};

export const isInFilters = (
  filters: ActionFilter[],
  property: FilterProperty,
  value: AnyPrimitive,
  checkFn?: (filter: ActionFilter) => boolean,
) => {
  return filters.some((filter) => {
    const checkFnResult = checkFn ? checkFn(filter) : true;
    return (
      filter.property === property && filter.value === value && checkFnResult
    );
  });
};

export const gatherPropertyValues = (filters: ActionFilter[]) => {
  return filters.reduce(
    (acc: PropertyFilters, { property, value, exclude }) => {
      const propOptions = acc[property!];
      if (!propOptions) {
        acc[property!] = { values: [value], exclude: !!exclude };
        return acc;
      }
      if (propOptions.values.includes(value)) {
        return acc;
      }
      propOptions.values.push(value);
      return acc;
    },
    {},
  );
};

export const doesItemMatchFilter = <T>(
  item: T,
  property: keyof T,
  filterValues: any[],
) => {
  const itemPropVal = item[property];
  if (
    filterValues.includes(GENERIC_FILTER_VALUE.TRUTHY) ||
    filterValues.includes(GENERIC_FILTER_VALUE.UNKNOWN)
  ) {
    if (typeof itemPropVal === 'object' && itemPropVal !== null) {
      return Object.keys(itemPropVal).length > 0;
    }
    return !!itemPropVal;
  }
  if (filterValues.includes(GENERIC_FILTER_VALUE.FALSY)) {
    // eslint-disable-next-line eqeqeq
    return itemPropVal == false || !itemPropVal;
  }

  if (filterValues.includes(undefined) || filterValues.includes(null)) {
    return itemPropVal === undefined || itemPropVal === null;
  }
  return filterValues.includes(itemPropVal);
};

export const applyFilter = <T>(
  collection: T[],
  filter: [property: keyof T, propertyFilter: PropertyFilter],
) => {
  const [property, propertyFilter] = filter;
  return collection.filter((item) => {
    const doesMatch = doesItemMatchFilter(
      item,
      property,
      propertyFilter.values,
    );
    return propertyFilter.exclude ? !doesMatch : doesMatch;
  });
};

export const filterActions = (
  filters: ActionFilter[],
  actions: FixtureAction[],
) => {
  if (!actions) return [];

  const filtersWithPropertyFilters = filters.filter(
    (filter) => filter.property,
  );

  const filtersPerProperty = Object.entries(
    gatherPropertyValues(filtersWithPropertyFilters),
  ) as [keyof FixtureAction, PropertyFilter][];

  const filteredActions = filtersPerProperty.reduce((acc, propertyFilter) => {
    return applyFilter(acc, propertyFilter);
  }, actions);
  return filteredActions;
};

export const removeGenericFilter = (
  filters: ActionFilter[],
  filterProperty: keyof FixtureAction,
) => {
  const newFilters = [...filters];
  const { TRUTHY, FALSY } = GENERIC_FILTER_VALUE;
  const genericFilterIndex = newFilters.findIndex(
    ({ property, value }) =>
      (property === filterProperty && value === TRUTHY) || value === FALSY,
  );
  genericFilterIndex !== -1 && newFilters.splice(genericFilterIndex, 1);
  return newFilters;
};

export const convertTemplateToFilters = (
  template: TemplateFormData | undefined,
  config: FixtureConfig | undefined,
): ActionFilter[] => {
  if (!template) {
    return [];
  }
  let filters: ActionFilter[] = [];
  const templateActions =
    template.actions?.values.map(
      (item) =>
        ({
          property: FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
          value: item.id,
          displayName: FILTER_DISPLAY_NAME.FIXTURE_ACTION_TYPE_ID,
          displayValue: item.name,
          exclude: template.actions?.hidden,
          isFromTemplate: true,
        }) as ActionFilter,
    ) ?? [];

  filters = filters.concat(templateActions);

  const templateSendTypes =
    template.sendTypes?.values.map((item) => ({
      property: FILTER_PROPERTY.SEND_TYPE_ID,
      value: Number(getKeyFromValue(item, MATCH_ACTION_SEND_TYPE_NAME)),
      displayName: FILTER_DISPLAY_NAME.SEND_TYPE_ID,
      displayValue: item,
      exclude: template.sendTypes?.hidden,
      isFromTemplate: true,
    })) ?? [];

  filters = filters.concat(templateSendTypes);

  const teams =
    template.team?.map((item) => ({
      property: FILTER_PROPERTY.TEAM_ID,
      value:
        item === FilterTemplateTeam.None
          ? undefined
          : (getHomeOrAwayTeam(item, config) as FixtureConfigTeam)?.id,
      displayName: FILTER_DISPLAY_NAME.TEAM_ID,
      displayValue:
        item === FilterTemplateTeam.None
          ? FilterTemplateTeam.None
          : (getHomeOrAwayTeam(item, config) as FixtureConfigTeam)?.name,
      isFromTemplate: true,
    })) ?? [];

  filters = filters.concat(teams);

  const player = template.player
    ? makePlayerFilter(template.player as unknown as GenericFilterValue, [])
    : undefined;

  player && filters.push({ ...player[0], isFromTemplate: true });

  const comment = template.comment
    ? ({
        property: FILTER_PROPERTY.COMMENT,
        value: template.comment,
        displayName: FILTER_DISPLAY_NAME.COMMENT,
        displayValue: getValueFromKey(template.comment, RADIO_COMMENT_LABELS),
        isFromTemplate: true,
      } as ActionFilter)
    : undefined;

  comment && filters.push(comment);

  const flag = template.flag
    ? ({
        property: FILTER_PROPERTY.FLAG,
        value: template.flag,
        displayName: FILTER_DISPLAY_NAME.FLAG,
        displayValue: getValueFromKey(template.flag, RADIO_FLAG_LABELS),
        isFromTemplate: true,
      } as ActionFilter)
    : undefined;

  flag && filters.push(flag);

  const actionsUpdated =
    template.actionsUpdated &&
    template.actionsUpdated !== GENERIC_FILTER_VALUE.FALSY
      ? ({
          nonPropertyFilter: NON_PROPERTY_FILTER.HIDE_UPDATED_ACTIONS,
          value: template.actionsUpdated,
          displayName: FILTER_DISPLAY_NAME.ACTIONS_UPDATED,
          displayValue: getValueFromKey(
            template.actionsUpdated,
            RADIO_ACTIONS_UPDATED,
          ),
          isFromTemplate: true,
        } as ActionFilter)
      : undefined;

  actionsUpdated && filters.push(actionsUpdated);

  const latency = template.latency
    ? ({
        nonPropertyFilter: NON_PROPERTY_FILTER.LATENCY,
        value: template.latency,
        displayName: FILTER_DISPLAY_NAME.LATENCY,
        displayValue: getValueFromKey(template.latency, RADIO_SLA_LATENCY),
        isFromTemplate: true,
      } as ActionFilter)
    : undefined;

  latency && filters.push(latency);

  const mistake = template.mistake
    ? ({
        nonPropertyFilter: NON_PROPERTY_FILTER.MISTAKE,
        value: template.mistake,
        displayName: FILTER_DISPLAY_NAME.MISTAKE,
        displayValue: getValueFromKey(template.mistake, RADIO_SLA_MISTAKE),
        isFromTemplate: true,
      } as ActionFilter)
    : undefined;

  mistake && filters.push(mistake);
  return filters;
};

export const convertFiltersToTemplate = (
  template: TemplateFormData,
  filters: ActionFilter[],
  config: FixtureConfig | undefined,
) => {
  filters.forEach((item) => {
    switch (item.property) {
      case FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID:
        const action: ActionFiterType = {
          id: item.value as number,
          name: item.displayValue,
        };
        template.actions = {
          hidden: !!item.exclude,
          values: [...(template.actions?.values ?? []), action],
        };
        break;
      case FILTER_PROPERTY.SEND_TYPE_ID:
        const sendType = item.displayValue as FilterTemplateSendType;
        template.sendTypes = {
          hidden: !!item.exclude,
          values: [...(template.sendTypes?.values ?? []), sendType],
        };
        break;
      case FILTER_PROPERTY.TEAM_ID:
        const team = getTeamValue(item.value, config);
        template.team = [...(template.team ?? []), team];
        break;
      case FILTER_PROPERTY.PLAYER_ID:
        item.value =
          item.value === UNKNOWN_PLAYER_ID
            ? GENERIC_FILTER_VALUE.UNKNOWN
            : item.value;
        item.value = item.value === undefined ? '' : item.value;

        if (isPlayerFilterValue(item.value)) {
          template.player = item.value as PlayerFilterValue;
        }
        break;
      case FILTER_PROPERTY.COMMENT:
        template.comment = item.value as GenericFilterValue;
        break;
      case FILTER_PROPERTY.FLAG:
        template.flag = item.value as GenericFilterValue;
        break;
    }
    switch (item.nonPropertyFilter) {
      case NON_PROPERTY_FILTER.HIDE_UPDATED_ACTIONS:
        template.actionsUpdated = item.value as GenericFilterValue;
        break;
    }
  });

  return template;
};

const getTeamValue = (
  value: AnyPrimitive,
  config: FixtureConfig | undefined,
) => {
  if (value === config?.awaySquad.team.id) {
    return FilterTemplateTeam.AwayTeam;
  }
  if (value === config?.homeSquad.team.id) {
    return FilterTemplateTeam.HomeTeam;
  }
  return FilterTemplateTeam.None;
};

export const getHomeOrAwayTeam = (
  value: string,
  config: FixtureConfig | undefined,
) => {
  if (value === FilterTemplateTeam.AwayTeam) {
    return config?.awaySquad.team;
  }
  if (value === FilterTemplateTeam.HomeTeam) {
    return config?.homeSquad.team;
  }
  return value;
};

const isPlayerFilterValue = (x: any): x is PlayerFilterValue =>
  PlayerFilterValueArray.includes(x);

export const isUniqueTemplateName = (
  sportTemplates: SportTemplate[] | undefined,
  templateName: string,
  id: string,
  sportId: number,
) => {
  if (!sportTemplates || sportTemplates.length === 0) {
    return true;
  }

  const sportTemplate = sportTemplates.find(
    (template) => template.sportId === sportId,
  );

  if (!sportTemplate) {
    return true;
  }

  const currentTemplate = sportTemplate.templateFilters?.find(
    (template) => template.id === id,
  );

  if (currentTemplate?.templateName === templateName) {
    return true;
  }

  const templateWitSameName = sportTemplate.templateFilters?.find(
    (template) => template.templateName === templateName && template.id !== id,
  );

  return !templateWitSameName;
};
