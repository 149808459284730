import { useContext } from 'react';
import { useDebounce } from 'usehooks-ts';
import { ScoringContext } from './context';

export function useUnseenActionsNumber() {
  const {
    state: { newFixtureActions },
  } = useContext(ScoringContext);
  const unseenActionsNumber = useDebounce(newFixtureActions.length, 100);
  return unseenActionsNumber;
}
