import { Fragment, useContext, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { ScoringContext } from '@/contexts/scoring/context';

import {
  StyledMenuItem,
  StyledMenuList,
} from '@/components/common/StyledMenuItems';
import { getSport } from '@/service/utils/getSport';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import {
  generateStatisticsTabs,
  StatisticsSubPage,
} from '@/components/FixtureTabs/helpers/generateStatisticsTabs';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';

export const FixtureStatistics = () => {
  const { permissions } = useContext(RolePermissionsContext);
  const {
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const [selectedSubPage, setSelectedSubPage] = useState<StatisticsSubPage>(
    StatisticsSubPage.Advanced
  );

  const sport = getSport(fixtureConfig);

  const statisticsTabs = useMemo(
    () => generateStatisticsTabs({ sportId: sport && sport.id, permissions }),
    [sport, permissions]
  );

  const isSubPageSelected = (subPage: StatisticsSubPage) =>
    subPage === selectedSubPage;

  return (
    <PermissionsChecker name='FixturePage.Statistics'>
      <Stack direction='row' display='flex' flex='1'>
        <StyledMenuList>
          {statisticsTabs.map((statisticsTab, index) => (
            <StyledMenuItem
              key={`stats-tabs-${statisticsTab.id}`}
              selected={isSubPageSelected(statisticsTab.id)}
              onClick={() => setSelectedSubPage(statisticsTab.id)}
            >
              {statisticsTab.name}
            </StyledMenuItem>
          ))}
        </StyledMenuList>
        {statisticsTabs.map(
          (statisticsTab) =>
            isSubPageSelected(statisticsTab.id) && (
              <Fragment key={`stats-components-${statisticsTab.id}`}>
                {statisticsTab.component}
              </Fragment>
            )
        )}
      </Stack>
    </PermissionsChecker>
  );
};
