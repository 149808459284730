import { MouseEventHandler, PropsWithChildren, useRef, useState } from 'react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import {
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  useTheme,
} from '@mui/material';
import EmojiPicker, {
  Emoji,
  EmojiClickData,
  EmojiStyle,
  Theme,
} from 'emoji-picker-react';
import ClearIcon from '@mui/icons-material/Clear';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { THEME_MODE } from '@/contexts/theme/constants';
import { MTEmojiType } from '../types';

type EmojiInputType = {
  onChange?: (value: MTEmojiType | null) => void;
  value?: MTEmojiType | null;
} & PropsWithChildren;

export const EmojiInput = ({ onChange, value, ...props }: EmojiInputType) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const theme = useTheme();

  const handleOnChange = (emojiInfo: EmojiClickData) => {
    const emoji: MTEmojiType = {};

    if (emojiInfo.names.some((name) => name.includes('flag'))) {
      emoji.country = emojiInfo.names
        .find((name) => name.includes('flag-'))
        ?.split('-')[1]
        .toLowerCase();
    }

    emoji.code = emojiInfo.unified;
    emoji.literal = emojiInfo.emoji;

    onChange?.(emoji);
    setOpen(false);
  };

  const handleOnClear: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onChange?.(null);
  };

  return (
    <>
      <TextField
        {...props}
        variant='outlined'
        size='small'
        value={''}
        inputRef={inputRef}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        sx={{ width: 95, height: 40 }}
        InputProps={{
          startAdornment: value ? (
            <InputAdornment position='start'>
              <Emoji unified={value?.code ?? ''} size={25} />
            </InputAdornment>
          ) : null,

          endAdornment: value ? (
            <InputAdornment
              position='end'
              sx={{
                ml: -0.5,
              }}
            >
              <IconButton onClick={handleOnClear} size='small'>
                <ClearIcon fontSize='small' />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment
              sx={{
                ml: -7.5,
              }}
              position='end'
            >
              <EmojiEmotionsOutlinedIcon
                onClick={() => setOpen(true)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  cursor: 'pointer',
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        aria-label={ARIA_LABEL.EMOJI_PICKER}
        open={open}
        anchorEl={inputRef?.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <EmojiPicker
          onEmojiClick={handleOnChange}
          autoFocusSearch
          theme={
            theme.palette.mode === THEME_MODE.LIGHT ? Theme.LIGHT : Theme.DARK
          }
          skinTonesDisabled
          emojiStyle={EmojiStyle.APPLE}
        />
      </Popover>
    </>
  );
};
