import { TableHead, TableRow } from '@mui/material';
import { ScoresheetTableHeaderCell } from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import { PLAYERS_TABLE_HEADER_KEYS } from '@/components/ScoresheetTables/PlayersTable/constants';

export const PlayersTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {PLAYERS_TABLE_HEADER_KEYS.map(({ key, subKeys }) => (
          <ScoresheetTableHeaderCell
            sx={{ padding: 0 }}
            rowSpan={!subKeys ? 2 : 1}
            colSpan={!subKeys ? 1 : subKeys.length}
            key={key}
          >
            {key}
          </ScoresheetTableHeaderCell>
        ))}
      </TableRow>
      <TableRow>
        {PLAYERS_TABLE_HEADER_KEYS.map(({ subKeys }) =>
          subKeys?.map((subKey) => (
            <ScoresheetTableHeaderCell
              colSpan={1}
              sx={{ padding: 0 }}
              key={subKey}
            >
              {subKey}
            </ScoresheetTableHeaderCell>
          ))
        )}
      </TableRow>
    </TableHead>
  );
};
