import CircularProgress from '@mui/material/CircularProgress';
import Stack, { StackProps } from '@mui/material/Stack';
import { FC } from 'react';
import { overlayBackgroundColorStyle } from './LoadingOverlay';

export const LoadingPlaceholder: FC<StackProps> = (props) => {
  return (
    <Stack
      flexGrow={1}
      height='100%'
      justifyContent='center'
      alignItems='center'
      {...props}
      sx={{
        ...overlayBackgroundColorStyle,
        ...props.sx,
      }}
    >
      <CircularProgress />
    </Stack>
  );
};
