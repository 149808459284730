import Grading from '@mui/icons-material/Grading';
import { Tooltip, IconButton, Badge } from '@mui/material';
import { FC, useContext } from 'react';
import { PANEL } from '@/contexts/UIState/types';
import { DICTIONARY } from '@/constants/dictionary';
import { UIStateContext } from '@/contexts/UIState/context';
import { ChecklistContext } from '@/contexts/checklist/context';
import { darkTheme } from '@/contexts/theme/monitoringTool.theme';
import { KeyboardButton } from '../common/KeyboardButton';

export const CHECKLIST_BUTTON_LABEL = 'Checklist';

export const ChecklistButton: FC = () => {
  const { setOpenedPanel } = useContext(UIStateContext);
  const { checkboxes } = useContext(ChecklistContext);

  const getChecklistIconColor = (): string => {
    if (!checkboxes.length) return 'inherit';
    if (checkboxes.some(({ warnings }) => warnings.length > 0)) {
      return darkTheme.palette.error.main;
    }
    if (!checkboxes.some(({ isChecked }) => !isChecked)) {
      return darkTheme.palette.success.main;
    }
    return 'inherit';
  };
  const checkboxesToCheck = checkboxes.reduce((acc, checkbox) => {
    if (!checkbox.isChecked && !checkbox.isDisabled) {
      acc++;
    }
    return acc;
  }, 0);

  return (
    <Tooltip
      arrow
      title={
        <>
          {DICTIONARY.COMMON.CHECKLIST}
          <KeyboardButton theKey='c' />
        </>
      }
    >
      <IconButton
        aria-label={CHECKLIST_BUTTON_LABEL}
        sx={{
          color: getChecklistIconColor(),
        }}
        onClick={() => setOpenedPanel(PANEL.CHECKLIST)}
      >
        <Badge color='error' badgeContent={checkboxesToCheck}>
          <Grading />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
