import { Box } from '@mui/material';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { FILER_TEMPLATE_COLUMN_QUANTITY } from '../constants';

type GridWrapProps = { columnCount?: number; children: React.ReactNode };

export const GridWrap = ({ columnCount, children }: GridWrapProps) => {
  return (
    <Box
      aria-label={ARIA_LABEL.GRID_WRAP}
      display='grid'
      gridTemplateColumns={`repeat(${
        columnCount ? columnCount : FILER_TEMPLATE_COLUMN_QUANTITY
      }, ${
        100 / (columnCount ? columnCount : FILER_TEMPLATE_COLUMN_QUANTITY)
      }%)`}
      columnGap={2}
      sx={{ width: '100%' }}
    >
      {children}
    </Box>
  );
};
