import { Stack } from '@mui/material';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { useContext, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { PreferencesContext } from '@/contexts/preferences/context';
import { VolumeSlider } from '@/components/common/VolumeSlider';

export const MASTER_VOLUME_LABEL = 'Master Volume';
export const NotificationMasterVolume = () => {
  const {
    soundsPreferences: { volume },
    actions: { updateSoundsPreferences },
  } = useContext(PreferencesContext);

  const [newVolume, setNewVolume] = useState(volume);
  const debouncedVolume = useDebounce(newVolume);

  const handleChangeVolume = (event: Event, newVolume: number | number[]) => {
    setNewVolume(newVolume as number);
  };

  useEffect(() => {
    if (volume === debouncedVolume) return;
    updateSoundsPreferences({ volume: debouncedVolume });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVolume, updateSoundsPreferences]);

  useEffect(() => {
    setNewVolume(volume);
  }, [volume]);

  return (
    <Stack gap={3} direction='row' alignItems='center'>
      <VolumeUp />
      <VolumeSlider
        aria-label={MASTER_VOLUME_LABEL}
        value={newVolume}
        onChange={handleChangeVolume}
      />
    </Stack>
  );
};
