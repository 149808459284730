import { Typography, Box } from '@mui/material';

interface LabelWithDataProps {
  label: string;
  data: string | number;
}

export const LabelWithData = ({ label, data }: LabelWithDataProps) => {
  return (
    <Box flex='1 0 0' data-testid='data-collectors-column'>
      <Typography variant='caption'>{label}</Typography>
      <Typography variant='body1'>{data}</Typography>
    </Box>
  );
};
