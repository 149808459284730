import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { BasketballStatsTableData } from '@/components/BasketballStatsTable/types';
import { FixtureConfig } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import { filterBasketballTableData } from '@/components/BasketballStatsTable/utils';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsContext } from '@/contexts/stats/context';
import { PlayersTableHeader } from '@/components/ScoresheetTables/PlayersTable/PlayersTableHeader';
import { CoachRow } from '@/components/ScoresheetTables/PlayersTable/Rows/CoachRow';
import { TotalsRow } from '@/components/ScoresheetTables/PlayersTable/Rows/TotalsRow';
import { generatePlayerRow } from '@/components/ScoresheetTables/PlayersTable/utils';
import { ARIA_LABEL } from '@/constants/ariaLabel';

interface PlayersTableProps {
  team?: BasketballStatsTableData;
  fixtureConfig?: FixtureConfig;
  teamId: string;
}

export const PlayersTable = ({
  team,
  fixtureConfig,
  teamId,
}: PlayersTableProps) => {
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);
  const {
    activeButtons: { quarter, statsType },
  } = useContext(StatsContext);

  const playersData = fixtureSummary
    ? (filterBasketballTableData({
        activeQuarter: quarter,
        activeType: STATS_BUTTONS.TYPE.PLAYER,
        activeTeam: teamId,
        activeStatsType: statsType,
        advancedSportStatistics: fixtureSummary.advancedSportStatistics,
        homeTeamId: fixtureSummary.homeTeam.id,
      }) as BasketballStatsTableData[])
    : [];

  return (
    <Stack gap={0.5} aria-label={ARIA_LABEL.PLAYERS_TABLE}>
      <Typography variant='h6' fontWeight={700}>
        {getTeamName(teamId, fixtureConfig, fixtureSummary)}
      </Typography>
      <TableContainer>
        <Table>
          <PlayersTableHeader />
          <TableBody>
            {playersData
              ?.map(({ statistics, playerId }) =>
                generatePlayerRow({
                  statistics,
                  playerId: playerId as string,
                  fixtureConfig,
                  fixtureSummary,
                  teamId,
                }),
              )
              .sort(
                (a, b) =>
                  a?.props.player.shirtNumber - b?.props.player.shirtNumber,
              )}
            <CoachRow teamStatistics={team?.statistics} />
            <TotalsRow teamStatistics={team?.statistics} />
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
