import { LevelLoadedData } from 'hls.js';
import { Dispatch, SetStateAction } from 'react';
import { FixtureAction, FixtureConfig } from '@/service/types';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import { formatDate } from '@/utils/formatDate/formatDate';

export const getStartOfStreamTimestamp = (
  data: LevelLoadedData,
  setStartOfStreamTimestamp: Dispatch<SetStateAction<number>>
) => {
  const m3u8Data = data.details.m3u8;
  const programDateTime = m3u8Data.match(/#EXT-X-PROGRAM-DATE-TIME:(.*?)\n/);
  if (programDateTime) {
    const dateTime = new Date(programDateTime[1]);
    setStartOfStreamTimestamp(dateTime.getTime());
  } else {
    setStartOfStreamTimestamp(1);
  }
};

export const getPositionInSecondsToPlayStreamFrom = (
  action: FixtureAction,
  startOfStreamTimestamp: number,
  fixtureStartTime?: string
) => {
  if (action && fixtureStartTime && startOfStreamTimestamp !== 0) {
    const streamStartPatter =
      new Date(fixtureStartTime).getTime() -
      new Date(startOfStreamTimestamp).getTime();
    return Math.floor(
      (new Date(action.timestamp).getTime() -
        new Date(fixtureStartTime).getTime()) /
        1000 +
        streamStartPatter / 1000 -
        15 // This is the time in seconds before the action that the video will start from (this could be configurable based on use case)
    );
  }
  return 1;
};

export const generateStreamingDialogTitle = (
  timestamp: string,
  fixtureActionTypeId: number,
  fixtureSeqNum: number,
  clockTime: string,
  fixtureConfig?: FixtureConfig
) => {
  const actionName = getActionName(fixtureActionTypeId, fixtureConfig);
  const actionTime = formatDate(timestamp);

  return `${fixtureSeqNum} - ${actionName} - ${clockTime} (${actionTime})`;
};
