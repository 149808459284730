import { PropsWithChildren, useEffect, useReducer } from 'react';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useUserRoles } from '@/service/hooks/useUserRoles';
import { RolePermissions } from '@/contexts/rolePermissions/types';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';

export const RolePermissionsProvider = ({ children }: PropsWithChildren) => {
  const token = useAuthToken();
  const {
    data: permissions,
    roleName,
    userId,
    isLoading,
  } = useUserRoles({ token });

  const rolePermissionsReducer = (
    state: RolePermissions,
    newValue: Partial<RolePermissions>
  ): RolePermissions => {
    return { ...state, ...newValue };
  };

  const [rolePermissionsState, dispatchRolePermissions] = useReducer(
    rolePermissionsReducer,
    {} as RolePermissions
  );

  useEffect(() => {
    if (permissions === undefined)
      return dispatchRolePermissions({
        permissions: permissions,
        isLoading: isLoading,
        roleName: roleName,
        userId: userId,
      });

    return dispatchRolePermissions({
      permissions,
      roleName,
      userId,
      isLoading: isLoading,
    });
  }, [permissions, isLoading, roleName, userId]);

  return (
    <RolePermissionsContext.Provider
      value={{
        ...rolePermissionsState,
        permissions: rolePermissionsState.permissions,
      }}
    >
      {children}
    </RolePermissionsContext.Provider>
  );
};
